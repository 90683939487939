import React, { useState, useContext, useEffect, useRef } from 'react'
import DictionaryContext from '../../../context/dictionaryContext'
import PatientContext from '../../../context/patientContext'
import UserPrefContext from '../../../context/userPrefContext'
import AuthContext from '../../../context/authContext'
import EFaceListRow from './EFaceListRow'

function EFaceList(props) {

    const dictCtx = useContext(DictionaryContext)
    const visCtx = useContext(PatientContext)
    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)
    
    const eFaceGetAllURL = `${process.env.REACT_APP_SERVER}/api/v1/the_efaces/get_graphs/${visCtx.pat_token}`

    const [efaces, setEfaces] = useState([])
    const [columnMapping, setColumnMapping] = useState([])


    const getAllEFaces = async() => {
        // console.log("EFaceList - getting all efaces")
        try {
            const response = await fetch(eFaceGetAllURL, {
                headers: {
                    'Authorization': `Bearer ${userPrefCtx.bearerToken}`,
                    auth: authCtx.authMode,
                },

            })

            if (response.status === 200) {
                const responseData = await response.json()
                // console.log("Get all e-Faces", responseData)
                // sort them by eface_date
                setEfaces(responseData.chart_data.sort((a,b) => new Date(a.eface_date) > new Date(b.eface_date) ? 1 : -1))
            } else {
                console.log("Failed to get all e-Faces", response)
            }
        } catch (e) {
            console.log("Failed to get all e-Faces", e)
        }

    }


    useEffect(() => {
        // console.log("EFaceList - getting column mappings")
        // setColumnMapping(props.columnMapping)
        if (dictCtx.dict_table.length > 0) {
            let headersMapping = dictCtx.dict_table.filter(entry => entry.group_display_name_short === 'the_eface')
            if (headersMapping.length > 0) {

            }
            setColumnMapping(JSON.parse(headersMapping[0].column_name))
        }
        
    }, [/*props.columnMapping*/ dictCtx.dict_table.length])

    useEffect(async() => {
        // console.log("EFaceList - props.updateDeleteCount", props.updateDeleteCount)
        if (userPrefCtx.bearerToken !== '') {
            getAllEFaces()
        }
        
    }, [userPrefCtx.bearerToken, props.updateDeleteCount])

    


    


    const onSelect = (eface) => {
        props.onSelect(eface)
    }

    return (
        <>
        <div>
            <table className="table table-sm table-borderless">
                <thead>
                    <tr>
                        {columnMapping.map((val, index) =>
                            <th scope="col" key={index} className='mx-4 px-10 text-left'>{val[0]}</th> 
                        )}
                    </tr>
                </thead>
                <tbody>
                    {efaces.map((ef, index) => 
                        <EFaceListRow key={index} columnMapping={columnMapping} eface={ef} onSelect={onSelect}/>
                    )}

                </tbody>
            </table>
            </div>
        </>
    )
}

export default EFaceList