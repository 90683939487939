import React, {useState, useContext, useEffect, useRef} from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ErrorDialogModal from '../modals/ErrorDialogModal'
import UserPrefContext from '../../../context/userPrefContext'
import AuthContext from '../../../context/authContext'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import SearchAnd from './SearchAnd'
import style from './SearchAndOr.module.css'
import {toLocalDateTime} from '../../../utils/Utils'
import ConfirmDialog from '../shared/ConfirmDialog'
import { BsFillPlusSquareFill, BsFillExclamationTriangleFill } from 'react-icons/bs'
import DOMPurify from 'dompurify'
import LoadingSpinner from '../shared/LoadingSpinner'
import { trackPromise } from 'react-promise-tracker'
import { saveAs } from 'file-saver'

function SearchAndModal(props) {

    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)

    const [searchFor1, setSearchFor1] = useState('')
    const [searchFor2, setSearchFor2] = useState('')

    const [selectedAnd1, setSelectedAnd1] = useState([])
    const [selectedNotLike1, setSelectedNotLike1] = useState([])
    const [selectedAnd2, setSelectedAnd2] = useState([])
    const [selectedNotLike2, setSelectedNotLike2] = useState([])

    const [showConfirm, setShowConfirm] = useState(false)
    const [confirmDialogText, setConfirmDialogText] = useState('')
    const [confirmDialogTitle, setConfirmDialogTitle] = useState('')

    const daysBefore = useRef()
    const daysAfter = useRef()

    const [errorMsg, setErrorMsg] = useState('')
    const [showError, setShowError] = useState(false);

    const [searchEnabled, setSearchEnabled] = useState(false)



    useEffect(() => {
        switch (props.reportBy.toLowerCase()) {
            case 'diagnosis':
                setSearchFor1('Diagnosis')
                setSearchFor2('Intervention')
                break
            case 'intervention':
                setSearchFor1('Intervention')
                setSearchFor2('Diagnosis')
                break
        }
    }, [props.reportBy])



    const selected1Changed = (selectedAnd, selectedNotLike) => {
        setSelectedAnd1(selectedAnd.map(se => se.value))
        setSelectedNotLike1(selectedNotLike.map(se => se.value))

        // enable/disable search button based on selected values: at least one AND conditions should be selected
        if (selectedAnd.length + selectedAnd2.length > 0) {
            setSearchEnabled(true)
        } else {
            setSearchEnabled(false)
        }
    }

    const selected2Changed = (selectedAnd, selectedNotLike) => {
        setSelectedAnd2(selectedAnd.map(se => se.value))
        setSelectedNotLike2(selectedNotLike.map(se => se.value))

        // enable/disable search button based on selected values: at least one AND conditions should be selected
        if (selectedAnd.length + selectedAnd1.length > 0) {
            setSearchEnabled(true)
        } else {
            setSearchEnabled(false)
        }
    }


    const closeConfirmDialog = (result) => {
        setShowConfirm(false)
        // handleClose()
    }

    const reportRequested = (report_type) => {

        const createReport = async() => {
            try {
                const reportUrl = `${process.env.REACT_APP_SERVER}/api/v1/web_services/${report_type}`
                await fetch(reportUrl, {
                    headers: {
                        Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                        // 'Access-Control-Expose-Headers': '*',
                        // 'Access-Control-Allow-Headers': '*',
                        auth: AuthContext.authMode,
                    }, 
    
                })
                .then((response) => {
                    const responseHeaders = [...response.headers]
                    const contentType = responseHeaders.findIndex(header => header[0] === 'content-type') >= 0 ? response.headers.get("content-type") : ''
                    const contentDisposition = responseHeaders.findIndex(header => header[0] === 'content-disposition') >= 0 ? response.headers.get("content-disposition") : ''
                    

                    console.log("reportRequested - content-type", contentType)
                    console.log("reportRequested - content-disposition", contentDisposition)
                    if (contentType && contentType.indexOf('application/json') >= 0) {
                        return response.json()
                            .then((data) => {
                                console.log("AdministrativeActions - reportRequested", data)
                            })
                    } else {
                        console.log("SearchAndModal - else branch for blob")
                        return response.blob()
                            .then((blob) => {
                                const excelData = new Blob([blob], { type: contentType })
                                const url = window.URL.createObjectURL(excelData)
                                const a = document.createElement('a')
                                a.style.display = 'none'
                                a.href = DOMPurify.sanitize(url, {SAFE_FOR_JQUERY:true})
                                // extract file name from disposition
                                const dispositionRegex = /filename=['"](?:.*?)['"]/gi
                                const match = contentDisposition.match(dispositionRegex)
                                if (!! match && match.length > 1) {
                                    a.download = `${match[1]}`
                                } else {
                                    a.download = `${report_type}_${toLocalDateTime(new Date(),'_').replace(/-/g, "_")}.xlsx`
                                }
                                // document.body.appendChild(a)
                                a.click()
                                window.URL.revokeObjectURL(url)
                            })
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                })
            }
            catch (e) {
                console.log(e)
            }
        }

        trackPromise(
            createReport()
        )
    }

    const handleSave = () => {

        console.log("SearchAndModal - selectedAnd1", selectedAnd1)
        console.log("SearchAndModal - selectedNotLike1", selectedNotLike1)
        console.log("SearchAndModal - selectedAnd2", selectedAnd2)
        console.log("SearchAndModal - selectedNotLike2", selectedNotLike2)

        let body = {}

        switch (props.reportBy.toLowerCase()) {
            case 'diagnosis':
                body = {
                    dict_diag_ids: selectedAnd1,
                    dict_intervention_ids: selectedAnd2,
                    not_like_dict_diag_ids: selectedNotLike1,
                    not_like_dict_intervention_ids: selectedNotLike2,
                }
                break
            case 'intervention':
                body = {
                    dict_diag_ids: selectedAnd2,
                    dict_intervention_ids: selectedAnd1,
                    not_like_dict_diag_ids: selectedNotLike2,
                    not_like_dict_intervention_ids: selectedNotLike1,
                }
                break
        }

        const submit = async (body) => {
            try {
                let url = `${process.env.REACT_APP_SERVER}/api/v1/web_services/diag_intvn_patient_ids_and/csv`
                let days = ''
                let and = ''
                if (!!daysBefore.current.value) {
                    days = `days_before=${daysBefore.current.value}`
                    and = '&'
                }

                if (!!daysAfter.current.value) {
                    days = `${days}${and}days_after=${daysAfter.current.value}`
                }

                if (days !== '') {
                    url = `${url}?${days}`
                }
                
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                        auth: authCtx.authMode,
                    },
                    body: JSON.stringify(body),
                })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Unprocessable Entity: ' + response.statusText);
                    }
                    const responseHeaders = [...response.headers]
                    const contentType = responseHeaders.findIndex(header => header[0] === 'content-type') >= 0 ? response.headers.get("content-type") : ''
                    const contentDisposition = responseHeaders.findIndex(header => header[0] === 'content-disposition') >= 0 ? response.headers.get("content-disposition") : ''
                    
    
                    // console.log("SearchAndModal - content-type", contentType)
                    // console.log("SearchAndModal - content-disposition", contentDisposition)
                    if (contentType && contentType.indexOf('application/json') >= 0) {
                        // console.log("AdministrativeActions - response", response, "response.status", response.status)
                        return response.json()
                            .then((data) => {
                                // console.log("AdministrativeActions - SearchAndModal", data, "response.status", response.status)
                                if (response.status === 200) {
                                    const reports = Object.keys(data)
                                    let blobs = []
                                    reports.forEach((key) => {

                                        const aReport = data[key]
                                        if (aReport.length > 0) {
                                            const lines = aReport.reduce((acc, curr) => {
                                                if (acc === "") {
                                                    let line = [Object.keys(curr).join(), Object.keys(curr).map(key => curr[key]).join()].join("\n")
                                                    return line
                                                } else {
                                                    return acc + "\n" + Object.keys(curr).map(key => curr[key]).join()
                                                }
                                            }, "")
                                            // console.log(key, "lines", lines)
                
                                            const blob = new Blob([lines], { type: 'text/csv' });

                                            blobs.push({blob:blob, filename: `${key}.csv`})
                                            // the following code (commented out) works for Firefox, Chrome and Edge but not for Safari
                                            // we have to use FileSaver.js library for this to work in Safari (and with delays for multiple files)
                                            
                                            // const url = URL.createObjectURL(blob);
                                            // const link = document.createElement('a');

                                            // link.href = DOMPurify.sanitize(url, {SAFE_FOR_JQUERY:true});
                                            // link.setAttribute('download', `${key}.csv`);
                                            // console.log("report link", link, "file",  `${key}.csv` )
                                            // document.body.appendChild(link);
                                            // link.click();
                                            //document.body.removeChild(link);

                                            // saveAs(blob, `${key}.csv`)
                                        }
 
                                        
                                        
                                    })

                                    // this is a hack for Safari - otherwise Safari saves just last file
                                    blobs.reverse()
                                    blobs.forEach((b,i) => {
                                        setTimeout(() => {
                                            saveAs(b.blob, b.filename)
                                        }, i*300)
                                    })
                                    handleClose()
                                } else if (response.status !== 200) {
                                    if (data.hasOwnProperty('detail')) {
                                        setConfirmDialogText(data.detail)
                                        setConfirmDialogTitle('Alert!')
                                        setShowConfirm(true)
                                    }
                                }
                            })
                    } else {
                        return response.blob()
                            .then((blob) => {
                                const excelData = new Blob([blob], { type: contentType })
                                const url = window.URL.createObjectURL(excelData)
                                const a = document.createElement('a')
                                a.style.display = 'none'
                                a.href = DOMPurify.sanitize(url, {SAFE_FOR_JQUERY:true})
                                // extract file name from disposition
                                const dispositionRegex = /filename=['"](?:.*?)['"]/gi
                                const match = contentDisposition.match(dispositionRegex)
                                if (!! match && match.length > 1) {
                                    a.download = `${match[1]}`
                                } else {
                                    a.download = `ReportByDiag-Interv_OR_${toLocalDateTime(new Date(),'@')}.csv`
                                }
                                // document.body.appendChild(a)
                                a.click()
                                window.URL.revokeObjectURL(url)



                                reportRequested('eface_report')
                                reportRequested('face_instrument_report')


                                handleClose()
                            })
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                    setShowError(true)
                })
            }
            catch (e) {
                console.log(e)
                setShowError(true)
            }
        }

        trackPromise(
            submit(body)
        )

        

    }


    const handleClose = () => {
        setErrorMsg('')
        setSelectedAnd1([])
        setSelectedNotLike1([])
        setSelectedAnd2([])
        setSelectedNotLike2([])


        props.onClose()
    }



    return (
        <>
        {!showConfirm &&
        <Modal 
            size="lg" 
            backdrop="static"
            show={props.show} 
            animation={false} 
            onHide={() => props.onClose('cancel')} 
            style={{ visibility: (showError) ? 'hidden' : 'visible' }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Report By {props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>       
                    <Row className='my-3'>
                        <SearchAnd searchFor={searchFor1} onSelect={selected1Changed} />
                    </Row>
                    <Row className='my-3'> 
                        <SearchAnd searchFor={searchFor2} onSelect={selected2Changed} />
                    </Row>
                    
                    
                    <Row className='my-3'>
                        <div className={`${style['section-no-border']} mx-auto `} >
                            <Row >
                                <Form.Group as={Col}>
                                    <Form.Label htmlFor="days_before" className={style['label-bold']}>Days Before</Form.Label>
                                    <Form.Control
                                        type='number'
                                        name="days_before"
                                        ref={daysBefore}
                                    />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label htmlFor="interventions" className={style['label-bold']}>Days After</Form.Label>
                                    <Form.Control
                                        type='number'
                                        name="days_after"
                                        ref={daysAfter}
                                    />
                                </Form.Group>
                            </Row>
                        </div>
                    </Row>
                    
                    <Row className='my-3'>
                        <p className={style.errorMsg}>{errorMsg}</p>
                    </Row>

                
                </Form>
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <div className="inline-block text-left">
                    <Button className='mx-2' variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </div>

                <div className='inline-block text-right'>
                    <Button 
                        className='mx-2' 
                        variant="primary" 
                        onClick={handleSave} 
                        /*disabled={selectedDiagnoses.length + selectedInterventions.length === 0}*/
                        disabled={!searchEnabled}
                        >
                        Generate Report
                    </Button>
                </div>
            </Modal.Footer>
            <LoadingSpinner size='100px' />
        </Modal>
        }
        <ConfirmDialog 
            show={showConfirm}
            iconColor='orange'
            icon={<BsFillExclamationTriangleFill />}
            title={confirmDialogTitle}
            // modal_class='diags-modal-delete-confirm-modal'
            title_class={style['alert-title']}
            // footer_class='diags-modal-delete-confirm-title'
            prompt={confirmDialogText}
            prompt_class={style['alert-prompt']}
            cancel_button_text=''
            confirm_button_text='OK'
            confirm_button_variant='primary'
            onClose={(result) => closeConfirmDialog(result)}
        />
        <ErrorDialogModal
          setOpen={showError}
          close={() => setShowError(false)}
          componentName="Report by Diag-Int AND Search"
        />
        </>
    )
}

export default SearchAndModal
