import React, {useState, useContext, useEffect, useRef} from 'react'
import Form from 'react-bootstrap/Form'
import AsyncSelect, { useAsync } from 'react-select/async'
import UserPrefContext from '../../../context/userPrefContext'
import AuthContext from '../../../context/authContext'
import CompanyContext from '../../../context/companyContext'

function SelectPatient(props) {

    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)
    const companyCtx = useContext(CompanyContext)
    const [error, setError] = useState('')
    const [headerStyle, setHeaderStyle] = useState('')
    const [headerText, setHeaderText] = useState('Select Patient:')

    const defaultRef = useRef(null)

    useEffect(() => {
        setError(props.error)
        // console.log("!!error", !!error, "error", error)
    }, [props.error])

    useEffect(() => {
        if (props.headerStyle !== undefined) {
            setHeaderStyle(props.headerStyle)
        }
        if (props.headerText !== undefined) {
            setHeaderText(props.headerText)
        }

    }, [props])

    const dropdownOptions = (e) => {
        // console.log('this is the value of e in dropdown options = ', e);
        return ` ${e.first_name} ${e.last_name}, MRN: ${e.local_site_mrn}, Gender: ${e.gender}, DOB: ${new Date(e.dob).toISOString().split("T")[0]}`
    }

    const searchHandler = async (searchString) => {
        // console.log('searchHandler', searchString);
        // setEventInput(e);
        props.onErrorReset()
        const trimmedSearch = searchString.replace(", ", ",").replace(" ,", ",")
        
    
        //const inputValueArray = trimmedSearch.split(',');
        //console.log(inputValueArray)
    
            const regex = /\d+/g; // regular expression to match digits
            let matches = searchString.match(regex)
        
            const inputValueArray = searchString.split(',');
            //console.log(inputValueArray)
        
            const splitInputValueArrayBySpace = searchString.split(' ');

            //const url = `${process.env.REACT_APP_SERVER}/api/v1/search_services/patient_search_with_site_loc?search_token=${trimmedSearch}&patient_search_mode=${companyCtx.company.patient_search_mode}`
            const url = `${process.env.REACT_APP_SERVER}/api/v1/search_services/patient_search_with_site_loc_epic?search_token=${trimmedSearch}&patient_search_mode=${companyCtx.company.patient_search_mode}`


            if((authCtx.authMode === 'DB') && (searchString.length > 0)){
                //console.log(authCtx.authMode)
          
                const response = await fetch(url, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                    auth: authCtx.authMode,
                  },
                })
          
                const jsonResponse = await response.json()
                if ( jsonResponse.response_code === 1) {
                  //console.log(jsonResponse.response_data)
                  return jsonResponse.response_data
                } else{
                    //console.log('cannot find anything');
                }
              }

              else if (((authCtx.authMode === 'AD') || (authCtx.authMode === 'OKTA')) && ((inputValueArray.length > 1 && inputValueArray[0].trim().length > 1 && inputValueArray[1].trim().length > 0) ||
              (splitInputValueArrayBySpace.length > 1 && splitInputValueArrayBySpace[0].trim().length > 1 && splitInputValueArrayBySpace[1].trim().length > 0) ||
              (matches && matches[0]?.length >= 3)
              )) {

            // console.log('sending request', trimmedSearch)

            // const url = `${process.env.REACT_APP_SERVER}/api/v1/search_services/patient_search_with_site_loc?search_token=${trimmedSearch}&patient_search_mode=${companyCtx.company.patient_search_mode}`
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                    auth: authCtx.authMode,
                },
            })
    
            const jsonResponse = await response.json()
            if ( jsonResponse.response_code === 1) {
                // console.log(jsonResponse.response_data)
                return jsonResponse.response_data
            }
        }
    }




    const handleChange = async (e) => {
        // e is null if the field is cleared
        if (e === null) {
            props.onClear()
        } else  {
            // console.log("SendSurveyByEmail handleChange - e", e)

            const postURL = `${process.env.REACT_APP_SERVER}/api/v1/patients/creat_patient_with_mrns`
    
            const response = await fetch(postURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                    auth: authCtx.authMode,
                },
                body: JSON.stringify({
                    emr_mrn: e.local_site_mrn,
                    first_name: e.first_name,
                    last_name: e.last_name,
                    gender: e.gender,
                    dob: new Date(e.dob).toISOString().split("T")[0],
                    all_mrn: e.all_mrn,
                })
            })
            .then((res) => res.json())
            .then((data) => {
                // console.log('patient created: ', data)
   
                // call parent method
                props.onChange(data)
    
            })
            .catch((err) => {
                console.log(err.message);
            })
        }
        
      
        
    }


    return (
        <Form.Group>
            <Form.Label htmlFor="search" className={headerStyle}>{headerText}
            {/* {!!error  &&
                <span className='send-survey-error'>{error}</span>
            } */}
            </Form.Label>
            <AsyncSelect
                ref={props.forwardedRef !== undefined ? props.forwardedRef : defaultRef}
                id='search'
                isClearable
                loadOptions={searchHandler}
                placeholder={"Type Name (Last, First)"}
                getOptionValue={dropdownOptions}
                getOptionLabel={dropdownOptions}
                onChange={handleChange}
                classNames={{
                    // control: () => !!error ? 'red-border' : ''
                    control: () => !!error ? 'select-patient-red-border' : ''
                }}
                autoFocus={props.autoFocus !== undefined ? props.autoFocus: false}
            />
            {!!error  &&
                <span className='invalid-feedback select-patient-is-invalid'>{error}</span>
            }
        </Form.Group>
    )

}

export default SelectPatient
