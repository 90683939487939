import React, {useState, useContext, useEffect, useRef} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ErrorDialogModal from '../modals/ErrorDialogModal'
import DictionaryContext from '../../../context/dictionaryContext'
import UserPrefContext from '../../../context/userPrefContext'
import PatientContext from '../../../context/patientContext'
import AuthContext from '../../../context/authContext'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {useReactToPrint} from 'react-to-print'
import style from './FollowupGroup.module.css'

import FollowupGroup from './FollowupGroup'
import ConfirmDialog from '../shared/ConfirmDialog'
import {BsSignStopFill,BsFillExclamationTriangleFill } from 'react-icons/bs' 

function FollowupModal(props) {

    const patCtx = useContext(PatientContext)
    const dictCtx = useContext(DictionaryContext)
    const userPreferenceCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)
    const [questions, setQuestions] = useState()
    const [groups, setGroups] = useState([])
    const [modalTitle, setModalTitle] = useState('')
    const [showError, setShowError] = useState(false)


    const followupsURL = (id_or_token) =>
    `${process.env.REACT_APP_SERVER}/api/v1/followup_sheets/${id_or_token}`


    const group_name = 'follow_up_sheet'

    const printRef = useRef()

    const [fuValues, setFuValues] = useState({})
    const [showConfirm, setShowConfirm] = useState(false)
    const [errors, setErrors] = useState([]) 



    useEffect(() => {
        // temp fixes
        let allQuestions = dictCtx.dict_modal.filter(dm => dm.group_display_name_short === group_name && dm.is_active)
        

        allQuestions = allQuestions
            .filter(dm => dm.group_display_name_short === group_name && dm.is_active)
            .sort((dm1, dm2) => dm1.group_id * 1000 + dm1.sort_position > dm2.group_id * 1000 + dm2.sort_position ? 1 : -1)



        // allQuestions = dictCtx.dict_modal
        //     .filter(dm => dm.group_display_name_short === group_name && dm.is_active)
        //     .sort((dm1, dm2) => dm1.group_id * 1000 + dm1.sort_position > dm2.group_id * 1000 + dm2.sort_position ? 1 : -1)
        // console.log("Follow Up dictionary", allQuestions) 
        // remove first element
        allQuestions.shift()
        setQuestions(allQuestions)

        // get group_ids of questions (only one id per group)
        const groups_c = allQuestions
            .map((oneq, index, qs) => (index > 0 && qs[index-1].group_id === oneq.group_id) ? null : oneq.group_id)
            .filter(oneq => oneq !== null)
        setGroups(groups_c)

        if (allQuestions.length > 0) {
            setModalTitle(`${allQuestions[0].modal_header} - ${patCtx.pat_last}, ${patCtx.pat_first}`)
        }
        let fuVals = {}
        allQuestions.forEach(q => {
            if (!!q.question_type && q.question_type.startsWith('[')) {
                let qtypes = JSON.parse(q.question_type)
                // if question_type is array of checkboxes set as empty array
                if (qtypes.length > 0 && qtypes[0] === 'checkbox') {
                    if (props.mode === 'edit') {
                        fuVals[q.display_name_short] = props.fu[q.display_name_short].split(',').filter(v => v !== '')
                    } else {
                        // mode = 'new'
                        fuVals[q.display_name_short] = []
                    }
                } else if (qtypes.length > 0 && qtypes[0] === 'input' && !!q.question_option_values && q.question_option_values.startsWith('[')) {
                    let qoptvals = JSON.parse(q.question_option_values)
                    if (qoptvals.length > 0) {
                        qoptvals.forEach(qov => {
                            fuVals[qov] = props.mode === 'edit' ? props.fu[qov] : ''
                        })
                        // if (props.mode === 'edit') {
                        //     fuVals[q.display_name_short] = props.fu[q.display_name_short].split(',').filter(v => v !== '')
                        // } else {
                        //     // mode = 'new'
                        //     fuVals[q.display_name_short] = []
                        // }
                    }

                } else {
                    if (props.mode === 'edit') {
                        fuVals[q.display_name_short] = props.fu[q.display_name_short]
                    } else {
                        // mode = 'new'
                        fuVals[q.display_name_short] = ''
                    }
                }
            } else {
                fuVals[q.display_name_short] = ''
            }

            if (!!q.question_type_sub_1 && q.question_type_sub_1.startsWith('[')) {
                let qsubtypes = JSON.parse(q.question_type_sub_1)
                // if question_type is array of checkboxes set as empty array
                if (qsubtypes.length > 0 && qsubtypes[0] === 'checkbox') {
                    if (props.mode === 'edit') {
                        fuVals[q.display_name_short_sub_1] = props.fu[q.display_name_short_sub_1].split(',').filter(v => v !== '')
                    } else {
                        // mode = 'new'
                        fuVals[q.display_name_short_sub_1] = []
                    }
                } else {
                    if (props.mode === 'edit') {
                        fuVals[q.display_name_short_sub_1] = props.fu[q.display_name_short_sub_1]
                    } else {
                        // mode = 'new'
                        fuVals[q.display_name_short_sub_1] = ''
                    }
                }
            } else if (!!q.question_type_sub_1) {
                fuVals[q.display_name_short_sub_1] = ''
            }

        })






        setFuValues(fuVals)
        // console.log("FollowupModal - fuVals", fuVals)

    }, [patCtx.pat_token, props.fu, props.mode])


    const handleClose = () => {
        // setErrors([])
        props.onClose('list')
    }



    const updateResult = async(operation,result) => {
        let method = ''
        let url = ''
        // new_mode controls what will happen on closing this dialog:
        // 'hide' - will go to Dashboard
        // 'list' - will go back to the fu list dialog
        let new_mode = 'hide' // default
        switch(operation) {
            case 'new':
                url = followupsURL(patCtx.pat_token)
                method = 'POST'
                new_mode = 'hide'
            break;

            case 'edit':
                url = followupsURL(props.fu.id)
                method = 'PATCH'
                new_mode = 'hide'
            break;

            case 'delete':
                url = followupsURL(props.fu.id)
                method = 'DELETE'
                new_mode = 'hide'
            break;
        }

        const responseData = await fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userPreferenceCtx.bearerToken}`,
                auth: authCtx.authMode,
            },
            body: JSON.stringify(result),
        })
        //.then((res) => res.json())
        .then((res) => {
          if (!res.ok) {
             throw new Error('Unprocessable Entity: ' + res.statusText);
          }
          return res.json();
        })
        .then((data) => {
            // console.log("Provider Followup method", method, "returned", data)
            props.onClose(new_mode)
        })
        .catch((err) => {
            console.log(err.message)
            setErrors([err.message])
            
        });
    }

    const handleDelete = () => {
        // updateResult('delete',null)
        setShowConfirm(true)
    }

    const handleSave = () => {
        let result = {}
        questions.forEach((q,indx) => {
            let elems = Array.from(document.getElementsByName(q.display_name_short))

            if (!!q.display_name_short_sub_1) {
                let elems2 = Array.from(document.getElementsByName(q.display_name_short_sub_1))
                if (elems2.length > 0) {
                    elems = elems.concat(elems2)
                    // console.log("Saving - elems", elems)
                }
            }
            if (elems.length > 0) {
                // console.log("Found elements:", elems)
                elems.forEach((elem,elindx) => {
                    // console.log(`Found element ${q.display_name_short}`, elem, "type", elem.nodeName)
                    switch (elem.nodeName) {
                        case 'TEXTAREA': 
                            // console.log("Value", elem.value)
                            // textarea may belong to a "group" of textareas defined within one dictionary entry. In this case 
                            // question_option_values are array of mapped properties - check for data-mappedto
                            let mappedto = elem.dataset.mappedto
                            if (!!mappedto) {
                                result[mappedto] = elem.value
                            } else {
                                result[q.display_name_short] = elem.value
                            }
                            
                        break;
    
                        case 'INPUT': 
                            switch (elem.type) {
                                case 'text':
                                    // console.log("Value", elem.value)
                                    result[q.display_name_short] = elem.value
                                break;
                                case 'checkbox':
                                    // array of values
                                    // if (!!!result[q.display_name_short]) {
                                    //     // create empty array
                                    //     result[q.display_name_short] = []
                                    // }

                                    if (!!!result[elem.name]) {
                                        // create empty array
                                        result[elem.name] = []
                                    }
                                    if (elem.checked) {
                                        // console.log("checkbox: q.display_name_short - checked", q.display_name_short, "Value", elem.value, "index", elindx)
                                        // don't add if it's already there - when we go over subN elements they all have the same display_name_short,
                                        // so the same elements will be in the loop
                                        // if (!result[q.display_name_short].includes(elem.value)) {
                                        //     result[q.display_name_short].push(elem.value)
                                        // }
                                        if (!result[elem.name].includes(elem.value)) {
                                            result[elem.name].push(elem.value)
                                        }
                                    }
                                break;
                                case 'radio':
                                    if (elem.checked) {
                                        // console.log("Value", elem.value)
                                        result[q.display_name_short] = elem.value
                                    }
                                break;
                            }
                            
                        break;
    
                        
                    }
                })

                

            }
        })      
        
        // console.log("Follow Up result", result)
        const operation = props.mode
        updateResult(operation,result)

    }

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        // onBeforePrint: () => {
        //     console.log("Before printing -set up title")
        //     printTitleRef.current = modalTitle
        // },
        documentTitle: modalTitle,
        // onAfterPrint: () => {
        //     console.log("Printed, title is", printTitleRef.current)
        //     printTitleRef.current = ''
        // }
    })


    const closeConfirmDialog = (result) => {
        setShowConfirm(false)
        if (result === 'confirm') {
            if (props.mode === 'edit' && props.fu.id !==null) {
                updateResult('delete',null)
            }

        }
    }


    const isSeparatorLineNeeded = (group_id) => {
        let groupQuestions=questions.filter(q => q.group_id === group_id)
        // check if question_type contains "input"
        let line = true
        groupQuestions.forEach(gq => {
            // console.log("gq", gq)
            if (!!gq.question_type && gq.question_type.includes("\"input\"")) {
                line = false
            }
        })

        line = false // testing
        return line
    }


    return (
        <>
        <Modal size="fullscreen" show={props.show} onHide={handleClose} animation={false} scrollable={true}
                style={{ visibility: (showError) ? 'hidden' : 'visible' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title><h4>{modalTitle}</h4></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div ref={printRef} >
                        <h3 className={style['print-only']}>{modalTitle}</h3>
                        {groups.filter(group_id => group_id <= 9).map((group_id, index) => 
                            <div key={index}>
                                <FollowupGroup 
                                    key={index}
                                    group_id={group_id}
                                    questions={questions.filter(q => q.group_id === group_id )}
                                    // onChange={valueChanged}
                                    // readOnly={readOnly}
                                    // data={capevData}
                                    fu={fuValues}
                                />
                                {isSeparatorLineNeeded(group_id) ? <hr></hr> : null}
                            </div>
                        )}


                        <div className='row'>
                            <div className='col'>
                                {groups.filter(group_id => group_id > 9 && group_id <= 12).map((group_id, index) => 
                                    <div key={index}>
                                        <FollowupGroup 
                                            key={index}
                                            group_id={group_id}
                                            questions={questions.filter(q => q.group_id === group_id )}
                                            // onChange={valueChanged}
                                            // readOnly={readOnly}
                                            // data={capevData}
                                            fu={fuValues}
                                        />
                                        {isSeparatorLineNeeded(group_id) ? <hr></hr> : null}
                                    </div>
                                )}
                            </div>
                            <div className='col'>
                                {groups.filter(group_id => group_id > 12).map((group_id, index) => 
                                    <div key={index}>
                                        <FollowupGroup 
                                            key={index}
                                            group_id={group_id}
                                            questions={questions.filter(q => q.group_id === group_id )}
                                            // onChange={valueChanged}
                                            // readOnly={readOnly}
                                            // data={capevData}
                                            fu={fuValues}
                                        />
                                        {isSeparatorLineNeeded(group_id) ? <hr></hr> : null}
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* {groups.map((group_id, index) => 
                            <div key={index}>
                                <FollowupGroup 
                                    key={index}
                                    group_id={group_id}
                                    questions={questions.filter(q => q.group_id === group_id )}
                                    // onChange={valueChanged}
                                    // readOnly={readOnly}
                                    // data={capevData}
                                    fu={fuValues}
                                />
                                {isSeparatorLineNeeded(group_id) ? <hr></hr> : null}
                            </div>
                        )} */}
                    </div>




                </Modal.Body>

                <Modal.Footer className='justify-content-between'>
                    <div className="inline-block text-left">
                        <Button className="mx-2" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {props.mode !== "new" && 
                            <Button
                                className="mx-2 danger-button"
                                variant="secondary"
                                onClick={handleDelete}
                            >
                                Delete
                            </Button>
                        }
                    </div>

                    {/* {!isEpicVisit && !readOnly && */}
                        <div className="inline-block text-right">
                            <Button className='mx-2' variant="secondary" onClick={handlePrint}>
                                Print
                            </Button>

                            <Button className="mx-2" variant="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </div>

                </Modal.Footer>
            </Modal>
            

            <ConfirmDialog 
                show={showConfirm}
                title='Please confirm'
                modal_class='delete-confirm-modal'
                title_class='delete-confirm-title'
                footer_class='delete-confirm-title'
                prompt='The Provider Follow up form will be permamently deleted. Are you sure you want to delete it?'
                prompt_class='diags-modal-delete-confirm-prompt'
                // cancel_button_text='Do not delete!'
                confirm_button_text='Delete'
                confirm_button_variant='danger'
                onClose={(result) => closeConfirmDialog(result)}
                icon={<BsSignStopFill />}
                iconColor='red'
                iconSize='1.3em'
            />


            {errors.length > 0 &&
                <ConfirmDialog 
                    show={errors.length > 0}
                    iconColor='red'
                    icon={<BsFillExclamationTriangleFill />}
                    title='Provider Follow up - Error'
                    // modal_class='diags-modal-delete-confirm-modal'
                    title_class={style['alert-title']}
                    // footer_class='diags-modal-delete-confirm-title'
                    prompt='Something Went Wrong. Please Contact Admin'
                    prompt_class={style['alert-prompt-black']}
                    cancel_button_text=''
                    confirm_button_text='Close'
                    confirm_button_variant='primary'
                    onClose={() => setErrors([])}
                />
            }
        </>
    )


}

export default FollowupModal
