import React, {useState, useContext, useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import UserPrefContext from '../../../context/userPrefContext'
import DictionaryContext from '../../../context/dictionaryContext'
import AuthContext from '../../../context/authContext'
import SelectPatient from '../shared/SelectPatient'
import ErrorDialogModal from '../modals/ErrorDialogModal'
import styles from './SendSurveyByEmail.module.css'
import sharedStyles from '../../../SharedStyles.module.css'

function SendSurveyByEmail(props) {

    const userPrefCtx = useContext(UserPrefContext)
    const dictContext = useContext(DictionaryContext)
    const authCtx = useContext(AuthContext)

    const [email, setEmail] = useState('')
    const [surveys, setSurveys] = useState([])
    const [surveyToSend, setSurveyToSend] = useState('')

    const [errors, setErrors] = useState({})
    const [showError, setShowError] = useState(false)
    const [emailMatchesDB, setEmailMatchesDB] = useState(false)
    

    const [patientData, setPatientData] = useState(null)


    useEffect(() => {
        const uniqueSurveys = [... new Set(dictContext.dict_intake_form_question
            .filter(ifquest => ifquest.DictIntakeForm.is_send_email === true)
            .map(ifquest => (JSON.stringify({
            name: ifquest.DictIntakeForm.display_name,
            short_name: ifquest.DictIntakeForm.display_name_short,

            post_api_url: ifquest.DictIntakeForm.post_api_url,
            user_dept: ifquest.DictIntakeForm.user_dept,
            dict_company_code_id: ifquest.DictIntakeForm.dict_company_code_id,
            company_name_display_name: ifquest.DictCompanyCode.company_name,
        }))))].map(s => JSON.parse(s))

        // console.log("Surveys:", uniqueSurveys)
        setSurveys(uniqueSurveys)
        // set default survey as the first in the list
        setSurveyToSend(uniqueSurveys[0]?.short_name || '')
    }, [dictContext.dict_intake_form_question])

  
    
    // check fro errors and send survey email
    const handleSend = (event) => {
        event.preventDefault()
        let error = false
        setEmailMatchesDB(false)
        // resetAllErrors()
        let formErrors = { email: null, survey: null, patient: null}
        if (patientData === null) {
            formErrors = {...formErrors, patient: 'Patient is not selected'}
            error = true
        } 
        const survey = document.getElementById('survey_selection')
        if (survey.value === '') {
            formErrors = {...formErrors, survey: 'Survey is not selected'}
            error = true
        } 
        if (email === '') {
            formErrors = {...formErrors, email: 'Email address is not populated'}
            error = true
        } else if (validateEmail(email) !== true) {
            formErrors = {...formErrors, email: 'Invalid email address'}
            error = true
        } else if (email !== patientData.email) {
            formErrors = {...formErrors, email: 'Email is not in the database. Click Update Email button to save new email'}
            error = true
        } else {
            // setEmailMatchesDB(true)
        }

        setErrors(formErrors)

        if (!error) {
            // alert('Not implemented yet!')
            sendSurvey()
            //handleClose()
        }
    }


    const sendSurvey = async () => {
        // console.log("Send Survey by Email surveys", surveys, "surveyToSend", surveyToSend)
        let srvy = surveys.filter(s => s.short_name === surveyToSend)[0]
        // console.log("Send Survey by Email srvy", srvy)
        const url = `${process.env.REACT_APP_SERVER}/api/v1/patient_survey_mappings/${patientData.patient_token}`
        const body={
            patient_token: patientData.patient_token,
            survey_name: surveyToSend,
            survey_name_display: srvy.name,
            company_dept: srvy.user_dept,
            dict_company_code_id: srvy.dict_company_code_id,
            company_name_display_name: srvy.company_name_display_name,
        }

        // console.log("Send Survey by Email body", body)

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                auth: authCtx.authMode,
            },
            body: JSON.stringify(body)
        })
        //.then((res) => res.json())
        .then((res) => {
            // Check for 422 or 500 response
            if (!res.ok) {
                 throw new Error('Unprocessable Entity: ' + res.statusText);
            }
            return res.json();
          })
        .then((data) => {
            //  console.log("Send Survey by Email - posted", data)
            handleClose()
        })
        .catch((err) => {
            console.log(err.message);
            setShowError(true);
        })
    }



    const handleClose = () => {
        // clear all errors, reset states
        setErrors({})
        setPatientData(null)
        setEmailMatchesDB(false)
        setEmail('')
        props.onClose()
    }

    const onEmailChange = (event) => {
        setEmail(event.target.value)
        setErrors({...errors, email: null})
        
    }

    const onUpdateEmail = (event) => {
        event.preventDefault()
        setEmailMatchesDB(false)
        // let errs = []
        let error = false
        let formErrors = { email: null, patient: null}
        if (patientData === null) {
            formErrors = {...formErrors, patient: 'Patient is not selected'}
            error = true
        } 
        if (email === '') {
            formErrors = {...formErrors, email: 'Email address is not populated'}
            error = true
        } else if (validateEmail(email) !== true) {
            formErrors = {...formErrors, email: 'Invalid email address'}
            error = true
        } else {
            setEmailMatchesDB(true)
        }

        setErrors({...errors, email: formErrors.email, patient: formErrors.patient})

        if (!error) {
            updateEmail()
        }

    }


    const validateEmail = (emailAddress) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress) !== true) {
            return false
        } else {
            return true
        }
    }




    const updateEmail = async () => {
        
        const url = `${process.env.REACT_APP_SERVER}/api/v1/patients/${patientData.patient_token}`
        const body=JSON.stringify({
            email: email,
            emr_mrn: patientData.emr_mrn,
        })
        const response = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                auth: authCtx.authMode,
            },
            body: body
        })
        .then((res) => res.json())
        .then((data) => {
            // console.log('SendSurveyByEmail - email updated: ', data)
            if (data.email !== null && data.email === email) {
                setErrors({...errors, email: null})
                setPatientData({...patientData, email: data.email})
            }
        })
        .catch((err) => {
            console.log(err.message);

        })
    }


    const handlePatientChange = (data) => {
        // patient selected from the search patient dropdown - set email
        // console.log("handleChange", data)
        setPatientData(data)
        if (data.email !== '') {
            // setEmailError('')
            setErrors({...errors, email: null})
            setEmail(data.email)
        }

    }

    const handleSurveyChange = (event) => {
        // console.log("Send Survey by Email - survey selected", event.target.value)
        setSurveyToSend(event.target.value)
        if (!!event.target.value) {
            setErrors({...errors, survey: null})
            
        } else {
            setErrors({...errors, survey: 'Survey is not selected'})
        }
    }


    // patient cleared from the serach field
    const handlePatientCleared = () => {
        setEmail('')
        setPatientData(null)
    }
    

    const resetAllErrors = () => {
        setErrors({...errors, email: null, survey: null, patient: null})
    }

    return (
        <>
            <ErrorDialogModal
                setOpen={showError}
                close={() => setShowError(false)}
                componentName="Send Survey by Email"
            />
            <Modal 
                backdrop="static"
                size="lg" 
                show={props.show} 
                animation={false} 
                onHide={() => props.onClose('cancel')} 
                style={{ visibility: (showError) ? 'hidden' : 'visible' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>New Patient Survey - Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SelectPatient 
                        error={errors.patient}
                        onErrorReset={() => setErrors({...errors, patient: null})}
                        onChange={handlePatientChange} 
                        onClear={handlePatientCleared}/>

                    <Form.Group className='my-4'>
                        <Form.Label htmlFor="surveys">Select Survey:
                        </Form.Label>
                        <Form.Control
                            as="select"
                            isInvalid={!!errors.survey}
                            aria-label="Select survey" 
                            id="survey_selection"
                            onChange={handleSurveyChange}
                            // make first available selected by default
                            // defaultValue={surveys[0]?.short_name || ''}
                            defaultValue={surveyToSend}
                        >
                            <option value=''>Select Survey</option>
                            {surveys.map((s, si) => (
                                <option key={`survey_${si}`} value={s.short_name}>{s.name}</option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.survey}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className='my-4' >
                    <Form.Label htmlFor="email">Email:
                    </Form.Label>
                    <Form.Control
                        isInvalid={!!errors.email}
                        isValid={!!!errors.email && emailMatchesDB}
                        type="email"
                        id="email"
                        aria-describedby="email address"
                        value={email}
                        onChange={onEmailChange}
                        required
                    />
                    <Form.Control.Feedback type="valid">Email updated</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    
                        <div className="container">
                            <div className="row my-3">
                                <div className="col-md-12 ">
                                <Button className='float-end' variant="primary" onClick={onUpdateEmail}>
                                    Update Email
                                </Button>
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                <div className={`${sharedStyles.divInlineBlock}  text-left`}>
                    <Button className='mx-2' variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </div>

                <div className={`${sharedStyles.divInlineBlock} text-right`}>
                    <Button className='mx-2' variant="primary" onClick={handleSend}>
                        Send Email
                    </Button>
                </div>
            </Modal.Footer>
            </Modal>
        </>
    )
}

export default SendSurveyByEmail
