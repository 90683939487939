import React, {useEffect, useState, useRef} from 'react'
import parse from "html-react-parser";
import style from './ProviderFollowupGroup.module.css'

function ProviderFollowupRow(props) {

    const [elements, setElements] = useState([])
    const [headers, setHeaders] = useState([])
    const trRef = useRef()
    const [fuValues, setFuValues] = useState({})

    useEffect(() => {
        if (!!props) {
            // console.log("ProviderFollowupRow props", props)

            // console.log("ProviderFollowupRow props.question.display_name_short",props.question.display_name_short)
            // if (!!props.fu && !!props.fu[props.question.display_name_short] ) {
            //     console.log("ProviderFollowupRow props.fu", props.fu[props.question.display_name_short])
            // } else {
            //     console.log("ProviderFollowupRow props.fu", props.fu)
            // }
            
        

        // build HTML elements
        let qtypes = []
        let qvalues = []
        let htmls = []
        let options = []
        let mappedOptions = []
        
        
        // in question - get question_option_values, question_option and question_type
        // console.log("props.questions", props.questions)
        if (!!props.question.question_type && props.question.question_type.startsWith('[')) {
            qtypes = JSON.parse(props.question.question_type)

            // DEBUG
            // if (props.question.question_option_values === "[\"\",0,0,0,\"c\",\"i\",0]") {
            //     qtypes.push("label")
            // }
        }

        // if (props.questions.DictIntakeFormQuestion.question_option_values !== null && props.question.DictIntakeFormQuestion.question_option_values.startsWith('[')) {
        //     qvalues = JSON.parse(props.question.DictIntakeFormQuestion.question_option_values)

        //     // DEBUG
        //     // if (props.question.question_option_values === "[\"\",0,0,0,\"c\",\"i\",0]") {
        //     //     qvalues.push("/100")
        //     // }
        // }

        if (props.question.question_option !== null && props.question.question_option.startsWith('[')) {
            options = JSON.parse(props.question.question_option)

            // DEBUG
            // if (props.question.question_option_values === "[\"\",0,0,0,\"c\",\"i\",0]") {
            //     headers.push("")
            // }
        }
        setHeaders(options)


        

        if (props.fu !== null) {
            // console.log("ProviderFollowupRow data", props.data)
        }

        // if (props.question.DictIntakeFormQuestion.mapped_property !== null && props.question.DictIntakeFormQuestion.mapped_property.startsWith('[')) {
        //     mappedOptions = JSON.parse(props.question.DictIntakeFormQuestion.mapped_property)
        // } else if (props.question.DictIntakeFormQuestion.mapped_property === null && qtypes.length === 1) {
        //     mappedOptions.push(props.question.DictIntakeFormQuestion.display_name_short)
        // }

        // remove 1st blank element from the arrays
        // if (qtypes.length > 0 && qvalues.length > 0 && mappedOptions.length > 0 && qtypes[0] === '' && qvalues[0] === '' && headers.length > 0 && headers[0] === '' && mappedOptions[0] === '') {
        //     // console.log("SHIFTING... qtypes", qtypes, "qvalues", qvalues, "headers", headers)
        //     qtypes.shift()
        //     qvalues.shift()
        //     headers.shift()
        //     mappedOptions.shift()
        //     // console.log("SHIFTED... qtypes", qtypes, "qvalues", qvalues, "headers", headers)
        // }
                
        // build html elements
        htmls = qtypes.map((type, index) => {
            // console.log("ROW: type", type, "index", index, "headers", headers)

            switch(type) {
                case 'input':
                    // const suffix = headers.length > index ? '_' + headers[index].toLowerCase(): ''
                    // return {
                    //     html: 'input', 
                    //     type: 'text', 
                    //     name: `${props.question.display_name_short}_index`,
                    //     label: !!options[index] ? options[index] : '',
                    //     id: `${props.question.display_name_short}_${(!!options[index] ? options[index] : '').replace(' ','_')}`,
                    // }

                    return {
                        html: 'textarea', 
                        type: 'textarea',
                        name: `${props.question.display_name_short}`,
                        label: !!options[index] ? options[index] : '',
                        id: `${props.question.display_name_short}_${(!!options[index] ? options[index] : '').replace(' ','_')}`,
                    }

                
                case 'radio':
                    return {
                        html: 'input', 
                        type: 'radio', 
                        name: `${props.question.display_name_short}`,
                        // value: qvalues[index]
                        label: !!options[index] ? options[index] : '',
                        id: `${props.question.display_name_short}_${(!!options[index] ? options[index] : '').replace(' ','_')}`,
                    }

                case 'checkbox':
                    return {
                        html: 'input', 
                        type: 'checkbox', 
                        name: `${props.question.display_name_short}`,
                        // value: qvalues[index]
                        label: !!options[index] ? options[index] : '',
                        id: `${props.question.display_name_short}_${(!!options[index] ? options[index] : '').replace(' ','_')}`,
                    }

                // case 'label':
                //     return {html: 'label', type: 'label', name: props.question.display_name_short, value: qvalues[index]}

                // case 'textarea':
                //     return {
                //         html: 'textarea', 
                //         type: 'textarea', 
                //         name: mappedOptions.length > index ? mappedOptions[index] : props.question.display_name_short
                //     }

                default:
                    return ""
            }
        })
        setElements(htmls)

        // create array of data in form {name: value}
        // const element_names = htmls.map((el, ix) => {
        //     return { [el.name]: props.data[el.name]}
        // })

        // const vals = element_names.reduce((accum, el) => {
        //     const key = Object.getOwnPropertyNames(el)[0]
        //     accum = {...accum, ...el}
        //     return accum
        // }, {})

        // if (props.question.group_id === 8) {
        //     console.log("ELEMENTS", htmls)
        //     console.log("ELEMENT NAMES", element_names)
        //     console.log("VALUES OF ELEMENTS", vals)
        // }
        // console.log("VALUES OF ELEMENTS", vals)
        // setValues(vals)


        // console.log("HTML ELEMENTS", htmls)
        
        
        // setValues(props.data)

        }

        // temporary code to fix incorrect format of values received from API
        // const tempFuValues = props.fu

        // // // changed to array of values:
        // // // prov_followup_history_of - passed as a string of values
        // tempFuValues.prov_followup_history_of = props.fu.prov_followup_history_of.split(',')
        // // // prov_followup_smile passed as a string "{\value1\",\"value2\"}"
        // tempFuValues.prov_followup_smile = JSON.parse(props.fu.prov_followup_smile.replace("{","[").replace("}","]"))

        // setFuValues({...tempFuValues})
        setFuValues(props.fu)

    }, [/*props.data,*/ props.question, props.fu])




    const value_changed = (event) => {
        // console.log("Value changed event.target", event.target)
        // console.log("Value changed event.target.value", event.target.value)
        let vals = fuValues[event.target.name]
        // console.log("current values before change", fuValues[event.target.name])
        if (Array.isArray(vals)) {
            if (vals.includes(event.target.value)) {
                // remove the value
                vals = vals.filter(f => f !== event.target.value)
            } else {
                // add value
                vals.push(event.target.value)
            }
        } else {
            vals = event.target.value
        }
        
        setFuValues({...setFuValues, [event.target.name]:vals})

    }





    const renderElelment = (el, ix, renderType) => {
        const element = 
            <div key={ix} >              

                {(el.type === 'text' || el.type === 'textarea') && el.label !== '' ? <label>{el.label}</label> : null} 
                {el.type === 'text' &&
                    <input 
                        type={el.type} 
                        name={el.name} 
                        onChange={(event) => value_changed(event, ix)}
                        data-input-type={el.type}
                        // value={values[el.name] !== undefined ? values[el.name] : ''}
                        readOnly={props.readOnly}
                        defaultValue={!!fuValues && !!fuValues[props.question.display_name_short] ? fuValues[props.question.display_name_short] : ''}
                    />
                }

                {el.type === 'textarea' &&
                    <textarea
                        className={style.fu_textarea}
                        name={el.name} 
                        data-input-type={el.type}
                        // value={values[el.name] !== undefined ? values[el.name] : ''}
                        defaultValue={!!fuValues && !!fuValues[props.question.display_name_short] ? fuValues[props.question.display_name_short] : ''}
                        // onChange={(event) => value_changed(event, ix)}
                        readOnly={props.readOnly}
                    />
                }

                
                

                {el.type === 'radio' &&
                    <input 
                        type={el.type} 
                        name={el.name} 
                        id={el.id}
                        onChange={(event) => value_changed(event, ix)}
                        data-input-type={el.type}
                        value={el.label}
                        checked={el.label === fuValues[el.name]}
                        disabled={props.readOnly}
                    />

                }       

                {el.type === 'checkbox' &&
                    <input 
                        type={el.type} 
                        name={el.name} 
                        id={el.id}
                        onChange={(event) => value_changed(event, ix)}
                        data-input-type={el.type}
                        value={el.label}
                        checked={fuValues[el.name].includes(el.label)}
                        disabled={props.readOnly}
                    />
                }

                

                {el.type === 'label' &&
                    <label
                        name={el.name} 
                        data-input-type={el.type}
                    >
                        {/* {el.value} */}
                    </label>
                }   



                {(el.type === 'checkbox' || el.type === 'radio') && el.label !== '' ? <label htmlFor={el.id}>{el.label}</label> : null} 

                
            </div>
            return element
    }



    const renderDisplayName = () => {
        const element = 
            <>
                {/* {props.errors.length > 0 && props.errors.includes(props.question.display_name_short) ? 
                    <span className='text-danger'>{props.question.display_name}</span> 
                    :
                    <span>{props.question.display_name}</span>
                } */}
                <span>{props.question.display_name}</span>
            </>

        return element
    }

    function OnInput() {
        this.style.height = 0;
        this.style.height = (this.scrollHeight) + "px";
    }

    useEffect(() => {
        const tx = document.getElementsByTagName("textarea");
        for (let i = 0; i < tx.length; i++) {
          tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
          tx[i].addEventListener("input", OnInput, false);
        }
    })


    if (props.question === null) {
        return null
    }
    
    return (
        <>
        <>
            {props.type === 'table' &&
                <tr ref={trRef}>
                    {
                        // do not output display name if empty
                        props.question.display_name !== null && props.question.display_name !== "" &&
                        <td >
                            {renderDisplayName()}
                        </td>
                    }
                    {elements.map((el, ix) => {
                        return (
                            el !== null && (
                            <td key={ix}>
                                {renderElelment(el,ix)}
                            </td>
                            )
                        )
                        
                    })}
                </tr>
            }

            {props.type === 'single' && (
                <>
                    {
                        // do not output display name if empty
                        props.question.display_name !== null && props.question.display_name !== "" &&
                        renderDisplayName()
                    }

                    {elements.map((el, ix) => {
                        return (
                            el !== null &&                             
                                renderElelment(el,ix, props.type)
                        )

                    })}
                </>
            )}

            {props.type === 'columns' &&
                <>
                    {
                        // do not output display name if empty
                        props.question.display_name !== null && props.question.display_name !== "" &&
                        <div /*className='col-4'*/ >
                            {renderDisplayName()}
                        </div>
                    }
                    {elements.map((el, ix) => {
                        return (
                            el !== null && (
                            <div /*className='col-4'*/ key={ix}>
                                {renderElelment(el,ix)}
                            </div>
                            )
                        )
                        
                    })}
                </>
            }
        </>






        {/* {props.type === 'single' && (
                <>
                    {
                        // do not output display name if empty
                        props.questions.display_name !== null && props.questions.display_name !== "" &&
                        renderDisplayName()
                    }

                    {elements.map((el, ix) => {
                        return (
                            el !== null &&                             
                                renderElelment(el,ix, props.type)
                        )

                    })}
                </>
            )} */}
        </>
    )
}

export default ProviderFollowupRow
