import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
//import { CountryDropdown, RegionDropdown, countryList } from 'react-select-country-list'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'
import ErrorDialogModal from '../modals/ErrorDialogModal';
import UserPrefContext from '../../../context/userPrefContext';
import PatientContext from '../../../context/patientContext';
import AuthContext from '../../../context/authContext';
import DictionaryContext from '../../../context/dictionaryContext';
import { trackPromise } from 'react-promise-tracker'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

function UpdatePatientInfoModal(props) {
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false)

  const [patientData, setPatientData] = useState();

  const [imageDate, setImageDate] = useState(new Date());
  const [nameString, setNameString] = useState();
  const [dobString, setDobString] = useState();
  const [genderString, setGenderString] = useState();
  const [mrnString, setMrnString] = useState();

//   const [value, setValue] = useState('')
  const [countryValue, setCountryValue] = useState(props.patientData?.country)
  const [countryValue2, setCountryValue2] = useState(props.patientData?.country)
  const [stateValue, setStateValue] = useState(props.patientData?.state)
  const [ageValue, setAgeValue] = useState()
  const [genderDropdownValues, setGenderDropdownValues] = useState()

  const options = useMemo(() => countryList().getData(), [])

  const suffixRef = useRef(null);
  const firstNameRef = useRef(null);
  const middleNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const genderRef = useRef(null);
  const dobRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const address1Ref = useRef(null);
  const address2Ref = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const zipCodeRef = useRef(null);
  const countryRef = useRef(null);
  const ageRef = useRef(null);
  const weightRef = useRef(null);
  const handednessRef = useRef(null);

  const [selectedDate, setSelectedDate] = useState();
  const [errors, setErrors] = useState({})

  let refArray = []
  const refs = useRef({});
  

  const patCtx = useContext(PatientContext);
  const userPrefCtx = useContext(UserPrefContext);
  const authCtx = useContext(AuthContext);
  const dictCtx = useContext(DictionaryContext);

  // const uploadProfileImageURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/upload/set_as_profile_image/${patCtx.pat_token}`;
  const patientURL = `${process.env.REACT_APP_SERVER}/api/v1/patients/${patCtx.pat_token}`;
  //const patientURL = '';

  //console.log(patCtx.pat_token)

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }

  const closeModal = () => {
    setErrors({})
    props.close()
  }

  const changeHandler = value => {
    setCountryValue(value)
  }

  const changeHandler2 = (value) => {
    //console.log(value)
    setCountryValue2(value)
  }

  const changeStateHandler = (value) => {
    //console.log(value)
    setStateValue(value)
  }
  const changeDobHandler = (date) => {
    if(date != null) {
    //console.log(date)
    setSelectedDate(date);
    const currentDate = new Date();

    // Calculate the difference in milliseconds between the current date and the DOB
    const timeDiff = currentDate.getTime() - date.getTime();

    // Calculate the age based on the time difference
    const ageDate = new Date(timeDiff); // Convert the time difference to a date object
    const age = Math.abs(ageDate.getUTCFullYear() - 1970); // Extract the year and calculate the age

    //console.log(age);
    setAgeValue(age)
    }
  }

//   useEffect(() => {
// }, [selectedDate]);
useEffect(() => {
    if (props.patientData) {

    const createRefArray = Object.keys(props.patientData)
    .filter((key) => key !== "patient_token" && key !== "id")
    .map((key, index) => {
        refArray.push(key);
        //return key;
    });

    //console.log(refArray)
    // console.log(createRefArray);

    // refArray.forEach((refName) => {
    //     //console.log(refName)
    //     refs.current[refName] = useRef();
    //   });
    }


    if (props.patientData?.dob) {
       //This is to set the default date of birth and setting it in the right format 
      const initialDate = moment(props.patientData.dob, 'YYYY-MM-DD').toDate();
      setSelectedDate(initialDate);
    //setSelectedDate(props.patientData?.dob);
    //console.log(initialDate)
    const currentDate = new Date();

    // Calculate the difference in milliseconds between the current date and the DOB
    const timeDiff = currentDate.getTime() - initialDate.getTime();

    // Calculate the age based on the time difference
    const ageDate = new Date(timeDiff); // Convert the time difference to a date object
    const age = Math.abs(ageDate.getUTCFullYear() - 1970); // Extract the year and calculate the age

    //console.log(age);
    setAgeValue(age)
    }
    if (props.patientData?.country) {
        //This is to look through the countryList options and find the value and label based off of the prop to pass into the Select in the render
        const selectedOption = options.find(option => option.label === props.patientData?.country);
        //console.log(selectedOption);
        setCountryValue(selectedOption)

        //console.log(countryList().getCountryByValue(selectedOption))
        //console.log(selectedOption)

        setCountryValue2(props.patientData?.country)

        //const countryData = countryLis;
        // const selectedCountryObj = options.find(
        //     (country) => country.label === props.patientData?.country
        // );
        // console.log(selectedCountryObj)

      }
      if (props.patientData?.state) {
        setStateValue(props.patientData?.state)
      }

      if(dictCtx){
        // console.log(dictCtx);
        const genderObject = dictCtx?.dict_modal.find(obj => obj?.display_name_short === 'gender');
        // console.log(genderObject)
        //const optionsArray = JSON.parse(genderObject?.question_option);
        let optionsArray;

        if (genderObject && genderObject.question_option) {
            try {
                optionsArray = JSON.parse(genderObject.question_option);
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        } else {
            // it will set it to an empty array if they don't exist
            optionsArray = [];
        }
        // console.log(optionsArray)
        setGenderDropdownValues(optionsArray)
      }
  }, [props.patientData, props.close, dictCtx]);

//   const refMapping = refNames.reduce((obj, refName) => {
//     obj[refName] = refs.current[refName];
//     return obj;
//   }, {});

  const saveChanges = async (value) => {
    //setValue(value)
    //console.log(suffixRef.current.value, firstNameRef.current.value, countryRef.current.value, dobRef.current.value, selectedDate, countryValue)
    //console.log(errors?.emr_mrn)

    let mrnValue = document.getElementById('patient_emr_mrn').value

    //checkIfMrnExists(mrnValue)

    let documentArr = []

    //if(errors?.emr_mrn === null || errors?.emr_mrn === undefined) {

    //console.log(props.patientData);

    // for(let i = 0; i< props.patientData?.length; i++){
    //     let currentProp = document.getElementById('patient_'+props.patientData[i]).value;
    //     //console.log(currentProp)
    //     documentArr.push(currentProp);
    // }

    const createRefArray = Object.keys(props.patientData)
    //.filter((key) => key !== "emr_mrn" && key !== "patient_token" && key !== "id")
    .map((key, index) => {
        //refArray.push(key);
        //console.log(key);
        //let currentProp = document.getElementById('patient_'+props.patientData[index]).value;
        let currentProp = null;
        //console.log(props.patientData[key])
        //const element = document.getElementById('patient_'+props.patientData[key]);
        const element = document.getElementById('patient_'+key);
        if (element) {
        currentProp = element.value;
        //documentArr.push(currentProp)
        }
        //documentArr.push(currentProp);
        //console.log(currentProp)
        //return key;
    });

    //console.log(documentArr);

    const createRefArray2 = Object.keys(props.patientData)
  //.filter((key) => key !== "emr_mrn" && key !== "patient_token" && key !== "id")
  .filter((key) => key !== "patient_token" && key !== "id" && key !== "file_name")
  .reduce((obj, key) => {
    let currentProp = null;
    const element = document.getElementById('patient_' + key);
    if (element) {
      //currentProp = element.value;
      currentProp = element.value;

      //var pattern = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    //if (pattern.test(currentProp) && key === "dob") {
    if (key === "dob") {
        //This is to check if there is a field that matches mm/dd/yyyy so that it can be converted
        //console.log("Valid date format", currentProp);

        const parts = currentProp.split('/');
        const convertedDate = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
        //currentProp = selectedDate;
        currentProp = convertedDate
        //console.log(selectedDate)
    }
      //documentArr.push(currentProp);
    } else {
        //console.log('not element: ', key)
        currentProp = '';
      //documentArr.push(currentProp);
    }
    obj[key] = currentProp;
    return obj;
  }, {});

  //console.log(createRefArray2);

  const sendObject = JSON.stringify(createRefArray2);

  //console.log(sendObject)


    const responseData = await fetch(patientURL, {
        method: 'PATCH',
        // body: JSON.stringify({
        //     "first_name": firstName,
        //     "last_name": lastName,
        //     "middle_name": middleName,
        //     "suffix": suffix,
        //     "country": country,
        //     "age": age,
        //     "weight": weight,
        //     "handedness": handedness,
        //     "email": email,
        //     "address1": address1,
        //     "address2": address2,
        //     "city": city,
        //     "state": state,
        //     "zipcode": zipCode,
        //     "phone": phone,
        //     "dob": dob
        //  }),
         body: sendObject,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userPrefCtx.bearerToken}`,
          auth: authCtx.authMode
        },
      })
      //.then((res) => res.json())
      .then((res) => {
        // Check for 422 or 500 response
        if (!res.ok) {
             throw new Error('Unprocessable Entity: ' + res.statusText);
        }
        return res.json();
      })
      .then((data) => {
         //console.log(data);
         props.sendTrigger(data);
         props.close();
      })
      .catch((err) => {
         //console.log(err.message);
         setShowError(true);
      });

    //}

  }

  const formatVariableName = (variableName) => {
    //This function is to take the underscore out put a space and uppercase the first letters
    return variableName
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const onBlurEvent = (e) => {
    // console.log('on blur', e)
    if(e === 'emr_mrn'){
      // props.checkIfMrnExists(e.target.value);
      let mrnValue = document.getElementById('patient_'+e).value
      //console.log(mrnValue);
      // props.checkIfMrnExists(e);
      checkIfMrnExists(mrnValue)
      //props.checkIfMrnExists(mrnValue);

    }
  }

  const checkIfMrnExists = async (mrnValue, saveButton) => {
    // if mrn is entered and exists show the error message
   // const mrnValue = updatedFields.find(f => f.display_name_short === 'emr_mrn').defaultValue
   let error = false;
    //if (!!mrnValue) {
        const checkMRNURL = `${process.env.REACT_APP_SERVER}/api/v1/patients/check_mrn_exists?emr_mrn=${mrnValue}`;
        const responseData = await fetch(checkMRNURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                auth: authCtx.authMode
            },
        })
        .then((res) => {
            // Check for 422 or 500 response
            if (!res.ok) {
                throw new Error('Unprocessable Entity: ' + res.statusText);
            }

            if(res.status === 240){
                //console.log('it is 240 - MRN Already exists!')
                //formErrors.emr_mrn = `MRN ${mrnValue} already exists`
                //if(!saveButton){
                  if(mrnValue !== props.patientData?.emr_mrn){
                    setErrors({...errors, emr_mrn: `MRN ${mrnValue} already exists`})
                  }
                  else if(mrnValue === props.patientData?.emr_mrn){
                    //This is to make sure that if its the same mrn that the patient had when the modal was opened, it doesnt give an error
                    setErrors({...errors, emr_mrn: null})
                    if(saveButton === 'saveChanges') {
                      //console.log(mrnValue)
                      //this is basically to detect if an argument came from the save button
                      saveChanges()
                    }
                  }
              //}
                // formErrors = {...formErrors, [field.display_name_short]: `MRN ${mrnValue} already exists`}
                //error = true
       
                // throw new Error('MRN Already exists!')
            } else {
                if(res.ok){
                 setErrors({})
                 if(saveButton === 'saveChanges') {
                  //this is basically to detect if an argument came from the save button
                  saveChanges()
                }
                 //console.log('in res.ok')
                    // console.log('checking first name: ', document.getElementById('create_patient_first_name').value)
                    //This is to go to the next step, to create a new patient if the MRN doesn't already exist
                    // sendToCreatePatientAPI(sendToPatientChart)
                }
            }
            return res.json();
        })
        .then((data) => {
            // console.log(data);
            // console.log('saveButton: ', saveButton, 'mrnValue: ', mrnValue)
            // if(saveButton === 'saveChanges') {
            //   //this is basically to detect if an argument came from the save button
            //   saveChanges()
            // }
            //  props.sendTrigger(data);
            //  props.close();
        })
        .catch((err) => {
            console.log(err.message);
            //setShowErrorMesssage(err.message);
            //setShowError(true);
        });
    }

    const setGenderDropdownFunction = (e) => {
      // console.log(e);
      if(e === 'M'){
        // console.log(e);
        return 'MALE';
      }
      else if (e === 'F'){
        // console.log(e);
        return 'FEMALE';
      }
      else if (e === 'N'){
        // console.log(e);
        return 'N/A';
      }
      else{
        // console.log(e);
        return e;
      }
    }


  return (
    <>
      <ErrorDialogModal
        setOpen={showError}
        close={() => setShowError(false)}
        componentName="Update Patient Information"
      />
    <Modal 
    //size="lg" 
    size="xl"
    //show={showModal2} 
    //onHide={handleClose} 
    show={props.setOpen}
    //onHide={props.close}
    onHide={closeModal}
    animation={false} 
    backdrop="static" 
    //centered
    //dialogClassName={classes.custom_modal}
    style={{ visibility: (showError) ? 'hidden' : 'visible' }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Patient Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {/* <h3>This is where the user name and password should be entered to continue session</h3> */}
      {/* <div className="columns_container">
        <div className="individual_column pat_first_col">
        </div>
      </div> */}
      { props.patientData != undefined &&
        <div className="two_column_container">
        {Object.keys(props.patientData)
        .filter((key) => key !== "patient_token" && key !== "id" && key !== "file_name")
        .map((key, index) => (
            
          <div key={key} className="patient_column">
            <Form.Group className='my-1'>
            <p>{formatVariableName(key)}</p>
            {/* { key !== "emr_mrn" && key !== "country" && key !== "state" && key !== "dob" && key !== "age" && */}
            { key !== "emr_mrn" && key !== "country" && key !== "state" && key !== "dob" && key !== "age" && key !== 'gender' &&
            <Form.Control 
                        defaultValue={props.patientData[key]}
                        id={'patient_'+key}
                        //disabled={key === "age"}
            />
            }
            { key === "emr_mrn" &&
            <>
              <Form.Control 
                id={'patient_'+key}
                //name={object.display_name_short}
                onBlur={() => onBlurEvent(key)}
                // onChange={onChangeInput}
                //required={object.question_required}
                //isInvalid={!!props.errors[object.display_name_short]}
                isInvalid={!!errors[key]}
                defaultValue={props.patientData[key]}
              />
              {/* <div className={`invalid-feedback ${!!errors[key] ? 'is-invalid': ''}`}>
              {errors[key]}
              </div> */}
              </>
            }
            { key === "country" &&
            <CountryDropdown
                // value={country}
                value={countryValue2}
                id={'patient_'+key}
                onChange={changeHandler2}
                priorityOptions={["US"]}
                // onChange={selectCountry} 
                className="country_dropdown"
                //style={{width: '100%'}}
            />
            }
            {   key === "state" &&
                <RegionDropdown
                    id={'patient_'+key}
                    country={countryValue2}
                    className="country_dropdown"
                    value={stateValue}
                    onChange={changeStateHandler} 
                />
            }
            {   key === "dob" &&
                <DatePicker 
                //selected={startDate} 
                id={'patient_'+key}
                selected={selectedDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                //selected={props.patientData?.dob}
                //onChange={(date) => setSelectedDate(date)} 
                onChange={changeDobHandler}
                //className="datepickerstyle" 
                //defaultValue={props.patientData?.dob}
                className="dob_select_style"
                ref={dobRef}
                />
            }
            { key === "age" &&
                <div>
                {/* <input type="text" defaultValue={props.patientData?.email} /> */}
                <Form.Control 
                    // defaultValue={props.patientData?.age}
                    //defaultValue={ageValue}
                    value={ageValue}
                    ref={ageRef}
                    disabled="disabled"
                />
            </div>
            }
            { key === "gender" &&
                <div>
                <Form.Control 
                as="select"
                    // defaultValue={props.patientData?.gender?.toUpperCase()}
                    defaultValue={setGenderDropdownFunction(props.patientData?.gender?.toUpperCase())}
                    id={'patient_'+key}
                    // disabled="disabled"
                >
                {/* <option value='' ></option> */}
                {genderDropdownValues.map(option => (
                    <option 
                      // key={option.value}
                      key={'gender_'+option}
                      // value={option.value}
                      value={option?.toUpperCase()}
                    >
                    {option}
                </option>
                ))}
                </Form.Control>
            </div>
            }
              <Form.Control.Feedback type="invalid" >
                  {errors[key]}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        ))}

      </div>
        }
      {/* {loginError && <p>{loginError}</p>} */}
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={props.close}>
          Close
        </Button> */}
        <Button variant="primary" 
          //onClick={saveChanges}
          onClick={() => checkIfMrnExists(document.getElementById('patient_emr_mrn').value,'saveChanges')}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}

export default UpdatePatientInfoModal;