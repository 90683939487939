import React, { useState, useEffect, useContext, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ErrorDialogModal from '../ErrorDialogModal';
import UserPrefContext from '../../../../context/userPrefContext';
import AuthContext from '../../../../context/authContext';


function EditDictIntervention(props) {
  const userPrefCtx = useContext(UserPrefContext);
  const authCtx = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [info, setInfo] = useState();
  const [groupNameList, setGroupNameList] = useState();
  const [interventionTypeList, setinterventionTypeList] = useState();

  const [groupNameValue, setGroupNameValue] = useState();
  const [interventionTypeValue, setInterventionTypeValue] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [openFPModal, setOpenFPModal] = useState(false);

  const [openOtherModal, setOpenOtherModal] = useState(false);

  const nameRef = useRef(null);
  const groupRef = useRef(null);
  const interventionTypeRef = useRef(null);

  //console.log(props.sendDictDashboard)

  if (props.setOpen === true) {
    //this if statement is supposed to send a false value for the dictintervention modal
    //props.func(false);
  }

  //sending false back to the NewDictIntervention modal to eventually close that modal when this modal is called
  //props.func(false);
  useEffect(() => {

    if(props.sendDictDashboard?.dict_intervention != undefined){

    const alphaProp = props.sendDictDashboard?.dict_intervention
    .sort((a, b) => a?.group_display_name
    .localeCompare(b?.group_display_name
      ));

      //console.log(alphaProp)

      const interventionType = [...new Map(props.sendDictDashboard.dict_intervention?.map(item => [item.intervention_type, item])).values()]
      //console.log(selectArray[0])
      setinterventionTypeList(interventionType);

      const groupName = [...new Map(props.sendDictDashboard.dict_intervention?.map(item => [item.group_display_name, item])).values()]

      setGroupNameList(groupName);

      //console.log(props.editData)
    }

  }, [props.sendDictDashboard?.dict_intervention, props.editData]);



  const patchEditIntervention = async (e) => {
    
    const patchDictInterventionURL = process.env.REACT_APP_SERVER + `/api/v1/dict_interventions/` + props.editData.id;

    let currentNameRef = nameRef.current.value;
    let currentGroupRef = groupRef.current.value;
    //let currentInterventionTypeRef = interventionTypeRef.current.value;
    let sendCategoryDisplayName = props.editData.category_display_name;
    let sendUniqueID = props.editData.unique_id;
    let sendCptCode = props.editData.cpt_code;
    let sendSortPosition = props.editData.sort_position;

    if(sendUniqueID === null){
        sendUniqueID = '';
    }
    if(sendCptCode === null){
        sendCptCode = '';
    }
    if(sendSortPosition === null){
        sendSortPosition = 0;
    }

    // console.log(currentGroupRef);
    // console.log(typeof currentGroupRef);


    // console.log('interventionTypeValue: ', interventionTypeValue, 'groupNameValue: ', groupNameValue, 'currentNameRef: ',currentNameRef, 'userPrefCtx.bearerToken: ', userPrefCtx.bearerToken)

    const responseData = await fetch(patchDictInterventionURL, {
      method: "PATCH",
      body: JSON.stringify({
        category_display_name: `${sendCategoryDisplayName}`,
        group_display_name: `${currentGroupRef}`,
        display_name: `${currentNameRef}`,
        display_name_short: `${currentNameRef}`,
        unique_id: sendUniqueID,
        cpt_code: sendCptCode,
        sort_position: sendSortPosition,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userPrefCtx.bearerToken}`,
        auth: authCtx.authMode
      },
    })
      //.then((res) => res.json())
      .then((res) => {
        // Check for 422 or 500 response
        if (!res.ok) {
             throw new Error('Unprocessable Entity: ' + res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        //console.log(data);
        //Sending data back to the visit panels so that it can trigger a rerender in the intervention list
        props.sendToParent(data);
        props.close();
        //props.getChild(data);
      })
      .catch((err) => {
        //console.log(err.message);
        setShowError(true);
      });


  }

  const selectGroupName = (e) => {
    //console.log('group name is: ', e.target.value)
    setGroupNameValue(e.target.value)

  }

  const selectInterventionType = (e) => {
    //console.log('intervention type is: ', e.target.value)
    setInterventionTypeValue(e.target.value)

  }

  return (
    <>
        <ErrorDialogModal
          setOpen={showError}
          close={() => setShowError(false)}
          componentName="Edit Dict Intervention"
        />
        <div
          onKeyDown={e => e.stopPropagation()}
          onClick={e => e.stopPropagation()}
          onFocus={e => e.stopPropagation()}
          onMouseOver={e => e.stopPropagation()}
        >
          <Modal size="xl" show={props.setOpen} onHide={props.close} animation={false}
            style={{ visibility: (showError) ? 'hidden' : 'visible' }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Dict Intervention</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Group Name</h4>
              <Form.Select aria-label="Default select example" onChange={selectGroupName} ref={groupRef} defaultValue={props.editGroupDisplayName}>
                {groupNameList?.map((x) => <option key={x.id} id={'intervention-button-type-'+x.group_display_name} value={x.group_display_name} >{x.group_display_name}</option>)}
              </Form.Select>
              <h4>Display Name</h4>
              <Form.Control 
                ref={nameRef} 
                size="lg" 
                type="text" 
                defaultValue={props.editData?.display_name} 
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={props.close}>
                Close
              </Button>
              <Button variant="primary" onClick={patchEditIntervention}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
      </div>
    </>
  );
}

export default EditDictIntervention;