import React from 'react'
import moment from 'moment'
import chartClasses from '../../stylesheets/Charts.module.css'

function EFaceListRow(props) {

    const onSelect = () => {
        props.onSelect(props.eface)
    }

    return (
        <tr className='mx-4 px-10'>
            {props.columnMapping.map((m,i) => {
                if (m[1] !== null) {
                    const cellValue = moment(props.eface[m[1]], moment.ISO_8601, true).isValid() ? moment(props.eface[m[1]]).format('MM/DD/YYYY') : props.eface[m[1]]
                    return <td scope="row" key={i}>{cellValue}</td>
                } else {
                    return <td scope="row" key={i}><span className={chartClasses.link} onClick={onSelect}>view</span></td>
                }
            })}
        </tr>
    )
}

export default EFaceListRow
