import React, { useState, useContext, useEffect } from "react"
import PatientContext from "../../../context/patientContext"
import UserPrefContext from '../../../context/userPrefContext'
import AuthContext from "../../../context/authContext"
import moment from 'moment'
import ErrorDialogModal from "../modals/ErrorDialogModal"

function CapeVList(props) {
    const patCtx = useContext(PatientContext)
    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)
    const CapeVGetAllURL = `${process.env.REACT_APP_SERVER}/api/v1/capevs/get_all_by_token/${patCtx.pat_token}`
    const [capeVs, setCapeVs] = useState([])
    const [showError, setShowError] = useState(false)

    useEffect(async() => {
        if (userPrefCtx.bearerToken) {
            const headers = { Authorization: `Bearer ${userPrefCtx.bearerToken}`, auth: authCtx.authMode, };
    
            try {
                const response = await fetch(CapeVGetAllURL, { headers })
                if (response.status === 200) {
        
                    const responseData = await response.json();
                    //console.log('All Intraops: ', responseData);
        
                //This is sorting the the objects by when it was created and then putting it in a useState
                setCapeVs(responseData.sort((a, b) =>
                    new Date(a.capev_date) > new Date(b.capev_date) ? 1 : -1
                ));
                //setIntraopList(responseData);
        
                } else {
                    console.log("Error in CapeVList - response:", response);
                    setShowError(true)
                }
            } catch (e) {
                console.log("Error in CapeVList - ", e);
                setShowError(true)
            }
    
    
        } else {
            console.log('no token')
        } //The triggers for the useEffect will be when a new Capev is created, edited, or deleted
    }, [userPrefCtx.bearerToken, patCtx.pat_token, props.refreshRequired, props.sendCapevModalResponse]);

    const capeVClicked = (event) => {
        // console.log("CapeV clicked")
        const capev_id = event.target.getAttribute("data-capev-id")
        // get data - don't use data in capeVs - the values may have changed
        props.onSelect(capev_id)
    }

    return (
        <>
            <ul>
                {capeVs.length > 0 &&
                    capeVs.map((capev) => {
                        return (
                            <li key={capev.id} data-capev-id={capev.id} onClick={capeVClicked}>
                                {/* {console.log(capev.capev_date)} */}
                                {moment(capev.capev_date).format('MM/DD/YYYY')}
                            </li>
                        )
                })}
            </ul>
            <ErrorDialogModal
                setOpen={showError}
                close={() => setShowError(false)}
                componentName="CapeV"
            />
        </>
    )
}

export default CapeVList
