
import React, {useEffect, useState, useRef} from 'react'
import parse from "html-react-parser";
import style from './FollowupGroup.module.css'

function FollowupRow2(props) {

    const [elements, setElements] = useState([])
    const [headers, setHeaders] = useState([])
    const trRef = useRef()
    const [fuValues, setFuValues] = useState({})

    const [subRows, setSubRows] = useState([])

    useEffect(() => {
        if (!!props) {
            // if (props.question.id === 97 || props.question.id === 40) {
            //     console.log(`FollowupRow id = ${props.question.id} props`, props)
            // }
            // console.log("ProviderFollowupRow props", props)

            // console.log("ProviderFollowupRow props.question.display_name_short",props.question.display_name_short)
            // if (!!props.fu && !!props.fu[props.question.display_name_short] ) {
            //     console.log("ProviderFollowupRow props.fu", props.fu[props.question.display_name_short])
            // } else {
            //     console.log("ProviderFollowupRow props.fu", props.fu)
            // }
            let subQtypes = []
            let subColumns = []
            let subValues = []
            if (props.question.question_type_sub_1 !== null && props.question.question_option_sub_1 !== null) {
                if (props.question.question_type_sub_1.startsWith("[")) {
                    subQtypes = JSON.parse(props.question.question_type_sub_1)
                }
                if (props.question.question_option_sub_1.startsWith("[")) {
                    subColumns = JSON.parse(props.question.question_option_sub_1)
                }
                if (props.question.question_option_values_sub_1.startsWith("[")) {
                    subValues = JSON.parse(props.question.question_option_values_sub_1)
                }

                // console.log("subQtypes", subQtypes)
                // console.log("subColumns", subColumns)
                // console.log("subValues", subValues)
                let newRows = subQtypes.map((sqt, sqtix) => {

                    let newRow = {
                        display_name: props.question.display_name_sub_1,
                        // display_name_short: `${props.question.display_name_short_sub_1}_${subColumns[sqtix]}`,
                        display_name_short: `${props.question.display_name_short_sub_1}`,
                        // name: props.question.display_name_short,
                        // mappedto: props.question.display_name_short_sub_1,
                        display_name_short_sub_1: null,
                        display_name_sub_1: null,
                        group_display_name: props.question.group_display_name,
                        group_display_name_short: props.question.group_display_name_short,
                        group_header1: null,
                        group_header2: null,
                        group_header3: null,
                        group_id: props.question.group_id,
                        id:props.question.id*1000+sqtix,
                        is_active: props.question.is_active,
                        modal_header: props.question.modal_header,
                        question_option: JSON.stringify([subColumns[sqtix]]),
                        question_option_sub_1: null,
                        question_option_values: JSON.stringify([subValues[sqtix]]),
                        question_option_values_sub_1: null,
                        question_type: JSON.stringify([subQtypes[sqtix]]),
                        question_type_sub_1: null,
                        sort_position: props.question.group_id*1000+sqtix,
                        user_dept: props.question.user_dept,
                        user_id: props.question.user_id,
                        // additional properties
                        level: 2,
                    }
                    return newRow
                    
                })

                // console.log("New Rows", newRows)
                setSubRows(newRows)
            }

        // build HTML elements
        let qtypes = []
        let qvalues = []
        let qoptionvalues = []
        let htmls = []
        let options = []
        let mappedOptions = []
        
        
        // in question - get question_option_values, question_option and question_type
        // console.log("props.questions", props.questions)
        if (!!props.question.question_type && props.question.question_type.startsWith('[')) {
            qtypes = JSON.parse(props.question.question_type)

            // DEBUG
            // if (props.question.question_option_values === "[\"\",0,0,0,\"c\",\"i\",0]") {
            //     qtypes.push("label")
            // }
        }

        if (!!props.question.question_option_values && props.question.question_option_values.startsWith('[')) {
            qoptionvalues = JSON.parse(props.question.question_option_values)
            // DEBUG
            // if (props.question.question_option_values === "[\"\",0,0,0,\"c\",\"i\",0]") {
            //     qvalues.push("/100")
            // }
        }

        if (props.question.question_option !== null && props.question.question_option.startsWith('[')) {
            options = JSON.parse(props.question.question_option)

            // DEBUG
            // if (props.question.question_option_values === "[\"\",0,0,0,\"c\",\"i\",0]") {
            //     headers.push("")
            // }
        }
        setHeaders(options)


        

        if (props.fu !== null) {
            // console.log("ProviderFollowupRow data", props.data)
        }

        // if (props.question.DictIntakeFormQuestion.mapped_property !== null && props.question.DictIntakeFormQuestion.mapped_property.startsWith('[')) {
        //     mappedOptions = JSON.parse(props.question.DictIntakeFormQuestion.mapped_property)
        // } else if (props.question.DictIntakeFormQuestion.mapped_property === null && qtypes.length === 1) {
        //     mappedOptions.push(props.question.DictIntakeFormQuestion.display_name_short)
        // }

        // remove 1st blank element from the arrays
        // if (qtypes.length > 0 && qvalues.length > 0 && mappedOptions.length > 0 && qtypes[0] === '' && qvalues[0] === '' && headers.length > 0 && headers[0] === '' && mappedOptions[0] === '') {
        //     // console.log("SHIFTING... qtypes", qtypes, "qvalues", qvalues, "headers", headers)
        //     qtypes.shift()
        //     qvalues.shift()
        //     headers.shift()
        //     mappedOptions.shift()
        //     // console.log("SHIFTED... qtypes", qtypes, "qvalues", qvalues, "headers", headers)
        // }
                
        // build html elements
        htmls = qtypes.map((type, index) => {
            // console.log("ROW: type", type, "index", index, "headers", headers)

            switch(type) {
                case 'number':
                    return {
                        html: 'input', 
                        type: 'number', 
                        name: `${props.question.display_name_short}`,
                        // value: qvalues[index]
                        label: !!options[index] ? options[index] : '',
                        id: `${props.question.display_name_short}_${(!!options[index] ? options[index] : '').replace(' ','_')}`,
                    }

                case 'input':
                    // const suffix = headers.length > index ? '_' + headers[index].toLowerCase(): ''
                    // return {
                    //     html: 'input', 
                    //     type: 'text', 
                    //     name: `${props.question.display_name_short}_index`,
                    //     label: !!options[index] ? options[index] : '',
                    //     id: `${props.question.display_name_short}_${(!!options[index] ? options[index] : '').replace(' ','_')}`,
                    // }

                    return {
                        html: 'textarea', 
                        type: 'textarea',
                        name: `${props.question.display_name_short}`,
                        mappedto: `${qoptionvalues.length > 0 ? qoptionvalues[index] : ''}`,
                        label: !!options[index] ? options[index] : '',
                        id: `${props.question.display_name_short}_${(!!options[index] ? options[index] : '').replace(' ','_')}`,
                    }

                
                case 'radio':
                    return {
                        html: 'input', 
                        type: 'radio', 
                        name: `${props.question.display_name_short}`,
                        mappedto: `${qoptionvalues.length > 0 ? qoptionvalues[index] : ''}`,
                        // value: qvalues[index]
                        label: !!options[index] ? options[index] : '',
                        id: `${props.question.display_name_short}_${(!!options[index] ? options[index] : '').replace(' ','_')}`,
                    }

                case 'checkbox':
                    return {
                        html: 'input', 
                        type: 'checkbox', 
                        name: `${props.question.display_name_short}`,
                        // mappedto: `${qoptionvalues.length > 0 ? qoptionvalues[index] : ''}`,
                        mappedto: `${props.question.display_name_short}`,
                        // value: qvalues[index]
                        label: !!options[index] ? options[index] : '',
                        id: `${props.question.display_name_short}_${(!!options[index] ? options[index] : '').replace(' ','_')}`,
                        level: `level_${!!props.question.level ? props.question.level : ''}`
                    }

                // case 'label':
                //     return {html: 'label', type: 'label', name: props.question.display_name_short, value: qvalues[index]}

                // case 'textarea':
                //     return {
                //         html: 'textarea', 
                //         type: 'textarea', 
                //         name: mappedOptions.length > index ? mappedOptions[index] : props.question.display_name_short
                //     }

                default:
                    return ""
            }
        })
        setElements(htmls)

        

        }

        
        setFuValues(props.fu)

    }, [props.question, props.fu])




    const value_changed = (event) => {
        // console.log("Value changed event.target", event.target)
        // console.log("Value changed event.target.value", event.target.value)
        let vals = fuValues[event.target.name]
        // console.log("current values before change", fuValues[event.target.name])
        if (Array.isArray(vals)) {
            if (vals.includes(event.target.value)) {
                // remove the value
                vals = vals.filter(f => f !== event.target.value)
            } else {
                // add value
                vals.push(event.target.value)
            }
        } else {
            vals = event.target.value
        }
        
        setFuValues({...setFuValues, [event.target.name]:vals})

    }





    const renderElelment = (el, ix, renderType) => {
        const element = 
            <div key={ix} >              

                {(el.type === 'text' || el.type === 'textarea') && el.label !== '' ? <label>{el.label}</label> : null} 
                {el.type === 'text' &&
                    <input 
                        type={el.type} 
                        name={el.name} 
                        onChange={(event) => value_changed(event, ix)}
                        data-input-type={el.type}
                        data-mappedto={el.mappedto}
                        // value={values[el.name] !== undefined ? values[el.name] : ''}
                        readOnly={props.readOnly}
                        defaultValue={!!fuValues && !!fuValues[props.question.display_name_short] ? fuValues[props.question.display_name_short] : ''}
                    />
                }

                {el.type === 'textarea' &&
                    <textarea
                        className={style.fu_textarea}
                        name={el.name} 
                        data-input-type={el.type}
                        data-mappedto={el.mappedto}
                        // value={values[el.name] !== undefined ? values[el.name] : ''}
                        // defaultValue={!!fuValues && !!fuValues[props.question.display_name_short] ? fuValues[props.question.display_name_short] : ''}
                        defaultValue={!!fuValues && !!fuValues[el.mappedto] ? fuValues[el.mappedto] : (!!fuValues[props.question.display_name_short] ? fuValues[props.question.display_name_short] : '')}
                        // onChange={(event) => value_changed(event, ix)}
                        readOnly={props.readOnly}
                    />
                }

                
                {el.type === 'number' &&
                    <input 
                        type={el.type} 
                        name={el.name} 
                        id={el.id}
                        onChange={(event) => value_changed(event, ix)}
                        data-input-type={el.type}
                        data-mappedto={el.mappedto}
                        value={el.label}
                        checked={fuValues[el.name].includes(el.label)}
                        disabled={props.readOnly}
                    />
                }

                {el.type === 'radio' &&
                    <input 
                        type={el.type} 
                        name={el.name} 
                        id={el.id}
                        onChange={(event) => value_changed(event, ix)}
                        data-input-type={el.type}
                        data-mappedto={el.mappedto}
                        value={el.label}
                        checked={el.label === fuValues[el.name]}
                        disabled={props.readOnly}
                    />

                }       

                {el.type === 'checkbox' &&
                    <input 
                        className={style[!!el.level ? el.level : '']}
                        type={el.type} 
                        name={el.name} 
                        id={el.id}
                        onChange={(event) => value_changed(event, ix)}
                        data-input-type={el.type}
                        data-mappedto={el.mappedto}
                        value={el.label}
                        checked={fuValues[el.name]?.includes(el.label)}
                        disabled={props.readOnly}
                    />
                }

                

                {el.type === 'label' &&
                    <label
                        name={el.name} 
                        data-input-type={el.type}
                    >
                        {/* {el.value} */}
                    </label>
                }   



                {(el.type === 'checkbox' || el.type === 'radio') && el.label !== '' ? <label htmlFor={el.id}>{el.label}</label> : null} 

                
            </div>
            return element
    }



    const renderDisplayName = () => {
        const element = 
            <>
                {/* {props.errors.length > 0 && props.errors.includes(props.question.display_name_short) ? 
                    <span className='text-danger'>{props.question.display_name}</span> 
                    :
                    <span>{props.question.display_name}</span>
                } */}
                <span>{props.question.display_name}</span>
            </>

        return element
    }

    function OnInput() {
        this.style.height = 0;
        this.style.height = (this.scrollHeight) + "px";
    }

    useEffect(() => {
        const tx = document.getElementsByTagName("textarea");
        for (let i = 0; i < tx.length; i++) {
          tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
          tx[i].addEventListener("input", OnInput, false);
        }
    })


    if (props.question === null) {
        return null
    }
    
    return (
        <>
            {props.type === 'table' &&
                <tr ref={trRef}>
                    {
                        // do not output display name if empty
                        props.question.display_name !== null && props.question.display_name !== "" &&
                        <td >
                            {renderDisplayName()}
                        </td>
                    }
                    {elements.map((el, ix) => {
                        return (
                            el !== null && (
                            <td key={ix}>
                                {renderElelment(el,ix)}
                            </td>
                            )
                        )
                        
                    })}
                </tr>
            }

            {props.type === 'single' && (
                <>
                    {
                        // do not output display name if empty
                        props.question.display_name !== null && props.question.display_name !== "" &&
                        renderDisplayName()
                    }

                    {elements.map((el, ix) => {
                        return (
                            el !== null &&                             
                                renderElelment(el,ix, props.type)
                        )

                    })}
                </>
            )}

            {props.type === 'columns' &&
                <>
                    {
                        // do not output display name if empty
                        props.question.display_name !== null && props.question.display_name !== "" &&
                        <div /*className='col-4'*/ >
                            {renderDisplayName()}
                        </div>
                    }
                    {elements.map((el, ix) => {
                        return (
                            el !== null && (
                            <div /*className='col-4'*/ key={ix}>
                                {renderElelment(el,ix)}
                            </div>
                            )
                        )
                        
                    })}
                </>
            }

            {subRows.length > 0 &&
                subRows.map((sr, srix) => (
                <FollowupRow2
                    key={srix}
                    // errors={props.errors}
                    question={sr}
                    // onValuesUpdate={updateValues}
                    // data={capevData}
                    // headers={headers}
                    type="columns"
                    // readOnly={props.readOnly}
                    // setErrors={changeErrors}
                    fu={props.fu}
                />


                ))


            
            }
        </>



    )
}




export default FollowupRow2
