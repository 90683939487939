import React, { useState, useEffect, useContext } from 'react';
import PatientContext from '../../../context/patientContext';
import UserPrefContext from '../../../context/userPrefContext';
import AuthContext from '../../../context/authContext';
import ReturnIntraopImage from './ReturnIntraopImage';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styles from './ReturnIntraopImage.module.css'


const IntraopPanelDisplayList = (props) => {

  const [intraopList, setIntraopList] = useState();
  const [imgArray, setImgArray] = useState([]) 

  const visCtx = useContext(PatientContext);
  const userPrefCtx = useContext(UserPrefContext)
  const authCtx = useContext(AuthContext)

  const IntraopListURL = process.env.REACT_APP_SERVER + "/api/v1/intra_op_images/get_all_by_token/" + visCtx.pat_token;

  useEffect(async() => {
    if (userPrefCtx.bearerToken) {
      const headers = { Authorization: `Bearer ${userPrefCtx.bearerToken}`, auth: authCtx.authMode };

      try {
        const response = await fetch(IntraopListURL, { headers })
        if (response.status === 200) {

          const responseData = await response.json();
          //console.log('All Intraops: ', responseData);

        //This is sorting the the objects by when it was created and then putting it in a useState
        setIntraopList(responseData.sort((a, b) =>a.created_at < b.created_at ? 1 : -1));
        //setIntraopList(responseData);

        } else {
          console.log("not 200");
        }
      } catch (e) {
        console.log(e);
      }


    }
    else {
      console.log('no token')
    } //The triggers for the useEffect will be when a new Intraop is created, edited, or deleted
}, [userPrefCtx.bearerToken, props.sendUpdate, props.sendDeleteTrigger, visCtx.pat_token]);

useEffect(() => {
    if (intraopList !== null || intraopList !== undefined) {
        const splitSize = 3
        let images = []
        for (let i = 0; i < intraopList?.length; i += splitSize) {
             images.push(intraopList.slice(i, i + splitSize));
        }
        //console.log(images);
        setImgArray(images);
    }

}, [intraopList])



  
  return (
    <div>
        {imgArray.length > 0 && 
        imgArray.map((img_row, row_ix) => {
            // output images 3 in a row
            return (
                <Row key={`img_${row_ix}`}>
                    {img_row.map((img, img_ix) => {
                        return (
                            <Col xs={4} md={9} lg={7} xl={4} key={`img_${img_ix}`} 
                              className={styles.padding_underneath_thumbnails}
                            >
                                <ReturnIntraopImage 
                                  mode='thumb'
                                  sendObject={img}
                                  isImageSelected={props.selectedIntraopImages.indexOf(img) >= 0}
                                  onIntraopImageSelect={(object) => props.onIntraopImageSelect(object) }
                                />
                                {/* {console.log(props.selectedIntraopImages)} */}
                                {/* {console.log('props.selectedIntraopImages.indexOf(img?.file_name) is ',props.selectedIntraopImages.indexOf(img?.file_name))} */}
                                {/* {console.log(img?.file_name)} */}
                            </Col>
                        
                        )
                    })}
                </Row>
            )
            })
    }

    </div>
  );
};

export default IntraopPanelDisplayList;