import React, {useState, useEffect, useContext, useRef, useLayoutEffect} from 'react'
import './OneNote.css'
import Form from "react-bootstrap/Form"
import DOMPurify from 'dompurify'
import moment from 'moment'
import CompanyContext from '../../../context/companyContext'
import { useDebouncedCallback } from 'use-debounce'



// props:
// visit
function OneNote(props) {
    // console.log("One Note - props", props)

    const companyCtx = useContext(CompanyContext)
    const [noteText, setNoteText] = useState('')
    const refDiv = useRef()
    const [embedHeight, setEmbedHeight] = useState(0)


    const getPdfNoteURL = (pdf_name) => {
        return `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/visit_notes/${pdf_name}?patient_id=${props.visit.Visit.patient_id}&dict_company_code_id=${companyCtx.company.id}#toolbar=0&navpanes=0&scrollbar=0&view=Fit`
    }



    const debounced = useDebouncedCallback(
        (value) => {
            setEmbedHeight(value)
        },
        // delay in ms
        500
    )

    useEffect(() => {
        if (props.visit.Note !== null) {
            if (!!props.visit.Note.note_html) {
                setNoteText(props.visit.Note.note_html)
            } else {
                setNoteText(props.visit.Note.note_plain || '')
            }
        } else {
            setNoteText('')
        }
    }, [props.visit])

    
    
    useEffect(() => {
        if (!refDiv.current) return
        const resizeObserver = new ResizeObserver((entries) => {
            if (props.visit.Note && props.visit.Note.hasOwnProperty('note_file_name') && !!props.visit.Note.note_file_name) {
                let {width} = refDiv.current.getBoundingClientRect()
                // adjust width for padding
                const paddingLeft =window.getComputedStyle(refDiv.current, null).getPropertyValue('padding-left')
                const paddingRight =window.getComputedStyle(refDiv.current, null).getPropertyValue('padding-right')
                // convert padding to number and adjust the size of the box for PDF
                const padL = paddingLeft.replace("px", "")
                const padR = paddingRight.replace("px", "")
                if (!isNaN(+padL )) {
                    width -= +padL
                }
                if (!isNaN(+padR )) {
                    width -= +padR
                }
                // 0.78 is approximate letter page aspect ratio width/height = 0.78
                // calculate letter page height for the given width
                const height = width/0.78

                // debounce setting up height in pixels (need to debounce otherwise it keeps
                // recalculating while the user dragging the size and it causes error in ResizeObserver "loop completed with undelivered notifications."")
                debounced(`${height}px`)
            }
        });

        resizeObserver.observe(refDiv.current)
        
        return () => {
            // clean up 
            resizeObserver.disconnect(); 
        }   
    },[])


    if (props.visit.Note === null) {
        return null
    }

    return (
        <div className='one-note-note' ref={refDiv}>
            <div className='one-note-date'>
                {moment(props.visit.Visit.visit_date).format('MM/DD/YYYY')}
            </div>
            

            {!!!props.visit.Note.note_html &&
                <div className='one-note-the-note'>
                    {noteText}
                </div>
            
            }





            {!!props.visit.Note && !!props.visit.Note.note_html &&
                <div className='d-flex' >
                <div className='inline-block'
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.visit.Note.note_html) }} />
                </div>
            }   





            {!!props.visit.Note && props.visit.Note.hasOwnProperty('note_file_name') && !!props.visit.Note.note_file_name &&
                <div id='pdf'  /*className='d-flex w-100'*/ >
                    <iframe className='d-flex w-100'
                        src={getPdfNoteURL(props.visit.Note.note_file_name)} 
                        type='application/pdf' 
                        width='100%' 
                        height={embedHeight}
                        >
                        
                    </iframe>

{/* 
                    <embed 
                        src={getPdfNoteURL(props.visit.Note.note_file_name)} 
                        type='application/pdf' 
                        width='100%' 
                        height={embedHeight}
                        /> */}

                </div>
            }    

            <hr className='one-note-separator'></hr>
        </div>
    )
}

export default OneNote
