import React, {useState, useEffect, useContext} from 'react'
import {BsFileWord, BsFileExcel, BsFilePdf, BsFileText, BsFileZip, BsFile, BsFileImage, BsCameraVideo} from 'react-icons/bs'
import {IconContext} from 'react-icons'
import {getFileExtension, fileExtensionType} from '../../../utils/Utils'
import CompanyContext from '../../../context/companyContext'


// pros:
// document - document object as comes from API
// mode - 'inline' | 'full'; inline used for showing visits in the list ob the visit page; full is for visit detail dialog
// patient_id
function VisitDocument(props) {
    const companyCtx = useContext(CompanyContext)
    const documentURL = (filename, patient_id) => `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/document/${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}`

    const [icon, setIcon] = useState('BsFileText')
    const [iconSize, setIconSize] = useState('1em')
    const [iconColor, setIconColor] = useState('gray')

    const [style, setStyle] = useState({ className: "global-class-name", size: '2em', color:'gray'})


    useEffect(() => {
        // console.log(props)
        // get document type from the document metadata
        if (props.document.file_name !== null) {
            const ext = getFileExtension(props.document.file_name).toLowerCase()

            switch (fileExtensionType(ext)) {
                case 'word':
                    setIcon('BsFileWord')
                    setIconColor('blue')
                    break;
                case 'excel':
                    setIcon('BsFileExcel')
                    setIconColor('green')
                    break;
                case 'pdf':
                    setIcon('BsFilePdf')
                    setIconColor('red')
                    break;
                case 'text':
                    setIcon('BsFileText')
                    setIconColor('gray')
                    break;
                case 'compressed':
                    setIcon('BsFileZip')
                    setIconColor('black')
                    break;
                case 'image':
                    setIcon('BsFileImage')
                    setIconColor('green')
                    break;
                case 'video':
                    setIcon('BsCameraVideo')
                    setIconColor('blue')
                    break;

                default:
                    setIcon('BsFile')
                    setIconColor('black')
            }
        }

    }, [props.document, props.mode])




    useEffect(() => {
        setStyle({...style, color: iconColor, size: props.mode === 'full' ? '5em' : '3em'})
    }, [iconColor])


    const toggleSelected = () => {
        if (props.mode === 'full') {
            props.onSelect(props.document)
        }
        
    }


    // depending on the mode we want click on the document open it or select it
    // in inline mode - open document
    // in full mode - toggle selection
    const documentRenderer = () => {
        return (
            <div className={`icon-with-text-under ${props.isSelected ? 'border-selected' : 'border-not-selected'}`} onClick={toggleSelected} >
                {icon === 'BsFileWord' &&
                    <BsFileWord  />
                }
                {icon === 'BsFileExcel' &&
                    <BsFileExcel />
                }
                {icon === 'BsFilePdf' &&
                    <BsFilePdf  />
                }
                {icon === 'BsFileText' &&
                    <BsFileText  />
                }
                {icon === 'BsFileZip' &&
                    <BsFileZip  />
                }
                {icon === 'BsFile' &&
                    <BsFile  />
                }
                {icon === 'BsFileImage' &&
                    <BsFileImage  />
                }
                {icon === 'BsCameraVideo' &&
                    <BsCameraVideo  />
                }
                <span className='icon-caption'>{props.document.orig_file_name}</span>
            </div>
        )
    }


    if (props.mode === 'full') {
        return (
            <IconContext.Provider value={style}>
                {documentRenderer()}
            </IconContext.Provider>
        )
    }

    if (props.mode === 'inline') {
        return (
            <IconContext.Provider value={style}>
                <a href={documentURL(props.document.file_name, props.patient_id)} download={props.document.file_name} target="_blank" >
                {documentRenderer()}
                </a>
            </IconContext.Provider>
        )
    }

    return null

    return (
        <IconContext.Provider value={style}>
            {/* <a href={documentURL(props.document.file_name, props.patient_id)} download={props.document.file_name} target="_blank" > */}
            <div className={`icon-with-text-under ${props.isSelected ? 'border-selected' : 'border-not-selected'}`} onClick={toggleSelected} >
                {icon === 'BsFileWord' &&
                    <BsFileWord  />
                }
                {icon === 'BsFileExcel' &&
                    <BsFileExcel />
                }
                {icon === 'BsFilePdf' &&
                    <BsFilePdf  />
                }
                {icon === 'BsFileText' &&
                    <BsFileText  />
                }
                {icon === 'BsFileZip' &&
                    <BsFileZip  />
                }
                {icon === 'BsFile' &&
                    <BsFile  />
                }
                {icon === 'BsFileImage' &&
                    <BsFileImage  />
                }
                {icon === 'BsCameraVideo' &&
                    <BsCameraVideo  />
                }
                <span className='icon-caption'>{props.document.file_name}</span>
            </div>
            {/* </a> */}
        </IconContext.Provider>
    )
}

export default VisitDocument;
