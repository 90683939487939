import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, Redirect, useNavigate, Navigate} from 'react-router-dom';
import PatientContext from '../../../context/patientContext';
import CompanyContext from '../../../context/companyContext';
import AuthContext from '../../../context/authContext';
import Table from 'react-bootstrap/Table';

const ORSchedule = (props) => {

  const navigate = useNavigate();
  const patCtx = useContext(PatientContext);
  const companyCtx = useContext(CompanyContext);
  const authCtx = useContext(AuthContext)

  // const [props.sendData, setAppts] = useState([]);
  
  // //console.log(props.sendData);

  // const token = props.sendBearerToken;
  // const id = props.sendProvider;
  // const date = props.sendCalendar;

  // useEffect(async () => {
  //   let url = process.env.REACT_APP_SERVER + `/api/v1/search_services/retrieve_provider_or_schedule?provider_id=${props.sendProvider}&visit_date=${props.sendCalendar}`;
    
  //   if ((token) && (id != null)) {
  //     const headers = { Authorization: `Bearer ${token}` };
  //     try {
  //       const response = await fetch(url, { headers });
  //       //console.log(res.status);
  //       if (response.status === 200) {
  //         const data = await response.json();
  //         setAppts(data.response_data);
  //         //setAppts(data);
  //         //console.log('schedule response', response)
  //         //console.log(data.response_data);
  //         console.log(data);

  //       } else {
  //         // console.log(res.status, res.detail);
  //         console.log('not 200');
  //         //setAppts(null);
  //       }
  //     } catch (e) {
  //       //console.log('in the catch block',e);
        
  //       //This will make sure that when there is no appointments for a provider, the calendar won't show
  //       setAppts(null);
  //     }
  //   } else {
  //     //This will help to not show the calendar if nothing is selected
  //     setAppts(null);
  //     console.log('this is going to the else statement and setting setAppts(null) ')
  //   }
  // }, [id, date]);

  const selectMRN = async(e) => {
    //let stringMRN = e.target.getAttribute('data-mrn');
    //console.log(e)
    let stringMRN = e;
    //console.log(stringMRN);

    const token = props.sendBearerToken;

    //This value is stringified
    // let url = `${process.env.REACT_APP_SERVER}/api/v1/search_services/patient_search_with_site_loc?search_token=${stringMRN}&patient_search_mode=${companyCtx.company.patient_search_mode}`;
    let url = `${process.env.REACT_APP_SERVER}/api/v1/search_services/patient_search_with_site_loc_epic?search_token=${stringMRN}&patient_search_mode=${companyCtx.company.patient_search_mode}`;

    if (url != null) {
      const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            auth: authCtx.authMode,
          },
        })

        const jsonResponse = await response.json()
        if ( jsonResponse.response_code === 1) {
          //return jsonResponse.response_data
          createPatient(jsonResponse.response_data[0]);
        }
      }
  }

  const createPatient = async(e) => {

    let postURL = process.env.REACT_APP_SERVER + `/api/v1/patients/creat_patient_with_mrns`;

    // var strdob = e.dob;
    // strdob = strdob.split('/');
    // var dobresult = strdob[2] + '-' + strdob[0] + '-' + strdob[1];
    //console.log(dobresult);

    // patCtx.pat_dob = e.dob
    patCtx.pat_dob = new Date(e.dob).toISOString().split("T")[0]
    patCtx.pat_mrn = e.local_site_mrn
    patCtx.pat_first = e.first_name
    patCtx.pat_last = e.last_name
    patCtx.pat_gender = e.gender
    patCtx.pat_email = e.email

    const responseData = await fetch(postURL, {
      method: 'POST',
      body: JSON.stringify({
        "emr_mrn": `${e.local_site_mrn}`,
        "first_name": `${e.first_name}`,
        "last_name": `${e.last_name}`,
        "gender": `${e.gender}`,
        // "dob": `${dobresult}`,
        dob: new Date(e.dob).toISOString().split("T")[0],
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${props.sendBearerToken}`,
        auth: authCtx.authMode,
      },
    }).then((res) => res.json())
    .then((data) => {
       console.log(data);

       patCtx.pat_token = data.patient_token
       patCtx.dob = data.dob
       patCtx.pat_first = data.first_name
       patCtx.pat_last = data.last_name
       patCtx.pat_mrn = data.emr_mrn
       patCtx.pat_gender = data.gender
       patCtx.pat_dob = data.dob


       patCtx.pat_active_mrn = data.emr_mrn
       patCtx.pat_local_site_mrn = data.emr_mrn
       patCtx.pat_profile_image_filename = data.file_name 
       patCtx.pat_id = data.id 
       patCtx.pat_middle = data.middle_name !== null ? data.middle_name : ''

    function formatDate(dateString) {
      //This function will convert yyyy-mm-dd to mm/dd/yyyy
      //console.log(dateString)
      const [year, month, day] = dateString.split("-");
      return `${month}/${day}/${year}`;
    }

    // function formatDate(dateString) {
    //   //This function will convert yyyy-mm-dd to mm/dd/yyyy
    //   const [year, month, day] = dateString.split("-");
    //   return `${month}/${day}/${year}`;
    // }
    console.log(data);
    const formattedDate = formatDate(data.dob);

    navigate('/Visit', 
    {state: {
        firstName: data.first_name,
        lastName: data.last_name,
        middleName: data.middle_name !== null ? data.middle_name : '',
        mrn: data.emr_mrn,
        dob: formattedDate,
        gender: data.gender,
        patientToken: data.patient_token,
        bearerToken: props.sendBearerToken,
        patId: data.id,
        profileImageFileName: data.file_name, 
        authMode: authCtx.authMode,
      }})
    
    })
    .catch((err) => {
       console.log(err.message);
    });
  }

  const selectRow = (e) => {
    console.log('in selectRow function ', e)
    // let stringMRN = e.target.getAttribute('data-mrn');
    // console.log(stringMRN);
  }

//add dob to the table

  return (
    <div className="row search_row_div">
      <div className="col-sm-8 col-md-12 col-lg-8">
      { props.sendData != null && props.sendData?.length > 0 &&
      <Table bordered hover>
      <thead>
        <tr>
          <th>Patient MRN</th>
          <th>Patient Name</th>
          <th>Surgeon Type</th>
          <th>Surgery Date</th>
          <th>Surgery Time</th>
          <th>Surgery Room</th>
        </tr>
      </thead>
      <tbody>
        {
        Object.keys(props.sendData).map((item, i) => (
                                
        <tr 
          key={i} 
          // onClick={() => selectRow(props.sendData[item].patientMRN)}
          onClick={() => selectMRN(props.sendData[item].patient_mrn)}
        >
          {/* <td>{props.sendData[item].surgery_start_date.slice(5, 7)}/
              {props.sendData[item].surgery_start_date.slice(8, 10)}/
              {props.sendData[item].surgery_start_date.slice(0, 4)}
          </td> */}
          <td>{props.sendData[item].surgery_start_date}</td>
          {/* <td>{props.sendData[item].surgery_start_date.substr(props.sendData[item].surgery_start_date.length - 8)}</td> */}
          <td>{props.sendData[item].surgery_start_time}</td>
          <td>{props.sendData[item].surgery_room_name}</td>
          <td 
            //onClick={selectMRN} 
            data-mrn={JSON.stringify(props.sendData[item].patient_mrn)}
            //This is coming from the Dashboard.css
            className="provider_appt_mrn_selection patient-oddrow-mrns"
          >
            {/* <a href="#0">{props.sendData[item].patientMRN}</a> */}
            {props.sendData[item].patient_mrn}
          </td>
          <td>{props.sendData[item].patient_name}</td>
          <td>{props.sendData[item].surgeon_type}</td>
          {/* <td>{props.sendData[item].surgery_start_date.slice(5, 7)}/
              {props.sendData[item].surgery_start_date.slice(8, 10)}/
              {props.sendData[item].surgery_start_date.slice(0, 4)}
          </td> */}
          {/* <td>{props.sendData[item].surgery_start_date}</td> */}
          {/* <td>{props.sendData[item].surgery_start_date.substr(props.sendData[item].surgery_start_date.length - 8)}</td> */}
          {/* <td>{props.sendData[item].surgery_start_time}</td>
          <td>{props.sendData[item].surgery_room_name}</td> */}
        </tr>
        
        ))
        }
        
      </tbody>
    </Table>
    }
    </div>
    </div>
  );
}
export default ORSchedule;