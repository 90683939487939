import React, { useContext, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { BsPersonCircle } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
import UserPreferencesModal from "../Users/UserPreferencesModal";
// import UserPreferences from '../modals/UserPreferences';
import LogoutAction from "../components/actions/LogoutAction";
// import '../../stylesheets/NavDropDown.css';
import classes from "./NavDropDown.module.css";
import UserPrefContext from "../../context/userPrefContext";
import AuthContext from '../../context/authContext'

import { useNavigate } from "react-router-dom"
import { isObjectEmpty } from "../../utils/Utils";

const NavDropdown = (props) => { 
    const { authState, oktaAuth } = useOktaAuth()
    const authCtx = useContext(AuthContext)
    // const [show, setShow] = useState(false)
    // const [userPrefMode, setUserPrefMode] = useState('')

    const [userPrefModalProps, setUserPrefModalProps] = useState({show:false, mode:''})

    let auth = false;
    const userPrefCtx = useContext(UserPrefContext);
    // console.log("NAV: ", userPrefCtx.userPreference)
    const login = async () => oktaAuth.signInWithRedirect();
    // const logout = async () => oktaAuth.signOut();

    const navigate = useNavigate()

    if (authCtx.authMode === 'OKTA' && authState?.isAuthenticated && !!oktaAuth.getAccessToken()) {
        if (authState) {
            auth = oktaAuth.authStateManager._authState['isAuthenticated'];
        } else {
            auth = false;   
        }
    } else if (!!authCtx.token) {
        auth = authCtx.isAuthenticated
    }


    const handleDropdownSelection = (eventKey) => {
        switch (eventKey) {
            case 'main':
                props.onModeChange(eventKey)
                navigate('/')
            break

            case 'user_prefs':
                // show dialog from selecting dropdown
                // setUserPrefMode('normal')
                props.onModeChange(eventKey)
                // setShow(true)
                setUserPrefModalProps({show:true, mode:'normal'})
            break

            case 'him':
                props.onModeChange(eventKey)
                navigate('/him')
            break

            case 'ipad':
                props.onModeChange(eventKey)
                navigate('/ipad')
                break

            case 'logout':
                logout()
            break
        }
    }


    const logout = () => {
        if (authCtx.authMode === 'OKTA') {
            oktaAuth.signOut()
        } else {
            try {
                localStorage.removeItem("db-auth-access-return")
                authCtx.logout()
                // force logout on App
                props.onModeChange('main')
            }
            catch(e) {
                console.log(e)
            }
        }
        navigate('/')
    } 


    const handleHide = () => {
        // setShow(false);
        // // if closing after initial setting of User Preferences (new user) - refresh Navbar
        // if (userPrefMode === 'new user') {
        //     props.needToRefresh()
        // }

        // if (userPrefModalProps.mode === 'new user') {
        //     //props.needToRefresh()
        //     props.forceRefresh()
        // }
        setUserPrefModalProps({...userPrefModalProps, show: false})

    }




    // useEffect(() => {
    //     console.log("NavDropDown: auth", auth, "userPrefCtx.userPreference", userPrefCtx.userPreference)
    //     if (auth && isObjectEmpty(userPrefCtx.userPreference)) {
    //         // user is authenticated and user prefernce is not set - show User Prefernce Dialog
    //         // setUserPrefMode('new user')
    //         // setShow(true)
    //         setUserPrefModalProps({show:true, mode: 'new user'})
    //     } 
    // }, [userPrefCtx.userPreference, auth])


    return (
        <React.Fragment>
            <Dropdown align={{ lg: 'end' }} className={classes.dd_pad} onSelect={handleDropdownSelection}>
            { auth == true && 
                <Dropdown.Toggle id="dropdown-basic" className={classes.dropdowntogglepadding}>
                    <BsPersonCircle className={`${classes.user_icon} bi bi-person-down`} />
                </Dropdown.Toggle> 
            }

            { auth == true && authCtx.authMode === 'OKTA' &&
                <Dropdown.Menu >
                    {authState.accessToken.claims.groups.indexOf(process.env.REACT_APP_MAIN_APP_GROUP) >= 0 && 
                        <Dropdown.Item eventKey="user_prefs"> User Preference </Dropdown.Item>
                    }   
                    {props.mode === 'him' &&
                        authState.accessToken.claims.groups.indexOf(process.env.REACT_APP_MAIN_APP_GROUP) >= 0 &&  
                        <Dropdown.Item eventKey="main" >EnlightenMD</Dropdown.Item>
                    }    
                    {/* {props.mode !== 'him' &&
                        authState.accessToken.claims.groups.indexOf(process.env.REACT_APP_MAIN_APP_GROUP) >= 0 &&  
                        authState.accessToken.claims.groups.indexOf(process.env.REACT_APP_HIM_GROUP) >= 0 && 
                        <Dropdown.Item eventKey="him" >Health Information Management</Dropdown.Item>
                    }

                    {props.mode !== 'ipad' &&
                        authState.accessToken.claims.groups.indexOf(process.env.REACT_APP_MAIN_APP_GROUP) >= 0 &&  
                        authState.accessToken.claims.groups.indexOf(process.env.REACT_APP_IPAD_GROUP) >= 0 && 
                        <Dropdown.Item eventKey="ipad" >iPad</Dropdown.Item>
                    } */}

                    {/* {authState.accessToken.claims.groups.indexOf(process.env.REACT_APP_IPAD_GROUP) >= 0 && 
                        <Dropdown.Item eventKey="ipad" >iPad</Dropdown.Item>
                    } */}

                    <Dropdown.Item eventKey="logout">Logout</Dropdown.Item>
                </Dropdown.Menu> 
            }

            { auth == true && authCtx.authMode !== 'OKTA' &&
                <Dropdown.Menu >

                    <Dropdown.Item eventKey="user_prefs"> User Preference </Dropdown.Item>
 
                    <Dropdown.Item eventKey="logout">Logout</Dropdown.Item>
                </Dropdown.Menu> 
            }


            </Dropdown>
            {/* <UserPreferencesModal show={show} onHide={handleHide} onLogout={logout} mode={userPrefMode} heading="User Preferences" company={props.company} /> */}
            <UserPreferencesModal show={userPrefModalProps.show} onHide={handleHide} onLogout={logout} mode={userPrefModalProps.mode} /*heading="User Preferences"*/  />
        </React.Fragment>
    )
}


export default NavDropdown;
