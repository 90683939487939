import React, { useEffect, useState, useContext } from 'react';
import classes from './UserPreferencesModal.module.css';
import dictionaryContext from '../../context/dictionaryContext';
import UserPrefContext from '../../context/userPrefContext';
import CopyUserPrefContext from '../../context/copyUserPrefContext';
import AuthContext from '../../context/authContext'
import Form from 'react-bootstrap/Form'
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import CompanyContext from '../../context/companyContext';
// import { endsWith } from 'lodash';
import {isObjectEmpty} from '../../utils/Utils'

const UserPreferencesList = (props) => {
    const dictCtx = useContext(dictionaryContext)
    const userPrefCtx = useContext(UserPrefContext)
    const copyUserPrefCtx = useContext(CopyUserPrefContext)
    const authCtx = useContext(AuthContext)
    const companyCtx = useContext(CompanyContext)

    const [sites, setSites] = useState([])
    const [selectedSite, setSelectedSite] = useState('')
    const [errors, setErrors] = useState({})
    
    useEffect(() => {
        setErrors(props.errors)
    }, [props.errors])

    useEffect(() => {
        let ignore = false

        const getPrefs = async () => {
            const url = `${process.env.REACT_APP_SERVER}/api/v1/user_preferences/me`
            const headers = {
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                Authorization: `Bearer ${authCtx.token}`,
                auth: authCtx.authMode
            }
        
            const response = await fetch(url, {
                headers: headers,
            })
            .then((res) => res.json())
            .then((data) => {
                //   console.log("UserPreferencesList - returned from get ",data) 
    
                //   console.log("UserPreferencesList - returned from get",JSON.parse(data)) 
    
                //   let dataobj = JSON.parse(data.UserPreference)
                //   console.log("UserPreferencesList - dataobj ",dataobj) 
                //   console.log("UserPreferencesModal - before update",userPrefCtx.userPreference) 
        
                //   console.log("UserPreferencesModal - new preferences will be",{...userPrefCtx.userPrefState, UserPreference: data.UserPreference}) 
        
                // update user context
                if (!ignore) {
                    // if no user preferences yet build sites list
                    // console.log("UserPreferencesList - dictCtx ",dictCtx) 
                    // if (!!!data.UserPreference) {
                    //     if (dictCtx.dict_site_loc.length > 0) {
                    //         let tempSites = dictCtx.dict_site_loc
                    //             // .filter(siteloc => siteloc.dict_company_code_id === companyCtx.company.id)
                    //             .sort((siteloc1, siteloc2) => siteloc1.site_loc_name > siteloc2.site_loc_name ? 1 : -1)
                    //             // .map(siteloc => siteloc.site_loc_code)
                    //         console.log("UserPrefList - sites", tempSites)
                    //         setSites(tempSites)
                
                            
                    //     }
                    // }
                    // important!!!
                    // due to Python nature arrays of strings returned not as correct json - they have single quotes around array elements
                    // instead of double quotes
                    // so array properties of UserPreference object are strings. We are going to fix it here and load them as arrays
                    let userPreference = !!data.UserPreference ? data.UserPreference : {}
                    if (!isObjectEmpty(userPreference)) {
                        Object.keys(userPreference).forEach(key => {
                            if (typeof userPreference[key] === "string" && userPreference[key].startsWith('[')) {
                                userPreference[key] = JSON.parse(userPreference[key].replace(/'/g, '"'))
                            }
                        })
                        userPrefCtx.setUserPrefState({...userPrefCtx.userPrefState, UserPreference: userPreference});
                        copyUserPrefCtx.setUserPrefState({...copyUserPrefCtx.userPrefState, UserPreference: userPreference}) 
                        // userPrefCtx.setUserPrefState({...userPrefCtx.userPrefState, UserPreference: data.UserPreference}) 
                        // copyUserPrefCtx.setUserPrefState({...copyUserPrefCtx.userPrefState, UserPreference: data.UserPreference}) 
                    }
                    
                }

                // setConsentDictionary(data.sort((d1,d2) => d1.id - d2.id))
            })
            .catch((err) => {
                console.log(err.message);
            })
        }

        // console.log("UserPreferencesList - getting preferences from API")
        if (!!authCtx.authMode) {
            getPrefs()
        }

        // console.log("UserPreferencesList - dict modal filtered", dictCtx.dict_modal
        //     .filter(key => key.group_display_name_short.endsWith('_user_preference')===true)
        //     .sort((a,b) => a.group_id - b.group_id))

        return () => {
            ignore = true
        }

    },[authCtx])

    
    const onSelect = (event) => {
        setSelectedSite(event.target.value)
        // console.log("{...errors, site_loc: null}", {...errors, site_loc: null})
        setErrors({...errors, site_loc: null})
    }

    // if (userPrefCtx.userPreference === null) {
    //     return <></>
    // } 

    return (
        <React.Fragment>
            {isObjectEmpty(userPrefCtx.userPreference) &&
                // <div className='container row'>
                    <FormGroup /*className={props.className}*/ className='container row'>
                        <FormLabel className='col-form-label col-sm-1 my-0'><h3>Site:</h3></FormLabel>
                        <div className='col-sm-4 my-1'>
                            <Form.Control
                                as="select"
                                className={!!errors.site_loc ? classes.error: 'xxx'}
                                name='site_dropdown' 
                                size='md'
                                onChange={onSelect}
                                value={selectedSite}
                            >
                                {selectedSite == '' &&
                                    <option value=''>Select Site Location</option>
                                }
                                
                                 {dictCtx.dict_site_loc.sort((siteloc1, siteloc2) => siteloc1.site_loc_name > siteloc2.site_loc_name ? 1 : -1).map((site) => 
                                <option 
                                        key={site.site_loc_code}
                                        value={site.site_loc_code}
                                    >
                                        {`${site.site_loc_code} (${site.site_loc_name})`}
                                    </option>
                                )}
                            </Form.Control>
                            <Form.Label className={classes.error}>{errors.site_loc}</Form.Label>
                        </div>
                        <hr />

                    </FormGroup>
                // </div>
            }
            <div className='container row'>
                {dictCtx.dict_modal
                .filter(key => key.group_display_name_short.endsWith('_user_preference')===true)
                .sort((a,b) => a.group_id - b.group_id)
                .map((heading, i) => {
                    return (
                    <div
                        key={i}
                        className={
                            heading.display_name_short === 'user_dept' ||
                            heading.display_name_short === 'panel_list'
                                ? 'col-12'
                                : `col-4 ${classes.handle_overflow}`
                        }
                    >
                        <h3>{heading.display_name}:</h3>

                        {heading.display_name_short === 'panel_list'
                        ? dictCtx.dict_panel
                            .sort((a, b) => a.sort_position - b.sort_position)
                            .map((panel, index) => {
                                // intervention and diagnosis panels should always be present - set their values to "checked" and hide them
                                // that way we wil laways send them beck in the list of panels
                                return (
                                <div key={panel.display_name_short} 
                                    className={`${panel.display_name_short === 'intervention_panel' || panel.display_name_short === 'diagnosis_panel' ? classes.hidden: ''}`}>
                                    <div className={`${classes.dept_header} `}>
                                        <input
                                            id={`${heading.display_name_short}_${panel.id}`}
                                            key={panel.display_name_short}
                                            type={heading.question_option}
                                            data-name={heading.display_name_short}
                                            data-value={panel.id}
                                            defaultChecked={
                                                panel.display_name_short === 'intervention_panel' || panel.display_name_short === 'diagnosis_panel' ?
                                                true 
                                                : 
                                                (!isObjectEmpty(userPrefCtx.userPreference) ? userPrefCtx.userPreference.panel_list.indexOf(panel.id) > -1  : false)}
                                        />
                                        <i className={classes.buffer} />
                                        <label htmlFor={`${heading.display_name_short}_${panel.id}`}>{panel.display_name}</label>
                                        
                                    </div>
                                </div>
                                )
                            })
                        : dictCtx.dict_department.map((dept, index) => {
                            // console.log("UserPreferenceList: dept", dept, "heading.display_name_short", heading.display_name_short)
                            // console.log("UserPreferenceList: userPrefCtx.userPreference!", userPrefCtx.userPreference)
                            // console.log("UserPreferenceList: userPrefCtx.userPreference[heading.display_name_short]", userPrefCtx.userPreference[heading.display_name_short])
                            return (
                                <div key={dept.display_name_short}>
                                    <div className={`${classes.dept_header}`}>
                                        <input
                                            id={`${heading.display_name_short}_${dept.display_name_short}`}
                                            key={index}
                                            name={
                                                heading.question_option === 'radio'
                                                ? heading.display_name_short
                                                : `${heading.display_name_short}_${dept.display_name_short}`
                                            }
                                            data-name={heading.display_name_short}
                                            data-value={dept.display_name_short}
                                            type={heading.question_option}
                                            defaultChecked={
                                                !isObjectEmpty(userPrefCtx.userPreference) ? 
                                                    userPrefCtx.userPreference[heading.display_name_short].indexOf(dept.display_name_short) >= 0
                                                    :
                                                    false
                                            }
                                        />
                                        <i className={classes.buffer} />
                                        <label htmlFor={`${heading.display_name_short}_${dept.display_name_short}`}>{dept.display_name}</label>
                                    </div>

                                </div>
                            )
                            })}
                            {heading.question_option === 'radio' && heading.display_name_short === 'user_dept' &&
                                <label className={classes.error}>{errors.user_dept}</label>
                            }
                        <hr />
                    </div>
                    )
                })}
            </div>
        </React.Fragment>
    )
}

export default UserPreferencesList;
