import React, {useEffect, useState, useRef} from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {toLocalDate} from '../../../utils/Utils'

// shows total score on eFaces and faceInstruments modals
// when hovered over shows the list of all scores sorted by dates in form of date: score
function TotalScore(props) {

    // props: 
    // data - array of data, sorted by date
    // propery_value_name: property to extract
    // property_date_name
    // first_or_last: {'first'|'last'} - which element value to show

    const [first_last, set_first_last] = useState(0)

    useEffect(() => {
        if (props.data.length > 0) {
            switch(props.first_or_last) {
                case 'first':
                    set_first_last(0)
                    break
                case 'last':
                    set_first_last(props.data.length-1)
                    break
                default:
                    set_first_last(0)
            }
        }
    }, [props.first_or_last, props.data])

    return (
        <>
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip id='total_score_tooltip'>
                <ul>
                    {props.data.map(data_element => {
                        return (
                            <li key={data_element.id}>{toLocalDate(data_element[props.property_date_name])}: {data_element[props.property_value_name]}</li>
                        )}
                    )}

                            
                </ul>
            </Tooltip>
          }
        >
            <span className='total-score'> {props.data[first_last][props.property_value_name]}</span>

        </OverlayTrigger>
            
            
      </>
    )
}

export default TotalScore
