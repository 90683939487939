import React from 'react'
import DOMPurify from 'dompurify'

function VisitHTMLNote(props) {

    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

    // if (props.visit.Note[0].note_plain !== null) {
    //     console.log("VisitHTMLNote: note before", props.visit.Note[0].note_plain)
    //     console.log("VisitHTMLNote: note after", props.visit.Note[0].note_plain.replace(/(?:\\[rn]|[\r\n]+)+/g, ""))
    //     console.log("isSafari", isSafari)
    // }

    if (props.visit.Note === null || props.visit.Note.length === 0) {
        return (
            <span>Note: </span>
        )
    } 

    
    if (!!props.visit.Note[0].note_html) {
        return (
            <>
            {/* <p>Note:</p>  */}
            <div className='d-flex' >
                <div className='inline-block '>Note:</div>
                <div className='visit-html-note-one-line inline-block '
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.visit.Note[0].note_html) }} />
            </div>
            {/* <div className='visit-html-note-one-line inline-block'
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.visit.Note[0].note_html) }} /> */}
            </>
        )
    } else if (props.visit.Note[0].note_plain !== null) {
        return (
            <>
            {/* <p> */}
                {/* Note: */}
            {/* </p>  */}
            {/* Remove \r\n in Safari - they break plain text in Safari */}
            {isSafari && 
                <span className='one-note-short-note text-truncate'>Note: {props.visit.Note[0].note_plain.replace(/(?:\\[rn]|[\r\n]+)+/g, "")}</span>
            }
            {!isSafari &&
                <span className='one-note-short-note text-truncate'>Note: {props.visit.Note[0].note_plain}</span>
            }
           
            </>
        )
    }

    

    // else
    return null
}

export default VisitHTMLNote
