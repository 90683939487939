import React, { useState, useRef, useContext, useEffect } from "react";
import { format, parse } from "date-fns";
import PatientContext from "../../../context/patientContext";
import visitPanelContext from "../../../context/visitPanelContext";
import userPrefContext from "../../../context/userPrefContext";
import DictionaryContext from '../../../context/dictionaryContext'
import AuthContext from "../../../context/authContext";
import CopyUserPrefContext from "../../../context/copyUserPrefContext";

//import format from 'date-fns/format'
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import VisualTreatmentModal from "../modals/VisualTreatmentModal";
import InterventionModal from "../modals/InterventionModal";
import FaceInstrumentModal from "../faceinstr/FaceInstrumentModal";
import EFaceModal from "../efaces/EFaceModal";
import InterventionPanelDisplayList from "./InterventionPanelDisplayList";
import EFaceCharts from "../efaces/EFaceCharts";
import EFaceChartsModal from "../efaces/EFaceChartsModal";
import FaceInstrumentCharts from "../faceinstr/FaceInstrumentCharts";
import FaceInstrumentChartsModal from "../faceinstr/FaceInstrumentChartsModal";
import CapeVModal from "../capev/CapeVModal";
import CapeVList from "../capev/CapeVList";
import GrbasModal from "../grbas/GrbasModal";
import GrbasList from "../grbas/GrbasList";
import DiagTypeChoiceModal from "../diags/DiagTypeChoiceModal";
import DiagsList from '../diags/DiagsList'
import DiagsModal from "../diags/DiagsModal";
import BotoxMapPanelDisplayList from "./BotoxMapPanelDisplayList";
import IntraopUpload from "../fileuploads/IntraopUpload";
import IntraopPanelDisplayList from "./IntraopPanelDisplayList";
import styles from "./VisitPanels.module.css"


const VisitPanels = (props) => {
  const [idData, setIdData] = useState();
  const [deleteBotoxTrigger, setDeleteBotoxTrigger] = useState();
  const [deleteIntraopTrigger, setDeleteIntraopTrigger] = useState();
  const [diagFromChild, setDiagFromChild] = useState();
  const [interventionFromChild, setInterventionFromChild] = useState();
  const [intraopReturnFromChild, setIntraopReturnFromChild] = useState();
  const [diagIDList, setDiagIDList] = useState([]);
  const [diagListforIntervention, setDiagListforIntervention] = useState();
  const [interventionIDList, setInterventionIDList] = useState([]);
  const [efaceChartsShow, setEfaceChartsShow] = useState(false);
  const [efaceChartsLoadedTime, setEfaceChartsLoadedTime] = useState(
    new Date()
  );
  const [faceInstrumentChartsShow, setFaceInstrumentChartsShow] =
    useState(false);
  const [faceInstrumentLastUpdatedTime, setFaceInstrumentLastUpdatedTime] =
    useState(new Date().getTime());
  const [faceInstrumentLastLoadedTime, setFaceInstrumentLastLoadedTime] =
    useState(0);
  const [capevShow, setCapevShow] = useState(false);
  const [capevData, setCapevData] = useState([]);
  const [capevRefreshRequired, setCapevRefreshRequired] = useState(true);
  const [grbasShow, setGrbasShow] = useState(false);
  const [grbasData, setGrbasData] = useState([]);
  const [grbasRefreshRequired, setGrbasRefreshRequired] = useState(true);

  //I'm creating this state to get a response from the modal when there is a new capev created or edited
  const [capevModalResponseData, setCapevModalResponseData] = useState();
  //I'm creating this state to get a response from the modal when there is a new grba created or edited
  const [grbasModalResponseData, setGrbasModalResponseData] = useState();
   //I'm creating this state to get a response from the modal when there is a new diag created or edited
   const [diagModalResponseData, setDiagModalResponseData] = useState();

//   const [showDiagsModal, setShowDiagsModal] = useState(false)
//   const [selectedDiagId,setSelectedDiagId] = useState(0)
//   const [selectedDiagType, setSelectedDiagType] = useState('')
  const [diagTypeAndID, setDiagTypeAndID] = useState({diag_type: '', diag_id: 0})
  const [refreshDiagsList, setRefreshDiagsList] = useState(true)

  const [openEditInterventionModal, setOpenEditInterventionModal] =
    useState(false);

  const [combinedInterventions, setCombinedInterventions] = useState();

  const [copySendInterventions, setCopySendInterventions] = useState(null);

  //console.log(props.sendDictDiag.user_preference)

  const panelCtx = useContext(visitPanelContext);
  const userPrefCtx = useContext(userPrefContext);
  const patCtx = useContext(PatientContext);
  const dictCtx = useContext(DictionaryContext)
  const authCtx = useContext(AuthContext)
  const CopyUserPreferenceCtx = useContext(CopyUserPrefContext);

  const componentMounted = useRef(true)

  //const sortPanels = panelCtx.user_pref_display_name;
  let sortPanels = [];

  if(panelCtx.user_pref_display_name.length > 0) {
    sortPanels = panelCtx.user_pref_display_name;
  } else {
    sortPanels = panelCtx.dict_display_name;
  }
//   console.log("panelCtx.user_pref_display_name", panelCtx.user_pref_display_name, "panelCtx.dict_display_name", panelCtx.dict_display_name)
  //console.log('panelCtx.user_pref_panel_id is ', panelCtx.user_pref_panel_id)
  // console.log('panelCtx.user_pref_display_name_full is ', panelCtx.user_pref_display_name_full)
//   console.log('sort panels are ',sortPanels)

  const panelNameArr = [];

  for (let i = 0; i < sortPanels?.length; i++) {
    panelNameArr.push(sortPanels[i]?.display_name_short);
  }

  // console.log('panelNameArr is: ', panelNameArr)
  // console.log(panelCtx)
  // console.log(panelCtx.user_pref_display_name)

  const dragItemShortName = useRef();
  const dragOverShortName = useRef();

  const dragItemFullName = useRef();
  const dragOverFullName = useRef();

  const dragID = useRef();
  const dragOverID = useRef();

  //This is set to an array and then will be updated in the useeffect
  const [sortList, setSortList] = useState([]);
  const [fullNameList, setFullNameList] = useState([]);
  const [idList, setIDList] = useState([]);

  const dragStart = (e, position) => {
    //This is for the short display name
    dragItemShortName.current = position;
    //This is for the full display name
    dragItemFullName.current = position;
    //This is for the panel ID
    dragID.current = position;
    //console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    //This is for the short display name
    dragOverShortName.current = position;
    //This is for the full display name
    dragOverFullName.current = position;
    //This is for the panel ID
    dragOverID.current = position;
    //console.log(e.target.innerHTML);
  };

  const drop = (e) => {
    //The list will be updated when the panels are dropped
    const copyListItemsShortName = [...sortList];
    const dragItemContentShortName =
      copyListItemsShortName[dragItemShortName.current];
    copyListItemsShortName.splice(dragItemShortName.current, 1);
    copyListItemsShortName.splice(
      dragOverShortName.current,
      0,
      dragItemContentShortName
    );
    dragItemShortName.current = null;
    dragOverShortName.current = null;

    //This is for the full display name list
    const copyListItemsFullName = [...fullNameList];
    const dragItemContentFullName =
      copyListItemsFullName[dragItemFullName.current];
    copyListItemsFullName.splice(dragItemFullName.current, 1);
    copyListItemsFullName.splice(
      dragOverFullName.current,
      0,
      dragItemContentFullName
    );
    dragItemFullName.current = null;
    dragOverFullName.current = null;

    //This is for the list of ids
    const copyListID = [...idList];
    //console.log(copyListID);
    const dragIDContent = copyListID[dragID.current];
    //console.log(dragIDContent);
    copyListID.splice(dragID.current, 1);
    copyListID.splice(dragOverID.current, 0, dragIDContent);
    dragID.current = null;
    dragOverID.current = null;

    //The list will change when the panels are dragged around
    setSortList(copyListItemsShortName);

    setFullNameList(copyListItemsFullName);
    //setSortList(copyListItems);
    //console.log(list);
    setIDList(copyListID);

    //console.log(copyListItemsShortName);
    // console.log(copyListItemsFullName);
    //console.log(copyListID);

    const sendIDtoDB = [];

    //This for loop will push the ids so that they can be in the proper format for the patch call
    for (let i = 0; i < copyListID.length; i++) {
      let index = i + 1;
      let strPosition = '"' + copyListID[i] + '": ' + index;
      //let strPosition = '"' + index + '": ' + copyListID[i];
      sendIDtoDB.push(strPosition);
    }
    //console.log('sendIDtoDB2 joined is: ', sendIDtoDB.join(', '))

    let patchDB = sendIDtoDB.join(", ");
    let bracketPatchDB = "{" + patchDB + "}";

    //This will call the function for the patch call, currently commented because there is a cors error
     callPanelPatch(bracketPatchDB);
     //callPanelPatch(copyListID);

    // refresh face instrument panel
    setFaceInstrumentLastLoadedTime(0)
  };

  const callPanelPatch = async (e) => {
    //This will be the patch call to update the order of the panels
    //The drop2 function will call this function
    //console.log(props.bearerToken)
    // console.log("e in callPanelPatch: ", e);
    // console.log('userPrefCtx is ', userPrefCtx);
    // console.log('userPrefCtx is ', userPrefCtx.userPrefState);
    //console.log('Calling database to patch panels... ')

    //const userPrefURL = process.env.REACT_APP_SERVER + "/api/v1/user_preference_dict_panels/";
    const userPrefURL = process.env.REACT_APP_SERVER + "/api/v1/user_preference_dict_panels";
    //const userPrefURL = process.env.REACT_APP_SERVER + "/api/v1/user_preferences/me";

    try {
      const response = await fetch(userPrefURL, {
        method: "PATCH",
        body: JSON.stringify({
          "dict_panel_id": JSON.parse(e),
        }),
        // body: sendUserPrefObject,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userPrefCtx.bearerToken}`,
          auth: authCtx.authMode,
        },
      }).then((res) => res.json())
      .then((data) => {
        //console.log(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
      } catch (e) {
        console.log(e);
      }
  };

  //This is calling the PatientContext to set the botox buttons and the diagnosis list below
  //const patientCTX = useContext(PatientContext);

  useEffect(() => {
    //console.log('patCtx.pat_token in visit panels: ',patCtx.pat_token)

    componentMounted.current = true

    //setting the usestate to have the array in the context
    if(panelCtx.user_pref_display_name.length > 0) {
      setSortList(panelCtx.user_pref_display_name);
    //   console.log("VisitPanels - settign up sortList to", panelCtx.user_pref_display_name);
      setFullNameList(panelCtx.user_pref_display_name_full);
      setIDList(panelCtx.user_pref_panel_id);
      //props.checkIfComponentMounted(componentMounted.current)
      // setTimeout(() => {
      //   props.checkIfComponentMounted(componentMounted.current)
      // }, 500);
    } else {
      setSortList(panelCtx.dict_display_name);
    //   console.log("VisitPanels - settign up sortList to", panelCtx.dict_display_name);
      setFullNameList(panelCtx.dict_display_name_full);
      setIDList(panelCtx.dict_panel_id);
      //props.checkIfComponentMounted(componentMounted.current)
      // setTimeout(() => {
      //   props.checkIfComponentMounted(componentMounted.current)
      // }, 500);
    }
    //console.log('panelCtx.dict_display_name length is ', panelCtx.dict_display_name.length)

// console.log("Visit Panels - dictCtx.dict_panel_departments", dictCtx)

      // setTimeout(() => {
      //   props.checkIfComponentMounted(componentMounted.current)
      // }, 1000);

      return () => {
        // console.log("iPad - unmounting useEffect #1")
        componentMounted.current = false
      }


  }, [/*data,*/ panelCtx.user_pref_display_name, panelCtx.dict_display_name, patCtx]);

  useEffect(() => {
    //This is needed to update the panels when patient is changed through searchbar
    setCapevRefreshRequired(true);
    setGrbasRefreshRequired(true);
    setRefreshDiagsList(true);
    setFaceInstrumentLastLoadedTime(0)
    //console.log('inside the capev useeffect', patCtx.pat_token)
    //capevRefreshStatus(true);
  }, [patCtx.pat_token, CopyUserPreferenceCtx.userPreference]);

  const getBotoxImageID = (data) => {
    //This is the function to get the color value from the BotoxType(child) component
    //console.log("This is the id in the visit panels", data);
    setIdData(data);

    //props.visitTrigger(data)
    props.clearBotoxIDForDelete(data);
    //props.visitTrigger(Math.random)
    //props.clearBotoxIDForDelete(data)
    //props.clearBotoxIDForDelete(Math.random)
  };

  const getDeleteBotoxTrigger = (data) => {
    //This is the function to get the delete information and use it as a trigger in the botox panel
    //console.log("This is data in getDeleteBotoxTrigger ", data);
    setDeleteBotoxTrigger(data);
    props.clearBotoxIDForDelete(data);
  };

  const getDeleteIntraopTrigger = (data) => {
    //This is the function to get the delete information and use it as a trigger in the intraop panel
    //console.log("This is data in setDeleteIntraopTrigger ", data);
    setDeleteIntraopTrigger(data);
    //It's using the same function as the botox callback because it accomplishes what is needed for the intraop as well
    props.clearBotoxIDForDelete(data);
  };

  const selectedID = document.getElementById("botox-input-id");
  if (selectedID) {
    //This is to help for the selection of the botox map id button on the panel
    selectedID.addEventListener("click", function (e) {
      let selectValue = e.target.value;
      //console.log('selectvalue ', selectValue)
    });
  }

  const newDiagArray = [];

  const getDiagnosisFromChild = (data) => {
    //This is the function to get the diagnosis value from the Diagnosis(child) component
    console.log(
      "This is the newly created diagnosis in the visit panels",
      data
    );
    newDiagArray.push(data);
    setDiagFromChild(data);
    //console.log('newDiagArray is: ', newDiagArray)

    //it was saving the value twice, but now it's able to save without saving the value twice using the if statement
    if (!diagIDList.includes(data)) {
      setDiagIDList([...diagIDList, data]);
    }
    //setDiagIDList([...diagIDList, data])
    //console.log(diagIDList);
  };

  const newInterventionArray = [];

  const getInterventionfromChild = (data) => {
    newInterventionArray.push(data);
    //This is mostly to send a trigger to the interventions useEffect as it's eventually passed as a prop
    setInterventionFromChild(data);
    //console.log('newInterventionArray is: ', newInterventionArray)

    //it was saving the value twice, but now it's able to save without saving the value twice using the if statement
    if (!interventionIDList.includes(data)) {
      setInterventionIDList([...interventionIDList, data]);
    }
    //setDiagIDList([...diagIDList, data])
    //console.log(interventionIDList);

  };

  const intraopCallbackFunction = (data) => {
    setIntraopReturnFromChild(data)

  };

  // open modal dialog with eface charts
  const efaceChartsClicked = () => {
    setEfaceChartsShow(true);
  };

  const efaceChartsClose = () => {
    setEfaceChartsShow(false);
  };

  const onEfaceUpdate = () => {
    setEfaceChartsLoadedTime(new Date());
    // console.log("@@@@@@@@@@@@  setEfaceChartsLoadedTime fired" )
  };

  // open modal dialog with face instrument charts
  const faceInstrumentChartsClicked = () => {
    setFaceInstrumentChartsShow(true);
  };

  const faceInstrumentChartsClose = () => {
    setFaceInstrumentChartsShow(false);
  };

  const capeVSelected = (data) => {
    // console.log("capeVSelected - data", data)
    setCapevData([data]);
    setCapevShow(true);
  };

  const handleCapVShow = () => {
    setCapevShow(true);
  };

  const handleCapeVClose = () => {
    // clear data that we possibly viewed
    setCapevData([]);
    setCapevShow(false);
  };

  const capevRefreshStatus = (isRefreshRequired) => {
    setCapevRefreshRequired(isRefreshRequired);
  };

  const capevModalResponseFunction = (data) => {
    //This function is to get data back from the CapeVModal and set the state that will be sent to the CapeVList
    //setCapevRefreshRequired(isRefreshRequired);
    setCapevModalResponseData(data)
  };

  const grbasModalResponseFunction = (data) => {
    //This function is to get data back from the GrbasModal and set the state that will be sent to the GrbasList
    setGrbasModalResponseData(data)
  };

  
  const diagModalResponseFunction = (data) => {
    //This function is to get data back from the GrbasModal and set the state that will be sent to the GrbasList
    setDiagModalResponseData(data)
  };

  const grbasSelected = (data) => {
    setGrbasData([data]);
    setGrbasShow(true);
  };

  const handleGrbasShow = () => {
    setGrbasShow(true);
  };

  const handleGrbasClose = () => {
    // clear data that we possibly viewed
    setGrbasData([]);
    setGrbasShow(false);
  };

  const grbasRefreshStatus = (isRefreshRequired) => {
    setGrbasRefreshRequired(isRefreshRequired);
  };


  const selectDiagnosis = (diag_id, diag_type) => {
    // console.log("selectDiagnosis diag_id, diag_type", diag_id, diag_type)
    // setSelectedDiagId(diagId)
    // setSelectedDiagType(diag_type)
    setDiagTypeAndID({diag_type: diag_type, diag_id: diag_id})
    // setShowDiagsModal(true)
  }


  const createNewDiag = (diag_type) => {
    // console.log("createNewDiag diag_type",  diag_type)
    // setSelectedDiagId(0)
    // setSelectedDiagType(diag_type)
    setDiagTypeAndID({diag_type: diag_type, diag_id: 0})
    // setShowDiagsModal(true)
  }

  const onDiagsModalClose = () => {
    setDiagTypeAndID({diag_type: '', diag_id: 0})
  }


  const onDiagsListRefreshed = () => {
    setRefreshDiagsList(false)
  }

  const getDiagnosisList = (data) => {
    //This is to get the list of diagnosis' from DiagList to send data to the Intervention Modal to see whether the create intv button will be active or disabled
    setDiagListforIntervention(data)
    //console.log('getDiagnosisList data is: ', data)
  }

  const retrieveMountStatusFromDiagList = (e) => {
    //This is a callback function that will get a true or false value from the diaglist on if it was mounted
    // console.log('retrieveMountStatusFromDiagList: ', e)
    //This will send the value back to the visit page to show the all visits after the visitpanels have rendered
    props.checkIfComponentMounted(e);
  }
  

  return (
    <div className={styles.visit_panel_component_div}>
      <CardGroup id="sortable3" className="custom-card-group">
        {sortList &&
          sortList.map((item, index) => (
            <div
              onDragStart={(e) => dragStart(e, index)}
              onDragEnter={(e) => dragEnter(e, index)}
              onDragEnd={drop}
              key={index}
              draggable
              //This creates 6 panels in each row
              className={styles.visit_panels_main_div}
            >
              <Card>
                {/* Displaying the name based on context, it is pushed by the same index in the visit page */}
                <Card.Header as="h5" className={styles.card_header}>
                  {" "}
                  {/* {fullNameList[index]} [{item}, &ensp;id: {idList[index]} ]:&ensp; */}
                  {fullNameList[index]}:&ensp;
                  {/* Compare item to the short display name you are using to display the component */}

                    {item === "diagnosis_panel" && (diagTypeAndID.diag_type !== '' || diagTypeAndID.diag_id > 0) && (
                        <DiagsModal 
                            show={diagTypeAndID.diag_type !== '' || diagTypeAndID.diag_id > 0} 
                            // setShow={(show) => setShowDiagsModal(show)}
                            onClose={onDiagsModalClose}
                            // diag_type={selectedDiagType}
                            // diag_id={selectedDiagId}
                            diag_type={diagTypeAndID.diag_type}
                            diag_id={diagTypeAndID.diag_id}
                            onDiagUpdated={() => setRefreshDiagsList(true)}
                            sendResponsetoVisitPanels={diagModalResponseFunction}
                        />

                    )}

                    {item === "diagnosis_panel" &&
                        <DiagTypeChoiceModal 
                            onDiagTypeSelected={createNewDiag}   
                        /> 
                    }



                  {item === "botox_map_panel" && (
                    <VisualTreatmentModal
                      sendBotoxtoModal={props.sendBotox}
                      getModal={getBotoxImageID}
                      sendSelectedBotoxIds={props.sendSelectedBotoxIds}
                      sendBotoxTypeID={props.sendBotoxTypeID}
                      sendBotoxObjects={props.sendBotoxObjects}
                      visitTrigger={props.visitTrigger}
                      deleteTrigger={getDeleteBotoxTrigger}
                      enlightenPanelButtonClicked={() => props.enlightenPanelButtonClicked()}
                    />
                  )}
                  {item === "face_instrument_panel" && sortList.length > 0 && userPrefCtx.userPrefState.UserPreference.user_dept === dictCtx.dict_panel_departments[item] && (
                    <FaceInstrumentModal
                        mode='main'
                        setLastUpdatedTime={(time) => setFaceInstrumentLastUpdatedTime(time)}
                    />
                  )}
                  {item === "the_eface_panel" && userPrefCtx.userPrefState.UserPreference.user_dept === dictCtx.dict_panel_departments[item] &&  (
                    <EFaceModal
                      onUpdate={onEfaceUpdate}
                    />
                  )}
                  {item === "intervention_panel" && (
                    <InterventionModal
                    //   sendDictDiag={props.sendDictVisit}
                      getChild={getInterventionfromChild}
                    //   bearerToken={props.bearerToken}
                      key={Math.random()} 
                      sendDiagList={diagListforIntervention}
                    />
                  )}
                  {item === "the_eface_panel" && (
                    <EFaceChartsModal
                      show={efaceChartsShow}
                      onClose={efaceChartsClose}
                    />
                  )}
                  {item === "face_instrument_panel" && (
                    <FaceInstrumentChartsModal
                      show={faceInstrumentChartsShow}
                      onClose={faceInstrumentChartsClose}
                      lastLoadedTime={faceInstrumentLastLoadedTime}
                      lastUpdatedTime={faceInstrumentLastUpdatedTime}
                    />
                  )}
                  {item === "capev_panel" && (
                    <CapeVModal
                      show={capevShow}
                      onClose={handleCapeVClose}
                      handleShow={handleCapVShow}
                      data={capevData}
                      onAnyDataChange={capevRefreshStatus}
                      sendResponsetoVisitPanels={capevModalResponseFunction}
                    />
                  )}
                  {item === "grbas_panel" && (
                    <GrbasModal
                      show={grbasShow}
                      onClose={handleGrbasClose}
                      handleShow={handleGrbasShow}
                      data={grbasData}
                      onAnyDataChange={grbasRefreshStatus}
                      sendResponsetoVisitPanels={grbasModalResponseFunction}
                    />
                  )}
                  {item === "intraop_panel" && (
                    <IntraopUpload 
                      enlightenPanelButtonClicked={() => props.enlightenPanelButtonClicked()}
                      sendDataToParent={intraopCallbackFunction}
                      sendSelectedIntraopImages={props.sendSelectedIntraopImages}
                      getChild={getDeleteIntraopTrigger}
                    />
                  )}
                </Card.Header>
                <Card.Body>
                { ((item != "diagnosis_panel") && (item != "intervention_panel") && (item != "capev_panel") && (item != "grbas_panel")) &&
                  <Card.Title className={styles.div_visit_panel}>
                    {/* {item === "botox_map_panel" && idData} */}
                    {item === "botox_map_panel" &&
                      <div>
                        <BotoxMapPanelDisplayList 
                        //onImageSelect={(img, imgId) => props.onImageSelect(img, imgId) }
                        onImageSelect={(img, imgId, botoxTypeId, object) => props.onImageSelect(img, imgId, botoxTypeId, object) }
                        selectedBotoxImages={props.selectedBotoxImages}
                        sendUpdate={idData}
                        sendDeleteTrigger={deleteBotoxTrigger}
                        />
                      </div>
                      }
                    {item === "the_eface_panel" && (
                        <div
                            // onClick={efaceChartsClicked}
                            width="100%"
                            className={styles.div_visit_panel}
                        >
                            <EFaceCharts
                                onClick={efaceChartsClicked}
                                loaded={efaceChartsLoadedTime}
                            />
                        </div>
                    )}
                  {item === "face_instrument_panel" && (
                    <div
                    //   onClick={faceInstrumentChartsClicked}
                      width="100%"
                      className={styles.div_visit_panel}
                    >
                      <FaceInstrumentCharts
                      onClick={faceInstrumentChartsClicked}
                        lastLoadedTime={faceInstrumentLastLoadedTime}
                        setLastLoadedTime={setFaceInstrumentLastLoadedTime}
                        lastUpdatedTime={faceInstrumentLastUpdatedTime}
                        mode="inline"
                        width={"80%"}
                        viewType="small"
                      />
                    </div>
                  )}

                  {item === "grbas_panel" && (
                    <div className={styles.div_visit_panel}>
                      <GrbasList
                        onSelect={grbasSelected}
                        setData={setGrbasData}
                        refreshRequired={grbasRefreshRequired}
                        onAnyDataChange={grbasRefreshStatus}
                        sendGrbasModalResponse={grbasModalResponseData}
                      />
                    </div>
                  )}
                  {item === "intraop_panel" && (
                    <div>
                    <IntraopPanelDisplayList
                      selectedIntraopImages={props.selectedIntraopImages}
                      onIntraopImageSelect={(object) => props.onIntraopImageSelect(object) }
                      sendUpdate={intraopReturnFromChild}
                      sendDeleteTrigger={deleteIntraopTrigger}
                    />
                    </div>
                  )}
                  </Card.Title>
        }


                  { ((item === "diagnosis_panel") || (item === "intervention_panel") || (item === "capev_panel") || (item === "grbas_panel"))  &&
                  //This is for the diagnosis and intervention panels to make the li to the left
                  <Card.Title className={styles.div_visit_panel_diag_intv}>
                    {item === "intervention_panel" && (
                      <div className={styles.list_panel_div}>
                        <InterventionPanelDisplayList
                        //   bearerToken={props.bearerToken}
                          sendDictVisit={props.sendDictVisit}
                          sendAddIntervention={interventionFromChild}
                        />
                      </div>
                    )}

                    {item === "diagnosis_panel" && (
                      // This is to show the diagnosis when it is newly created on the panel
                      <div className={styles.list_panel_div}>
                        <DiagsList 
                          onSelect={(diagId,diag_type) => selectDiagnosis(diagId, diag_type)}
                          refresh={refreshDiagsList}
                          onListRefreshed={onDiagsListRefreshed}
                          sendDiagListToParent={getDiagnosisList}
                          sendDiagModalResponse={diagModalResponseData}
                          retrieveMountStatusFromDiagList={retrieveMountStatusFromDiagList}
                        />
                      </div>
                    )}

                  {item === "capev_panel" && (
                    <div className={styles.list_panel_div}>
                      <CapeVList
                        onSelect={capeVSelected}
                        setData={setCapevData}
                        refreshRequired={capevRefreshRequired}
                        onAnyDataChange={capevRefreshStatus}
                        sendCapevModalResponse={capevModalResponseData}
                      />
                    </div>
                  )}
                  {item === "grbas_panel" && (
                    <div className={styles.list_panel_div}>
                      <GrbasList
                        onSelect={grbasSelected}
                        setData={setGrbasData}
                        refreshRequired={grbasRefreshRequired}
                        onAnyDataChange={grbasRefreshStatus}
                        sendGrbasModalResponse={grbasModalResponseData}
                      />
                    </div>
                  )}
                  </Card.Title>
                  }
                  <Card.Text></Card.Text>

                </Card.Body>
              </Card>
            </div>
          ))}
      </CardGroup>
    </div>
  );
};
export default VisitPanels;
