import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import AddDictIntervention from "./AddDictIntervention";
import EditDictIntervention from "./EditDictIntervention";
import PromptDictInterventionDelete from "./PromptDictInterventionDelete";

function NewDictInt(props) {
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState();
  const [truth, setTruth] = useState(props.setOpen);

  const [editData, setEditData] = useState();
  const [deleteID, setDeleteID] = useState();
  const [alphaProp, setalphaProp] = useState();

  const [openAddInterventionModal, setOpenAddInterventionModal] = useState(false);
  const [openEditInterventionModal, setOpenEditInterventionModal] = useState(false);
  const [openDeleteInterventionModal, setOpenDeleteInterventionModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //const [isChecked, setIsChecked] = useState(false);

  //console.log(props.sendDictDashboard)

  useEffect(() => {
    //This is to sort them alphabetically
    if(props.sendDictDashboard?.dict_intervention != undefined){
    
    const alphaProp3 = props.sendDictDashboard.dict_intervention?.sort((a, b) => {
      // Sort by group_display_name
    const groupSort = a.group_display_name.localeCompare(b.group_display_name);
      if (groupSort !== 0) {
        return groupSort;
      }
      // Sort by display_name
      return a.display_name.localeCompare(b.display_name);
    });

    setalphaProp(alphaProp3);

    }

  }, [props.sendDictDashboard?.dict_intervention]);

  //console.log(alphaProp)

  const callAddDiag = () => {
    console.log("in the callAddDiag function");
    setOpenAddDiagModal(true);
  };

  // useEffect(() => {

  // }, [truth]);

  const callAddIntervention = () => {
    //console.log("in the callAddIntervention function");
    setOpenAddInterventionModal(true);
  };

  const retrieveAddData = (data) => {
    props.sendToParent(data);
    //console.log(data)
  }
  const retrieveEditData = (data) => {
    props.sendToParent(data);
    //console.log(data)
  }
  const retrieveDeleteData = (data) => {
    props.sendToParent(data);
    //console.log(data)
  }

  const handleEditClick = (data) => {
    setEditData(data)
    setOpenEditInterventionModal(true)
  };

  const handleDeleteClick = (id) => {
    //console.log(e);
    setDeleteID(id);
    //console.log(id);
    setOpenDeleteInterventionModal(true)
  };

  const closeAddIntervention = () => {
    setOpenAddInterventionModal(false);
    props.close()
  };

  const closeEditIntervention = () => {
    setOpenEditInterventionModal(false);
    props.close()
  };

  const closeDeleteIntervention = () => {
    setOpenDeleteInterventionModal(false);
    props.close()
  };

  return (
    <>
      <Modal
        size="fullscreen"
        show={props.setOpen}
        onHide={props.close}
        animation={false}
        //This is needed because when the Add, Edit or Delete Intervention buttons are clicked, it needs to hide this modal while the child shows up
        style={{ visibility: (openAddInterventionModal || openEditInterventionModal || openDeleteInterventionModal) ? 'hidden' : 'visible' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>New/Modify Interventions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Interventions</h3>
          <Button onClick={() => callAddIntervention()}>
            Add Interventions
          </Button>
          <AddDictIntervention
            sendDictDashboard={props.sendDictDashboard}
            setOpen={openAddInterventionModal}
            //close={() => setOpenAddInterventionModal(false)}
            close={closeAddIntervention}
            sendToParent={retrieveAddData}
            //func={closeOnAddDiag}
          />
          <EditDictIntervention
            sendDictDashboard={props.sendDictDashboard}
            editData={editData}
            setOpen={openEditInterventionModal}
            //close={() => setOpenEditInterventionModal(false)}
            close={closeEditIntervention}
            sendToParent={retrieveEditData}
            //func={closeOnEditIntervention}
          />
          <PromptDictInterventionDelete 
            setOpen={openDeleteInterventionModal}
            //close={() => setOpenDeleteInterventionModal(false)}
            close={closeDeleteIntervention}
            sendDeleteID={deleteID}
            sendToParent={retrieveDeleteData}
          />

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Group Name</th>
                <th>Intervention Name</th>
              </tr>
            </thead>
            <tbody>
              { alphaProp != null &&
              Object.keys(alphaProp).map((item, i) => (
                <tr key={item}>
                  <td>{alphaProp[item].id}</td>
                  <td>{alphaProp[item].group_display_name}</td>
                  <td>{alphaProp[item].display_name_short}</td>
                  <td>
                    <Button 
                      className="btn btn-warning"
                      onClick={() => handleEditClick(alphaProp[item])}
                    >
                      Edit
                    </Button>
                  </td>
                  <td>
                    <Button className="btn btn-danger" onClick={() => handleDeleteClick(alphaProp[item].id)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewDictInt;
