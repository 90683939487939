import React, { useState, useEffect, useContext, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ErrorDialogModal from '../ErrorDialogModal';
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import ReusableButton from '../../buttons/reusableButton';
import UserPrefContext from '../../../../context/userPrefContext';
import AuthContext from '../../../../context/authContext';
import classes from './AddDiagnosis.module.css'
//import FPdiagModal from './FPdiagModal';
//import OtherdiagModal from './OtherdiagModal';


function AddNewUserModal(props) {

  const userPrefCtx = useContext(UserPrefContext);
  const authCtx = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false)
  const [info, setInfo] = useState();
  const [groupNameList, setGroupNameList] = useState();
  const [diagTypeList, setdiagTypeList] = useState();

  const [groupNameValue, setGroupNameValue] = useState();
  const [diagTypeValue, setDiagTypeValue] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [openFPModal, setOpenFPModal] = useState(false);

  const [openOtherModal, setOpenOtherModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const userNameRef = useRef(null);


  //sending false back to the NewDictDiag modal to eventually close that modal when this modal is called
  //props.func(false);
  // useEffect(() => {


  // }, [props.sendDictDashboard?.dict_diag]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



  const postAddUser = async (e) => {
    
     const postUserURL = process.env.REACT_APP_SERVER + `/api/v1/users/`
    //const postUserURL = '';


    // console.log(typeof currentGroupRef);

    let currentFirstNameRef =  firstNameRef.current.value;
    let currentLastNameRef =  lastNameRef.current.value;
    let currentEmailRef =  emailRef.current.value;
    let currentpasswordRef =  passwordRef.current.value;
    let currentUserNameRef =  userNameRef.current.value;


    //console.log('currentDiagTypeRef: ', currentDiagTypeRef, 'currentGroupRef: ', currentGroupRef, 'currentNameRef: ',currentNameRef, 'userPrefCtx.bearerToken: ', userPrefCtx.bearerToken)
    //console.log('currentFirstNameRef: ', currentFirstNameRef, 'currentLastNameRef: ', currentLastNameRef, 'currentEmailRef: ',currentEmailRef, 'currentpasswordRef ', currentpasswordRef, 'currentUserNameRef ', currentUserNameRef)

    const responseData = await fetch(postUserURL, {
      method: "POST",
      body: JSON.stringify({
        first_name: `${currentFirstNameRef}`,
        last_name: `${currentLastNameRef}`,
        email: `${currentEmailRef}`,
        password: `${currentpasswordRef}`,
        preferred_username: `${currentUserNameRef}`,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userPrefCtx.bearerToken}`,
        auth: authCtx.authMode
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data);
        //props.close();
        props.closeAndUpdateParent(data);
      })
      .catch((err) => {
        console.log(err.message);
        setShowError(true);
      });


  }

  const selectGroupName = (e) => {
    //console.log('group name is: ', e.target.value)
    setGroupNameValue(e.target.value)

  }

  const selectDiagType = (e) => {
    //console.log('diag type is: ', e.target.value)
    setDiagTypeValue(e.target.value)

  }

  return (
    <>
      <ErrorDialogModal
        setOpen={showError}
        close={() => setShowError(false)}
        componentName="Add New User"
      />
      <Modal 
        //size="xl" 
        size="lg"
        show={props.setOpen} 
        onHide={props.close} 
        animation={false}
        backdrop="static"
        //style={{ zIndex: '9999'}}
        //dialogClassName={{ zIndex: '9999'}}
        dialogClassName={classes.custom_modal}
        style={{ visibility: (showError) ? 'hidden' : 'visible' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h4>Group Name</h4>
          <Form.Select aria-label="Default select example" onChange={selectGroupName} ref={groupRef} >
            {groupNameList?.map((x) => <option key={x.id} id={'diag-button-type-'+x.group_display_name} value={x.group_display_name} >{x.group_display_name}</option>)}
          </Form.Select>
          <h4>Diag Type</h4>
          <Form.Select aria-label="Default select example" onChange={selectDiagType} ref={diagTypeRef}>
            {diagTypeList?.map((x) => <option key={x.id} id={'diag-button-type-'+x.diag_type} value={x.diag_type} >{x.diag_type}</option>)}
          </Form.Select>
          <h4>Display Name</h4>
          <Form.Control 
            // onSubmit={saveBotox} 
            ref={nameRef} 
            size="lg" 
            type="text" 
            //placeholder="Other Info" 
          /> */}
          <p>First Name</p>
          <Form.Control aria-label="Default select example" ref={firstNameRef} />
          <p>Last Name</p>
          <Form.Control aria-label="Default select example" ref={lastNameRef}/>
          <p>Email</p>
          <Form.Control aria-label="Default select example" type="email" ref={emailRef} />
          <p>Password</p>
          <div className="password-input-container">
          <Form.Control 
            aria-label="Default select example" 
            //type="password" 
            type={showPassword ? 'text' : 'password'}
            ref={passwordRef} 
          />
          <div className="password-toggle-dashboard" onClick={togglePasswordVisibility}>
            {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
          </div>
          </div>
          <p>Preferred Username</p>
          <Form.Control aria-label="Default select example" ref={userNameRef} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
          </Button>
          <Button variant="primary" onClick={postAddUser}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddNewUserModal;