import React, {useState, useContext} from 'react'
import CompanyContext from '../../../context/companyContext'
import styles from './ReturnIntraopImage.module.css'


function ReturnIntraopImage(props) {

    const companyCtx = useContext(CompanyContext)

    //const imageURL = (filename, patient_id, mode) => `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/image/${props.mode}_${filename}?patient_id=${patient_id}`
    // const imageURL = (filename, patient_id, mode) => `${process.env.REACT_APP_SERVER}/api/v1/intra_op_images/image/${mode}_${filename}?patient_id=${patient_id}`
    const imageURL = (filename, patient_id, mode) => `${process.env.REACT_APP_SERVER}/api/v1/intra_op_images/image/${mode}_${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}&time=${new Date()}`

    const toggleSelected = () => {
        //props.onImageSelect(props.image, props.image_id, props.botox_type_id, props.sendObject)
        props.onIntraopImageSelect(props.sendObject)
        //console.log('props.isImageSelected', props.isImageSelected)
    }


    return (
        <div 
            // className={`visit-image ${props.isImageSelected ? 'visit-image-selected' : ''}`} 
            // className={`${styles.intraop_img_return}' ${props.isImageSelected ? `${styles.intraop_img_selected}` : ''}`} 
            //className={styles.intraop_img_return + ' ' + (props.isImageSelected ? styles.intraop_img_selected : '')}
            //This is coming from index.css
            className={` ${props.isImageSelected ? 'border-selected' : 'border-not-selected'}`}
            //className={props.isImageSelected ? styles.intraop_border_selected : styles.intraop_border_not_selected}
            onClick={toggleSelected}
        >
            <img 
                className={styles.IntraopImg} 
                //className={`visit-image ${props.isImageSelected ? 'visit-image-selected' : ''}`} 
                src={imageURL(props.sendObject.file_name, props.sendObject.patient_id, props.mode)}
            ></img>
        </div>
    )
}

export default ReturnIntraopImage;