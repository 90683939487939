import React, {useState, useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {IconContext} from 'react-icons'

// props:
// title - header text
// prompt - prompt text
// onClose - function that returns confirmation value (cancel or confirm)
// icon - pass component that is an icon - for example <BsFillExclamationTriangleFill />
// iconColor - color of icon; if icon is passed and iconColor not passed the default (gray) will be used
// iconSize - size of the icon
function ConfirmDialog(props) {

    const [iconStyle, setIconStyle] = useState({color: 'gray'})

    useEffect(() => {
        let style = iconStyle
        if (props.hasOwnProperty('iconColor')) {
            style = {...style, color: props.iconColor}
        }
        if (props.hasOwnProperty('iconSize')) {
            style = {...style, size: props.iconSize}
        }
        if (props.hasOwnProperty('iconColor') || props.hasOwnProperty('iconSize')) {
            setIconStyle({...style})
        }
        
    }, [props.iconColor, props.iconSize])

    const Icon = () => {
        if (props.hasOwnProperty('icon')) {
            let icon = props.icon
            return <>{icon}</>
        } else {
            return <></>
        }
    }

    return (
        <>
        <IconContext.Provider value={iconStyle}>
            <Modal 
                className={props.modal_class !== undefined ? props.modal_class : ''}
                size="lg" 
                show={props.show} 
                animation={false} 
                onHide={() => props.onClose('cancel')} 
            >
                <Modal.Header className={props.title_class !== undefined ? props.title_class : ''} closeButton>
                    <Modal.Title className={props.title_class !== undefined ? props.title_class : ''} >
                        <div className='d-flex inline-block align-items-center'>
                            {!!props.icon && 
                                <>
                                <Icon /> 
                                <span>&nbsp;&nbsp;</span>
                                </>
                            }
                            {props.title}
                        </div>
                        </Modal.Title>
                </Modal.Header>
                <Modal.Body className={props.prompt_class !== undefined ? props.prompt_class : ''}>
                    <>
                        <h3 >{props.prompt}</h3>
                        <ul>
                            { props.text !== undefined && props.text.length > 0 && props.text.map((msg,ix) => {
                                return (
                                    <li key={ix} className={props.textClass !== undefined ? props.textClass : ''}>{msg}</li>
                                )})
                            }
                        </ul>
                    </>
                </Modal.Body>
                <Modal.Footer className={props.footer_class !== undefined ? props.footer_class : ''} >
                    {((props.cancel_button_text !== undefined && props.cancel_button_text !== '') || (props.cancel_button_text === undefined)) &&
                    <Button variant={props.cancel_button_variant !== undefined ? props.cancel_button_variant : 'secondary'} onClick={() => props.onClose('cancel')}>
                        {props.cancel_button_text !== undefined ? props.cancel_button_text : 'Cancel'}
                    </Button>
                    }
                    {((props.confirm_button_text !== undefined && props.confirm_button_text !== '') || (props.confirm_button_text === undefined)) &&
                    <Button variant={props.confirm_button_variant !== undefined ? props.confirm_button_variant : 'secondary'} onClick={() => props.onClose('confirm')}>
                        {props.confirm_button_text !== undefined ? props.confirm_button_text : 'Confirm'}    
                    </Button>
                    }
                </Modal.Footer>
            </Modal>
            </IconContext.Provider>
        </>
      )
}

export default ConfirmDialog
