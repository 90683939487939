import React, {useState, useContext, useEffect, useRef} from 'react'
import DOMPurify from 'dompurify';
import SelectPatient from '../shared/SelectPatient'
import sharedStyles from '../../../SharedStyles.module.css'
import UserPrefContext from '../../../context/userPrefContext'
import DictionaryContext from '../../../context/dictionaryContext'
import AuthContext from '../../../context/authContext'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DatePicker from 'react-datepicker'
import styles from './HimPage.module.css'

import { useNavigate } from "react-router-dom"
import { useOktaAuth } from '@okta/okta-react'

import LoadingSpinner from '../shared/LoadingSpinner'
import { trackPromise } from 'react-promise-tracker'
import CompanyContext from '../../../context/companyContext'
import ErrorDialogModal from '../modals/ErrorDialogModal';
import ConfirmDialog from '../shared/ConfirmDialog';
import { BsFillPlusSquareFill, BsFillExclamationTriangleFill } from 'react-icons/bs'
import jwt_decode from 'jwt-decode'


// Health Information Management
function HimPage(props) {
    const { authState, oktaAuth } = useOktaAuth()
    const componentMounted = useRef(true)
    const userPrefCtx = useContext(UserPrefContext)
    const dictContext = useContext(DictionaryContext)
    const authCtx = useContext(AuthContext)
    const companyCtx = useContext(CompanyContext)
    const [patientError, setPatientError] = useState('')
    const [patientData, setPatientData] = useState(null)
    const [errors, setErrors] = useState([]) 
    const [validationErrors, setValidationErrors] = useState([]) 
    const [warningText, setWarningText] = useState('')
    const [showConfirm, setShowConfirm] = useState(false)

    const [visitsLoadedTime, setVisitsLoadedTime] = useState(new Date());
    const [dates, setDates] = useState([])
    const [checkboxGroups, setCheckboxGroups] = useState([])

    const selectPatientRef = useRef()

    const navigate = useNavigate()

    
    // console.log("HIM - dictContext.dict_modal", dictContext.dict_modal, "User Context",  userPrefCtx)


    useEffect(() => {
        console.log("Home - mounting - useEffect 1")
        componentMounted.current = true
        if (!!companyCtx.company.auth_mode && !!!authCtx.authMode) {
            if (companyCtx.company.auth_mode === 'OKTA') {
                authCtx.setAuthMode(companyCtx.company.auth_mode)
            } else {
                // check if session should be restored after page reload 
                console.log("Home - restore session: companyCtx.company", companyCtx.company)
                dbAuthRestore(companyCtx.company.auth_mode)
            }
        }

        return () => {
            // console.log("Unmounting Home #1")
            componentMounted.current = false
        }
        
    }, [companyCtx.company.auth_mode, authCtx.authMode])



    const dbAuthRestore = (authMode) => {
        try {
            let data = localStorage.getItem("db-auth-access-return")
            // console.log("restoring Session - local storage", data, "!!data", !!data)
            if (!!data) {
                data = JSON.parse(data)
                // console.log("restoring Session - parsed data", data, "!!data.access_token", !!data.access_token, "data.access_token", data.access_token)
                
                if (!!data.access_token && !isTokenExpired(data.access_token)) {
                    // if we got here because user just typed him in url (and user is logged in):
                    // check if user is him user. If so let her; if not logout
                    if (!data.is_him_user) {
                        // not him user - got to the Dashboard
                        // handleClose()
                        navigate('/')
                    }

                    // console.log("restoring Session - data", data)
                    // userPreferenceCtx.setBearerTokenFunction(data.access_token)
                    authCtx.setToken(data.access_token)
                    authCtx.setIsAuthenticated(true)
                    
                    authCtx.setAuthMode(authMode)

                    const uInfo = {
                        first_name: data.first_name,
                        last_name: data.last_name,
                        is_him_user: data.is_him_user,
                        is_ipad_user: data.is_ipad_user,
                        is_readonly: data.is_readonly,
                        is_site_admin: data.is_site_admin,
                        is_superuser: data.is_superuser,
                        is_writeuser: data.is_writeuser,
                        name: data.first_name + ' ' + data.last_name,
                        // email: 'unknown',
                        // email_verified: false,
                        family_name: data.last_name,
                        given_name: data.first_name,
                        // locale: '',
                        // preferred_username: '',
                        // zoneinfo: '',
                    }
        
                    authCtx.setUserInfo(uInfo)
                    // setUserInfo(uInfo)
                    // setIsAuthenticated(true)
                    // setToken(data.accessToken)
                } else {
                    // token expired - logout
                    handleClose()
                }
            } else {
                // logout - user needs to login
                handleClose()
            }
            
        }
        catch (e) {
            console.log(e)
        }
        
    }

    // if (!!!authCtx.authMode) {
    //     authCtx.setAuthMode(props.authMode)
    // }

    const isTokenExpired = token => {
        if (!!!token) {
            // console.log("isTokenExpired - yes (no token)", token)
            return true
        }

        let decoded = jwt_decode(token)
        if (!!!decoded.exp) {
            // console.log("isTokenExpired - yes (decoded.exp is null)", decoded.exp)
            return true
        }

        // console.log("isTokenExpired - new Date(decoded.exp*1000) < new Date()", new Date(decoded.exp*1000) < new Date())
        return new Date(decoded.exp*1000) < new Date()
    }
    

    useEffect(() => {
        // console.log('Mounting HimPage #1 - mounted=', componentMounted.current, 'userPrefCtx.bearerToken', userPrefCtx.bearerToken)
        componentMounted.current = true

        let bearerToken = userPrefCtx.bearerToken
        if (userPrefCtx.bearerToken === '') {
            if (authCtx.authMode === 'OKTA') {
                if (!authState || !authState.isAuthenticated) {


                } else {
                    oktaAuth
                        .getUser()
                        .then((info) => {
                            userPrefCtx.setUserGroups(authState.accessToken.claims.groups)
                            userPrefCtx.setBearerTokenFunction(oktaAuth.getAccessToken())
                            bearerToken = oktaAuth.getAccessToken()
                        })
                        .catch((err) => {
                            console.error(err);
                        })
                }
            } else {
                // non-OKTA
                // console.log("HimPage - authCtx", authCtx)
                if (authCtx.isAuthenticated) {
                    bearerToken = authCtx.token
                    userPrefCtx.setBearerTokenFunction(bearerToken)
                }
            }
            
        }
        

        if (!!bearerToken) {
            if (dictContext.dict_modal.length === 0) {
                loadDictionaries(bearerToken)
            }
        }     

        return () => {
            // console.log("Unmounting HimPage #1")
            componentMounted.current = false
        }
    }, [authCtx.authMode,userPrefCtx, authState, oktaAuth])



    useEffect(() => {
        // console.log('Mounting HimPage #2 - mounted=', componentMounted.current)
        componentMounted.current = true

        if (dictContext.dict_modal.length > 0) {
            let cboxes = dictContext.dict_modal
                .filter(dm => dm.group_display_name_short === 'him' && dm.question_type !== null && dm.question_type.startsWith('['))
                .sort((dm1, dm2) => (dm1.sort_position*100+dm1.id) - (dm2.sort_position*100+dm2.id))
                // if (componentMounted.current) { 
                    // setHeaderText(cboxes[0].modal_header)
                // }
            let boxGroups = cboxes
                .map(d => {
                return {
                    group_id: d.id,
                    group_header: d.group_header2,
                    values: JSON.parse(d.question_option_values),
                    texts: JSON.parse(d.question_option),
                    types:  JSON.parse(d.question_type),
                }
            })

            // if (componentMounted.current){ 
                // console.log("boxGroups", boxGroups)
                setCheckboxGroups(boxGroups)
            // }
            
            // setCheckboxes(cboxes)


            // set dates

            let tempDates = dictContext.dict_modal
                .filter(dm => dm.group_display_name_short === 'him' && dm.question_type !== null && dm.question_type === 'date')
                .sort((dm1, dm2) => (dm1.sort_position*100+dm1.id) - (dm2.sort_position*100+dm2.id))
                .map(dm => {return {[dm.display_name_short]: null, value: dm.display_name_short, text: dm.display_name, is_error: false }})

                // console.log("HimPage dates: ", tempDates)
                // if (componentMounted.current){ 
                    setDates(tempDates)
                // }
        }

        return () => {
            // console.log("Unmounting HimPage #2")
            componentMounted.current = false
        }
    }, [dictContext.dict_modal.length])


    const loadDictionaries = async (token) => {
        const dictURL = `${process.env.REACT_APP_SERVER}/api/v1/dict_services/visit`
        // console.log("HIM - bearer", userPrefCtx.bearerToken)
        const headers = () => { return {authorization: `Bearer ${token}`, auth: authCtx.authMode, }}
        const response = await fetch(dictURL, {
            headers: headers(),
        })
        .then((res) => res.json())
        .then((data) => {
            // console.log("HIM - got dictionaries", data)
            // if (componentMounted.current){ 
                dictContext.setAllDictionaries(data)
            // }
            
        })
        .catch((err) => {
            console.log(err.message);
        })
    }


    const onSelectAll = (event) => {
        // console.log("select all clicked", event.target, "checked?", event.target.checked)
        const allCheckBoxes = document.querySelectorAll("input[type='checkbox']")
        allCheckBoxes.forEach(chk => {
            // console.log("checkbox is found", chk)
            if (chk.id !== event.target.id) {
                chk.checked = event.target.checked
            }
        })

    }


    const handlePatientChange = (data) => {
        // patient selected from the search patient dropdown
        // console.log("HIM Page - patiet data", data)
        setPatientData(data)

    }


    // patient cleared from the search field
    const handlePatientCleared = () => {
        resetForm({clearSelection:false})
    }

    const resetForm = (clearSelection) => {
        
        setPatientData(null)
        // setDates([])

        dates[0][dates[0].value] = null
        dates[0].is_error = false
        dates[1][dates[1].value] = null
        dates[1].is_error = false

        // console.log("HIM PAGE resetForm - ref", selectPatientRef.current)
        

        const allCheckBoxes = document.querySelectorAll("input[type='checkbox']")
        allCheckBoxes.forEach(chk => {
            chk.checked = false
        })

        if (clearSelection.clearSelection) {
            // clear selection
            selectPatientRef.current.clearValue()
        }

        setErrors([])
        setValidationErrors([])
        setPatientError('')

        
    }

    const handleClose = () => { 
        // console.log("HimPage - userPrefCtx.userGroups", userPrefCtx.userGroups)

        if (userPrefCtx.userGroups.indexOf(process.env.REACT_APP_MAIN_APP_GROUP) >= 0) {
            props.onModeChange("main")
            // navigate('/')
        } else {
            // console.log("logging out")
            if (authCtx.authMode === 'OKTA') {
                oktaAuth.signOut()
                // navigate('/')
            } else {
                authCtx.logout()
                try {
                    localStorage.removeItem("db-auth-access-return")
                }
                catch (e) {
                    console.log(e)
                }
                
                // navigate('/')
            }

        }
        navigate('/')
    }


    const handleApply = () => {
        if (validate()) {
            trackPromise(
                sendRequest()
            )
            // alert('not implemented yet!')
            resetForm({clearSelection:true})
        }
        
    }

    const validate = () => {
        // reset all errors
        let tempErrors = []
        let tempDates = [...dates]
            tempDates.forEach(dt => {
                dt.is_error = false
            })
        setDates(tempDates)


        // patient has to be selected
        if (patientData === null) {
            setPatientError('*')
            tempErrors.push('Patient is required')
        }
        // dates - if selected date from should be <= date to
        if ((dates[0][dates[0].value] !== null && dates[1][dates[1].value] === null) || (dates[0][dates[0].value] === null && dates[1][dates[1].value] !== null)) {
            // both dates should be either blank or have values
            tempErrors.push('Both dates should be either blank or have values')
            let tempDates = [...dates]
            tempDates.forEach(dt => {
                dt.is_error = true
            })
            setDates(tempDates)
        }
        if (dates[0][dates[0].value] !== null && dates[1][dates[1].value] !== null && dates[0][dates[0].value] > dates[1][dates[1].value] ) {
            // date from should be <= date to
            tempErrors.push(`Date ${dates[0].text} should be <= Date ${dates[1].text}`)
            let tempDates = [...dates]
            tempDates.forEach(dt => {
                dt.is_error = true
            })
            setDates(tempDates)
        }
        // at least one checkbox have to be selected
        const allCheckBoxes = document.querySelectorAll("input[type='checkbox']")
        let anyChecked = false
        allCheckBoxes.forEach(chk => {
            anyChecked = anyChecked || chk.checked
        })
        if (!anyChecked) {
            tempErrors.push('At least one checkbox have to be selected')
        }
        setValidationErrors(tempErrors)
        return tempErrors.length === 0
    }



    const dateChanged = (newDate, dateObj, index) => {
        let tempDates = [...dates]
        tempDates[index][dateObj.value] = newDate
        setDates(tempDates)
    }



    const sendRequest = async () => {
        // console.log("HimPage submitting data: dates", dates)
        let dateParams = ''
        if (dates[0][dates[0].value] !== null && dates[1][dates[1].value] !== null) {
            dateParams = `&from_date=${dates[0][dates[0].value].toISOString().split('T')[0]}&to_date=${dates[1][dates[1].value].toISOString().split('T')[0]}`
        }
        const getHimDataURL = `${process.env.REACT_APP_SERVER}/api/v1/web_services/get_him_data/${patientData.patient_token}?dict_company_code_id=${companyCtx.company.id}${dateParams}`
        // console.log("HimPage submitting data: URL", getHimDataURL)

        let bodyValues = []
        const allCheckBoxes = document.querySelectorAll("input[type='checkbox']")
        allCheckBoxes.forEach(chk => {
            // console.log("HimPage - sleected checkboxes: id=", chk.id, "value=", chk.value, "checked?=", chk.checked)
            // ignore select all checkbox
            if (chk.checked && chk.id !== "select_all") {
                bodyValues.push(chk.value)
            }
        })
        let body = { 
            retrieve_data_for: bodyValues
        }

        const headers = () => { 
            return {
                authorization: `Bearer ${userPrefCtx.bearerToken}`, 
                "Content-Type": "application/json", 
                auth: authCtx.authMode,
            }
        }
        const response = await fetch(getHimDataURL, {
            headers: headers(),
            method: "POST",
            body: JSON.stringify(body),
        })
        .then((response) => {
            const contentType = response.headers.get("content-type")
            // console.log("Him Page contentType", contentType)
            // content type is either 'application/json' (no data) or 'application/zip' (blob with zip file)

            if (contentType && contentType.indexOf('application/zip') >= 0) {
                return response.blob()
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob)
                        const a = document.createElement('a')
                        a.style.display = 'none'
                        a.href = DOMPurify.sanitize(url, {SAFE_FOR_JQUERY:true})
                        // save zip file - remove/replace date/time separators from the file name
                        a.download = `HIM_${patientData.emr_mrn}_${new Date().toISOString().replace(/-/g,'').replace(/:/g,'').replace('T', '_').replace('Z','')}.zip`
                        // document.body.appendChild(a)
                        a.click()
                        window.URL.revokeObjectURL(url)
                    })
            } else if (contentType && contentType.indexOf('application/json') >= 0) {
                    return response.json()
                        .then((data) => {
                            if (response.status === 200 && data.Result !== undefined) {
                                // console.log("HimPage", data.Result)
                                setErrors([data.Result])
                            } else if (response.status === 240 && data.detail !== undefined) {
                                setWarningText([data.detail])
                            } else {
                                console.log("HimPage", data)
                                setErrors([data])
                            }
                            
                        })
                // }
            } else {
                console.log("HimPage - unexpected content type received:", contentType)
            }
        })
        .catch((err) => {
            console.log(err.message);
            setErrors([err.message])
        })
    }


    



    

    return (
        <>
        {!!warningText && 
        <ConfirmDialog 
            show={!!warningText}
            iconColor='orange'
            icon={<BsFillExclamationTriangleFill />}
            title='Health Information Management - Warning'
            // modal_class='diags-modal-delete-confirm-modal'
            title_class={styles['alert-title-orange']}
            // footer_class='diags-modal-delete-confirm-title'
            prompt={warningText}
            prompt_class={styles['alert-prompt']}
            cancel_button_text=''
            confirm_button_text='Close'
            confirm_button_variant='primary'
            onClose={() => setWarningText('')}
        />
        }

        {errors.length > 0 &&
        <ConfirmDialog 
            show={errors.length > 0}
            iconColor='red'
            icon={<BsFillExclamationTriangleFill />}
            title='Health Information Management - Error'
            // modal_class='diags-modal-delete-confirm-modal'
            title_class={styles['alert-title']}
            // footer_class='diags-modal-delete-confirm-title'
            prompt='Something Went Wrong. Please Contact Admin'
            prompt_class={styles['alert-prompt-black']}
            cancel_button_text=''
            confirm_button_text='Close'
            confirm_button_variant='primary'
            onClose={() => setErrors([])}
        />
        // <ErrorDialogModal
        //     setOpen={errors.length > 0}
        //     close={() => setErrors([])}
        //     componentName="Health Information Management"
        // />
        }
        <h1 className={styles.title}>Health Information Management</h1>
        <Container className={styles.himPageLocation}>
            <div className={styles.centered}>
                <SelectPatient 
                    forwardedRef={selectPatientRef}
                    error={patientError} 
                    onErrorReset={() => setPatientError('')}
                    // onErrorReset={resetAllErrors}
                    onChange={handlePatientChange} 
                    onClear={handlePatientCleared}
                    headerText='Patient Search'
                    headerStyle={styles.patientSearchHeader} />
            </div>
            


            <Form className={styles.form}>
                <div className={styles.formHeader}>Select search criteria</div>
                <div className={styles.formInnerDiv}>
                    <Row>
                        <Col xs={6} lg={4}>
                            <Row><h4 className={styles.h4}>Visit Dates</h4></Row>
                            {dates.map((dt, dt_ix) => {
                                return (
                                    <Form.Group as={Row} key={`dates_${dt_ix}`} >
                                        <Form.Label column xs="4">
                                            {dt.text}
                                        </Form.Label>
                                        <Col xs="8">
                                            <DatePicker className={dt.is_error ? styles.dateError : ''}
                                                selected={dt[dt.value]} 
                                                onChange={(date) => dateChanged(date, dt, dt_ix)} 
                                            />
                                        </Col>
                                    </Form.Group>
                                )
                                
                            })}
                        </Col>

                        <Col xs={6} lg={6}>
                            <Row className={styles.selectAll} >
                                <Col >
                                    <input id='select_all'
                                        type='checkbox'
                                        onClick={onSelectAll}
                                    />
                                    <label htmlFor='select_all'>Select All</label>
                                </Col>
                            </Row>



                            <Row>
                               
                                {checkboxGroups.map(bg => {
                                    return (
                                        <Col xs={12} lg={6} key={`grp_${bg.group_id}`}>
                                             <ul className={styles.himUl}>
                                                {bg.types.map((type, type_ix) => {
                                                    return (
                                                        <Row key={`${bg.group_id}_${type_ix}`}>
                                                            <li className={styles.himLi}>
                                                                {type === 'label' && 
                                                                    <label>{bg.texts[type_ix]}</label>
                                                                }
                                                                {type === 'checkbox' && 
                                                                <>
                                                                    <input 
                                                                        id={bg.values[type_ix]}
                                                                        type={type}
                                                                        value={bg.values[type_ix]}
                                                                    />
                                                                    <label htmlFor={bg.values[type_ix]}>{bg.texts[type_ix]}</label>
                                                                </>
                                                                }


                                                            </li>
                                                        </Row>
                                                    )
                                                })}
                                             </ul>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                    </Row>
                </div>

                {/* <div className={styles.errors}>
                    {errors.map((err, err_ix) => {
                        return (
                            <span key={err_ix}>{err}</span>
                        )
                    })}
                </div> */}

                
                <div className={styles.div_errors}>
                     {validationErrors.length > 0 &&
                        <ul className={styles.error}>
                            {validationErrors.map((error, err_ix) => {
                                return (
                                <li key={err_ix} >
                                    {error}
                                </li>
                                )
                            })}
                        </ul>
                        

                    }
                </div>


                <div className='d-flex justify-content-between'>
                    
                   

                        <div className="inline-block text-left">
                            <Button className='mx-2' variant="secondary" onClick={handleClose}>
                                {userPrefCtx.userGroups.indexOf(process.env.REACT_APP_MAIN_APP_GROUP) >= 0 ? 'Close' : 'Logout'}
                                
                            </Button>

                            <Button className='mx-2' variant="secondary" onClick={() => resetForm({clearSelection:true})}>Reset All</Button>
                        </div>

                        <div className='inline-block text-right'>
                            <Button className='mx-2' variant="primary" onClick={handleApply}>
                                Apply
                            </Button>
                        </div>

                </div>
                
                {/* <div className={`${sharedStyles.divInlineBlock} ${styles.w100} text-end`}>
                    <Button className='mx-4' variant="primary" onClick={handleApply}>
                        Apply
                    </Button>
                </div> */}
            </Form>
        </Container>

        <LoadingSpinner onEndOfPromise={() => setVisitsLoadedTime(new Date())} />
        
        </>
    )
}

export default HimPage
    