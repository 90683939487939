import React, {useState, useEffect, useContext} from 'react'
import PatientContext from '../../../context/patientContext'
import UserPrefContext from "../../../context/userPrefContext"
import DictionaryContext from '../../../context/dictionaryContext'
import AuthContext from '../../../context/authContext'
import EFaceOneChart from './EFaceOneChart'
import TotalScore from '../shared/TotalScore'
import chartClasses from '../../stylesheets/Charts.module.css'

function EFaceCharts(props) {
    const dictCtx = useContext(DictionaryContext)
    const visCtx = useContext(PatientContext)
    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)
    

    // const [token, setToken] = useState(null)
    // const { authState, oktaAuth } = useOktaAuth()

    const [chartDataSorted, setChartDataSorted] = useState([])
    
    const [efaces, setEfaces] = useState([])
    const [x_axis_categories, set_x_axis_categories] = useState([])

    const [dictChartSorted, setDictChartSorted] = useState([])

    // console.log("**** EFaceCharts ****")

    // useEffect(() => {
    //     // console.log("checking for token...")
    //     if (!authState.isAuthenticated) {
    //       // When user isn't authenticated, forget any user info
    //       setToken(null)
    //     //   console.log("no token :(")
    //     } else {
    //         // console.log("have a token :)")
    //       setToken(oktaAuth.getAccessToken());
    //     }
    //   }, [authState, oktaAuth])


      useEffect(() => {

// console.log("**** EFaceCharts loading ****")

        // get data 
        // const dict_chart = props.dictVisit.dict_chart.filter((chart) => chart.group_display_name_short === "the_eface").sort((a,b) => a.group_id - b.group_id)
        let dict_chart = dictCtx.dict_chart.filter((chart) => chart.group_display_name_short === "the_eface").sort((a,b) => a.group_id - b.group_id)
        if (props.viewType !== 'full') {
            // limit number of charts on visit page
            dict_chart = dict_chart.slice(0, process.env.REACT_APP_EFACE_VISITPAGE_LIMIT)
        }
        setDictChartSorted(dict_chart)

        const x_categories = dict_chart.map((chart, index) => {
            return JSON.parse(chart.x_axis_category)
        })
        // console.log("x_categories", x_categories)
        // each element in x_categories is a two-elemnt array; first alement is the name of the chart; second is the name of the corresponding value field in eface data
        set_x_axis_categories(x_categories)

        let ignoreEfaces = false
        const getAllEFaces = async() => {
            try {
                const eFaceGetAllURL = `${process.env.REACT_APP_SERVER}/api/v1/the_efaces/get_graphs/${visCtx.pat_token}`
                const response = await fetch(eFaceGetAllURL, {
                    headers: {
                        'Authorization': `Bearer ${userPrefCtx.bearerToken}`,
                        auth: authCtx.authMode,
                    },
    
                })
    
                if (response.status === 200) {
                    if (!ignoreEfaces) {
                        const responseData = await response.json()
                        // console.log("Get all e-Faces", responseData)
                        setEfaces(responseData.chart_data)
                    }
                    
                } else {
                    console.log("Failed to get all e-Faces", response)
                }
            } catch (e) {
                console.log("Failed to get all e-Faces", e)
            }
    
        }

        // console.log("EFaceCharts - bearer", userPrefCtx.bearerToken)
        if (userPrefCtx.bearerToken !== null) {
            getAllEFaces()
        }
        

        return () => {
            ignoreEfaces = true
        }
        //I added the patient context as a dependency so that the the panel can be consistent with the user through the searchbar
    }, [userPrefCtx.bearerToken, props.loaded, dictCtx.dict_chart.length, visCtx.pat_token])



    useEffect(() => {
        // sort the data
        // console.log("efaces", efaces)
        setChartDataSorted(efaces.sort((a,b) => new Date(a.eface_date) > new Date(b.eface_date) ? 1 : -1))
    }, [efaces])

    const chartsClicked = () => {
        if (chartDataSorted.length > 0 && props.hasOwnProperty('onClick')) {
            props.onClick()
        }
    }


    return (
        <div height='500' className={`eface-charts ${props.viewType === 'full' ? chartClasses['charts-full']: chartClasses['charts-sm'] }`} 
            onClick={chartsClicked}
        >
            {props.viewType === 'full' && chartDataSorted.length > 0 &&
                <h3 className={chartClasses['charts-center']}>
                    Total Facial Function Score: 
                    <TotalScore 
                        data={chartDataSorted}    
                        property_date_name='eface_date'
                        property_value_name='smile_score'
                        first_or_last='last'
                    />
                </h3>
            }
            { dictChartSorted.length > 0 && chartDataSorted.length > 0 && dictChartSorted.map((achart, chart_ix) => {
                    return (
                        <EFaceOneChart key={chart_ix}
                            chart={achart} 
                            data={chartDataSorted}
                            x_categories={x_axis_categories[chart_ix]}
                            width={'98%'}
                            height={props.viewType === 'full' ? 350 : 150}
                            viewType={props.viewType}
                        />
                    )
                })
            }

        </div>
    )
}

export default EFaceCharts
