import React, { useState, useContext, useEffect } from 'react'
import PatientContext from '../../../context/patientContext'
import UserPrefContext from '../../../context/userPrefContext'
import DictionaryContext from '../../../context/dictionaryContext'
import AuthContext from '../../../context/authContext'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { BsFillPlusSquareFill, BsFillExclamationTriangleFill } from 'react-icons/bs'
import CapeVQuestionGroup from './CapeVQuestionGroup'
import ConfirmDialog from '../shared/ConfirmDialog'
import ErrorDialogModal from '../modals/ErrorDialogModal'
import classes from './CapeV.module.css'

function CapeVModal(props) {
    const panelName='capev_panel'
    const [readOnly, setReadOnly] = useState(false)
    const patCtx = useContext(PatientContext)
    const userPrefCtx = useContext(UserPrefContext)
    const dictCtx = useContext(DictionaryContext)
    const authCtx = useContext(AuthContext)
    const [groups, setGroups] = useState([])
    const [sortedQuestions, setSortedQuestions] = useState([])
    // const [errors, setErrors] = useState([])
    const [showError, setShowError] = useState(false);
    const [modalTitle, setModalTitle] = useState('')
    const [capevData, setCapevData] = useState([])

    const [showConfirm, setShowConfirm] = useState(false)
    const [confirmDialogText, setConfirmDialogText] = useState('')

    const CapeVCreateURL = `${process.env.REACT_APP_SERVER}/api/v1/capevs/${patCtx.pat_token}`
    const CapeVUpdateURL = id => `${process.env.REACT_APP_SERVER}/api/v1/capevs/${id}`

    const initialData = {
        overall_severity_mi: 0,
        overall_severity_mo: 0,
        overall_severity_se: 0,
        overall_severity: 'i',
        overall_severity_total: 0,
        roughness_mi: 0,
        roughness_mo: 0,
        roughness_se: 0,
        roughness: 'i',
        roughness_total: 0,
        breathiness_mi: 0,
        breathiness_mo: 0,
        breathiness_se: 0,
        breathiness: 'i',
        breathiness_total: 0,
        strain_mi: 0,
        strain_mo: 0,
        strain_se: 0,
        strain: 'i',
        strain_total: 0,
        pitch_mi: 0,
        pitch_mo: 0,
        pitch_se: 0,
        pitch: 'i',
        pitch_total: 0,
        loudness_mi: 0,
        loudness_mo: 0,
        loudness_se: 0,
        loudness: 'i',
        loudness_total: 0,
        loudness_abnormality: '',
        resonance_normal: '',
        resonance_other: '',
        addnl_features: '',
        clinician_initial: '',
        capev_date: new Date()
    }


    const httpHeaders = (token) => {
        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'accept': 'application/json',
            auth: authCtx.authMode,
        }
    }

    // props.data is null if new; otherwise it has capeV data to edit
    useEffect(() => {
        let allTypesAndOptions = []

        if (dictCtx.dict_panel.length > 0) {
            // get value of the panel from dict_panel
            const dictPanel = dictCtx.dict_panel.find(pnl => pnl.display_name_short === panelName)

            if (dictPanel !== undefined) {
                // find dict_intake_form by display_name_short:
                // display_name_short in dict_panle looks like "panelname_panel"
                // display_name_short in dict_intake_form looks like "panelname"
                const intakeForm = dictCtx.dict_intake_form.find(form => {
                    return form.display_name_short === dictPanel.display_name_short.replace("_panel","")
                })

                if (intakeForm !== undefined) {
                    // sort questions by sort_position
                    const questions = dictCtx.dict_intake_form_question.filter(quest => {
                        return quest.DictIntakeFormQuestion.dict_intake_form_id === intakeForm.id
                    })
                    
                    const comp = (a,b) => {
                        var agrp = (+a.DictIntakeFormQuestion.group_id) * 100 + (+a.DictIntakeFormQuestion.sort_position)
                        var bgrp = (+b.DictIntakeFormQuestion.group_id) * 100 + (+b.DictIntakeFormQuestion.sort_position)

                        // console.log("sort:", typeof anum, typeof bnum, (anum - bnum))
                        return agrp - bgrp
                    }
                    // const sortedQuestions = questions.sort((a,b) => a.sort_position - b.sort_postion)
                    const sortedQs = questions.sort(comp)
                    // console.log("CapeVModal - Sorted questions", sortedQs)

                    setSortedQuestions(sortedQs)

                    // set Modal Title with patient name
                    setModalTitle(`${intakeForm.display_name} - ${patCtx.pat_last}, ${patCtx.pat_first}`)
                    
                    // get group_ids of questions (only one id per group)
                    const groups_c = sortedQs
                        .map((value, index, qs) => (index > 0 && qs[index-1].DictIntakeFormQuestion.group_id === value.DictIntakeFormQuestion.group_id) ? null : value.DictIntakeFormQuestion.group_id)
                        .filter(value => value !== null)

                    setGroups(groups_c)
                }
            }
        }
        
    }, [dictCtx.dict_panel.length])


    useEffect(() => {
        // set readOnly Mode
        if (userPrefCtx.userPrefState.UserPreference.user_dept === dictCtx.dict_panel_departments[panelName]) {
            setReadOnly(false)
        } else {
            setReadOnly(true)
        }
    }, [userPrefCtx.userPrefState.UserPreference.user_dept, dictCtx.dict_panel_departments])


    useEffect(() => {
        // console.log("******* CapeVModal props ******", props)
        let ignoreCapeV = false

        const getCapeVById = async(id) => {
            try {
                const CapeVGetByIdURL = `${process.env.REACT_APP_SERVER}/api/v1/capevs/${id}`
                const response = await fetch(CapeVGetByIdURL, {
                    headers: httpHeaders(userPrefCtx.bearerToken),
                })
    
                if (response !== null) {
                    const responseData = await response.json()
                    // console.log("response", responseData)
                    if (responseData.hasOwnProperty('capev_date')) {
                        setCapevData({...responseData, capev_date: new Date(responseData.capev_date)})
                    } else {
                        // console.log("Failed to get CapeV with id", id, responseData)
                        if (responseData.hasOwnProperty('detail')) {
                            setConfirmDialogText(responseData.detail)
                            setShowConfirm(true)
                        }
                    }
                    
                } else {
                    console.log("Failed to get CapeV with id", id, response)
                    setShowError(true)
                }
            } catch (e) {
                console.log("Failed to get CapeV with id", id, e)
                setShowError(true)
            }
        }

        // props.data is either null (new CapeV) or CapeV Id
        if (props.data === null || props.data.length === 0) {
            setCapevData(initialData)
        } else {
            if (userPrefCtx.bearerToken !== '') {
                getCapeVById(props.data[0])
            }
            
        }


        return () => {
            ignoreCapeV = true
        }

    },[props.data, userPrefCtx.bearerToken])


    const valueChanged = (new_values) => {
        // console.log("faceInstrumentValues", faceInstrumentValues)
        // console.log("Question value changed! Question:", question, ", Value:", value)
        // setFaceInstrumentValues({...faceInstrumentValues, [question]:value})
        // console.log("CAPEV MODAL NEW VALUES", new_values)
        setCapevData({...capevData, ...new_values})

    }



    const capeVOperation = async(operation) => {
        try {
            const id = capevData.hasOwnProperty('id') ? capevData.id : ''
            const httpMethod = id === '' ? "POST" : (operation === 'delete' ? "DELETE" : "PATCH")
            const url = id === '' ? CapeVCreateURL : CapeVUpdateURL(id)

            const response = await fetch(url, {
                headers: httpHeaders(userPrefCtx.bearerToken),
                method: httpMethod,
                body: JSON.stringify(capevData),
            })

            if (response.status === 200) {
                const responseData = await response.json()
                // console.log("Created/updated e-Face", responseData)
                // console.log("Created/updated capeV", responseData)
                props.sendResponsetoVisitPanels(responseData);
                props.onAnyDataChange(true)
                props.onClose()
                

            } else {
                console.log("Failed to create/update/delete CapeV", response)
                if (!response.ok) {
                    throw new Error('Unprocessable Entity: ' + response.statusText);
               }
            }
        } catch (e) {
            console.log("Failed to create/update/delete CapeV", e)
            setShowError(true);
        }
    }



    // validate and save changes
    const handleSave = () => {
        // validate data (capevData) before saving
        // console.log("SAVING DATA", capevData)

        capeVOperation('new or update')
        // props.onAnyDataChange(true)
        // props.onClose()
    }




    const handleDelete = () => {
        capeVOperation('delete')
        // force refresh CapeV List
        // props.onAnyDataChange(true)
        // props.onClose()
    }


    const handleClose = () => {
        // setErrors([])
        props.onClose()
    }

    const closeConfirmDialog = (result) => {
        setShowConfirm(false)
        handleClose()
    }

    return (
        <>
        {!readOnly &&
            <BsFillPlusSquareFill onClick={props.handleShow} className="icon-pointer" />
        }
            
        {!showConfirm &&
        
            <Modal size="fullscreen" show={props.show} onHide={handleClose} animation={false}
                style={{ visibility: (showError) ? 'hidden' : 'visible' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title><h4>{modalTitle}</h4></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>

                        {groups.map((group_id, index) => 
                            <CapeVQuestionGroup 
                                key={index}
                                group_id={group_id}
                                questions={sortedQuestions.filter(q => q.DictIntakeFormQuestion.group_id === group_id)}
                                onChange={valueChanged}
                                readOnly={readOnly}
                                data={capevData}
                            />
                        )}
                    </div>
                </Modal.Body>

                <Modal.Footer className='justify-content-between'>

                        <div className="inline-block text-left">
                            <Button className='mx-2' variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            {props.data !== null && props.data.length > 0 && !readOnly &&                
                                <Button className='mx-2 danger-button' variant="secondary" onClick={handleDelete}>
                                    Delete
                                </Button>
                            }
                        </div>

                        {!readOnly &&
                            <div className='inline-block text-right'>
                                <Button className='mx-2' variant="primary" onClick={handleSave}>
                                    Save Changes
                                </Button>
                            </div>
                        }
                        

                </Modal.Footer>
            </Modal>
            }
            <ConfirmDialog 
                show={showConfirm}
                iconColor='red'
                icon={<BsFillExclamationTriangleFill />}
                title='Something went wrong'
                // modal_class='diags-modal-delete-confirm-modal'
                title_class={classes['alert-title']}
                // footer_class='diags-modal-delete-confirm-title'
                prompt={confirmDialogText}
                prompt_class={classes['alert-prompt']}
                cancel_button_text=''
                confirm_button_text='OK'
                confirm_button_variant='primary'
                onClose={(result) => closeConfirmDialog(result)}
            />
            <ErrorDialogModal
                setOpen={showError}
                close={() => setShowError(false)}
                componentName="CapeV"
            />

        </>
    )
}

export default CapeVModal
