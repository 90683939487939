import React, {useContext, useEffect, useState} from 'react'
import { useOktaAuth } from "@okta/okta-react";
import {useNavigate} from 'react-router-dom'
import AuthContext from './context/authContext'
import CompanyContext from './context/companyContext';
import UserPrefContext from './context/userPrefContext';
import DictionaryContext from './context/dictionaryContext';

function Logout(props) {

    const authCtx = useContext(AuthContext)
    const companyCtx = useContext(CompanyContext)
    const userPrefCtx = useContext(UserPrefContext)
    const dictCtx = useContext(DictionaryContext)
    const { authState, oktaAuth } = useOktaAuth()
    let navigate = useNavigate()
   


    useEffect(() => {
        if (props.doLogout) {
            // !!! important - call onLoggedOut to reset property props.doLogout to false - other wise it stays true and automatically logges me out on next login
            props.onLoggedOut()
            console.log("Logout.jsx - cleaning up session")
            if (companyCtx.authMode === 'OKTA') {
                try {
                    console.log("signing out from OKTA")
                    oktaAuth.signOut()
                    console.log("signed out from OKTA")
                    navigate('/')
                }
                catch (e) {
                    console.log("error", e)
                }
            } else {
                // non-OKTA
                try {
                    localStorage.removeItem("db-auth-access-return")
                }
                catch (e) {
                    console.log("error", e)
                }
                navigate('/')
                try {
                    
                    // userPrefCtx.setBearerTokenFunction('')
                    // dictCtx.reset()
                    authCtx.logout()
                }
                catch (e) {
                    console.log("error", e)
                }
            }

        }
    }, [props.doLogout])
    

    return (
        <></>
    )
}

export default Logout
