import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, Redirect, useNavigate, Navigate} from 'react-router-dom';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
//import { CountryDropdown, RegionDropdown, countryList } from 'react-select-country-list'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'
import ErrorDialogModal from '../modals/ErrorDialogModal';
import CreatePatientGroups from './CreatePatientGroups';
import DictionaryContext from '../../../context/dictionaryContext';
import UserPrefContext from '../../../context/userPrefContext';
import PatientContext from '../../../context/patientContext';
import AuthContext from '../../../context/authContext';
import CompanyContext from '../../../context/companyContext';
import { trackPromise } from 'react-promise-tracker'
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

function CreatePatientModal(props) {

    const [showError, setShowError] = useState(false)
    const [showErrorMessage, setShowErrorMesssage] = useState(false)

    const [sendErrorObject, setSendErrorObject] = useState({});

    const [groups, setGroups] = useState([]);
    const [sortedFields, setSortedFields] = useState([])

    const patCtx = useContext(PatientContext);
    const userPrefCtx = useContext(UserPrefContext);
    const authCtx = useContext(AuthContext);
    const dictCtx = useContext(DictionaryContext);
    const companyCtx = useContext(CompanyContext);

    const [errors, setErrors] = useState({})

    const navigate = useNavigate();

    
    useEffect(() => {
        if(dictCtx) {
            // console.log(dictCtx.dict_modal)
            const filteredArray = dictCtx.dict_modal.filter(obj => obj.group_display_name_short === 'create_patient');
            // console.log("Create Patient Modal - filteredArray", filteredArray)


            //This will essentially sort by group first, then sort position
            const comp = (a,b) => {
                var agrp = (+a.group_id) * 100 + (+a.sort_position)
                var bgrp = (+b.group_id) * 100 + (+b.sort_position)
                return agrp - bgrp
            }

            let sortedList = filteredArray.sort(comp);

             console.log("Create Patient Modal - sortedList ", sortedList)

            sortedList = sortedList.map(f => {return {...f, defaultValue:null}}) 

            

            setSortedFields(sortedList)

            // get group_ids of questions (only one id per group)
            const groups_c = sortedList
                .map((value, index, qs) => (index > 0 && qs[index-1].group_id === value.group_id) ? null : value.group_id)
                .filter(value => value !== null)

            // console.log("groups",groups_c)
            setGroups(groups_c)

        }



           
    }, [dictCtx]);

    




    const savePatient = async(sendToPatientChart) => {
        let formErrors = {}
        let error = false

        let defaultValues = []
        sortedFields.forEach((field, fi) => {
            const elem = document.getElementById(field.display_name_short)
            if (!!!elem.value && field?.question_required) {
                formErrors = {...formErrors, [field.display_name_short]: field.display_name + ' is required'}
                error = true

            } 
            defaultValues[fi] = elem.value
        })

        let updatedFields = sortedFields.map((f,ix) => {
            return {...f, defaultValue: defaultValues[ix]}
        })

        setSortedFields(updatedFields)


        // if mrn is entered and exists show the error message
        const mrnValue = updatedFields.find(f => f.display_name_short === 'emr_mrn').defaultValue
        if (!!mrnValue) {
            const checkMRNURL = `${process.env.REACT_APP_SERVER}/api/v1/patients/check_mrn_exists?emr_mrn=${mrnValue}`;
            const responseData = await fetch(checkMRNURL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                    auth: authCtx.authMode
                },
            })
            .then((res) => {
                // Check for 422 or 500 response
                if (!res.ok) {
                    throw new Error('Unprocessable Entity: ' + res.statusText);
                }

                // console.log(res)
                // console.log(res.status)
                if(res.status === 240){
                    //console.log('it is 240 - MRN Already exists!')
                    formErrors.emr_mrn = `MRN ${mrnValue} already exists`
                    // formErrors = {...formErrors, [field.display_name_short]: `MRN ${mrnValue} already exists`}
                    error = true
                    // throw new Error('MRN Already exists!')
                } else {
                    if(res.ok){
                        // console.log('checking first name: ', document.getElementById('create_patient_first_name').value)
                        //This is to go to the next step, to create a new patient if the MRN doesn't already exist
                        // sendToCreatePatientAPI(sendToPatientChart)
                    }
                }
                return res.json();
            })
            .then((data) => {
                //console.log(data);
                //  props.sendTrigger(data);
                //  props.close();
            })
            .catch((err) => {
                console.log(err.message);
                setShowErrorMesssage(err.message);
                //setShowError(true);
            });
        }

        setErrors(formErrors)
    
        if (!error) {
            sendToCreatePatientAPI(updatedFields, sendToPatientChart)
        }

  }


    const sendToCreatePatientAPI = async(updatedFields, sendToPatientChart) => {

        // console.log('checking first name: ', document.getElementById('create_patient_first_name').value)

        const patientData = updatedFields.reduce((obj, objItem) => {
            //console.log("Building Patient Data - obj", obj, "objItem", objItem)
            if (objItem.question_type === 'date') {
                if (!!objItem.defaultValue) {
                    obj[objItem.display_name_short] = moment(objItem.defaultValue).format('YYYY-MM-DD')
                } else {
                    obj[objItem.display_name_short] = ''
                }
            } 
            // else if (objItem.question_option === 'number') {
            //     obj[objItem.display_name_short] = parseInt(!!objItem.defaultValue ? objItem.defaultValue : '0');
            // } 
            else {
                obj[objItem.display_name_short] = objItem.defaultValue
            }
            
            return obj;
        }, {})

        //console.log("New Patient Data:", patientData)
        const sendObject = JSON.stringify(patientData)
        const createPatientAPI = `${process.env.REACT_APP_SERVER}/api/v1/patients/create_patient_db`;


        const responseData = await fetch(createPatientAPI, {
            method: 'POST',
            body: sendObject,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                auth: authCtx.authMode
            },
        })
        //.then((res) => res.json())
        .then((res) => {
            // Check for 422 or 500 response
            if (!res.ok) {
                throw new Error('Unprocessable Entity: ' + res.statusText);
                //throw new Error;
            }
            return res.json();
        })
        .then((data) => {
            //console.log(data);
            //  props.sendTrigger(data);
            if(sendToPatientChart.sendToPatientChart === true){
                //console.log("sending to patient chart")
                selectMRN(data.emr_mrn)
            } else {
                props.close();
            }
        })
        .catch((err) => {
            console.log(err.message);
            //setShowError(true);
        });

    }

    const selectMRN = async(e) => {
        //let stringMRN = e.target.getAttribute('data-mrn');
        let stringMRN = e;
        //console.log(stringMRN);
    
        const token = props.sendBearerToken;
    
        //This value is stringified
        // let url = `${process.env.REACT_APP_SERVER}/api/v1/search_services/patient_search_with_site_loc?search_token=${stringMRN}&patient_search_mode=${companyCtx.company.patient_search_mode}`;
        let url = `${process.env.REACT_APP_SERVER}/api/v1/search_services/patient_search_with_site_loc_epic?search_token=${stringMRN}&patient_search_mode=${companyCtx.company.patient_search_mode}`;

    
        if (url != null) {
          const response = await fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                auth: authCtx.authMode,
              },
            })
            .then((res) => {
              if (!res.ok) {
                 throw new Error('Unprocessable Entity: ' + res.statusText);
              }
              return res.json();
            })
            .then((data) => {
                 //console.log(data)
                createPatient(data.response_data[0]);
            })
            .catch((err) => {
                console.log(err.message)
                //errorDialogComponent
                //setErrorDialogComponent('Set Profile Picture Button')
                setShowError(true);
            });
          }
      }
    
      const createPatient = async(e) => {
    
        let postURL = process.env.REACT_APP_SERVER + `/api/v1/patients/creat_patient_with_mrns`;
    
        patCtx.pat_dob = e.dob
        patCtx.pat_dob = new Date(e.dob).toISOString().split("T")[0]
        patCtx.pat_mrn = e.local_site_mrn
        patCtx.pat_first = e.first_name
        patCtx.pat_last = e.last_name
        patCtx.pat_gender = e.gender
        patCtx.pat_email = e.email
    
        const responseData = await fetch(postURL, {
          method: 'POST',
          body: JSON.stringify({
            "emr_mrn": `${e.local_site_mrn}`,
            "first_name": `${e.first_name}`,
            "last_name": `${e.last_name}`,
            "gender": `${e.gender}`,
            // "dob": `${dobresult}`
            dob: new Date(e.dob).toISOString().split("T")[0]
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userPrefCtx.bearerToken}`,
            auth: authCtx.authMode,
          },
        }).then((res) => res.json())
        .then((data) => {
        //    console.log(data);
    
           patCtx.pat_token = data.patient_token
           patCtx.dob = data.dob
           patCtx.pat_first = data.first_name
           patCtx.pat_last = data.last_name
           patCtx.pat_mrn = data.emr_mrn
           patCtx.pat_gender = data.gender
           patCtx.pat_dob = data.dob
    
    
           patCtx.pat_active_mrn = data.emr_mrn
           patCtx.pat_local_site_mrn = data.emr_mrn
           patCtx.pat_profile_image_filename = data.file_name 
           patCtx.pat_id = data.id 
           patCtx.pat_middle = data.middle_name !== null ? data.middle_name : ''
    
        function formatDate(dateString) {
          //This function will convert yyyy-mm-dd to mm/dd/yyyy
          const [year, month, day] = dateString.split("-");
          return `${month}/${day}/${year}`;
        }
    
        function formatDate(dateString) {
          //This function will convert yyyy-mm-dd to mm/dd/yyyy
          const [year, month, day] = dateString.split("-");
          return `${month}/${day}/${year}`;
        }
    
        const formattedDate = formatDate(data.dob);
    
        navigate('/Visit', 
        {state: {
            firstName: data.first_name,
            lastName: data.last_name,
            middleName: data.middle_name !== null ? data.middle_name : '',
            mrn: data.emr_mrn,
            dob: formattedDate,
            gender: data.gender,
            patientToken: data.patient_token,
            bearerToken: props.sendBearerToken,
            patId: data.id,
            profileImageFileName: data.file_name,
            authMode: authCtx.authMode,
          }})
        
        })
        .catch((err) => {
           console.log(err.message);
        });
      }
    

    const checkIfMrnExists = async (mrnValue) => {
       // if mrn is entered and exists show the error message
      // const mrnValue = updatedFields.find(f => f.display_name_short === 'emr_mrn').defaultValue
      let error = false;
       //if (!!mrnValue) {
           const checkMRNURL = `${process.env.REACT_APP_SERVER}/api/v1/patients/check_mrn_exists?emr_mrn=${mrnValue}`;
           const responseData = await fetch(checkMRNURL, {
               method: 'GET',
               headers: {
                   'Content-Type': 'application/json',
                   Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                   auth: authCtx.authMode
               },
           })
           .then((res) => {
               // Check for 422 or 500 response
               if (!res.ok) {
                   throw new Error('Unprocessable Entity: ' + res.statusText);
               }

               if(res.status === 240){
                   //console.log('it is 240 - MRN Already exists!')
                   //formErrors.emr_mrn = `MRN ${mrnValue} already exists`
                   setErrors({...errors, emr_mrn: `MRN ${mrnValue} already exists`})
                   // formErrors = {...formErrors, [field.display_name_short]: `MRN ${mrnValue} already exists`}
                   //error = true
          
                   // throw new Error('MRN Already exists!')
               } else {
                   if(res.ok){
                    setErrors({})
                    //console.log('in res.ok')
                       // console.log('checking first name: ', document.getElementById('create_patient_first_name').value)
                       //This is to go to the next step, to create a new patient if the MRN doesn't already exist
                       // sendToCreatePatientAPI(sendToPatientChart)
                   }
               }
               return res.json();
           })
           .then((data) => {
               //console.log(data);
               //  props.sendTrigger(data);
               //  props.close();
           })
           .catch((err) => {
               console.log(err.message);
               setShowErrorMesssage(err.message);
               //setShowError(true);
           });
       //}
    }


  return (
    <>
      <ErrorDialogModal
        setOpen={showError}
        close={() => setShowError(false)}
        componentName="Update Patient Information"
      />
    <Modal 
    //size="lg" 
    size="xl"
    //show={showModal2} 
    //onHide={handleClose} 
    show={props.setOpen}
    onHide={props.close}
    animation={false} 
    backdrop="static" 
    //centered
    //dialogClassName={classes.custom_modal}
    style={{ visibility: (showError) ? 'hidden' : 'visible' }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Patient</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <h4>{showErrorMessage}</h4>


        <div >
            {groups.map((group_id, index) => 
                <CreatePatientGroups 
                    key={index}
                    group_id={group_id}
                    fields={sortedFields.filter(q => q.group_id === group_id)}
                    sendErrorObject={sendErrorObject}
                    checkIfMrnExists={checkIfMrnExists}
                    errors={errors}
                />
            )}
        </div>
      
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" 
            onClick={savePatient}
        >
          Create Patient
        </Button>
        <Button variant="primary" 
            onClick={() => savePatient({sendToPatientChart: true})}
        >
          Create Patient & Go To Chart
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}

export default CreatePatientModal;