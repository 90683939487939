import React, {useState, useEffect} from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, LabelList } from 'recharts'
import chartClasses from '../../stylesheets/Charts.module.css'

// draw one group of eface charts
function EFaceOneChart(props) {

    // const barColors = ['#00429d', '#1f4ea3', '#305ba9', '#3e67ae', '#4a74b4', '#5681b9', '#618fbf', '#6d9cc4', '#79a9c9', '#85b7ce', '#93c4d2', '#a1d1d7', '#b1dfdb', '#c3ebde', '#daf7e1', '#ffeed3', '#ffdcc6', '#ffcab9', '#ffb8ab', '#ffa59e', '#fd9291', '#f78085', '#f06f7a', '#e75d6f', '#dd4c65', '#d23b5b', '#c52a52', '#b61849', '#a60741', '#93003a']
    const barColors = ['#7CB5EC', '#434348', '#90ED7D', '#F7A35C', '#8085E9', '#F15C80', '#E4D354', '#2B908F', '#F45B5B', '#91E8E1']


    const [efaces, setEfaces] = useState([])
    const [chart, setChart] = useState({})
    const [dates, setDates] = useState([])
    const [values, setValues] = useState([])
    const [barGap, setBarGap] = useState(3) // default
    const [barSize, setBarSize] = useState(10) // default
    const [yAxisWidth, setYAxisWidth] = useState(0)

    // console.log("%%%%%%%%%%EFaceOneChart - props", props)

    // useEffect(() => {
    //     // setEfaces(props.data)
    //     setChart(props.chart)

    // }, [/*props.data,*/ props.chart])

    // build data arrays
    // data arrays that feed recharts are different from what we get, so we need to build them
    useEffect(() => {

        // console.log("props.chart", props.chart)
        // build values map
        let valuesArray = props.x_categories.map((category_element, ar_ix) => {
            // console.log("category_element", category_element, "ar_ix", ar_ix)
            // console.log("x_value_map[ar_ix]", x_value_map[ar_ix])
    
            // get array of values. category_element is a 2-elemnt array; 1st element name (for header); 2nd - name of the fied in eface data
            // get array of data into vs
            const vs = props.data.map((a)=> a[category_element[1]])
            // console.log("vs", vs)
    
            // create array of objects from array of values (generate object key)
            const vs2 = vs.map((val, val_ix) => {return {[`d${val_ix}`]: val} })
            // console.log("vs2", vs2)
    
    
            // combine array of objects into one object with all properties combined
            var flat = vs2.reduce((obj, item) => {
                // console.log("*** flattening... obj", obj, "item", item, "item.keys", Object.keys(item))
                return {...obj, [Object.keys(item)[0]]: item[[Object.keys(item)[0]]]}
            } ,{})
            // console.log("flat", flat)
    
            // return name of the group along with values (for all dates)
            return {... flat, name: category_element[0]}
        
        })

        // console.log("================= ValuesArray", valuesArray)
        setValues(valuesArray)

        // create array of dates
        setDates(props.data.map((d) => d.eface_date))

        // calculate barSize and barGap based on the number of bars and bar groups to show
        const y_axis_width = props.viewType === 'full' ? 160 : 30
        setYAxisWidth(y_axis_width)

        // get width of the chart area
        const chart_area = document.getElementsByClassName('eface-charts')
        let chart_width = 0
        // there are multiple chart areas - on the main screen and in the open modal - get the last one
        if (chart_area !== null && chart_area.length > 0) {
            chart_width = chart_area[chart_area.length-1].offsetWidth
            
        }

        // part of the chart is taken by Y-axis - adjust value to exclude it
        chart_width = chart_width - y_axis_width
        // console.log("WIDTH OF CHART", chart_width)
   
        const barGroupCount = valuesArray.length
        const barsInGroup = Object.keys(valuesArray[0]).filter(key => key.startsWith('d')).length

        const oneGroupPercent = 100/barGroupCount
        const oneBarPercent = oneGroupPercent/barsInGroup * (1/3)

        const bsize = chart_width * oneBarPercent / 100
        const gsize = chart_width * oneBarPercent / 5 / 100
        setBarSize(bsize)
        setBarGap(gsize)

    },[props.x_categories, props.data])



    // take the chart title, remove ":" from teh end, replace space with underscore, convert to lower case
    // that will give the property name for chart data
    // take the latest value of that property
    // data already sorted by date so it's the last data
    const getTheTitleScore = (title) => {
        const data_property = title.toLowerCase().replace(/\s/g, '_').replace(/:$/, '')
        // console.log("title", title, "data propetery name", data_property)
        return props.data[props.data.length-1][data_property]
    }

    const CustomTooltip = ({ active, payload, label }) => {
        // console.log("**** tooltip: label", label, "payload", payload)
        if (active && payload && payload.length) {
            return (
                <div className={props.viewType === 'full' ? chartClasses['custom-tooltip-full'] : chartClasses['custom-tooltip-sm'] }>
                <span className={chartClasses['tooltip-header']}>{label}</span>
                <ul className={chartClasses["ul-tooltip"]}>
                    {/* {console.log("UL: payload[0]", payload[0])} */}
                    {Object.keys(payload[0].payload).filter((akey) => akey.startsWith('d')).map((d_key, d_ix) => {
                        const hStyle = { color: barColors[d_ix % barColors.length] }
                        return (
                            <li key={d_ix}>
                                    <span  style={hStyle}>{dates[d_ix]}:</span> {payload[0].payload[d_key]}
                            </li>
                        )}
                    )}
                    
                </ul>

                </div>
            )
        
        } else {
            return null
        }
    }


    const legendContent = (props) => {
        // console.log("legendContent: dates", dates)
        return (
            <div width="100%" >
                <ul className={chartClasses['legend']}>
                    {dates.map((d,i) => {
                        const dot_color = { background: barColors[i % barColors.length]  }
                        return (
                            <li key={i}><span className={chartClasses["dot"]} style={dot_color}></span><span>{d}</span></li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    const truncate = (str, n) => {
        return (str.length > n) ? str.slice(0, n-1) + '...' : str
    }

    // value - label value
    const formatXAxisLabel = (value) => {

        return props.viewType === 'full' ? value : truncate(value,6)
    }




    return (
        <>
            <h1 className="fs-6 text-center">{props.chart.chart_title} {getTheTitleScore(props.chart.chart_title)}</h1>
            
            <ResponsiveContainer width="98%" height={props.height}>
                <BarChart
                    data={values}
                    margin={{
                        top: 5,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                    // barGap={3}
                    // barSize={10}
                    // barCategoryGap={10}
                    barGap={barGap}
                    barSize={barSize}
                    maxBarSize={50}
                    // maxBarSize={10}
                >
                <XAxis dataKey="name" 
                    angle={props.viewType === 'full' ? 0 : -45} 
                    tickFormatter={formatXAxisLabel} 
                    dy={10}
                    height={40}
                >
    
                </XAxis>
                <YAxis 
                    label={{ value: 'Score', angle: -90, position: 'insideLeft', textAnchor: 'middle', offset: 7 }} 
                    domain={[0, props.viewType === 'full' ? Number(props.chart.y_axis_max) : 100]}
                    width={yAxisWidth + (props.viewType === 'full' ? -100: 25)}
                    // padding={{ top: 30, bottom: 30 }}
                />

                {/*wrapperStyle={{ 'border-radius': '5px', 'border-color':'#0d6efd', 'border-style':'solid', 'border-width':'2px' }}*/}

                <Tooltip  content={CustomTooltip} cursor={{fill: '#f0f0f0'}} 
                   />
                {
                    props.viewType === 'full' && 
                        <Legend iconType="circle" 
                            margin={{top:20, left: 100, right: 100, bottom:20 }}
                            verticalAlign="bottom" 
                            content={legendContent}

                            iconSize={30} width={'100%'} 
                            //height={50} 
                            layout='horizontal' 
                            horizontalAlign='center'
                        />
                }
                <CartesianGrid strokeDasharray="3 3" />
                    
                {   values && values.length > 0 &&  
                        Object.keys(values[0])
                        .filter((akey) => akey.startsWith('d'))
                        .map((akey,key_ix) => 
                            <Bar key={key_ix} 
                                dataKey={akey} 
                                fill={barColors[key_ix % barColors.length] }   
                                maxBarSize={10}                                
                            >
                                {/* <LabelList dataKey={akey} position="top" /> */}
                            </Bar>
                    )
                }
                </BarChart>
            </ResponsiveContainer>
        {/* // </div> */}
        </>
    )
}

export default EFaceOneChart
