import React, {useState, useContext, useEffect, useRef} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ErrorDialogModal from '../modals/ErrorDialogModal'
import DictionaryContext from '../../../context/dictionaryContext'
import UserPrefContext from '../../../context/userPrefContext'
import PatientContext from '../../../context/patientContext'
import AuthContext from '../../../context/authContext'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import style from './ProviderFollowupGroup.module.css'


function ProviderFollowupListModal(props) {

    const [modalTitle, setModalTitle] = useState('Followups list')
    const patCtx = useContext(PatientContext)
    const dictCtx = useContext(DictionaryContext)
    const userPreferenceCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)

    const [followupList, setFollowupList] = useState([])
    const [showError, setShowError] = useState(false)



    const followupsURL = (patientToken) =>
        `${process.env.REACT_APP_SERVER}/api/v1/provider_followups/get_all_by_token/${patientToken}`


    const getAllFollowups = async () => {
        const responseData = await fetch(followupsURL(patCtx.pat_token), {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userPreferenceCtx.bearerToken}`,
                auth: authCtx.authMode,
            },
            
        })
        //.then((res) => res.json())
        .then((res) => {
          if (!res.ok) {
             throw new Error('Unprocessable Entity: ' + res.statusText);
          }
          return res.json();
        })
        .then((data) => {
            // console.log("Provider Followup List", data)
            setFollowupList(data.sort((a,b) => new Date(b.updated_at) - new Date(a.updated_at)))

        })
        .catch((err) => {
            console.log(err.message)
            
        });
    }


    const fuSelected = (fu_id) => {
        const fu = followupList.find(fu => fu.id === fu_id)
        // console.log("Followup selected with id", fu_id, "values", fu)
        props.onSelect(fu)

    }

    const handleClose = () => {
        // setErrors([])
        props.onClose()
    }

    const createNew = () => {
        props.onCreateNew()
    }

    useEffect(() => {
        if (props.show) {
            getAllFollowups()
        }
    },[props.show, patCtx.pat_token])

    return (
        <>
        <Modal size="md" show={props.show} onHide={handleClose} animation={false}
                style={{ visibility: (showError) ? 'hidden' : 'visible' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title><h4>{modalTitle}</h4></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div >
                        <table className="table table-sm table-borderless">
                            <thead>
                                <tr>
                                    <th>Action</th>
                                    <th scope="col" className='mx-4 px-10 text-left'>Date</th> 
                                </tr>
                            </thead>
                            <tbody>
                                
                                {followupList.length > 0 && followupList.map((fu, index) => 
                                    <tr key={index}>
                                        <td>
                                            <span className={style.link} onClick={() => fuSelected(fu.id)}>View</span>
                                        </td>
                                        <td>
                                            {new Date(fu.updated_at).toLocaleString('en-US', {hour12: true,})}
                                        </td>
                                    </tr>
                                )}
                                {followupList.length === 0 && 
                                    <tr><td>No data found</td></tr>
                                }
                            </tbody>
                        </table>


                    </div>

                </Modal.Body>

                <Modal.Footer className='justify-content-between'>
                    <div className="inline-block text-left">
                        <Button className="mx-2" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </div>

                    {/* {!isEpicVisit && !readOnly && */}
                        <div className="inline-block text-right">
                            <Button className="mx-2" variant="primary" onClick={createNew}>
                                Create New
                            </Button>
                        </div>

                </Modal.Footer>
            </Modal>
            
        </>
    )
}

export default ProviderFollowupListModal
