import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReusableButton from '../buttons/reusableButton';
//import { PatientContextProvider } from '../../../context/patientContext';
import PatientContext from '../../../context/patientContext';
import UserPrefContext from '../../../context/userPrefContext';
import AuthContext from '../../../context/authContext';

function PromptBotoxMapDelete(props) {
  const [show, setShow] = useState(false);
  const [panelID, setPanelID] = useState();
  const [editData, setEditData] = useState([]);

  //console.log(props)

  const handleClose = () => setShow(false);
  const handleShow = () => {
    //setting the id to null when the new botox button is clicked
    //setEditData([null]);
    setShow(true);
    //console.log(editData)
    //setPanelID(null);
    //setEditData([null]);
  }

  const promptCtx = useContext(PatientContext);
  const userPrefCtx = useContext(UserPrefContext);
  const authCtx = useContext(AuthContext)
  //console.log(visCtx.pat_bearer_token)

  //const ptCtx = PatientContextProvider;

  //console.log(ptCtx)

  const deleteBotox = async () => { 
    //sending the edit when the edit botox button is clicked

    //probably put an if statement so that the edit map cant get selected unless there is a thumbnail/button selected
    //setShow(true); 
    // setPanelID(props.sendID);
    //console.log(props.sendID);

    if (props.sendID != null) {

        //console.log(userPrefCtx.bearerToken);

        const deleteBotoxMapURL = process.env.REACT_APP_SERVER + `/api/v1/botox_maps/` + props.sendID;

        const responseData = await fetch(deleteBotoxMapURL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userPrefCtx.bearerToken}`,
            //Authorization: `Bearer ${promptCtx.pat_bearer_token}`,
            auth: authCtx.authMode,
          },
        }).then((res) => res.json())
        .then((data) => {
           //console.log(data);
           //This will send the data back to the EditInterventionModal to trigger a delete in the visitpanel
           props.getChild(data);
           //once it successfully deletes the intervention, it will close the modal
           props.close();
        })
        .catch((err) => {
           console.log(err.message);
        });
    }
  
  }

  //console.log(props.sendID)
  //console.log('editData outside function is ', editData)

  //console.log('props in VisualTreatmentModal is ', props.sendBotoxtoModal)
  //console.log('patient token is ', props.sendToken)
  // console.log('props.sendBotoxtoModal[0].dictionary_type_value in VisualTreatmentModal is ', props.sendBotoxtoModal[0].dictionary_type_value)
  const getBotoxImageID = (data) => {
    //This is the function to get the color value from the BotoxType(child) component
    //console.log('this is the id in the modal', data);
    props.getModal(data)
    //colorChild.push(data);
    // setColorArr(data);
  }

  return (
    <>
      <Modal size="lg" show={props.setOpen} onHide={props.close} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>Delete Botox Map Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h3>Are you sure you want to delete this Botox Map?</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
          </Button>
          <Button variant="secondary" onClick={deleteBotox}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PromptBotoxMapDelete;