import React, {useState, useEffect, useContext} from 'react'
import PatientContext from '../../../context/patientContext'
import UserPrefContext from "../../../context/userPrefContext"
import DictionaryContext from '../../../context/dictionaryContext'
import AuthContext from '../../../context/authContext'
import FaceInstrumentOneChart from './FaceInstrumentOneChart'
// import {buildFaceInstrumentGroups} from '../../../utils/Utils'
import TotalScore from '../shared/TotalScore'
import chartClasses from '../../stylesheets/Charts.module.css'



function FaceInstrumentCharts(props) {

    const panelName = 'face_instrument_panel'
    const dictCtx = useContext(DictionaryContext)
    const visCtx = useContext(PatientContext)
    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)
    const faceInstrumentGetAllURL = `${process.env.REACT_APP_SERVER}/api/v1/face_instruments/get_graphs/${visCtx.pat_token}`

    const [groups, setGroups] = useState([])

    const [chartDataSorted, setChartDataSorted] = useState([])
    
    const [faceInstruments, setFaceInstruments] = useState([])
    const [x_axis_categories, set_x_axis_categories] = useState([])

    const [dictChartSorted, setDictChartSorted] = useState([])


    const buildFaceInstrumentGroups = () => {
        const dictPanel = dictCtx.dict_panel.find(pnl => pnl.display_name_short === panelName)
        
        if (dictPanel !== undefined) {
            // find dict_intake_form by display_name_short:
            // display_name_short in dict_panle looks like "panelname_panel"
            // display_name_short in dict_intake_form looks like "panelname"
            const intakeForm = dictCtx.dict_intake_form.find(form => {
                return form.display_name_short === dictPanel.display_name_short.replace("_panel","")
            })
        
            if (intakeForm !== undefined) {
                // setIntakeFormId(intakeForm.id)
                // sort questions by sort_position
                const questions = dictCtx.dict_intake_form_question.filter(quest => {
                    return quest.DictIntakeFormQuestion.dict_intake_form_id === intakeForm.id
                })
                
                const comp = (a,b) => {
                    var agrp = (+a.DictIntakeFormQuestion.group_id) * 100 + (+a.DictIntakeFormQuestion.sort_position)
                    var bgrp = (+b.DictIntakeFormQuestion.group_id) * 100 + (+b.DictIntakeFormQuestion.sort_position)
        
                    // console.log("sort:", typeof anum, typeof bnum, (anum - bnum))
                    return agrp - bgrp
                }
                // const sortedQuestions = questions.sort((a,b) => a.sort_position - b.sort_postion)
                const sortedQuestions = questions.sort(comp)
  
                // get group_ids of questions (only one id per group)
                const groups = sortedQuestions
                    .map((value, index, qs) => (index > 0 && qs[index-1].DictIntakeFormQuestion.group_id === value.DictIntakeFormQuestion.group_id) ? null : value.DictIntakeFormQuestion.group_id)
                    .filter(value => value !== null)

                // build array of group objects: {group_id: 1, group_questions: [questions]}
                const group_questions = groups.map((group_id) => {
                    // const group_qs = sortedQuestions.filter((q) => q.group_id === group_id)
                    return {group_id: group_id, group_questions: sortedQuestions.filter((q) => q.DictIntakeFormQuestion.group_id === group_id), chart: dictCtx.dict_chart.find(chart => chart.group_id === group_id)}
                })
                return group_questions
            }
        }
        return []
    }




    useEffect(() => {
        // console.log("**** FaceInstrumentCharts loading **** authCtx", authCtx)
        let ignoreFaceInstruments = false
        const getFaceInstrumentsData = async() => {
            try {
                const response = await fetch(faceInstrumentGetAllURL, {
                    headers: {
                        'Authorization': `Bearer ${userPrefCtx.bearerToken}`,
                        auth: authCtx.authMode,
                    },
    
                })
                // console.log("face instruments response", response)
                if (response.status === 200) {
                    // console.log("face instruments ignoreFaceInstruments", ignoreFaceInstruments)
                    if (!ignoreFaceInstruments) {
                        const responseData = await response.json()
                        // sort data by created_at
                        const sorted_data = responseData.chart_data.sort((a,b) => new Date(a.created_at) > new Date(b.created_at) ? 1 : -1)
                        // console.log("Get all face instruments", sorted_data)
                        setFaceInstruments(sorted_data)
                    }
                    
                } else {
                    console.log("Failed to get all face instrumentss", response)
                }
            } catch (e) {
                console.log("Failed to get all face instruments", e)
            }
    
        }

        // console.log("FaceInstrumentCharts: mode=", props.mode, "lastLoadedTime", props.lastLoadedTime, "lastUpdatedTime", props.lastUpdatedTime)
        // reload face instrument data only if either in 'dialog' mode or if data was updated AFTER it was loaded
        if ((props.mode === 'dialog' || (props.lastLoadedTime < props.lastUpdatedTime)) && userPrefCtx.bearerToken !== null && dictCtx.dict_panel.length > 0) {

            // console.log("Let's load face instrument data")
            getFaceInstrumentsData()

            // if in inline mode update loaded time
            if (props.mode === 'inline') {
                props.setLastLoadedTime(new Date().getTime())
            }

            // build face instrument groups
            const fiGroups = buildFaceInstrumentGroups()
            // console.log("FaceInstrumentCharts - groups", fiGroups)
            setGroups(fiGroups)
            // console.log("fiGroups:", fiGroups)
    
            const x_categories = fiGroups.map((grp) => {
                const grp_cats = JSON.parse(grp.chart.x_axis_category)
                // console.log("grp_cats", grp_cats)
                return grp_cats
            })
                
            // console.log("x_categories", x_categories)
            // each element in x_categories is a two-elemnt array; first alement is the name of the chart; second is the name of the corresponding value field in eface data
            set_x_axis_categories(x_categories)
        }
        




        // return () => {
        //     ignoreFaceInstruments = true
        // }

    }, [userPrefCtx.bearerToken, props.lastUpdatedTime, props.lastLoadedTime, dictCtx.dict_panel.length, visCtx.pat_token])


    const chartsClicked = () => {
        if (faceInstruments.length > 0 && props.hasOwnProperty('onClick')) {
            props.onClick()
        }
    }

    return (
        <div className={`face-instrument-charts ${props.viewType === 'full' ? chartClasses['charts-full']: chartClasses['charts-sm'] }`} height='300' 
            onClick={chartsClicked}
        >
            {props.viewType === 'full' && faceInstruments.length > 0 &&
                <h3 className={chartClasses['charts-center']}>
                    Total Face Instrument Score: 
                    <TotalScore 
                        data={faceInstruments}    
                        property_date_name='created_at'
                        property_value_name='faceInstrumentsScore'
                        first_or_last='first'
                    />
                </h3>
            }

            {
                groups.length > 0 && faceInstruments.length > 0 && groups.map((grp, grp_ix) => {
                    const group_title = grp.group_questions.length > 1 ? `${grp.group_questions[0].display_name_short}-${grp.group_questions[grp.group_questions.length-1].display_name_short}` : grp.group_questions[0].display_name_short
                    return (
                    <FaceInstrumentOneChart 
                        key={grp_ix}
                        // chart_title={group_title}
                        // chart_title={grp.chart.chart_title}
                        group={grp}
                        data={faceInstruments}
                        x_categories={x_axis_categories[grp_ix]}
                        width={props.width}
                        viewType={props.viewType}
                        height={props.viewType === 'full' ? 500 : 100}
                    />
                    )   

                })
            }
        </div>
    )
}

export default FaceInstrumentCharts
