import React, { useState, useEffect, useContext } from 'react';
import DOMPurify from 'dompurify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SystemMaintenance from './SystemMaintenance';
import MergePatientModal from './MergePatientModal';
import { trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../shared/LoadingSpinner'
import { toLocalDateTime } from '../../../../utils/Utils'
import UserPrefContext from '../../../../context/userPrefContext';
import AuthContext from '../../../../context/authContext';

function SystemConfigModal(props) {
  const [show, setShow] = useState(false);
  const [showPatientMergeModal, setShowPatientMergeModal] = useState(false);
  const [dataFromChild, setDataFromChild] = useState();
  const [sendAnotherUpdate, setSendAnotherUpdate] = useState();

  const userPrefCtx = useContext(UserPrefContext)
  const authCtx = useContext(AuthContext)

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }

  const handleClosePatientMerge = () => setShowPatientMergeModal(false);
  const handleShowPatientMerge = () => setShowPatientMergeModal(true);

  const callBackFunction = (e) => {
    setSendAnotherUpdate(e)
    props.sendToAdminActions(e)
    //handleClose();
    //handleShow();
  }

  useEffect(() => {
    setSendAnotherUpdate(dataFromChild);
    
  }, [dataFromChild])

  const closeAllFromMergePatient = () => {
    //This will close the child modal when they call this function, as well as the SystemConfigModal
    setShowPatientMergeModal(false)
    props.close();
  }

  const reportRequested = (report_type) => {
    // testCreateFromJson()
    // return
    const createReport = async() => {
        try {
            const reportUrl = `${process.env.REACT_APP_SERVER}/api/v1/patients/all_patients_from_db`
            //const reportUrl = '';
            await fetch(reportUrl, {
                headers: {
                    Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                    // 'Access-Control-Expose-Headers': '*',
                    // 'Access-Control-Allow-Headers': '*',
                    auth: authCtx.authMode,
                }, 

            })
            .then((response) => {
                const responseHeaders = [...response.headers]
                const contentType = responseHeaders.findIndex(header => header[0] === 'content-type') >= 0 ? response.headers.get("content-type") : ''
                const contentDisposition = responseHeaders.findIndex(header => header[0] === 'content-disposition') >= 0 ? response.headers.get("content-disposition") : ''
                
                if (!response.ok) {
                  console.log('response is not ok - admin actions')
                  throw new Error('Unprocessable Entity: ' + response.statusText);
                }
                if (contentType && contentType.indexOf('application/json') >= 0) {
                    return response.json()
                        .then((data) => {
                            // console.log("AdministrativeActions - SystemConfigModal", data, "response.status", response.status)
                            if (response.status === 200) {

                              function padValue(value, width) {
                                const stringValue = String(value || '');
                                const padding = ' '.repeat(Math.max(0, width - stringValue.length));
                                return stringValue + padding;
                              }

                              function downloadCSV(arrayOfObjects, fileName) {
                                // console.log(arrayOfObjects)

                                const filteredArray = arrayOfObjects.map(obj => {
                                  // Destructure the object and take out patient_token
                                  const { patient_token, ...rest } = obj;
                                  return rest;
                                });
                                
                                // console.log(filteredArray);

                                const allKeys = Array.from(new Set(filteredArray.flatMap(obj => Object.keys(obj))));

                                  // Calculate column widths dynamically
                                const columnWidths = allKeys.reduce((widths, key) => {
                                  widths[key] = Math.max(
                                    key.length,
                                    ...filteredArray.map(obj => String(obj[key] || '').length)
                                  );
                                  return widths;
                                }, {});

                                // Convert Array of Objects to CSV String
                                const csvContent = 
                                allKeys.join(',') + '\n' +

                                filteredArray.reduce((acc, obj) => {
                                  const values = allKeys.map(key => {
                                    const value = obj[key] || '';
                                    return `"${String(value).padEnd(columnWidths[key])}"`;
                                  });
                                  return acc + values.join(',') + '\n';
                                }, '');
                              
                                // Create Blob Object
                                const blob = new Blob([csvContent], { type: 'text/csv' });
                              
                                // Create a Download Link
                                const link = document.createElement('a');
                                link.href = window.URL.createObjectURL(blob);
                                link.download = fileName || 'data.csv';
                              
                                // Append the Link to the Document
                                document.body.appendChild(link);
                              
                                // Trigger a click event on the link
                                link.click();
                              
                                // Remove the link from the document
                                document.body.removeChild(link);
                              }

                              downloadCSV(data, `patient_list_${toLocalDateTime(new Date(),'_').replace(/-/g, "_")}.csv`)
    
                            } else if (response.status !== 200) {
                                if (data.hasOwnProperty('detail')) {
                                    // setConfirmDialogText(data.detail)
                                    // setConfirmDialogTitle('Alert!')
                                    // setShowConfirm(true)
                                }
                            }
                        })

                } else {
                  if (!response.ok) {
                    console.log('response is not ok - admin actions')
                    throw new Error('Unprocessable Entity: ' + response.statusText);
                  }
                    return response.blob()
                        .then((blob) => {
                            const excelData = new Blob([blob], { type: contentType })
                            const url = window.URL.createObjectURL(excelData)
                            const a = document.createElement('a')
                            console.log(a)
                            a.style.display = 'none'
                            a.href = DOMPurify.sanitize(url, {SAFE_FOR_JQUERY:true})
                            // extract file name from disposition
                            const dispositionRegex = /filename=['"](?:.*?)['"]/gi
                            const match = contentDisposition.match(dispositionRegex)
                            if (!! match && match.length > 1) {
                                a.download = `${match[1]}`
                            } else {
                                // use filename with date and time in format report_name_yyyy_mm_dd_hhmmss
                                a.download = `${report_type}_${toLocalDateTime(new Date(),'_').replace(/-/g, "_")}.xlsx`
                            }
                            // document.body.appendChild(a)
                            a.click()
                            window.URL.revokeObjectURL(url)
                        })
                }
            })
            .catch((err) => {
                console.log(err.message);
                //setShowError(true);
            })
        }
        catch (e) {
            console.log(e)
        }
    }

    trackPromise(
        createReport()
    )
}


  return (
    <>
    <SystemMaintenance setOpen={show} close={handleClose} updateParent={callBackFunction} sendDataBack={dataFromChild} sendAnotherUpdate={sendAnotherUpdate} />
    <MergePatientModal setOpen={showPatientMergeModal} close={handleClosePatientMerge} closeAll={closeAllFromMergePatient} />
      <Modal 
      size="fullscreen" 
      show={props.setOpen} 
      onHide={props.close} 
      animation={false} 
      style={{ visibility: (show, showPatientMergeModal) ? 'hidden' : 'visible' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>System Configuration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row mb-2">
          <div className="col">
            <Button variant="secondary" 
                onClick={handleShow}
            >
                User Table
            </Button>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <Button variant="secondary" 
                onClick={handleShowPatientMerge}
            >
                Merge Duplicate Patient
            </Button>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <Button variant="secondary" 
                onClick={() => {reportRequested()}}
            >
                Patient List
            </Button>
          </div>
        </div>
        <LoadingSpinner size='100px' /*onEndOfPromise={() => setLoadedTime(new Date())}*/ />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SystemConfigModal;