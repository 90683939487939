import React, { useState, useEffect, useContext } from 'react';
import DatePicker from 'react-datepicker';
import UserPrefContext from '../../../context/userPrefContext';
import 'react-datepicker/dist/react-datepicker.css';

const ProviderCalendar = (props) => {
  // const [startDate, setStartDate] = useState(new Date());
  const [startDate, setStartDate] = useState();
  const userPrefCtx = useContext(UserPrefContext);

  //console.log(startDate);

  // This is sending the date to the parent component (Dashboard)
  //props.func(startDate)
  // useEffect(() => {
  //   props.func(startDate)
  //   console.log(startDate)
  //   //console.log(startDate.toLocaleString());

  // }, [startDate]);

  useEffect(() => {
    if(userPrefCtx.userPreference.visit_date != null){
      setStartDate(new Date(userPrefCtx.userPreference.visit_date))
      props.func(new Date(userPrefCtx.userPreference.visit_date))
      //setStartDate(formatDate(userPrefCtx.userPreference.visit_date))
    } else {
      //console.log(userPrefCtx)
      //console.log('in the else', userPrefCtx.userPreference.visit_date)
      if(userPrefCtx.userPreference.visit_date != undefined){
        setStartDate(new Date())
      }
    }

  }, [userPrefCtx.userPreference.visit_date]);

  const getDate = (e) => {
    setStartDate(e);
    props.func(e)
  }


  return (
    <div>
      <h4>Appointment Date</h4>
      <DatePicker selected={startDate} 
        // onChange={(date) => setStartDate(date)} 
        onChange={(date) => getDate(date)} 
        className="datepickerstyle" />
    </div>
  );
};

export default ProviderCalendar;
