import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const InterventionCalendar = (props) => {
  const [startDate, setStartDate] = useState(new Date());

  //console.log(startDate);

  useEffect(() => {
    props.func(startDate)

  }, [startDate]);
  
  return (
    <div>
      <h4>Intervention Date</h4>
      <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="datepickerstyle" />
    </div>
  );
};

export default InterventionCalendar;