import React, {useState, useContext} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { BsFillPlusSquareFill } from 'react-icons/bs'
import DictionaryContext from '../../../context/dictionaryContext'
import DiagsModal from './DiagsModal'

function DiagTypeChoiceModal(props) {

    const dictionaryContext = useContext(DictionaryContext)
    const dict_diag = dictionaryContext.dict_diag
    // get unique diag types
    const diagTypes = [... new Set(dict_diag.map(d => d.diag_type))]

    const [show, setShow] = useState(false)
    const [selectedDiagType, setSelectedDiagType] = useState('')


    const openDialog = () => {
        setShow(true)
    }

    const buttonClicked = (event) => {
        const diag_type = event.target.value
        // console.log("diag type selected", diag_type)
        // setSelectedDiagType(diag_type)
        props.onDiagTypeSelected(diag_type)
        setShow(false)

    }

    const handleClose = () => {
        setSelectedDiagType('')
        setShow(false)
    }


    return (
        <>
            <BsFillPlusSquareFill onClick={openDialog} color="black"  className="icon-pointer" />

            <Modal show={show} onHide={handleClose} animation={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Diagnosis Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex' }}>
                        {diagTypes.map((type, type_ix) => (
                            <Button 
                                key={type_ix}
                                className='diag-type-choice-modal-button'
                                value={type}
                                onClick={buttonClicked}
                            >
                                {type}
                            </Button>
                        ))
                        }
                    </div>
{/* 
                    {selectedDiagType !== '' &&
                        <DiagsModal 
                            diag_type={selectedDiagType}
                            diag_id={0}
                            mode='new'
                        >

                        </DiagsModal>
                    } */}
                    {/* <SingleDiagModal
                        sendDictDiag={props.sendDictDiag}
                        setOpen={openSingleDiagModal}
                        sendDiagType={diagnosisType}
                        getChild={getDiagnosisFromChild}
                        close={closeSingleDiagModal}
                        bearerToken={props.bearerToken}
                    /> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DiagTypeChoiceModal
