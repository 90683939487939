import React, {useState, useContext, useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ErrorDialogModal from '../modals/ErrorDialogModal'
import UserPrefContext from '../../../context/userPrefContext'
import SelectPatient from '../shared/SelectPatient'
import SignaturePad from 'signature_pad'
import styles from './PatientConsent.module.css'
import AuthContext from '../../../context/authContext'


function PatientConsent(props) {

    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)
    const [patientData, setPatientData] = useState(null)
    const [consentDictionary, setConsentDictionary] = useState([])
    const [selectAllId, setSelectAllId] = useState(0)

    const [consentValues, setConsentValues] = useState([])

    const [signaturePad, setSignaturePad] = useState(null)
    const [consent, setConsent] = useState(null)

    const [patientError, setPatientError] = useState('')
    const [signatureError, setSignatureError] = useState('')

    const [showError, setShowError] = useState(false);
    

    const clearPad = () => {
        signaturePad.clear()
    }

    useEffect(() => {
        if (props.show) {

            // clear everything
            setPatientData(null)
            setConsentValues([])
            setSignatureError('')
            setPatientError('')

            const canvas = document.querySelector("canvas")
            const ratio =  Math.max(window.devicePixelRatio || 1, 1);
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = canvas.offsetHeight * ratio;
            canvas.getContext("2d").scale(ratio, ratio);
            
            const sPad = new SignaturePad(canvas, {
                backgroundColor: 'rgba(255, 255, 255, 0)',
                penColor: 'rgb(0, 0, 0)'
            })

            sPad.clear(); // otherwise isEmpty() might return incorrect value
            setSignaturePad(sPad)

        }

    }, [props.show])


    useEffect(() => {
        const getDictionary = async () => {
            const url = `${process.env.REACT_APP_SERVER}/api/v1/patient_consents/dict_data`
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                auth: authCtx.authMode,
            }

            const response = await fetch(url, {
                headers: headers,
            })
            .then((res) => res.json())
            .then((data) => {
               
                setConsentDictionary(data.filter(d => d.is_active).sort((d1,d2) => d1.sort_position - d2.sort_position))
                const all = data.find(d => d.display_name_short === 'approve_all')
                if (!!all) {
                    setSelectAllId(all.id)
                }
            })
            .catch((err) => {
                console.log(err.message);
            })
        }

        if (userPrefCtx.bearerToken !== null && userPrefCtx.bearerToken !== '' && props.show) {
            // console.log("bearer",userPrefCtx.bearerToken)
            getDictionary()
        }
        

    },[userPrefCtx.bearerToken, props.show])





    // patient cleared from the serach field
    const handleCleared = () => {
        // clear everything
        setPatientData(null)
        setConsentValues([])
        clearPad()
    }



    const handleChange = (data) => {
        handleCleared()
        // patient selected from the search patient dropdown - set email
        setPatientData(data)

        // check if there is already consent for this patient
        loadConsent(data)
    }

    const loadConsent = async (patData) => {
        let values = []

        const url = `${process.env.REACT_APP_SERVER}/api/v1/patient_consents/get_all_by_token/${patData.patient_token}`
       
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                auth: authCtx.authMode,
            },
        })
        .then((res) => res.json())
        .then((data) => {
            console.log('consent loaded: ', data)
            if (data.length > 0) {
                let consents = data.sort((c1,c2) => new Date(c1.created_at) - new Date(c2.created_at))
                console.log('consents (sorted): ', consents)
                let values = []
                consents.map(cnst => {
                    if (cnst.DictPatConsntQsn !== null && values.indexOf(cnst.PatConsntDictPatConsntQsn.dict_pat_consnt_qsn_id) < 0) {
                        values.push(cnst.PatConsntDictPatConsntQsn.dict_pat_consnt_qsn_id)
                    }
                })
                //I think this was preventing the 'all of the above' from appearing when it's historical data
                //values = setApproveAll(values)

                // historical data may come with Select All checked while not all values selected - if so make all values selected
                if (values.indexOf(selectAllId) >= 0) {
                        values = consentDictionary.map(q => q.id)
                }

                setConsentValues([...values])
                // get image - use the latest
                const sign = consents[consents.length-1].PatientConsent.signature_base64
                signaturePad.fromDataURL(sign)
                setSignatureError('')

            }

        })
        .catch((err) => {
            console.log(err.message);
        })
    }


    const handleSave = () => {

        let error = false
        if (patientData === null) {
            setPatientError('Patient is not selected')
            error = true
        } else {
            setPatientError('')
        }
        if (signaturePad.isEmpty()) {
            setSignatureError('Please sign in the box below')
            error = true
        } else {
            setSignatureError('')
        }

        // save as PNG file
        if (!error) {
            const signature = signaturePad.toDataURL('image/png')
            saveConsent(signature)
            // close consent dialog 
            //props.onClose()
        } 
        

    }


    const saveConsent = async (signature) => {
        let values = []

        const url = `${process.env.REACT_APP_SERVER}/api/v1/patient_consents/${patientData.patient_token}`
        const body=JSON.stringify({
            signature_base64: signature,
            dict_pat_consnt_qsn_id: consentValues
        })
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                auth: authCtx.authMode,
            },
            body: body
        })
        //.then((res) => res.json())
        .then((res) => {
            // Check for 422 or 500 response
            if (!res.ok) {
                 throw new Error('Unprocessable Entity: ' + res.statusText);
            }
            return res.json();
          })
        .then((data) => {
            props.onClose()
        })
        .catch((err) => {
            console.log(err.message);
            setShowError(true);
        })
    }



    const handleClose = () => {
        props.onClose()
    }


    const handleValueChanged = (event) => {
        let vals = [...consentValues]
        const checked = event.target.checked
        // we need the value as integer
        const value = parseInt(event.target.value)
        // console.log("value", value, "checked?", checked, "all values", vals)
        const index = vals.indexOf(value)
        // console.log("index", index, "value", vals[index])
        // special case - talk to Tushar
        if (event.target.id === 'approve_all') {
            if (checked) {
                // check all
                vals = consentDictionary.map(q => q.id)
            } else {
                // uncheck all
                vals = []
            }
        } else {
            if (checked && index < 0) {
                // add value to the array
                vals.push(value)
            } else if (!checked && index >= 0) {
                // console.log("removing value: index", index, "value", vals[index])
                vals.splice(index,1)
            }
        }

        // adjust select_all check status depending on other values
        // if at least one not checked then select all should be unchecked too; otherwise it should be checked
        // let ons = vals.filter(v => v !== selectAllId)
        // const selectAllIndex = vals.indexOf(selectAllId)
        // if (ons.length < consentDictionary.length - 1) {
        //     // not all selected - uncheck select all checkbox
        //     if (selectAllIndex >= 0) {
        //         vals.splice(selectAllIndex,1)
        //     }
        // } else {
        //     // all checked - make sure that select all is also checked
        //     if (selectAllIndex < 0) {
        //         vals.push(selectAllId)
        //     }
        // }

        vals = setApproveAll(vals)
        
        // console.log("values", vals)
        setConsentValues([...vals])
    }

    // values - array of checked values
    // returns array possibly updated with approveAll check status 
    const setApproveAll = (values) => {
// adjust select_all check status depending on other values
        // if at least one not checked then select all should be unchecked too; otherwise it should be checked
        let vals = [...values] 
        let ons = vals.filter(v => v !== selectAllId)
        const selectAllIndex = vals.indexOf(selectAllId)
        if (ons.length < consentDictionary.length - 1) {
            // not all selected - uncheck select all checkbox
            if (selectAllIndex >= 0) {
                vals.splice(selectAllIndex,1)
            }
        } else {
            // all checked - make sure that select all is also checked
            if (selectAllIndex < 0) {
                vals.push(selectAllId)
            }
        }

        return vals
    }


    return (
        <>
        <ErrorDialogModal
          setOpen={showError}
          close={() => setShowError(false)}
          componentName="Patient Consent"
        />
        <Modal 
            backdrop="static"
            size="lg" 
            show={props.show} 
            animation={false} 
            onHide={() => props.onClose('cancel')} 
            style={{ visibility: (showError) ? 'hidden' : 'visible' }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Patient Consent</Modal.Title>
            </Modal.Header>
            <Modal.Body>              
                <SelectPatient 
                    error={patientError} 
                    onErrorReset={() => setPatientError('')}
                    // onErrorReset={resetAllErrors}
                    onChange={handleChange} 
                    onClear={handleCleared}/>

                <ul className={styles.patientConsentUl}>
                {consentDictionary.map(q => {
                    return q.is_active ? (
                        <li key={q.unique_id} >
                        <input id={q.unique_id}
                            style={{marginRight: 5 }} 
                            type={q.question_type}
                            value={q.id}
                            onChange={handleValueChanged}
                            checked={consentValues.indexOf(q.id)>=0}
                            // checked={diags[gi.id]}
                            // defaultChecked={gi.diag_type === props.diag_type && gi.display_name === 'Facial Paralysis'}
                            // onChange={checkboxClicked}
                            />
                        <label htmlFor={q.unique_id} style={{whiteSpace:"normal", display:"inline"}}>{q.display_name}</label>
                    </li>       
                    ) : null;
                })}
                </ul>
                
                

                <div className={styles.patientConsentWrapper}>
                    {signatureError !== '' &&
                        <span className={styles.errorMsg}>{signatureError}</span>
                    }
                    <canvas id="signature-pad" className={`${styles.consentSignaturePad} ${!!signatureError ? styles.consentSignaturePadError : styles.consentSignaturePadNormal}`} ></canvas>
                    <Button onClick={clearPad} >Clear</Button>
                </div>
                
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <div className="inline-block text-left">
                    <Button className='mx-2' variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </div>

                <div className='inline-block text-right'>
                    <Button className='mx-2' variant="primary" onClick={handleSave}>
                        Save Record
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default PatientConsent
