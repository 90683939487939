import React, { useState, useContext, useEffect } from 'react'
import DictionaryContext from '../../../context/dictionaryContext'
import UserPrefContext from '../../../context/userPrefContext'
import Form from 'react-bootstrap/Form'
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap'

function VisitType(props) {

    const {dict_visit_types} = useContext(DictionaryContext)

    const [visitType, setVisitType] = useState(props.visit_type)
    
    
    useEffect(() => {
        // console.log("VisitType - dict_visit_types", dict_visit_types)
        setVisitType(props.visit_type)
    }, [props.visit_type])



    const onSelect = (event) => {
       
        // get selected value (text)
        const selected=dict_visit_types.find(vt => vt.dictionary_value === event.target.value)
        setVisitType(event.target.value)
        // console.log("On Select", event.target.value, "text", selected)
        props.onSelect(selected.dictionary_value)
    }

    return (
        <>
            <FormGroup /*className={props.className}*/ className='row'>
                <FormLabel className='col-form-label col-sm-3 my-0'>Visit Type:</FormLabel>

                <div className='col-sm-9 my-1'>

                    <Form.Select
                        name='visit_type_dropdown' 
                        size='sm'
                        onChange={onSelect}
                        value={visitType !== null ? visitType : ''}
                        disabled={props.readOnly}
                        // value={values[el.name] !== undefined ? values[el.name] : ''}
                        // onChange={(event) => value_changed(event, ix)}
                    >
                        <option value=''></option>
                        {/* {dict_visit_name.filter(vt => vt.dictionary_detail === 'VISIT_TYPE' && vt.dictionary_value !== '').sort((a,b) => a.dictionary_value > b.dictionary_value ? 1 : -1 ).map((vt) =>  */}
                        {/* {visitTypes.filter(vt => vt.dictionary_detail === 'VISIT_TYPE' && vt.dictionary_value !== '').sort((a,b) => a.dictionary_value > b.dictionary_value ? 1 : -1 ).map((vt) =>  */}
                        {dict_visit_types.map((vt,ix) => 
                        <option 
                                key={ix}
                                value={vt.dictionary_value}
                            >
                                {vt.dictionary_value}
                            </option>
                        )}
                    </Form.Select>
                </div>
                

            </FormGroup>
        </>
    )
}

export default VisitType
