import React, {useContext, useState, useEffect} from 'react'
// import { useOktaAuth } from '@okta/okta-react';
import Dropdown from "react-bootstrap/Dropdown"
// import DropdownButton from 'react-bootstrap/DropdownButton'
import classes from "./NavDropDown.module.css"
import DictionaryContext from '../../context/dictionaryContext'
import UserPrefContext from '../../context/userPrefContext' 
import AuthContext from '../../context/authContext'
import CompanyContext from '../../context/companyContext'
// import axios from 'axios'
// import useLoadContext from '../../hooks/useLoadContext'

function SiteDropDown(props) {

    // const { authState, oktaAuth } = useOktaAuth();
    const dictCtx = useContext(DictionaryContext)
    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)
    const companyCtx = useContext(CompanyContext)
    const [sites, setSites] = useState([])
    const [selectedSite, setSelectedSite] = useState({code:'', name:''}) ///useState(props.site)
    


    const visitDictURL = `${process.env.REACT_APP_SERVER}/api/v1/dict_services/visit`
    const userPrefMeURL = `${process.env.REACT_APP_SERVER}/api/v1/user_preferences/me`
    const dictSiteLocsURL = `${process.env.REACT_APP_SERVER}/api/v1/dict_site_locs`

    


    useEffect(() => {
  
        if (dictCtx.dict_site_loc.length > 0) {
            let tempSites = dictCtx.dict_site_loc
                // .filter(siteloc => siteloc.dict_company_code_id === companyCtx.company.id)
                // .map(siteloc => siteloc.site_loc_code)
            // console.log("SiteDropDown - sites", tempSites)
            setSites(tempSites)
            if (!!userPrefCtx.userPreference?.site_loc) {
                const siteName = tempSites.find(site => site.site_loc_code === userPrefCtx.userPreference.site_loc)?.site_loc_name || ''
                // console.log("SiteDropDown - siteName", siteName)
                setSelectedSite({code: userPrefCtx.userPreference.site_loc, name: siteName})
                // console.log("SiteDropDown - selectedSite", {code: userPrefCtx.userPreference.site_loc, name: siteName})
            }
            
        }
    }, [dictCtx.dict_site_loc, userPrefCtx.userPreference?.user_dept, userPrefCtx.userPreference?.site_loc, props.site])

    


    const handleDropdownSelection = (eventKey) => {
        // on site selection save preferred site into user preferences
        // console.log("SiteDropDown - site selected", eventKey)
        // console.log("SiteDropDown - set Selected Site", sites.find(site => site.site_loc_code === eventKey))
        const sel_site = sites.find(site => site.site_loc_code === eventKey)
        // setSelectedSite(sites.find(site => site.site_loc_code === eventKey))
        setSelectedSite({code: sel_site.site_loc_code, name: sel_site.site_loc_name})
        updateSiteLoc(eventKey)
    }

    const updateSiteLoc = async (site) => {
        const userPrefMeURL = `${process.env.REACT_APP_SERVER}/api/v1/user_preferences/me`
        const headers = () => { return { 
            Authorization: `Bearer ${userPrefCtx.bearerToken}`, 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'mode': "no-cors", 
            auth: authCtx.authMode 
        }}
        const response = await fetch(userPrefMeURL, { 
            headers: headers(),
            method: 'PATCH',
            body: JSON.stringify({
                "site_loc": site,
                "user_dept": userPrefCtx.userPreference.user_dept,
            }),
        })
        .then((res) => res.json())
        .then((data) => {
            // refresh site drop down
            // props.forceRefresh()
            
        })
        .catch((err) => {
            console.log(err.message);
        })

    }


    if (sites.length === 0) {
        return (<span>{selectedSite.name}</span>)
    }


    // if (sites.length === 0) {
    //     return null
    // }

    return (
        <Dropdown autoClose /*align={{ lg: 'end' }}*/ className={classes.dd_pad} onSelect={handleDropdownSelection}>
            <Dropdown.Toggle className={classes.site_loc}>
                {selectedSite.name}
            </Dropdown.Toggle> 
            <Dropdown.Menu>
                {sites.map((site) => {
                        return (
                            <Dropdown.Item key={site.id} eventKey={site.site_loc_code}>
                                {/* {site.site_loc_code} */}
                                {site.site_loc_name}
                            </Dropdown.Item>
                        )
                    })
                
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default SiteDropDown
