import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReusableButton from '../buttons/reusableButton';
import AuthContext from '../../../context/authContext';
import UserPrefContext from '../../../context/userPrefContext';
import ConfirmDialog from '../shared/ConfirmDialog';
import {BsSignStopFill } from 'react-icons/bs'

function PromptEditInterventionDelete(props) {
  const [show, setShow] = useState(false);
  const [panelID, setPanelID] = useState();
  const [editData, setEditData] = useState([]);
//   const [showConfirm, setShowConfirm] = useState(props.setOpen)

  const authCtx = useContext(AuthContext)
  const userPrefCtx = useContext(UserPrefContext);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    //setting the id to null when the new botox button is clicked
    //setEditData([null]);
    setShow(true);
    //console.log(editData)
    //setPanelID(null);
    //setEditData([null]);
  }

  const deleteIntervention = async () => { 
    //sending the edit when the edit botox button is clicked

    //probably put an if statement so that the edit map cant get selected unless there is a thumbnail/button selected
    //setShow(true); 
    // setPanelID(props.sendID);
    //console.log(props.sendID);

    if (props.sendID != null) {
        const patchInterventionURL = process.env.REACT_APP_SERVER + `/api/v1/interventions/` + props.sendID;

        const responseData = await fetch(patchInterventionURL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userPrefCtx.bearerToken}`,
            auth: authCtx.authMode,
          },
        }).then((res) => res.json())
        .then((data) => {
           //console.log(data);
           //This will send the data back to the EditInterventionModal to trigger a delete in the visitpanel
           props.getChild(data);
           //once it successfully deletes the intervention, it will close the modal
           props.close();
        })
        .catch((err) => {
           //console.log(err.message);
        });
    }
  
  }

  //console.log(props.sendID)
  //console.log('editData outside function is ', editData)

  //console.log('props in VisualTreatmentModal is ', props.sendBotoxtoModal)
  //console.log('patient token is ', props.sendToken)
  // console.log('props.sendBotoxtoModal[0].dictionary_type_value in VisualTreatmentModal is ', props.sendBotoxtoModal[0].dictionary_type_value)
  const getBotoxImageID = (data) => {
    //This is the function to get the color value from the BotoxType(child) component
    //console.log('this is the id in the modal', data);
    props.getModal(data)
    //colorChild.push(data);
    // setColorArr(data);
  }


  const closeConfirmDialog = (result) => {
    // setShowConfirm(false)
    props.close()
    if (result === 'confirm') {
        deleteIntervention()
    }
}


  return (
    <>
    <ConfirmDialog 
            show={props.setOpen}
            title='Please confirm'
            modal_class='delete-confirm-modal'
            title_class='delete-confirm-title'
            footer_class='delete-confirm-title'
            prompt='Are you sure you want to delete this intervention?'
            prompt_class='delete-confirm-prompt'
            // cancel_button_text='Do not delete!'
            confirm_button_text='Delete'
            confirm_button_variant='danger'
            onClose={(result) => closeConfirmDialog(result)}
            icon={<BsSignStopFill />}
            iconColor='red'
            iconSize='1.3em'
        />

      {/* <Modal size="lg" show={props.setOpen} onHide={props.close} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Intervention Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h3>Are you sure you want to delete this intervention?</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
          </Button>
          <Button variant="secondary" onClick={deleteIntervention}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export default PromptEditInterventionDelete;