import React, {useState, useContext, useEffect, useRef} from 'react'
import Select, { ActionMeta, OnChangeValue, StylesConfig } from 'react-select'
import Form from 'react-bootstrap/Form'
import UserPrefContext from '../../../context/userPrefContext'
import DictionaryContext from '../../../context/dictionaryContext'
import Row from 'react-bootstrap/Row'
import style from './SearchAndOr.module.css'


function SearchAnd(props) {

    const userPrefCtx = useContext(UserPrefContext)
    const dictCtx = useContext(DictionaryContext)

    
    const [andOptions, setAndOptions] = useState([])
    const [notLikeOptions, setNotLikeOptions] = useState([])


    const [selectedAndOptions, setSelectedAndOptions] = useState([])
    const [selectedNotLikeOptions, setSelectedNotLikeOptions] = useState([])

    const [interventions, setInterventions] = useState([])
    const [diagnoses, setDiagnoses] = useState([])


    const sortByLabel = (opt1, opt2) => {
        if (opt1.label < opt2.label) {
            return -1
        } else {
            return 1
        }
    }

    useEffect(() => {
        if (!!userPrefCtx.userPreference.user_dept) {
            setInterventions(dictCtx.dict_intervention
                .filter(iv => iv.user_dept === userPrefCtx.userPreference.user_dept)
                .map(iv => {
                    return {
                        label: iv.display_name,
                        // value: iv.display_name_short,
                        value: iv.id,
                    }
                })
                .sort(sortByLabel)
            )
            setDiagnoses(dictCtx.dict_diag
                .filter(dg => dg.user_dept === userPrefCtx.userPreference.user_dept)
                .map(dg => {
                    return {
                        label: dg.display_name,
                        // value: dg.display_name_short,
                        value: dg.id,
                    }
                })
                .sort(sortByLabel)
            )
        }
    }, [userPrefCtx.userPreference.user_dept, dictCtx.dict_intervention, dictCtx.dict_diag])


    useEffect(() => {
        if (props.searchFor.toLowerCase() === 'diagnosis') {
            setAndOptions([...diagnoses])
            setNotLikeOptions([...diagnoses])
        }

        if (props.searchFor.toLowerCase() === 'intervention') {
            setAndOptions([...interventions])
            setNotLikeOptions([...interventions])
        }
    }, [interventions, diagnoses])


    const onChange = (value, actionMeta) => {

        // console.log("SearchAnd - searchFor", props.searchFor)
        // console.log("SearchAnd - onChange: actionMeta", actionMeta, "value", value)

        let selectedAnd = [...selectedAndOptions]
        let selectedNotLike = [...selectedNotLikeOptions]

        switch (actionMeta.action) {
            case 'select-option':
                if (actionMeta.name === 'and_search') {
                    selectedAnd = [...selectedAndOptions, actionMeta.option].sort(sortByLabel)
                    setSelectedAndOptions(selectedAnd)
                    // remove selected opton from not like array
                    setNotLikeOptions([...notLikeOptions.filter(nlo => nlo.value !== actionMeta.option.value).sort(sortByLabel)])
                }

                if (actionMeta.name === 'not_like') {
                    selectedNotLike = [...selectedNotLikeOptions, actionMeta.option].sort(sortByLabel)
                    setSelectedNotLikeOptions([...selectedNotLike])
                    // remove selected opton from not and array
                    setAndOptions([...andOptions.filter(ao => ao.value !== actionMeta.option.value).sort(sortByLabel)])
                }
                break

            case 'remove-value':
                if (actionMeta.name === 'and_search') {
                    selectedAnd = [...selectedAndOptions].filter(sel => sel.value !== actionMeta.removedValue.value)
                    setSelectedAndOptions([...selectedAnd.sort(sortByLabel)])
                    // add removed option to not like options array
                    let notLike = [...notLikeOptions]
                    notLike.push(actionMeta.removedValue)

                    setNotLikeOptions([...notLike.sort(sortByLabel)])
                }

                if (actionMeta.name === 'not_like') {
                    selectedNotLike = [...selectedNotLikeOptions].filter(sel => sel.value !== actionMeta.removedValue.value)
                    setSelectedNotLikeOptions([...selectedNotLike])
                    // add removed option to and options array
                    let and = [...andOptions]
                    and.push(actionMeta.removedValue)
                    setAndOptions([...and.sort(sortByLabel)])
                }
                break

            case 'clear':
                if (actionMeta.name === 'and_search') {
                    setSelectedAndOptions([])
                    selectedAnd = []
                    setNotLikeOptions([...notLikeOptions.concat(actionMeta.removedValues).sort(sortByLabel)])
                    if (props.searchFor === 'diagnoses') {
                        setAndOptions([...diagnoses])
                    }
                    if (props.searchFor === 'interventions') {
                        setAndOptions([...interventions])
                    }
                }

                if (actionMeta.name === 'not_like') {
                    selectedNotLike = []
                    setSelectedNotLikeOptions([])
                    setAndOptions([...andOptions.concat(actionMeta.removedValues).sort(sortByLabel)])
                    if (props.searchFor === 'diagnoses') {
                        setNotLikeOptions([...diagnoses])
                        
                    }
                    if (props.searchFor === 'interventions') {
                        setNotLikeOptions([...interventions])
                    }
                }
                break;
        }

        props.onSelect(selectedAnd, selectedNotLike)
    }


    // const handleSave = () => {
    //     const body = {
    //         dict_diag_ids: selectedDiagnoses.map(d=>d.value),
    //         dict_intervention_ids: selectedInterventions.map(i=>i.value),
    //         not_like_dict_diag_ids: [],
    //         not_like_dict_intervention_ids: [],
    //     }


    //     const submit = async (body) => {
    //         const url = `${process.env.REACT_APP_SERVER}/api/v1/diag_intvn_patient_ids_or/csv`
    //         const response = await fetch(url, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${userPrefCtx.bearerToken}`,
    //             },
    //             body: JSON.stringify(body),
    //         })
    //         .then((res) => res.json())
    //         .then((data) => {
    
    //         })
    //         .catch((err) => {
    //             console.log(err.message);
    //         })
    //     }


    // }


    return (
        <>
            <div className={`${style.section} mx-auto`}>
                <h3>{props.searchFor}</h3>
                <Row className='my-3'>      
                    <Form.Group>
                        <Form.Label htmlFor="and_search" className={style['label-bold']} >AND Search</Form.Label>
                        <Select
                            onChange={onChange}
                            value={selectedAndOptions}
                            isMulti
                            name="and_search"
                            options={andOptions}
                            // className="basic-multi-select"
                            // classNamePrefix="select"
                            placeholder='Select multiple values to include all...'
                        />
                    </Form.Group>
                </Row>
                <Row className='my-3'>      
                    <Form.Group>
                        <Form.Label htmlFor="not_like" className={style['label-bold']}>NOT Like</Form.Label>
                        <Select
                            onChange={onChange}
                            value={selectedNotLikeOptions}
                            isMulti
                            name="not_like"
                            options={notLikeOptions}
                            // className="basic-multi-select"
                            // classNamePrefix="select"
                            placeholder='Select multiple values to exclude all...'
                        />
                    </Form.Group>
                </Row>
            </div>
        </>
    )

}

export default SearchAnd
