import React, { useState, useEffect, useContext, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import UserPrefContext from '../../../context/userPrefContext';
import {IconContext} from 'react-icons'


function ErrorDialogModal(props) {
  const userPrefCtx = useContext(UserPrefContext);

  const [show, setShow] = useState(false);
  const [iconStyle, setIconStyle] = useState({color: 'orange'})


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  return (
    <>
    <IconContext.Provider value={iconStyle}>
      <Modal 
        //size="xl" 
        size="xl"
        show={props.setOpen} 
        // show={() => {
        //   props.setOpen
        //   props.closeParent(); 
        // }}
        onHide={props.close} 
        // onHide={props.close} 
        // onHide={() => {
        //   props.close(); // Close the Child modal
        //  // props.closeParent(); // Close the Parent modal
        // }}
        animation={false}
        backdrop="static"
        //style={{ zIndex: '9999'}}
        //dialogClassName={{ zIndex: '9999'}}
        //dialogClassName={classes.custom_modal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
          {/* <Icon />  */}
          {/* <BsFillExclamationTriangleFill />
            Error */}
            <div className='d-flex inline-block align-items-center'>
                
              <>
              <BsFillExclamationTriangleFill />
              <span>&nbsp;&nbsp;</span>
              </>
          
              Error
            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="centered_text">
          <h3>{props.componentName}</h3>
          { (!props.customMessage && !props.customMessageSplitLines && !props.customMessageSplitLinesAquireMedia) &&
          <h2>Something Went Wrong, Please Contact Admin</h2>
          }
          { props.customMessage &&
            <h2>{props.customMessage}</h2>
          }
            {/* { props.customMessageSplitLines &&
              <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: '<h2>' + props.customMessageSplitLines + '</h2>' }} />
            } */}

          { props.customMessageSplitLinesAquireMedia &&
              <div 
                //style={{ justifyContent: 'space-between' }}
              >
                <h2 style={{ textAlign: 'left' }}>{props.customMessageSplitLinesAquireMedia.split('<br>')[0]}</h2>
                <h2 style={{ textAlign: 'left' }}>{props.customMessageSplitLinesAquireMedia.split('<br>')[1]}</h2>
                <h2 style={{ textAlign: 'right' }}>{props.customMessageSplitLinesAquireMedia.split('<br>')[2]}</h2>
              </div>
            }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </IconContext.Provider>
    </>
  );
}

export default ErrorDialogModal;