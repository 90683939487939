import React from "react";

const VisitPanelContext = React.createContext({
  user_pref_display_name: [],
  user_pref_display_name_full: [],
  user_pref_panel_id: [],

  dict_display_name: [],
  dict_display_name_full: [],
  dict_panel_id: [],
});

export const VisitPanelContextProvider = (props) => {

  const contextValue = {
    //This is for the visit panels
    user_pref_display_name,
    user_pref_display_name_full,
    user_pref_panel_id,

    dict_display_name,
    dict_display_name_full,
    dict_panel_id,
  };

  return (
    <VisitPanelContext.Provider value={contextValue}>
      {props.children}
    </VisitPanelContext.Provider>
  );
};

export default VisitPanelContext;
