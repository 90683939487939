import React, { useState, useContext, useEffect, useRef, forwardRef } from "react";
import PatientContext from "../../../context/patientContext";
import UserPrefContext from "../../../context/userPrefContext";
import AuthContext from "../../../context/authContext";
import "./FileUpload.css";
import { trackPromise } from 'react-promise-tracker'
import DatePicker from 'react-datepicker'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import {FaRegCalendarAlt} from 'react-icons/fa'
import moment from 'moment'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ErrorDialogModal from "../modals/ErrorDialogModal";
import CompanyContext from "../../../context/companyContext";

const MAX_COUNT = 50;

function FileUpload(props) {
    const [token, setToken] = useState(null);

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileLimit, setFileLimit] = useState(false);
    const [visitDate, setVisitDate] = useState(new Date())
    const [showError, setShowError] = useState(false);
    const customInputRef = useRef()
    const customInputRef2 = useRef(null)

    const visCtx = useContext(PatientContext);
    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)
    const companyCtx = useContext(CompanyContext)

    //   const mediaCreateURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/upload/${visCtx.pat_token}`;

    const headers = {
        // 'Content-Type': chosenFiles[0].typ,
        Authorization: `Bearer ${userPrefCtx.bearerToken}`,
        accept: "application/json",
        auth: authCtx.authMode,
    };


    const handleUploadFiles = (chosenFiles) => {
        const uploaded = [...uploadedFiles];

        // console.log("FileUpload - uploaded", uploaded)
        // console.log("FileUpload - chosenFiles",  chosenFiles)
        let limitExceeded = false;

        const files = new FormData();
        // console.log("FileUpload - companyCtx", companyCtx)
        //console.log('visitDate is: ',visitDate.toISOString())
        const formattedDate = typeof visitDate === 'string' ? visitDate : visitDate.toISOString();
        //console.log(formattedDate)
        
        // const mediaCreateURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/upload/${visCtx.pat_token}?dict_company_code_id=${companyCtx.company.id}&visit_date=${moment(visitDate).format('YYYY-MM-DD')}`;
        const mediaCreateURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/upload/${visCtx.pat_token}?dict_company_code_id=${companyCtx.company.id}&visit_date=${formattedDate}`;

        chosenFiles.forEach((val, index) => {
            files.append("files", chosenFiles[index]);
        });

        chosenFiles.some((file) => {
            // console.log(file);

            const files = new FormData();
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);

                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    alert(`You can only add a maximum of ${MAX_COUNT} chosenFiles`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }

                // chosenFiles.some((i) => {
                //   files.append("files", chosenFiles[i]);
                // });
            }
        });

        trackPromise(
            fetch(mediaCreateURL, {
                headers: headers,
                method: "POST",
                body: files,
                
            })        
            .then((response) => {
                if (!response.ok) {
                    console.log(response.json())
                    throw new Error("Network response was not ok");
                }
                // reset datepicker to default (today)
                setVisitDate(new Date())
                return response.json();
              })
            .then((data) => {
                // Handle the successful response here
                if (!!data.Result && data.Result === 'OK') {
                    // all good
                } else if (!!data.message) {
                    console.log("FileUpload - message", data.message)
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.log(error)
                setShowError(true);
            })
        )


        // const response = fetch(mediaCreateURL, {
        //   headers: headers,
        //   method: "POST",
        //   body: files,
        // });

        // console.log(response);

        // trackPromise(
        //     uploadFiles()
        // )

        if (!limitExceeded) setUploadedFiles(uploaded);
    };




    const handleFileEvent = (e) => {
        // console.log("Visit Date", visitDate)

        if (e.target.files.length > 0) {
            const chosenFiles = Array.prototype.slice.call(e.target.files);
            // const chosenFiles = Array.prototype.slice.call(e.target.files);

            handleUploadFiles(chosenFiles);
        }
        
    };


    // const CustomDatepickerInput = forwardRef((props, ref) => {
    //     return <input {...props} ref={ref} size='10' className='dpickerInput' />;
    // });


    const CustomDatepickerInput2 = forwardRef((props, ref) => {
        // return (<FaRegCalendarAlt {...props} ref={ref} />);
        return (
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip className='datepickerTooltip'>
                        <div>{moment(visitDate).format('MM/DD/YYYY')}</div>
                    </Tooltip>
                }
            >
                <div {...props} ref={ref}>
                    <FaRegCalendarAlt  />
                </div>
            </OverlayTrigger> 
        )
    });


  return (
    <div className="App">
      <form>
        <input
          id="fileUpload"
          type="file"
          multiple
          // accept='application/pdf, image/png'
          onChange={handleFileEvent}
          disabled={fileLimit}
        />
        {/* <div className={`btn btn-primary btn-sm ${!fileLimit ? "" : "disabled"} file-upload-button`}>
            <label htmlFor="fileUpload">
                <a 
                    //size="sm"
                    // <a className={`btn btn-primary btn-sm ${!fileLimit ? "" : "disabled"} `}
                >
                    Upload Files
                </a>
                </label>
                <DatePicker 
                    selected={visitDate} 
                    onChange={(date) => setVisitDate(date)}
                    wrapperClassName="datepickerWrapper" 
                    customInput={<CustomDatepickerInput  />}
                />


            
        </div> */}
        {/* <div className="uploaded-files-list-scrollable">
          {uploadedFiles.map((file) => (
            <div>{file.name}</div>
          ))}
        </div> */}

        <ButtonGroup>
            {/* <Button variant="primary"  className={`btn btn-primary btn-sm ${!fileLimit ? "" : "disabled"} file-upload-button`}>Upload Files</Button> */}
            <label htmlFor="fileUpload">
                <a className={`btn btn-primary btn-sm ${!fileLimit ? "" : "disabled"} `} >
                    Upload Files
                </a>
            </label>
            <div variant="primary" className={`btn btn-primary btn-sm`}>
                <DatePicker 
                    selected={visitDate} 
                    onChange={(date) => setVisitDate(date)}
                    wrapperClassName="datepickerWrapper" 
                    customInput={<CustomDatepickerInput2 title={moment(visitDate).format('MM/DD/YYYY')} />}
                />
            </div>
        </ButtonGroup>
        
      </form>
      <ErrorDialogModal
          setOpen={showError}
          close={() => setShowError(false)}
          componentName="File Upload"
        />
    </div>
  );
}





export default FileUpload;
