import React, { useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import userPrefContext from '../../../context/userPrefContext';
import DictionaryContext from '../../../context/dictionaryContext';
import AuthContext from '../../../context/authContext';
// import VisitWithPrefContext from '../../../context/visitWithPrefContext';
import '../../stylesheets/BotoxMap.css'
import { toInteger } from 'lodash';

const BotoxMapImage = (props) => {
  const [imageMap, setImageMap] = useState([]);
  const [imageMapFromContext, setImageMapFromContext] = useState();
  const [defaultImage, setDefaultImage] = useState();
  let [imagePointer, setImage] = useState('Select Provider');

  const userPrefCtx = useContext(userPrefContext);
  const dictCtx = useContext(DictionaryContext);
  const authCtx = useContext(AuthContext)
//   const visitWithPrefCtx = useContext(VisitWithPrefContext);

  let propImage = dictCtx.dict_botox_map_src_image;
  //let propImage = userPrefCtx.dictBotoxMapSrcImage;

  let defaultVals = userPrefCtx.dictBotoxMapSrcImage?.id

   const mapImage = [...new Map(propImage?.map(item => [item.id, item])).values()]

   var imageArray = [];
     imageArray.push(_.groupBy(propImage, function(n) {
     return n.id;
   }));
   // console.log(imageArray.file_name)

  useEffect(() => {
    setImageMap(props.sendProviderProps);
    //console.log(props.sendProviderProps)
    //console.log(dictCtx.dict_botox_map_src_image)
    //console.log(userPrefCtx)
    //console.log(dictCtx)
  }, [props.sendProviderProps]);

  // useEffect(() => {
  //   //setImageMap(props.sendProviderProps);
  //   //console.log(props.sendProviderProps)
  //   //console.log(dictCtx.dict_botox_map_src_image)
  //   //console.log(userPrefCtx)
  //   const mapImage2 = [...new Map(dictCtx.dict_botox_map_src_image?.map(item => [item.id, item])).values()]
  //   setImageMapFromContext(mapImage2);
  //   setDefaultImage(userPrefCtx.dictBotoxMapSrcImage?.id)
  //   // if (userPrefCtx.dictBotoxMapSrcImage?.id) {
  //   //   setDefaultImage(userPrefCtx.dictBotoxMapSrcImage.id);
  //   // }
  //   console.log(userPrefCtx.dictBotoxMapSrcImage?.id)
  //   console.log(dictCtx)
  // }, [dictCtx.dict_botox_map_src_image, userPrefCtx.dictBotoxMapSrcImage, props.sendProviderProps]);


  let handleImageChange = (e) => {
    setImage(e.target.value)
    console.log(e.target.value);
    props.sendBotoxImageToParent(e.target.value)
    //console.log(e.target.getAttribute('data-key'));
    // console.log('called the handlePhysicianChange function');
  }
  //console.log('image pointer is ', imagePointer)

  let handleImageChangePatch = async (e) => {

    if(e.target.value === "" || e.target.value === null || e.target.value === undefined){
      console.log('e.target.value is blank')
    } else {

      setImage(e.target.value)
      //console.log(e.target.value);
      props.sendBotoxImageToParent(e.target.value)
  
      //console.log(typeof e.target.value);
  
      let integerID = parseInt(e.target.value);
  
  
      //console.log(typeof integerID);

    const dictBotoxMapSrcImgURL = `${process.env.REACT_APP_SERVER}/api/v1/user_preferences/dict_botox_map_src_image_id`;

    const response = await fetch(dictBotoxMapSrcImgURL, {
      method: "PATCH",
      body: JSON.stringify({
        "dict_botox_map_src_image_id": e.target.value
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userPrefCtx.bearerToken}`,
        auth: authCtx.authMode,
      },
    }).then((res) => res.json())
    .then((data) => {
      //console.log('returning: ', data);
      //This is to trigger the visit useEffect after the patch call is made
      props.visitTrigger(data);
      //console.log(data);
    })
    .catch((err) => {
      console.log(err.message);
    });
  }
}

  return (
    // <div className='botox_type_div'>
    <div>
      {/* <h4>Botox Map Image</h4> */}
      <Form.Select aria-label="Default select example" onChange={handleImageChangePatch} id="botox-image-select" defaultValue={defaultVals}>
        <option value={0}>botox-map-full.png</option>
        {mapImage?.map((item) => 
        <option key={item?.id} value={item?.id} data-key="data">{item?.orig_file_name}</option>
        )}
      </Form.Select>
    </div>
  );
}
export default BotoxMapImage;