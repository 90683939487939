import React, {useEffect, useState, useRef} from 'react'
import parse from 'html-react-parser'
import { forEach } from 'lodash'
import DatePicker from 'react-datepicker'
import Form from 'react-bootstrap/Form'

function GrbasQuestionRow(props) {

    const trRef = useRef()
    const [values, setValues] = useState([])
    const [elements, setElements] = useState([])

    useEffect(() => {
        // if (props.question.group_id === 12) {
            // console.log("GrbasQuestionRow props.data", props.data)
        // }

        // build HTML elements
        let qtypes = []
        let qvalues = []
        let htmls = []
        let headers = []
        let mappedOptions = []
        
        
        // in question - get question_option_values, question_option and question_type
        if (props.question.DictIntakeFormQuestion.question_type !== null && props.question.DictIntakeFormQuestion.question_type.startsWith('[')) {
            qtypes = JSON.parse(props.question.DictIntakeFormQuestion.question_type)

            // DEBUG
            // if (props.question.question_option_values === "[\"\",0,0,0,\"c\",\"i\",0]") {
            //     qtypes.push("label")
            // }
        }

        if (props.question.DictIntakeFormQuestion.question_option_values !== null && props.question.DictIntakeFormQuestion.question_option_values.startsWith('[')) {
            qvalues = JSON.parse(props.question.DictIntakeFormQuestion.question_option_values)

            // DEBUG
            // if (props.question.question_option_values === "[\"\",0,0,0,\"c\",\"i\",0]") {
            //     qvalues.push("/100")
            // }
        }

        if (props.question.DictIntakeFormQuestion.question_option !== null && props.question.DictIntakeFormQuestion.question_option.startsWith('[')) {
            headers = JSON.parse(props.question.DictIntakeFormQuestion.question_option)

            // DEBUG
            // if (props.question.question_option_values === "[\"\",0,0,0,\"c\",\"i\",0]") {
            //     headers.push("")
            // }
        }

        if (props.question.DictIntakeFormQuestion.mapped_property !== null && props.question.DictIntakeFormQuestion.mapped_property.startsWith('[')) {
            mappedOptions = JSON.parse(props.question.DictIntakeFormQuestion.mapped_property)
        } else if (props.question.DictIntakeFormQuestion.mapped_property === null && qtypes.length === 1) {
            mappedOptions.push(props.question.DictIntakeFormQuestion.display_name_short)
        }

        // remove 1st blank element from the arrays
        if (qtypes.length > 0 && qvalues.length > 0 && mappedOptions.length > 0 && qtypes[0] === '' && qvalues[0] === '' && headers.length > 0 && headers[0] === '' && mappedOptions[0] === '') {
            // console.log("SHIFTING... qtypes", qtypes, "qvalues", qvalues, "headers", headers)
            qtypes.shift()
            qvalues.shift()
            headers.shift()
            mappedOptions.shift()
            console.log("SHIFTED... qtypes", qtypes, "qvalues", qvalues, "headers", headers)
        }
                
        // build html elements
        htmls = qtypes.map((type, index) => {
            // console.log("ROW: type", type, "index", index, "headers", headers)
            switch(type) {
                case 'select':
                    return {
                        html: 'select',
                        type: 'select',
                        name:  mappedOptions.length > index ? mappedOptions[index] : `${props.question.display_name_short}${suffix}`,
                        values: qvalues[index],
                    }

                break;
                case 'number':
                    const suffix = headers.length > index ? '_' + headers[index].toLowerCase(): ''
                    return {
                        html: 'input', 
                        type: 'number', 
                        name: mappedOptions.length > index ? mappedOptions[index] : `${props.question.display_name_short}${suffix}`,
                        calc_sum_element: null,
                    }
                break
                case 'radio':
                    return {
                        html: 'input', 
                        type: 'radio', 
                        name: mappedOptions.length > index ? mappedOptions[index] : props.question.display_name_short,
                        value: qvalues[index]
                    }
                break
                case 'date':
                    
                    return {
                        html: 'datepicker', 
                        type: 'datepicker', 
                        name: mappedOptions.length > index ? mappedOptions[index] : props.question.display_name_short
                    }
                    // return new Date()
                break
                case 'text':
                    return {
                        html: 'input', 
                        type: 'text', 
                        name: mappedOptions.length > index ? mappedOptions[index] : props.question.display_name_short
                    }
                break
                case 'label':
                    return {html: 'label', type: 'label', name: props.question.display_name_short, value: qvalues[index]}
                break
                case 'textarea':
                    return {
                        html: 'textarea', 
                        type: 'textarea', 
                        name: mappedOptions.length > index ? mappedOptions[index] : props.question.display_name_short
                    }
                break;
                default:
                    
                    return null
                    
            }
        })
        setElements(htmls)

        // create array of data in form {name: value}
        const element_names = htmls.map((el, ix) => {
            return { [el.name]: props.data[el.name]}
        })

        const vals = element_names.reduce((accum, el) => {
            const key = Object.getOwnPropertyNames(el)[0]
            accum = {...accum, ...el}
            return accum
        }, {})

        // if (props.question.group_id === 8) {
        //     console.log("ELEMENTS", htmls)
        //     console.log("ELEMENT NAMES", element_names)
        //     console.log("VALUES OF ELEMENTS", vals)
        // }
        // console.log("VALUES OF ELEMENTS", vals)
        setValues(vals)


        // console.log("HTML ELEMENTS", htmls)
            

        
        
        // setValues(props.data)

    }, [props.data, props.question])



    


    const date_value_changed = (new_date, name) => {
        let new_data = values
        new_data[name] = new_date
        props.onValuesUpdate(new_data)
    }

    const value_changed = (event, cell_index) => {
        // custom attribute of the element data-input-type shoudl contain type
        // let new_vals = values

        // console.log("value_changed: value, cell_index", event.target.value, cell_index)
        let new_data = values

        // console.log("elelemnt", event.target)
        const name = event.target.getAttribute('name')
        // console.log("elelemnt", event.target, "name", name)
        // different processing for numeric vs non-numeric values
        if (event.target.getAttribute('data-input-type') === 'number') {
            // allow only numeric characters
            const textVal = event.target.value.replace(/[^0-9]+/, '')
            new_data[name] = +(textVal)
            // new_vals = values.map((val, ix) => (ix !== cell_index ? val : +(textVal)))
        } else if (event.target.getAttribute('data-input-type') === 'text' || event.target.getAttribute('data-input-type') === 'textarea') {
            // new_vals = values.map((val, ix) => (ix !== cell_index ? val : event.target.value))
            new_data[name] = event.target.value
        } else if (event.target.getAttribute('data-input-type') === 'radio') {
            // new_vals = values.map((val, ix) => (ix !== cell_index ? val : event.target.value))
            new_data[name] = event.target.value
        } else { ///if (event.target.getAttribute('data-input-type') === 'select') {
            new_data[name] = event.target.value
        }
        // console.log("NEW VALUES AFTER UPDATE", new_vals)


        
        
        // update values
        setValues(new_data)

        // console.log("Updating data:", new_data)
        props.onValuesUpdate(new_data)

    }


    const renderDisplayName = () => {
        const element = 
            <>
                <span>{props.question.DictIntakeFormQuestion.display_name}</span>
            </>

        return element
    }


    const renderElement = (el, ix, renderType) => {
        // console.log("renderElement", el)
        const element = 
            <div key={ix}>
                {el.type === 'number' &&
                    <input 
                        type={el.type} 
                        name={el.name} 
                        onChange={(event) => value_changed(event, ix)}
                        data-input-type={el.type}
                        value={values[el.name] !== undefined ? values[el.name] : 0}
                        placeholder={0}
                        readOnly={props.readOnly}
                    />
                }

                {el.type === 'text' &&
                    <input 
                        type={el.type} 
                        name={el.name} 
                        onChange={(event) => value_changed(event, ix)}
                        data-input-type={el.type}
                        value={values[el.name] !== undefined ? values[el.name] : ''}
                        readOnly={props.readOnly}
                    />
                }

                {el.type === 'datepicker' &&
                    <DatePicker 
                        selected={values[el.name]} 
                        data-input-type={el.type} 
                        name={el.name} 
                        onChange={(date) => date_value_changed(date, el.name)} 
                        readOnly={props.readOnly}
                    />
                }


                {el.type === 'radio' &&
                    <input 
                        type={el.type} 
                        name={el.name} 
                        onChange={(event) => value_changed(event, ix)}
                        data-input-type={el.type}
                        value={el.value}
                        checked={el.value === values[el.name]}
                        disabled={props.readOnly}
                    />
                }       

                {el.type === 'textarea' &&
                    <textarea
                        className='capev-textarea'
                        name={el.name} 
                        data-input-type={el.type}
                        value={values[el.name] !== undefined ? values[el.name] : ''}

                        onChange={(event) => value_changed(event, ix)}
                        readOnly={props.readOnly}
                    />
                }

                {el.type === 'label' &&
                    <label
                        name={el.name} 
                        data-input-type={el.type}
                    >{el.value}
                    </label>
                }

                {el.type === 'select' &&
                    <Form.Select
                        name={el.name} 
                        data-input-type={el.type}
                        value={values[el.name] !== undefined ? values[el.name] : ''}
                        onChange={(event) => value_changed(event, ix)}
                        disabled={props.readOnly}
                    >
                        <option value=''></option>
                        {el.values.map((v,vi) => 
                            <option 
                                key={vi}
                                value={v}
                            >
                                {v}
                            </option>
                        )}
                    </Form.Select>
                }   

                
            </div>
            return element
    }
    


    return (
        <>
            {props.type === 'table' &&
                <tr ref={trRef}>
                    {
                        // do not output display name if empty
                        props.question.DictIntakeFormQuestion.display_name !== null && props.question.DictIntakeFormQuestion.display_name !== "" &&
                            <td >
                                {renderDisplayName()}
                            </td>
                    }
                    {
                        elements.map((el, ix) => {
                            return (
                                el !== null && (
                                <td key={ix}>
                                    {renderElement(el,ix)}
                                </td>
                                )
                            )
                        
                        })
                    }
                </tr>
            }

            {props.type === 'single' && (
                <>
                    {
                        // do not output display name if empty
                        props.question.DictIntakeFormQuestion.display_name !== null && props.question.DictIntakeFormQuestion.display_name !== "" &&
                        renderDisplayName()
                    }

                    {elements.map((el, ix) => {
                        return (
                            el !== null &&                             
                                renderElement(el,ix, props.type)
                        )

                    })}
                </>
            )}
        </>


    )
}

export default GrbasQuestionRow
