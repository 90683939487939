import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import EFaceCharts from './EFaceCharts'
import {toLocalDate} from '../../../utils/Utils'


function EFaceChartsModal(props) {

    const handleClose = () => {
        props.onClose()
    }

    return (
        <Modal size="fullscreen" show={props.show} onHide={handleClose} animation={false} scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>The eFace {toLocalDate(new Date())}</h3>
                </Modal.Title>
            </Modal.Header>
        <Modal.Body>
            <EFaceCharts
                // dictVisit={props.dictVisit}
                // dictPanelId={props.dictPanelId}
                viewType='full'
            />
        </Modal.Body>

        <Modal.Footer className='justify-content-between'>

            
            <div >
                <Button className='mx-2' variant="secondary" onClick={handleClose}>
                    Close
                </Button>

            </div>
            
        </Modal.Footer>
    </Modal>







    )
}

export default EFaceChartsModal
