import React, {useEffect, useState, useContext, useRef} from 'react'
import PatientContext from '../../../context/patientContext'
import UserPrefContext from '../../../context/userPrefContext'
import AuthContext from '../../../context/authContext'
import styles from './DiagsList.module.css'
import moment from 'moment'

function DiagsList(props) {

    const patCtx = useContext(PatientContext)
    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)


    const [allDiags, setAllDiags] = useState([])

    const componentMounted = useRef(true)
    

    

    useEffect(async() => {
        componentMounted.current = true;

        if (userPrefCtx.bearerToken) {

            const headers = { Authorization: `Bearer ${userPrefCtx.bearerToken}`, auth: authCtx.authMode };
            const allDiagnosesURL = `${process.env.REACT_APP_SERVER}/api/v1/diags/get_all_by_token/${patCtx.pat_token}`
        
            try {
                const response = await fetch(allDiagnosesURL, { headers })
                if (response.status === 200) {
                    const responseData = await response.json();
                    // console.log('All Diags: ', responseData);
                    // use diagnosis_dept from user preferences to filter diagnoses to show
                    const diagDepartments = userPrefCtx.userPreference.diagnosis_dept
                    // console.log("DiagsList - userPrefCtx.userPreference", userPrefCtx.userPreference)
                    //This is sorting the the objects by when it was created and then putting it in a useState
                    let diagnoses = responseData
                        .filter(ddata => diagDepartments.includes(ddata.user_dept))
                        .sort((diag1, diag2) => new Date(diag1.onset_date) > new Date(diag2.onset_date) ? -1: 1)
                        .map(diag => {
                            return {...diag, date: moment(new Date(diag.onset_date)).format('MM/DD/YYYY')}
                        })
                    // console.log("all diagnoses", diagnoses)
                    // console.log("DiagsList - user prefs", userPrefCtx)
                    setAllDiags(diagnoses)
                    props.sendDiagListToParent(diagnoses)
                    //This is going to send back to the VisitPanels that the component has mounted
                    props.retrieveMountStatusFromDiagList(componentMounted.current);
                } else {
                    console.log("not 200");
                }
            } catch (e) {
                console.log(e);
            }
        
        
            }
            else {
            console.log('no token')
            } 

        return () => {
            // console.log("iPad - unmounting useEffect #1")
            componentMounted.current = false;
        }
        //The triggers for the useEffect will be when a new Capev is created, edited, or deleted
    }, [userPrefCtx.bearerToken, patCtx.pat_token, props.refresh, props.sendDiagModalResponse, userPrefCtx.userPreference.diagnosis_dept]);





    // useEffect(() => {
    //     allDiags.map((diag, diag_ix) => {
    //         console.log("====== diag", diag)
    //         console.log("====  diag names ", diag.diag_dict_diag.map(oneDiag => oneDiag.DictDiag.display_name).join(', '))
    //     })
    // }, [allDiags])



    const diagClicked = (diag) => {
        // console.log("clicked on diag ", diag)
        // if diagnosis from other department - ignore click
        if (diag.user_dept === userPrefCtx.userPreference.user_dept) {
            // console.log("DiagsList - selecting diag id", diag.id, "diag type", diag.diagnosis_type)
            props.onSelect(diag.id, diag.diagnosis_type)
        }
        
    }


    return (
        <div>
            <ul>
                {allDiags.map((diag, diag_ix) => {
                    return (
                        <div key={diag.id}>
                        {diag_ix > 0 && allDiags[diag_ix-1].date !== diag.date &&
                            <hr key={`diaghr_${diag_ix}`} className={styles.hr_line}/>
                        }
                        <li key={diag.id}
                            // data-diag-type={diag.diag_type}
                            className={diag.user_dept !== userPrefCtx.userPreference.user_dept ? styles.muted : styles.inherit}
                            onClick={() => diagClicked(diag)}
                        >
                            {diag.diag_dict_diag.map(oneDiag => oneDiag.DictDiag.display_name).join(', ')}
                            &nbsp;({diag.date})
                        </li>
                        </div>
                    )
                })}
            </ul>
        </div>
    )
}

export default DiagsList
