import React from "react";
import Button from 'react-bootstrap/Button';

const reusableButton = ({
    height,
    backgroundColor,
    color,
    border,
    borderColor,
    fontSize,
    buttonText,
    setStyle,
    setVariant, 
    setClick
}) => (
  <div>
      <Button variant={setVariant} className={setStyle} onClick={setClick} >
        {buttonText}
      </Button>
  </div>
);

export default reusableButton;