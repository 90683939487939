import React, { useState, useContext, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import ErrorDialogModal from "../modals/ErrorDialogModal";
import PatientContext from "../../../context/patientContext";
import userPrefContext from "../../../context/userPrefContext";
import AuthContext from "../../../context/authContext";
import CompanyContext from "../../../context/companyContext";
import { BsFillPlusSquareFill, BsTrash3 } from "react-icons/bs";
import PromptIntraopDelete from "../modals/PromptIntraopDelete";
import "./IntraopUpload.css";

const MAX_COUNT = 99;

function IntraopUpload(props) {
    const [openDeleteIntraopModal, setOpenDeleteIntraopModal] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [showError, setShowError] = useState(false)

  const patCtx = useContext(PatientContext);
  const userPrefCtx = useContext(userPrefContext);
  const authCtx = useContext(AuthContext)
  const companyCtx = useContext(CompanyContext)

  // const mediaCreateURL = `${process.env.REACT_APP_SERVER}/api/v1/intra_op_images/upload/` + patCtx.pat_token;
  const mediaCreateURL = `${process.env.REACT_APP_SERVER}/api/v1/intra_op_images/upload/` + patCtx.pat_token + '?dict_company_code_id=' + companyCtx.company.id;


  const headers = {
    // 'Content-Type': chosenFiles[0].typ,
    Authorization: `Bearer ${userPrefCtx.bearerToken}`,
    accept: "application/json",
    auth: authCtx.authMode,
  };

  const handleUploadFiles = async (chosenFiles) => {
    //console.log(chosenFiles)

    const uploaded = [...uploadedFiles];
    let limitExceeded = false;

    //const files = new FormData();
    const formData = new FormData();

    chosenFiles.forEach((val, index) => {
        formData.append("files", chosenFiles[index]);
      //files.append("files", chosenFiles[index]);
    });

    //console.log(formData)

    chosenFiles.some((file) => {
      // console.log(file);

      const files = new FormData();
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);

        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} chosenFiles`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }

        // chosenFiles.some((i) => {
        //   files.append("files", chosenFiles[i]);
        // });
      }
    });

    const response = fetch(mediaCreateURL, {
      headers: headers,
      method: "POST",
      body: formData,
      file: formData,
    })
    //.then((res) => res.json())
    .then((res) => {
      // Check for 422 or 500 response
      if (!res.ok) {
           throw new Error('Unprocessable Entity: ' + res.statusText);
      }
      return res.json();
    })
    .then((data) => {
      //console.log(data);
      props.sendDataToParent(data);
      //This is to close the modal when there is a response
      //handleClose();
      //This is to set the useState list of files to empty afterwards to reset it once the modal is close after response
      //setFileStateArray([])
    })
    .catch((err) => {
      console.log(err.message);
      setShowError(true);
    });

    //console.log(response);

    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    handleUploadFiles(chosenFiles);
  };

  const closeDeleteModal = (data) => {
    setOpenDeleteIntraopModal(false)
    //this is to close the previous modal when a diagnosis is deleted
    //props.close();
  }

  const openDeleteModal = (data) => {
    if(props.sendSelectedIntraopImages.length === 1){
      setOpenDeleteIntraopModal(true)
    } else {
      alert('A Maximum Of 1 Images Can Be Selected');
    }
    //this is to close the previous modal when a diagnosis is deleted
    //props.close();
  }

  return (
    <div>
      <form>
      <Button
        size="sm"
        className="margin_right"
        onClick={() => props.enlightenPanelButtonClicked()}
       >
          Enlighten Panel
      </Button>
        <input
          id="intraopImageUpload"
          type="file"
          multiple
          accept='image/png, image/jpg, image/jpeg'
          onChange={handleFileEvent}
          //disabled={fileLimit}
        />

        <label htmlFor="intraopImageUpload">
          {/* <a className={`btn btn-secondary ${!fileLimit ? "" : "disabled"} `}>
          Acquire Media
          </a> */}
          <BsFillPlusSquareFill className="icon-pointer" />
        </label>

        <BsTrash3 onClick={openDeleteModal}  className="icon-pointer"/>

      </form>
      <PromptIntraopDelete 
        setOpen={openDeleteIntraopModal} 
        close={closeDeleteModal}
        sendSelectedIntraopImages={props.sendSelectedIntraopImages}
        getChild={props.getChild}
        // getChild={sendDeletetoParent}
      />
      <ErrorDialogModal
        setOpen={showError}
        close={() => setShowError(false)}
        componentName="Intraop"
      />
    </div>
  );
}

export default IntraopUpload;