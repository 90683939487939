import React, { useState, useEffect, useContext } from 'react';
import UserPrefContext from '../../../context/userPrefContext';
import PatientContext from '../../../context/patientContext';
import AuthContext from '../../../context/authContext';
import CompanyContext from '../../../context/companyContext';
import { trackPromise } from 'react-promise-tracker'
import ErrorDialogModal from '../modals/ErrorDialogModal';

const MAX_COUNT = 1;

function VisitUploadImage(props) {
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [updateImage, setUpdateImage] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [consentColor, setConsentColor] = useState();
  const [allPatientConsent, setAllPatientConsent] = useState();
  const [dictPatientConsent, setDictPatientConsent] = useState();

  const [imageDate, setImageDate] = useState(new Date());

  const patCtx = useContext(PatientContext);
  const userPrefCtx = useContext(UserPrefContext);
  const authCtx = useContext(AuthContext)
  const companyCtx = useContext(CompanyContext);

  // const uploadProfileImageURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/upload/set_as_profile_image/${patCtx.pat_token}`;
  // const uploadProfileImageURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/upload/profile_image/${patCtx.pat_token}`;
  // const uploadProfileImageURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/upload/profile_image/${patCtx.pat_token}?dict_company_code_id=${userPrefCtx.userPreference.dict_company_code_id}`;
  const uploadProfileImageURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/upload/profile_image/${patCtx.pat_token}?dict_company_code_id=${companyCtx.company.id}`;

  //console.log(patCtx.pat_token)

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }


  const profileImageURL = () => {
    //console.log(patCtx)
          if (patCtx.pat_profile_image_filename === null) {
            //console.log('img url is ', "meeicon.png")
            //console.log('img url is ', "generic-user-icon.png")
            return "generic-user-icon.png"
        }
        else {
            //console.log('img url is ',`${process.env.REACT_APP_SERVER}/api/v1/file_uploads/profile_thumb_image?patient_token=${patCtx.pat_token}`)
            //console.log(`${process.env.REACT_APP_SERVER}/api/v1/file_uploads/profile_thumb_image?patient_token=${patCtx.pat_token}&time=${imageDate}`)
            // return `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/profile_thumb_image?patient_token=${patCtx.pat_token}&time=${imageDate}`
            // return `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/profile_thumb_image?patient_token=${patCtx.pat_token}&dict_company_code_id=${userPrefCtx.userPreference.dict_company_code_id}&time=${imageDate}`
            return `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/profile_thumb_image?patient_token=${patCtx.pat_token}&dict_company_code_id=${companyCtx.company.id}&time=${imageDate}`
        }
        
  }

  const [profileImage, setProfileImage] = useState(profileImageURL());

  const [profileImageUrlState, setProfileImageUrlState] = useState(
    profileImageURL(patCtx.pat_profile_image_filename, patCtx.pat_id)
  );

  useEffect(() => {

    const getDictionary = async () => {
      const dictPatientConsentURL = `${process.env.REACT_APP_SERVER}/api/v1/patient_consents/dict_data`
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userPrefCtx.bearerToken}`,
          auth: authCtx.authMode,
      }

      const response = await fetch(dictPatientConsentURL, {
          headers: headers,
      })
      .then((res) => res.json())
      .then((data) => {
          //console.log('dictionary consent data: ', data);
          setDictPatientConsent(data)

      })
      .catch((err) => {
          //console.log(err.message);
      })
  }


    const patientConsentURL = `${process.env.REACT_APP_SERVER}/api/v1/patient_consents/get_all_by_token/${patCtx.pat_token}`

    //try {
      const getAllConsents = async () => {
      const response = await fetch(patientConsentURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userPrefCtx.bearerToken}`,
          auth: authCtx.authMode,
        },
      }).then((res) => res.json())
      .then((data) => {
        //console.log(data);
        setAllPatientConsent(data);
        if(data.length > 0){
          //console.log('patient consent data exists! ', data);
        }
      })
      .catch((err) => {
          console.log(err.message);
      })
    }

    if (userPrefCtx.bearerToken !== null && userPrefCtx.bearerToken !== '' ) {
      // console.log("bearer",userPrefCtx.bearerToken)
      getDictionary();
      getAllConsents();
  }

  }, [updateImage, patCtx.pat_profile_image_filename, profileImageUrlState, patCtx.pat_token, userPrefCtx.bearerToken, props.newImageUpdatefromButton, props.updateImagefromParent]);

  useEffect(() => {

    if(dictPatientConsent != null && allPatientConsent != null) {
      //console.log('dictPatientConsent is not null')

      const patientConsentKeys = Object.keys(allPatientConsent);
      const matchingKeys = Object.values(dictPatientConsent)
        .filter(value => value.unique_id !== 'approve_all')
        .map(value => value.unique_id)
        .filter(key => {
          //uncomment the below 2 lines when the database is fixed
          const matchingKey = Object.values(allPatientConsent).find(pc => pc['DictPatConsntQsn'].unique_id === key);
          return matchingKey !== undefined;
        });

        //console.log(matchingKeys)
        //console.log(patientConsentKeys)

        if (matchingKeys.length === patientConsentKeys.length - 1) {
          // All unique_ids in patient consent match with the dictionary except 'approve_all'
          //console.log('matches')
          setConsentColor('green')
        } 
        else {
          //This filters out the approve_all from the dict and creates an array of unique_id to match with what the user consented to
          const newDict = Object.values(dictPatientConsent)
          .filter(value => value.unique_id !== 'approve_all')
          .map(value => value?.unique_id).sort()

          //console.log(newDict)
  
        const newPatientConsent = Object.values(allPatientConsent)
          .map(value => value['DictPatConsntQsn']?.unique_id).sort()

          //console.log(newPatientConsent)

          //This takes the index of everything in the dictionary without approve_all and sees if it's matched in the patient consent
          const isEqual = newDict.every((value, index) => value === newPatientConsent[index]);
          //console.log(isEqual)

          if(isEqual === true){
            //console.log('in the else, but all options are selected except for approve_all')
            setConsentColor('green')
          }

          else if(allPatientConsent?.length > 0){
            setConsentColor('yellow')
            //console.log('the length is greater than 0: ', allPatientConsent?.length)
          } else {
            setConsentColor('red')
            //console.log('the length is not greater than 0: ', allPatientConsent?.length)


          }
        }



    }

  }, [dictPatientConsent, allPatientConsent]);

  useEffect(() => {
    //The purpose of this useeffect is to  update the profile picture when it is set from the button in the visit page
      // console.log('props.newImageUpdatefromButton is ', props.newImageUpdatefromButton)
      // console.log('props.newImageUpdatefromButton length is ', props.newImageUpdatefromButton?.file_name?.length)
      if(props.newImageUpdatefromButton?.file_name?.length > 0){
        //console.log('the length is greater than 0')
        updateProfileImageUrl(props.newImageUpdatefromButton.file_name)
        setImageDate(new Date());
        setUpdateImage(Math.random());
      }
  }, [props.newImageUpdatefromButton, props.updateImagefromParent]);


  const updateProfileImageUrl = (filename) => {
    //console.log('updateProfileImageUrl filename is ', filename)
    setProfileImageUrlState(
      profileImageURL(filename, patCtx.pat_id)
    );
  };

  const headers = {
    // 'Content-Type': chosenFiles[0].typ,
    Authorization: `Bearer ${ userPrefCtx.bearerToken}`,
    accept: "application/json",
    auth: authCtx.authMode,
  };


  const handleUploadFiles = (chosenFiles) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;

    const files = new FormData();

    chosenFiles.forEach((val, index) => {
      files.append("file", chosenFiles[index]);
    });

    chosenFiles.some((file) => {
      // console.log(file);

      const files = new FormData();
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);

        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          //alert(`You can only add a maximum of ${MAX_COUNT} chosenFiles`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }

      }
    });


  const response = fetch(uploadProfileImageURL, {
    headers: headers,
    method: "POST",
    body: files,
    file: files,
  })
  //.then((res) => res.json())
  .then((res) => {
    if (!res.ok) {
       throw new Error('Unprocessable Entity: ' + res.statusText);
    }
    return res.json();
  })
  .then((data) => {
    //console.log(data);
    patCtx.pat_profile_image_filename = data.file_name;
    setImageDate(new Date());
    //console.log( patCtx.pat_profile_image_filename);
    //This is a function call
    updateProfileImageUrl(data.file_name);
    //setUpdateImage(data);
    setUpdateImage(Math.random());
    //props.visitTrigger(data)

  })
  .catch((err) => {
    console.log(err.message);
    setShowError(true);
  });

  if (!limitExceeded) setUploadedFiles(uploaded);
};

const handleFileEvent = (e) => {
    if (e.target.files.length > 0) {
        const chosenFiles = Array.prototype.slice.call(e.target.files);

        handleUploadFiles(chosenFiles);
    }
  
};


  return (
    // <>
    <div>
      
      <ErrorDialogModal
        setOpen={showError}
        close={() => setShowError(false)}
        componentName="Visit Upload Image"
      />
      
      <form>
      <input
          id="profilePicUpload"
          type="file"
          //multiple
          // accept='application/pdf, image/png'
          accept='image/png, image/jpg, image/jpeg'
          onChange={handleFileEvent}
          //disabled={fileLimit}
        />
        <label htmlFor="profilePicUpload">
          <a 
            onClick={handleShow}
          >
            <div 
              className="visit_upload_image_div"
              //This is being used as a property to send the color
              style={{borderColor: consentColor}}
            >
              <img 
                //src={profileImageURL(patCtx.pat_profile_image_filename, patCtx.pat_id)} 
                //src={() => profileImageURL()}
                src={profileImageURL()}
                //src={profileImageUrlState}
                //src={profileImage}
                data-date={imageDate}
                alt="visit-picture" 
              />
            </div>
          </a>
        </label>
      </form>
    </div>
  );
}

export default VisitUploadImage;