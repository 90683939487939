import React, { useState, useContext, useEffect } from 'react'
import PatientContext from '../../../context/patientContext';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import FaceInstrumentQuestionGroup from './FaceInstrumentQuestionGroup'
import ErrorDialogModal from '../modals/ErrorDialogModal';
import DictionaryContext from '../../../context/dictionaryContext'
import UserPrefContext from '../../../context/userPrefContext'
import AuthContext from '../../../context/authContext';
import { useNavigate } from "react-router-dom"
import { useOktaAuth } from '@okta/okta-react'
import Logout from '../../../Logout';

function FaceInstrumentModal(props) {

    const { authState, oktaAuth } = useOktaAuth()
    const navigate = useNavigate()

    const panelName = 'face_instrument_panel'
    const visCtx = useContext(PatientContext);
    const dictCtx = useContext(DictionaryContext)
    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)

    

    const [show, setShow] = useState(false);

    const [groups, setGroups] = useState([])
    const [sortedQuestions, setSortedQuestions] = useState([])
    const [modalTitle, setModalTitle] = useState('')
    const [faceInstrumentValues, setFaceInstrumentValues] = useState({})
    const [errors, setErrors] = useState([])
    const [showError, setShowError] = useState(false);
    const [doLogout, setDoLogout] = useState(false)


    useEffect(() => {
        if (dictCtx.dict_intake_form.length > 0) {
            const intakeForm = dictCtx.dict_intake_form.find(form => {
                return form.display_name_short === panelName.replace("_panel","")
            })

            if (intakeForm !== undefined) {
                // setIntakeFormId(intakeForm.id)
                // sort questions by sort_position
                const questions = dictCtx.dict_intake_form_question.filter(quest => {
                    return quest.DictIntakeFormQuestion.dict_intake_form_id === intakeForm.id
                })
                
                const comp = (a,b) => {
                    var agrp = (+a.DictIntakeFormQuestion.group_id) * 100 + (+a.DictIntakeFormQuestion.sort_position)
                    var bgrp = (+b.DictIntakeFormQuestion.group_id) * 100 + (+b.DictIntakeFormQuestion.sort_position)
                    return agrp - bgrp
                }
                // const sortedQuestions = questions.sort((a,b) => a.sort_position - b.sort_postion)
                const sortedQs = questions.sort(comp)
                // console.log("Sorted questions", sortedQs)
                setSortedQuestions(sortedQs)

                if (props.mode === 'ipad' && !!props.patient) {
                    // ipad mode - get user name from props.patient
                    setModalTitle(`${intakeForm.display_name} - ${props.patient.last_name}, ${props.patient.first_name}`)
                } else {
                    // "normal mode - take patient details from patient context"
                    setModalTitle(`${intakeForm.display_name} - ${visCtx.pat_last}, ${visCtx.pat_first}`)
                }
                


                // create result object for Face Instrument like {q0:0, q1:0,...}
                // we will use this object to post results
                const fiVals = sortedQs.reduce((accum, element) => {
                    accum[element.DictIntakeFormQuestion.display_name_short] = 0
                    return accum
                }, {})

                // console.log("fiVals", fiVals)
                setFaceInstrumentValues(fiVals)



                // setFormQuestions(sortedQuestions)

                // get group_ids of questions (only one id per group)
                const groups_c = sortedQs
                    .map((value, index, qs) => (index > 0 && qs[index-1].DictIntakeFormQuestion.group_id === value.DictIntakeFormQuestion.group_id) ? null : value.DictIntakeFormQuestion.group_id)
                    .filter(value => value !== null)

                // console.log("groups",groups_c)
                setGroups(groups_c)
            }

            // ipad mode
            if (!!props.mode && props.mode === 'ipad') {
                setShow(true)
            }
        }
    }, [dictCtx.dict_intake_form.length, visCtx, props.mode, props.patient])


    const valueChanged = (question, value) => {
        // console.log("faceInstrumentValues", faceInstrumentValues)
        // console.log("Question value changed! Question:", question, ", Value:", value)
        setFaceInstrumentValues({...faceInstrumentValues, [question]:value})
    }

    // validate and save changes
    const handleSave = () => {
        // console.log("faceInstrumentValues before save", faceInstrumentValues)

        // make sure we have all values set 
        const keys = Object.keys(faceInstrumentValues)

        // console.log("faceInstrumentValues keys", keys)

        const errorsArr = keys.filter((key) => {
            return faceInstrumentValues[key] === 0 
        })

        setErrors(errorsArr)

        // console.log("Errors - missing values in questions", errorsArr)

        // if errors array is empty then all fields populated; otherwise some are not
        if (errorsArr.length === 0) {
            // save data
            saveFaceInsruments()

            if (props.mode === 'main') {
                // change last updated time
                if (!!props.setLastUpdatedTime) {
                    props.setLastUpdatedTime(new Date().getTime())
                }
                setShow(false)
            } else if (props.mode === 'ipad') {
                // ipad mode - log out
                logout()
            }
            
        }        
    }


    

    const handleClose = () => {
        if (props.mode === 'ipad') {
            logout()
        } else {
            setErrors([])
            setShow(false)
        }
    }


    // const logout = () => {
    //     // console.log("logging out")
    //     oktaAuth.signOut()
    //     navigate('/')
    // }


    const handleShow = () => setShow(true)

    const saveFaceInsruments = async() => {
        try {
            // console.log("Creating face instruments", JSON.stringify(faceInstrumentValues))
            let faceInstrumentsCreateURL = ''
            if (props.mode === 'ipad' && !!props.patient) {
                // ipad mode - get patient token from props.patient
                faceInstrumentsCreateURL = `${process.env.REACT_APP_SERVER}/api/v1/face_instruments/from_ipad/${props.patient.form_id}/${props.patient.patient_token}`
            } else {
                // "normal" mode - take patient token from patient context
                faceInstrumentsCreateURL = `${process.env.REACT_APP_SERVER}/api/v1/face_instruments/${visCtx.pat_token}`
            }
            
            const response = await fetch(faceInstrumentsCreateURL, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userPrefCtx.bearerToken}`,
                    'accept': 'application/json',
                    auth: authCtx.authMode,
                },
                method: "POST",
                body: JSON.stringify(faceInstrumentValues),
            })

            if (response.status === 200) {
                const responseData = await response.json()
                // console.log("Created Face Instruments: responseData", responseData)
                

            } else {
                console.log("Failed to create Face Instruments", response)
                if (!response.ok) {
                    throw new Error('Unprocessable Entity: ' + response.statusText);
               }
            }
        } catch (e) {
            console.log("Failed to create Face Instruments", e)
            setShowError(true);
        }

    }

    const logout = () => {
        setDoLogout(true)
    }

    const loggedOut = () => {
        setDoLogout(false)
    }

    return (
        <>
            {(!!!props.mode  || (!!props.mode && props.mode !== 'ipad')) && 
                <BsFillPlusSquareFill onClick={handleShow} className="icon-pointer"/>
            }
            <ErrorDialogModal
                setOpen={showError}
                close={() => setShowError(false)}
                componentName="Face Instrument"
            />
            <Modal size="fullscreen" show={show} onHide={handleClose} animation={false}
                style={{ visibility: (showError) ? 'hidden' : 'visible' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title><h4>{modalTitle}</h4></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        {groups.map((group_id, index) => 
                            <FaceInstrumentQuestionGroup 
                                key={index}
                                group_id={group_id}
                                questions={sortedQuestions.filter(q => q.DictIntakeFormQuestion.group_id === group_id)}
                                onChange={valueChanged}
                                errors={errors}
                            />
                        )}
                    </div>
                </Modal.Body>

                <Modal.Footer className='justify-content-between'>
                    <div className='w-100'>
                        {errors.length > 0 && <span style={{color:'red', fontWeight:'bold'}} className='text-danger font-weight-bold'>All fields must be populated!</span>}
                    </div>
                    
                    <div className="inline-block text-left">
                        <Button className='mx-2' variant="secondary" onClick={handleClose}>
                            {props.mode === 'ipad' ? 'Logout' : 'Close'}
                        </Button>
                    </div>
                    <div className='inline-block text-right'>
                        <Button className='mx-2' variant="primary" onClick={handleSave}>
                            Save Changes
                        </Button>
                    </div>
                    
                </Modal.Footer>
            </Modal>
            <Logout doLogout={doLogout} onLoggedOut={loggedOut} /> 
        </>
    )
}

export default FaceInstrumentModal
