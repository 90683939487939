import React, { useEffect, useState, useRef } from "react";
import parse from "html-react-parser";
import CapeVQuestionRow from "./CapeVQuestionRow";

function CapeVQuestionGroup(props) {
    const [answers, setAnswers] = useState([]);
    const standard_inputs = ["number", "radio", "text"];
    const trRef = useRef();
    const [values, setValues] = useState([]);
    const [htmlElements, setHtmlElements] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [capevData, setCapevData] = useState([]);

    useEffect(() => {
        // console.log("CapeVQuestionGroup PROPS", props)

        // console.log("GROUP", props.group_id, "DATA", props.data)
        let columns = [];
        let qtypes = [];
        let qvalues = [];
        // in questions - if question_option is array - use it as column headers
        if (props.questions.length > 0) {
            if (props.questions[0].DictIntakeFormQuestion.question_option !== null) {
                if (props.questions[0].DictIntakeFormQuestion.question_option.startsWith("[")) {
                    // we have an array of headers - parse it
                    columns = JSON.parse(props.questions[0].DictIntakeFormQuestion.question_option);
                    // console.log("COLUMNS", columns)
                    // make sure that we have arrays in question_type and possibly in question_option_values
                    if (props.questions[0].DictIntakeFormQuestion.question_type !== null &&
                        props.questions[0].DictIntakeFormQuestion.question_type.startsWith("[")) {
                            qtypes = JSON.parse(
                            props.questions[0].DictIntakeFormQuestion.question_type
                        );
                    }
                }
            }
        }

        setHeaders(columns);
        setCapevData(props.data);

    }, [props.group_id, props.questions, props.data]);

    // called from the child component to update values
    const updateValues = (new_values) => {
        // console.log("CapeVQuestionGroup: updateValues - new_values", new_values)
        // setValues(values.map((v,i) => i === row_index ? new_values : v))
        props.onChange(new_values);
    };

    const parseCalculatedElement = (element) => {
        // console.log("CapeVQuestionGroup parsing input_sum_protected", element)
        const pattern = /^input_sum_protected\([0-9-]+\)$/g;
        const regex = /[0-9]+/g;
        if (element.match(pattern) !== null) {
            const match = element.match(regex);
            // we should have in match something like [a,b] - if the input type was input_sum_protected(a-b)
            if (match !== null && match.length == 2 && parseInt(match[0]) < parseInt(match[1])) {
                let elementsList = [];
                for (let i = parseInt(match[0]); i <= parseInt(match[1]); i++) {
                    elementsList.push(i);
                }
                return elementsList;
            }
        }
        // something is wrong
        return null;
    };

    // const changeErrors = (errs) => {
    //     props.setErrors(errs)
    // }

    return (
        <div>
            {props.questions[0].DictIntakeFormQuestion.group_header1 &&
                parse(`${props.questions[0].DictIntakeFormQuestion.group_header1}`)}
            {props.questions[0].DictIntakeFormQuestion.group_header2 &&
                parse(`${props.questions[0].DictIntakeFormQuestion.group_header2}`)}
            {props.questions[0].DictIntakeFormQuestion.group_header3 &&
                parse(`${props.questions[0].DictIntakeFormQuestion.group_header3}`)}

            <div className="no_border">
                {headers.length > 0 && (
                    <table className="table table-sm table-borderless">
                        <thead>
                            <tr>
                                {headers.map((hdr, index) => (
                                <td scope="col" key={index}>
                                    {hdr}
                                </td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {props.questions.map((q, row_index) => {
                                return (
                                    <CapeVQuestionRow
                                        key={row_index}
                                        // errors={props.errors}
                                        question={q}
                                        onValuesUpdate={updateValues}
                                        data={capevData}
                                        // headers={headers}
                                        type="table"
                                        readOnly={props.readOnly}
                                        // setErrors={changeErrors}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                )}

                {headers.length === 0 &&
                    props.questions.map((q, row_index) => {
                        return (
                            <CapeVQuestionRow
                                key={row_index}
                                // errors={props.errors}
                                question={q}
                                onValuesUpdate={updateValues}
                                data={capevData}
                                // headers={headers}
                                type="single"
                                readOnly={props.readOnly}
                                // setErrors={changeErrors}
                            />
                        );
                    })
                }
            </div>
        </div>
    );
}

export default CapeVQuestionGroup;
