import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ErrorDialogModal from './ErrorDialogModal';
import UserPrefContext from '../../../context/userPrefContext';
import AuthContext from '../../../context/authContext';

function PromptIntraopDelete(props) {
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);

  //console.log(props)

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }

  const userPrefCtx = useContext(UserPrefContext);
  const authCtx = useContext(AuthContext)
  //console.log(visCtx.pat_bearer_token)

  //const ptCtx = PatientContextProvider;

  //console.log(ptCtx)

  const deleteIntraop = async () => { 

    //console.log(props.sendSelectedIntraopImages);

    if (props.sendSelectedIntraopImages != null) {

        //console.log(userPrefCtx.bearerToken);

        const deleteIntraopURL = process.env.REACT_APP_SERVER + `/api/v1/intra_op_images/` + props.sendSelectedIntraopImages[0].id;

        const responseData = await fetch(deleteIntraopURL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userPrefCtx.bearerToken}`,
            //Authorization: `Bearer ${promptCtx.pat_bearer_token}`,
            auth: authCtx.authMode,
          },
        })
        //.then((res) => res.json())
        .then((res) => {
          // Check for 422 or 500 response
          if (!res.ok) {
               throw new Error('Unprocessable Entity: ' + res.statusText);
          }
          return res.json();
        })
        .then((data) => {
           //console.log(data);
           //This will send the data back to the EditInterventionModal to trigger a delete in the visitpanel
           props.getChild(data);
           //once it successfully deletes the intervention, it will close the modal
           props.close();
        })
        .catch((err) => {
           //console.log(err.message);
           setShowError(true);
        });
    }
  
  }


  return (
    <>
      <ErrorDialogModal
          setOpen={showError}
          close={() => setShowError(false)}
          componentName="Delete Intraop"
      />
      <Modal size="lg" show={props.setOpen} onHide={props.close} animation={false} 
        style={{ visibility: (showError) ? 'hidden' : 'visible' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Image Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h3>Are you sure you want to delete this Image?</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
          </Button>
          <Button variant="secondary" onClick={deleteIntraop}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PromptIntraopDelete;