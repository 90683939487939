import React, {useState, useEffect} from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, LabelList } from 'recharts'
import {toLocalDate} from '../../../utils/Utils' 
import chartClasses from '../../stylesheets/Charts.module.css'

// draw one group of eface charts
function FaceInstrumentOneChart(props) {

    // const barColors = ['#00429d', '#1f4ea3', '#305ba9', '#3e67ae', '#4a74b4', '#5681b9', '#618fbf', '#6d9cc4', '#79a9c9', '#85b7ce', '#93c4d2', '#a1d1d7', '#b1dfdb', '#c3ebde', '#daf7e1', '#ffeed3', '#ffdcc6', '#ffcab9', '#ffb8ab', '#ffa59e', '#fd9291', '#f78085', '#f06f7a', '#e75d6f', '#dd4c65', '#d23b5b', '#c52a52', '#b61849', '#a60741', '#93003a']
    const barColors = ['#7CB5EC', '#434348', '#90ED7D', '#F7A35C', '#8085E9', '#F15C80', '#E4D354', '#2B908F', '#F45B5B', '#91E8E1']


    const [efaces, setEfaces] = useState([])
    const [chart, setChart] = useState({})
    const [dates, setDates] = useState([])
    const [values, setValues] = useState([])
    const [y_axis, set_y_axis] = useState([])
    const [barGap, setBarGap] = useState(3) // default
    const [barSize, setBarSize] = useState(10) // default
    const [yAxisWidth, setYAxisWidth] = useState(0)

    useEffect(() => {
        // console.log("FaceInstrumentOneChart props",props)
        // console.log("FaceInstrumentOneChart props.group",props.group)
        // console.log("FaceInstrumentOneChart (props.group.group_questions",props.group.group_questions)
        // console.log("FaceInstrumentOneChart props",props)
        // if (props.group.group_questions[0].question_option !== "") {
        //     const chart_y_axis = JSON.parse(props.group.group_questions[0].question_option)
        //     // console.log("chart_y_axis.slice(1)", chart_y_axis.slice(1))
        //     set_y_axis(chart_y_axis.slice(1))
        // }


        const q_answ = JSON.parse(props.group.group_questions[0].DictIntakeFormQuestion.question_option).slice(1)
        // question values - ignore element 0
        const q_vals = JSON.parse(props.group.group_questions[0].DictIntakeFormQuestion.question_option_values).slice(1)
        // set answers as array - each element has properties: answer and value
        // sort by value
        let q_answ_val = q_answ.map((a, i) => ({answer: a, value: q_vals[i]})).sort((a,b) => a.value - b.value)
        // we want Y-axis always to start with 0; if the 1st value is not 0 add one
        if (q_answ_val[0].value !== 0) {
            q_answ_val.unshift({answer:"", value:0})
        }
        // q_answ_val.push({answer:"", value:q_answ_val[q_answ_val.length-1].value+1})

        // console.log("FaceInstrumentOneChart - q_answ_val", q_answ_val)
        set_y_axis(q_answ_val)





        // build values map
        let valuesArray = props.x_categories.map((category) => {

            // if full size view (viewType = "full") then show short and long description
            // otherwise (viewType = "small") show only short name (Q0, Q1, etc)
            const name = props.viewType === 'full' ? category[0] : category[1].toUpperCase()
    
            // get array of data (value of corresponding qx) into vs
            const vs = props.data.map((a)=> a[category[1]])
    
            // create array of objects from array of values (generate object key)
            const vs2 = vs.map((val, val_ix) => {return {[`d${val_ix}`]: val} })
            // console.log("vs2", vs2)
    
    
            // combine array of objects into one object with all properties combined
            var flat = vs2.reduce((obj, item) => {
                // console.log("*** flattening... obj", obj, "item", item, "item.keys", Object.keys(item))
                return {...obj, [Object.keys(item)[0]]: item[[Object.keys(item)[0]]]}
            } ,{})
            // console.log("flat", flat)
    
            // return name of the group along with values (for all dates)
            return {...flat, name: name}
        
        })

        // console.log("================= ValuesArray", valuesArray)
        setValues(valuesArray)

        // create array of dates
        // setDates(props.data.map((d) => d.created_at))
        setDates(props.data.map((d) => toLocalDate(d.created_at)))

        // calculate barSize and barGap based on the number of bars and bar groups to show
        const y_axis_width = props.viewType === 'full' ? 160 : 30
        setYAxisWidth(y_axis_width)

        // get width of the chart area
        const chart_area = document.getElementsByClassName('face-instrument-charts')
        let chart_width = 0
        // there are multiple chart areas - on the main screen and in the open modal - get the last one
        if (chart_area !== null && chart_area.length > 0) {
            chart_width = chart_area[chart_area.length-1].offsetWidth
            
        }

        // part of the chart is taken by Y-axis - adjust value to exclude it
        chart_width = chart_width - y_axis_width
        // console.log("WIDTH OF CHART", chart_width)
   
        const barGroupCount = valuesArray.length
        const barsInGroup = Object.keys(valuesArray[0]).filter(key => key.startsWith('d')).length

        const oneGroupPercent = 100/barGroupCount
        const oneBarPercent = oneGroupPercent/barsInGroup * (1/3)

        const bsize = chart_width * oneBarPercent / 100
        const gsize = chart_width * oneBarPercent / 5 / 100
        setBarSize(bsize)
        setBarGap(gsize)

        // setBarSize(`${oneBarPercent}%`)
        // setBarGap(`${oneBarPercent/2}%`)

        // console.log("barSize", bsize)
        // console.log("barGap", gsize)




    },[props.group, props.data, props.viewType])


    // labels - array of labels, value
    const formatYAxisLabel = (value) => {
        // console.log("formatYAxisLabel = value", value, "label", y_axis[value-1])
        // if (value <= y_axis.length) {
        //     return y_axis[value-1]
        // } else {
        //     return ""
        // }
        // console.log("formatYAxisLabel - y_axis", y_axis, "value", value)
        const label = y_axis.find(yval => yval.value === value)

        // if view is not full, return blank (we don't have space for the labels)
        if (props.viewType === 'full') {
            return typeof label !== "undefined" ? label.answer : "" 
        } else {
            return ""
        }
        
    }

    const CustomTooltip = ({ active, payload, label }) => {
        // console.log("**** tooltip: label", label, "payload", payload)
        if (active && payload && payload.length) {
            return (
                // 
                <div className={props.viewType === 'full' ? chartClasses['custom-tooltip-full'] : chartClasses['custom-tooltip-sm'] }>
                    <span className={chartClasses['tooltip-header']}>{label}</span>
                    <ul className={chartClasses["ul-tooltip"]}>
                        {/* {console.log("UL: payload[0]", payload[0])} */}
                        {Object.keys(payload[0].payload).filter((akey) => akey.startsWith('d')).map((d_key, d_ix) => {
                            const hStyle = { color: barColors[d_ix % barColors.length] }
                            // let span1 = props.viewType === 'full' ? `${dates[d_ix]}:` : payload[0].payload[d_key]
                            // let span2 = props.viewType === 'full' ? payload[0].payload[d_key] : ""

                            // span1 = `${dates[d_ix]}:` 
                            // span2 = payload[0].payload[d_key] 
                            return (
                                <li key={d_ix}>
                                    {/* {console.log("*** generating li - dkey", d_key, "d_ix", d_ix, "keys")} */}
                                    
                                        {/* <span className="li-padding" style={hStyle}>{span1}</span> {span2} */}
                                        <span  style={hStyle}>{dates[d_ix]}:</span> {payload[0].payload[d_key]}
                                </li>
                            )}
                        )}
                        
                    </ul>

                </div>
            )
        
        } else {
            return null
        }
    }


    const legendContent = (props) => {
        // console.log("legendContent: dates", dates)
        return (
            <div width="100%" >
                <ul className={chartClasses.legend}>
                    {dates.map((d,i) => {
                        const dot_color = { background: barColors[i % barColors.length]  }
                        return (
                            <li key={i}><span className={chartClasses.dot} style={dot_color}></span><span>{d}</span></li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    






    return (
        <>
        {/* <div width={'100%'} className='vh-50'> */}
            {/* {console.log("Rendering Bars - y_axis", y_axis)} */}

            {/* <h1 className="fs-6 text-center">{props.chart_title}</h1> */}
            <h1 className="fs-6 text-center">{props.group.chart.chart_title}</h1>

            <ResponsiveContainer width="98%" height={props.height}>
                <BarChart
                    // width={800}
                    // height={300}
                    data={values}
                    // data={myarr}
                    margin={{
                        top: 5,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                    barGap={barGap}
                    barSize={barSize}
                    maxBarSize={50}
                >
                <XAxis dataKey="name" />
                <YAxis 
                    label={{ value: props.group.chart.y_axis_name, angle: -90, position: 'insideLeft', textAnchor: 'middle' }} 
                    width={yAxisWidth}
                    domain={y_axis.length > 0 ? [y_axis[0].value, y_axis[y_axis.length-1].value ] : [0,0]}
                    interval={0}
                    tickFormatter={formatYAxisLabel}
                    tickCount={y_axis.length}
                    axisLine={false}
                    tickLine={false}
                    padding={{top:props.viewType === 'full' ? 20 : 0}}
                    // ticks={y_axis}
                >
                    {/* <Label
                        style={{
                            textAnchor: "middle",
                            fontSize: "130%",
                            fill: "white",
                        }}
                        angle={270} 
                        value={"Height (ft.)"} 
                    /> */}
                </YAxis>
                {/* wrapperStyle={{ 'border-radius': '5px', 'border-color':'#0d6efd', 'border-style':'solid', 'border-width':'2px' }} */}
                <Tooltip  content={CustomTooltip} cursor={{fill: '#f0f0f0'}} 
                     />
                {
                    props.viewType === 'full' && 
                        <Legend iconType="circle" 
                            margin={{top:20, bottom:20 }}
                            verticalAlign="bottom" 
                            content={legendContent}

                            iconSize={30} width={'100%'} 
                            //height={50} 
                            layout='horizontal' 
                            horizontalAlign='center'
                        />
                }
                <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                    
                {   values && values.length > 0 &&  
                        Object.keys(values[0])
                        .filter((akey) => akey.startsWith('d'))
                        .map((akey,key_ix) => 
                            <Bar key={key_ix} 
                                dataKey={akey} 
                                fill={barColors[key_ix % barColors.length] }   
                                maxBarSize={10}                                
                            >
                                {/* <LabelList dataKey={akey} position="top" /> */}
                            </Bar>
                    )
                }
                </BarChart>
            </ResponsiveContainer>
        {/* // </div> */}
        </>
    )
}

export default FaceInstrumentOneChart
