import React, {useState, useContext, useEffect, useRef} from 'react'
import Select, { ActionMeta, OnChangeValue, StylesConfig } from 'react-select'
import DOMPurify from 'dompurify';
import Form from 'react-bootstrap/Form'
import ErrorDialogModal from '../modals/ErrorDialogModal'
import UserPrefContext from '../../../context/userPrefContext'
import DictionaryContext from '../../../context/dictionaryContext'
import AuthContext from '../../../context/authContext'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import { toLocalDateTime } from '../../../utils/Utils'
import style from './SearchAndOr.module.css'
import LoadingSpinner from '../shared/LoadingSpinner'
import { trackPromise } from 'react-promise-tracker'
import { saveAs } from 'file-saver'

function SearchOrModal(props) {

    const userPrefCtx = useContext(UserPrefContext)
    const dictCtx = useContext(DictionaryContext)
    const authCtx = useContext(AuthContext)

    const [interventions, setInterventions] = useState([])
    const [diagnoses, setDiagnoses] = useState([])
    const [selectedInterventions, setSelectedInterventions] = useState([])
    const [selectedDiagnoses, setSelectedDiagnoses] = useState([])

    const [errorMsg, setErrorMsg] = useState('')
    const [showError, setShowError] = useState(false);
    
    
    
    const sortByLabel = (opt1, opt2) => {
        if (opt1.label < opt2.label) {
            return -1
        } else {
            return 1
        }
    }

    useEffect(() => {
        if (!!userPrefCtx.userPreference?.user_dept) {
            setInterventions(dictCtx.dict_intervention
                .filter(iv => iv.user_dept === userPrefCtx.userPreference.user_dept)
                .map(iv => {
                    return {
                        label: iv.display_name,
                        // value: iv.display_name_short,
                        value: iv.id,
                    }
                })
                .sort(sortByLabel)
                
            )
            setDiagnoses(dictCtx.dict_diag
                .filter(dg => dg.user_dept === userPrefCtx.userPreference.user_dept)
                .map(dg => {
                    return {
                        label: dg.display_name,
                        // value: dg.display_name_short,
                        value: dg.id,
                    }
                })
                .sort(sortByLabel)
            )
        }
    }, [userPrefCtx.userPreference?.user_dept, dictCtx.dict_intervention, dictCtx.dict_diag])





    const onChange = (value, actionMeta) => {

        // console.log("SearchOr - onChange: value", value)
        // console.log("SearchOr - onChange: actionMeta", actionMeta)
        // remove error message 
        setErrorMsg('')
        switch (actionMeta.action) {
            case 'select-option':
                if (actionMeta.name === 'interventions') {
                    setSelectedInterventions([...selectedInterventions, actionMeta.option])
                }
                if (actionMeta.name === 'diagnoses') {
                    setSelectedDiagnoses([...selectedDiagnoses, actionMeta.option])
                }
                break
            case 'remove-value':
                if (actionMeta.name === 'interventions') {
                    let selected = [...selectedInterventions].filter(sel => sel.value !== actionMeta.removedValue.value)

                    setSelectedInterventions([...selected])
                }

                if (actionMeta.name === 'diagnoses') {
                    let selected = [...selectedDiagnoses].filter(sel => sel.value !== actionMeta.removedValue.value)

                    setSelectedDiagnoses([...selected])
                }
                break
            case 'clear':
                if (actionMeta.name === 'interventions') {
                    setSelectedInterventions([])
                }

                if (actionMeta.name === 'diagnoses') {
                    setSelectedDiagnoses([])
                }
                break;
        }

    }




    const handleClose = () => {
        setErrorMsg('')
        setSelectedDiagnoses([])
        setSelectedInterventions([])
        props.onClose()
    }


    const handleSave = () => {
        const body = {
            dict_diag_ids: selectedDiagnoses.map(d=>d.value),
            dict_intervention_ids: selectedInterventions.map(i=>i.value),
            not_like_dict_diag_ids: [],
            not_like_dict_intervention_ids: [],
        }

        const submit = async (body) => {
            try {
                const url = `${process.env.REACT_APP_SERVER}/api/v1/web_services/diag_intvn_patient_ids_or/csv`
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                        auth: authCtx.authMode,
                    },
                    body: JSON.stringify(body),
                })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Unprocessable Entity: ' + response.statusText);
                   }
                    const responseHeaders = [...response.headers]
                    const contentType = responseHeaders.findIndex(header => header[0] === 'content-type') >= 0 ? response.headers.get("content-type") : ''
                    const contentDisposition = responseHeaders.findIndex(header => header[0] === 'content-disposition') >= 0 ? response.headers.get("content-disposition") : ''
                    
    
                    console.log("SearchOrModal - content-type", contentType)
                    console.log("SearchOrModal - content-disposition", contentDisposition)
                    if (contentType && contentType.indexOf('application/json') >= 0) {

                        return response.json()
                            .then((data) => {
                                console.log("AdministrativeActions - SearchOrModal", data)
                                if (response.status === 200) {
                                    const reports = Object.keys(data)
                                    let blobs = []
                                    reports.forEach((key) => {

                                        const aReport = data[key]
                                        if (aReport.length > 0) {
                                            const lines = aReport.reduce((acc, curr) => {
                                                if (acc === "") {
                                                    let line = [Object.keys(curr).join(), Object.keys(curr).map(key => curr[key]).join()].join("\n")
                                                    return line
                                                } else {
                                                    return acc + "\n" + Object.keys(curr).map(key => curr[key]).join()
                                                }
                                            }, "")
                                            // console.log(key, "lines", lines)
                
                                            const blob = new Blob([lines], { type: 'text/csv' });

                                            blobs.push({blob:blob, filename: `${key}.csv`})
                                            // the following code (commented out) works for Firefox, Chrome and Edge but not for Safari
                                            // we have to use FileSaver.js library for this to work in Safari (and with delays for multiple files)
                                            
                                            // const url = URL.createObjectURL(blob);
                                            // const link = document.createElement('a');

                                            // link.href = DOMPurify.sanitize(url, {SAFE_FOR_JQUERY:true});
                                            // link.setAttribute('download', `${key}.csv`);
                                            // console.log("report link", link, "file",  `${key}.csv` )
                                            // document.body.appendChild(link);
                                            // link.click();
                                            //document.body.removeChild(link);

                                            // saveAs(blob, `${key}.csv`)
                                        }
 
                                        
                                        
                                    })

                                    // this is a hack for Safari - otherwise Safari saves just last file
                                    blobs.reverse()
                                    blobs.forEach((b,i) => {
                                        setTimeout(() => {
                                            saveAs(b.blob, b.filename)
                                        }, i*300)
                                    })
                                    handleClose()
                                } else if (response.status !== 200) {
                                    if (data.hasOwnProperty('detail')) {
                                        setConfirmDialogText(data.detail)
                                        setConfirmDialogTitle('Alert!')
                                        setShowConfirm(true)
                                    }
                                }
                                
                            })
                    } else {
                        console.log("SearchOrModal - else branch for blob")
                        return response.blob()
                            .then((blob) => {
                                const excelData = new Blob([blob], { type: contentType })
                                const url = window.URL.createObjectURL(excelData)
                                const a = document.createElement('a')
                                a.style.display = 'none'
                                a.href = DOMPurify.sanitize(url, {SAFE_FOR_JQUERY:true})
                                // extract file name from disposition
                                const dispositionRegex = /filename=['"](?:.*?)['"]/gi
                                const match = contentDisposition.match(dispositionRegex)
                                if (!! match && match.length > 1) {
                                    a.download = `${match[1]}`
                                } else {
                                    a.download = `ReportByDiag-Interv_OR_${toLocalDateTime(new Date(),'_').replace(/-/g, "_")}.csv`
                                }
                                // document.body.appendChild(a)
                                a.click()
                                window.URL.revokeObjectURL(url)
                                handleClose()
                            })
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                    setShowError(true)
                })
            }
            catch (e) {
                console.log(e)
                setShowError(true)
            }
        }

        trackPromise(
            submit(body)
        )
        // if (errorMsg === '') {
        //     props.onClose()
        // }
        
    }


    return (
        <>
        <ErrorDialogModal
          setOpen={showError}
          close={() => setShowError(false)}
          componentName="Report by Diag-Int or Search"
        />
        <Modal 
            backdrop="static"
            size="lg" 
            show={props.show} 
            animation={false} 
            onHide={() => props.onClose('cancel')} 
            style={{ visibility: (showError) ? 'hidden' : 'visible' }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Report by Diagnoses/Interventions - OR Search</Modal.Title>
            </Modal.Header>
            <Modal.Body>              
                <Row className='my-3'>  
                    <Form.Group>
                        <Form.Label htmlFor="diagnoses" className={style['label-bold']} >Diagnosis OR Search</Form.Label>
                        <Select
                            onChange={onChange}
                            value={selectedDiagnoses}
                            isMulti
                            name="diagnoses"
                            options={diagnoses}
                            // className="basic-multi-select"
                            // classNamePrefix="select"
                            placeholder='Select multiple values to include any...'
                        />
                    </Form.Group>
                </Row>
                
                <Row className='my-3'>  
                    <Form.Group>
                        <Form.Label htmlFor="interventions" className={style['label-bold']} >Intervention OR Search</Form.Label>
                        <Select
                            onChange={onChange}
                            value={selectedInterventions}
                            isMulti
                            name="interventions"
                            options={interventions}
                            // className="basic-multi-select"
                            // classNamePrefix="select"
                            placeholder='Select multiple values to include any...'
                        />
                    </Form.Group>
                </Row>

                
                <Row className='my-3'>
                    <p className={style.errorMsg}>{errorMsg}</p>
                </Row>
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <div className="inline-block text-left">
                    <Button className='mx-2' variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </div>

                <div className='inline-block text-right'>
                    <Button className='mx-2' variant="primary" onClick={handleSave} disabled={selectedDiagnoses.length + selectedInterventions.length === 0}>
                        Generate Report
                    </Button>
                </div>
            </Modal.Footer>
            <LoadingSpinner size='100px' />
        </Modal>
        </>
    )
}

export default SearchOrModal
