import React, { useState, useContext, useEffect } from "react";
import PatientContext from "../../../context/patientContext";
import userPrefContext from "../../../context/userPrefContext";
import AuthContext from "../../../context/authContext";
import CompanyContext from "../../../context/companyContext";
import ErrorDialogModal from "../modals/ErrorDialogModal";
import "./FileUpload.css";

//This is to directly upload an image in the botox map, not using any modals

const MAX_COUNT = 1;

function DictBotoxSrcImageUpload(props) {

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [showError, setShowError] = useState(false)

  const userPrefCtx = useContext(userPrefContext);
  const authCtx = useContext(AuthContext)
  const companyCtx = useContext(CompanyContext);


  //const mediaCreateURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/upload/botox_src_image`;
  // const mediaCreateURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/upload/dict_botox_map_src_image`;
  // const mediaCreateURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/upload/dict_botox_map_src_image?dict_company_code_id=${userPrefCtx.userPreference.dict_company_code_id}`;
  const mediaCreateURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/upload/dict_botox_map_src_image?dict_company_code_id=${companyCtx.company.id}`;


  //console.log(userPrefCtx.bearerToken);

  const headers = {
    // 'Content-Type': chosenFiles[0].typ,
    Authorization: `Bearer ${userPrefCtx.bearerToken}`,
    accept: "application/json",
    auth: authCtx.authMode,
  };


  const handleUploadFiles = async (chosenFiles) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;

    //console.log(uploaded)

    const formData = new FormData();


    //This will append the key 'file' and the value of chosenFiles to the formData
    chosenFiles.forEach((val, index) => {
      formData.append("file", chosenFiles[index]);
    });

    //console.log(formData)

    chosenFiles.some((file) => {
      // console.log(file);

      const files = new FormData();

      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);

        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} chosenFiles`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });

    const response = await fetch(mediaCreateURL, {
      headers: headers,
      method: "POST",
      body: formData,
      file: formData,
    })
    //.then((res) => res.json())
    .then((res) => {
      if (!res.ok) {
         throw new Error('Unprocessable Entity: ' + res.statusText);
      }
      return res.json();
    })
    .then((data) => {
      //console.log(data);
      props.visitTrigger(data);
    })
    .catch((err) => {
      console.log(err.message);
      setShowError(true);
    });

    //console.log(response);

    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    handleUploadFiles(chosenFiles);
  };

  return (
    <div className="App">
          <ErrorDialogModal
            setOpen={showError}
            close={() => setShowError(false)}
            componentName={'Botox Map Image Upload Button'}
    />
      <form>
        <input
          id="botoxImageUpload"
          type="file"
          //multiple
          //accept='application/pdf, image/png'
          onChange={handleFileEvent}
          disabled={fileLimit}
        />

        <label htmlFor="botoxImageUpload" className="button_container_label">
        {/* botox-src-img-button is defined in the css file */}
          <a className={`btn btn-primary botox-src-img-button ${!fileLimit ? "" : "disabled"} `}>
          Upload New Botox Map Image
          </a>
        </label>

        {/* <div className="uploaded-files-list">
          {uploadedFiles.map((file) => (
            <div>{file.name}</div>
          ))}
        </div> */}
      </form>
    </div>
  );
}

export default DictBotoxSrcImageUpload;