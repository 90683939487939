import React, { useState, useEffect, useContext, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ErrorDialogModal from '../ErrorDialogModal';
import UserPrefContext from '../../../../context/userPrefContext';
import AuthContext from '../../../../context/authContext';


function EditDictDiag(props) {
  const userPrefCtx = useContext(UserPrefContext);
  const authCtx = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false)
  const [info, setInfo] = useState();
  const [groupNameList, setGroupNameList] = useState();
  const [diagTypeList, setdiagTypeList] = useState();

  const [groupNameValue, setGroupNameValue] = useState();
  const [diagTypeValue, setDiagTypeValue] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [openFPModal, setOpenFPModal] = useState(false);

  const [openOtherModal, setOpenOtherModal] = useState(false);

  const nameRef = useRef(null);
  const groupRef = useRef(null);
  const diagTypeRef = useRef(null);

  //console.log(props.sendDictDashboard)

  //console.log('props.setOpen is ',props.setOpen)
  if (props.setOpen === true) {
    //this if statement is supposed to send a false value for the dictdiag modal
    //props.func(false);
  }

  //sending false back to the NewDictDiag modal to eventually close that modal when this modal is called
  //props.func(false);
  useEffect(() => {
    if(props.sendDictDashboard?.dict_diag != undefined){

    const alphaProp = props.sendDictDashboard?.dict_diag
    .sort((a, b) => a?.group_display_name
    .localeCompare(b?.group_display_name
      ));

  //console.log(alphaProp)

  const diagType = [...new Map(props.sendDictDashboard.dict_diag?.map(item => [item.diag_type, item])).values()]
  //console.log(selectArray[0])
  setdiagTypeList(diagType);

  const groupName = [...new Map(props.sendDictDashboard.dict_diag?.map(item => [item.group_display_name, item])).values()]

  setGroupNameList(groupName);
    }

  }, [props.sendDictDashboard?.dict_diag]);



  const callDictServices = () => { 
  
    setShow(true);
  }

  const patchEditDiagnosis = async (e) => {
    
    const patchDictDiagURL = process.env.REACT_APP_SERVER + `/api/v1/dict_diags/` + props.editID;

    let currentNameRef = nameRef.current.value;
    let currentGroupRef = groupRef.current.value;
    let currentDiagTypeRef = diagTypeRef.current.value;
    let sendUniqueID = props.editUniqueID;
    let sendSortPosition = props.editSortPosition;

    if(sendUniqueID === null){
        sendUniqueID = '';
    }
    if(sendSortPosition === null){
        sendSortPosition = 0;
    }

    // console.log(currentGroupRef);
    // console.log(typeof currentGroupRef);


    // console.log('diagTypeValue: ', diagTypeValue, 'groupNameValue: ', groupNameValue, 'currentNameRef: ',currentNameRef, 'userPrefCtx.bearerToken: ', userPrefCtx.bearerToken)

    const responseData = await fetch(patchDictDiagURL, {
      method: "PATCH",
      body: JSON.stringify({
        diag_type: `${currentDiagTypeRef}`,
        group_display_name: `${currentGroupRef}`,
        display_name: `${currentNameRef}`,
        display_name_short: `${currentNameRef}`,
        unique_id: sendUniqueID,
        sort_position: sendSortPosition,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userPrefCtx.bearerToken}`,
        auth: authCtx.authMode
      },
    })
      //.then((res) => res.json())
      .then((res) => {
        // Check for 422 or 500 response
        if (!res.ok) {
             throw new Error('Unprocessable Entity: ' + res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        //console.log(data);
        //Sending data back to the visit panels so that it can trigger a rerender in the intervention list
        props.close();
        props.sendToParent(data);
        //props.getChild(data);
      })
      .catch((err) => {
        //console.log(err.message);
        setShowError(true);
      });


  }

  const selectGroupName = (e) => {
    console.log('group name is: ', e.target.value)
    setGroupNameValue(e.target.value)

  }

  const selectDiagType = (e) => {
    console.log('diag type is: ', e.target.value)
    setDiagTypeValue(e.target.value)

  }

  return (
    <>
      {/* <Modal show={show} onHide={handleClose} animation={false}> */}
      <ErrorDialogModal
          setOpen={showError}
          close={() => setShowError(false)}
          componentName="Edit Dict Diagnosis"
        />
        <div
          onKeyDown={e => e.stopPropagation()}
          onClick={e => e.stopPropagation()}
          onFocus={e => e.stopPropagation()}
          onMouseOver={e => e.stopPropagation()}
        >
      <Modal size="xl" show={props.setOpen} onHide={props.close} animation={false}
      style={{ visibility: (showError) ? 'hidden' : 'visible' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Dict Diagnosis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Group Name</h4>
          <Form.Select aria-label="Default select example" onChange={selectGroupName} ref={groupRef} defaultValue={props.editGroupDisplayName}>
            {/* {groupName.map((x) => <option id={'diag-button-type-'+x.group_display_name} value={x.group_display_name} onClick={openFP} >{x.group_display_name}</option>)} */}
            {groupNameList?.map((x) => <option key={x.id} id={'diag-button-type-'+x.group_display_name} value={x.group_display_name} >{x.group_display_name}</option>)}
          </Form.Select>
          <h4>Diag Type</h4>
          <Form.Select aria-label="Default select example" onChange={selectDiagType} ref={diagTypeRef} defaultValue={props.editDiagType}>
            {/* {diagType.map((x) => <option id={'diag-button-type-'+x.diag_type} value={x.diag_type} onClick={openFP} >{x.diag_type}</option>)} */}
            {diagTypeList?.map((x) => <option key={x.id} id={'diag-button-type-'+x.diag_type} value={x.diag_type} >{x.diag_type}</option>)}
          </Form.Select>
          <h4>Display Name</h4>
          <Form.Control 
            // onSubmit={saveBotox} 
            ref={nameRef} 
            size="lg" 
            type="text" 
            defaultValue={props.editDisplayNameShort}
            //placeholder="Other Info" 
          />
          {/*selectArray.map((x) => <div ><button id={'diag-button-type-'+x.diag_type} value={x.diag_type} onClick={openFP} >{x.diag_type}</button></div>) */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
          </Button>
          <Button variant="primary" onClick={patchEditDiagnosis}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    </>
  );
}

export default EditDictDiag;