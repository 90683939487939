import React, { useState, useEffect, useContext, useRef } from "react";
import DictionaryContext from "../../../context/dictionaryContext";
import groupBy from 'lodash/groupBy';
import '../../stylesheets/BotoxMap.css'

const ColorBox = (props) => {
  const dictCtx = useContext(DictionaryContext);

  const [finalColorBoxMap, setFinalColorBoxMap] = useState(null);


  useEffect(() => {
    //I'm trying to be change the values of the colorboxes so that the user can type in 
    if(props.sendSelection != null){
    // console.log('in colorBox useEffect')
    //console.log(props.sendSelection)

    // console.log('props.sendColorValueSource is ', props.sendColorValueSource);
    // console.log('This is props.sendEditBotoxMapData ', props.sendEditBotoxMapData)

    //console.log('dictCtx is ', dictCtx)

    let selectionNum = 0;

    //The type of the props is a string by default, gets converted to number in the 2nd render, but want to account for it at the beginning
    if(typeof props.sendSelection === 'string'){
      //if (props.selection instanceof String) {
        selectionNum = Number(props.sendSelection);
      } else {
        selectionNum = props.sendSelection;
      } 

    //This is to find the selection from the botox type in the dictionary 
    const filteredBotoxArray = dictCtx.dict_botox_map.filter(d => d['DictBotoxMap'].id === selectionNum)[0]//['DictBotoxMap'].dictionary_detail
    //console.log(filteredBotoxArray?.DictBotoxMap?.dictionary_detail ?? 'undefined');
    const filteredBotoxName = filteredBotoxArray?.DictBotoxMap?.dictionary_detail ?? 'undefined';


    var breakDownDictionaryArray = [];

    breakDownDictionaryArray.push(_.groupBy(dictCtx.dict_botox_map, function (n) {
      //console.log('n.id inside the push',n.id)
      return n['DictBotoxMap'].id;
    }));
    //console.log("breakDownDictionaryArray", breakDownDictionaryArray)

    let defaultColorOptionsArray = []

     //console.log(dictCtx.dict_botox_map)
    // console.log(breakDownDictionaryArray)
    // console.log(props.sendSelection)
    
    let arraySize = Object.keys(breakDownDictionaryArray[0][props.sendSelection]).length;

    //console.log(arraySize)

    for (let i = 0; i < arraySize; i++) {
      //This is to set the default color options for the text areas
      defaultColorOptionsArray.push(breakDownDictionaryArray[0][props.sendSelection][i]);
    }
    defaultColorOptionsArray.sort(function (a, b) {
      return a['DictBotoxMapColorScale'].sort_position - b['DictBotoxMapColorScale'].sort_position;
    });

    //console.log('defaultColorOptionsArray in useeffect', defaultColorOptionsArray);

  //Below I will be sorting edit data to get the colorbox values from the database

  let editReturnArray = []

  for (const property in props.sendEditBotoxMapData) {
    if (property.startsWith("botox_map_pen_color_")) {
      editReturnArray.push(
        { 
          DictBotoxMap: {
          dictionary_detail: props.sendEditBotoxMapData.botox_map_type
        },
          
          DictBotoxMapColorScale: {

            color_name: property.substring(20),
            dictionary_detail: props.sendEditBotoxMapData[property],
            // botox_map_type: props.sendEditBotoxMapData.botox_map_type
            //dictionary_type_value: 
        }
      });
    }
  }

  //console.log('editReturnArray is ', editReturnArray)

  const mergedArray = [];

  //This is to go throught the editReturnArray and add value such as sort and hex values from the dictionary to a merged array
  editReturnArray.map(obj1 => {
    //const obj2 = defaultColorOptionsArray.find(obj2 => obj2['DictBotoxMap'].dictionary_detail === obj1['DictBotoxMap'].dictionary_detail);
    const obj2 = defaultColorOptionsArray.find(obj2 => obj2['DictBotoxMapColorScale'].color_name === obj1['DictBotoxMapColorScale'].color_name);

    if (obj2) {
      const mergedObj =
      { 
        DictBotoxMap: {
        dictionary_detail: obj1['DictBotoxMap'].dictionary_detail,
        id: obj2['DictBotoxMap'].id,
        is_editable: obj2['DictBotoxMap'].is_editable,
      },
        
        DictBotoxMapColorScale: {

          color_name: obj1['DictBotoxMapColorScale'].color_name,
          dictionary_detail: obj1['DictBotoxMapColorScale'].dictionary_detail,
          dictionary_value: obj2['DictBotoxMapColorScale'].dictionary_value,
          sort_position: obj2['DictBotoxMapColorScale'].sort_position,
          // botox_map_type: props.sendEditBotoxMapData.botox_map_type
          //dictionary_type_value: 
      }
    }
      mergedArray.push(mergedObj);
    }
  });

  //console.log("mergedArray after push: ", mergedArray)

  mergedArray.sort(function (a, b) {
    //This will sort the useState array by the sort position
    return a['DictBotoxMapColorScale'].sort_position - b['DictBotoxMapColorScale'].sort_position;
  });


  if (mergedArray.length > 0 && props.sendColorValueSource === 'data'){
    //console.log('mergedArray is ', mergedArray)
      if((selectionNum === mergedArray[1]['DictBotoxMap'].id) && (filteredBotoxName === props.sendEditBotoxMapData.botox_map_type) ){
      //console.log('props.sendEditBotoxMapData is ', props.sendEditBotoxMapData)
      setFinalColorBoxMap(mergedArray);
    } else {
      // console.log('in the inner, 1st else, props.sendEditBotoxMapData is ', props.sendEditBotoxMapData)
      setFinalColorBoxMap(defaultColorOptionsArray);
    }
  } else{
    // console.log('in the 2nd else, props.sendEditBotoxMapData is ', props.sendEditBotoxMapData)
    setFinalColorBoxMap(defaultColorOptionsArray);
  }
}

//console.log('finalColorBoxMap is ', finalColorBoxMap);
    
}, [props.sendSelection, props.sendEditBotoxMapData, dictCtx]);



  const blankChangeHandler = (e) => {
    //This does nothing, just there to stop warnings
  }

  //There are 2 mapping functions because if it's XEOMIN, then the user has the ability to change the numbers in the colorbox, defaultvalue will allow it to change
  //But if it's not XEOMIN then the user can't change the numbers, value will keep it locked
  return (
    <div 
      className='container'
    >
      <div 
        id='colorpicker' 
        className='botox_text_area' 
      >
        {/* { finalColorBoxMap != null && finalColorBoxMap[0]['DictBotoxMap']?.dictionary_detail === 'XEOMIN' && */}
        { finalColorBoxMap != null && finalColorBoxMap[0]['DictBotoxMap']?.is_editable === true &&

            finalColorBoxMap.map((d, i) => (
            <textarea
              //key={i}
              key={Math.random()}
              defaultValue={d['DictBotoxMapColorScale'].dictionary_detail}
              //This style needs to stay because it is based on the data, it is moreso an attribute of the textarea
              style={{ backgroundColor: d['DictBotoxMapColorScale'].dictionary_value, flexGrow: 1, minWidth: 0  }}
              id={d['DictBotoxMapColorScale'].dictionary_value.split('#')[1]}
              onChange={blankChangeHandler}
            />
            ))
        
        }
        {/* {finalColorBoxMap != null && finalColorBoxMap[0]['DictBotoxMap']?.dictionary_detail != 'XEOMIN' && */}
        {finalColorBoxMap != null && finalColorBoxMap[0]['DictBotoxMap']?.is_editable === false &&
            finalColorBoxMap.map((d, i) => (
            <textarea
              key={Math.random()}
              value={d['DictBotoxMapColorScale'].dictionary_detail}
              //This style needs to stay because it is based on the data, it is moreso an attribute of the textarea
              style={{ backgroundColor: d['DictBotoxMapColorScale'].dictionary_value, flexGrow: 1, minWidth: 0  }}
              id={d['DictBotoxMapColorScale'].dictionary_value.split('#')[1]}
              onChange={blankChangeHandler}
            />
            ))
        }
      </div>
    </div>

  );
}
export default ColorBox;