import React, {useState, useContext} from 'react'
import styles from './ReturnBotoxImage.module.css'
import CompanyContext from '../../../context/companyContext';




function ReturnBotoxImage(props) {
    const companyCtx = useContext(CompanyContext);
    // const imageURL = (filename, patient_id, mode) => `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/image/${props.mode}_${filename}?patient_id=${patient_id}`
    const imageURL = (filename, patient_id, mode) => `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/image/${props.mode}_${filename}?dict_company_code_id=${companyCtx.company.id}&patient_id=${patient_id}&time=${new Date()}`

    const toggleSelected = () => {
        props.onImageSelect(props.image, props.image_id, props.botox_type_id, props.sendObject)
        //console.log(props.image,'the id is ', props.image_id)
        // console.log('props.isImageSelected', props.isImageSelected)
    }


    return (
        <div 
            // className={`visit-image ${props.isImageSelected ? 'visit-image-selected' : ''}`} 
            //className={props.isImageSelected ? styles.botox_border_selected : styles.botox_border_not_selected}
            //This is coming from index.css
            className={` ${props.isImageSelected ? 'border-selected' : 'border-not-selected'}`}
            onClick={toggleSelected}
        >
            {/* {console.log(props.isImageSelected)} */}
            <img 
                className={styles.botoxImg} 
                //className={`visit-image ${props.isImageSelected ? 'visit-image-selected' : ''}`} 
                src={imageURL(props.image['BotoxMapImage']?.file_name, props.patient_id)}
            ></img>
            {/* {console.log(props.image['BotoxMapImage'])} */}
        </div>
    )
}

export default ReturnBotoxImage