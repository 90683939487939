import React, { useState, useContext, useEffect } from 'react'
import PatientContext from '../../../context/patientContext'
import UserPrefContext from '../../../context/userPrefContext'
import DictionaryContext from '../../../context/dictionaryContext'
import AuthContext from '../../../context/authContext'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import EFaceGroup from './EFaceGroup'      
import ErrorDialogModal from '../modals/ErrorDialogModal'           
import DatePicker from 'react-datepicker'
import moment from 'moment'
import ConfirmDialog from '../shared/ConfirmDialog'
import {BsSignStopFill } from 'react-icons/bs'


function EFaceEditModal(props) {

    const panelName = 'the_eface_panel'
    const dictCtx = useContext(DictionaryContext)

    const visCtx = useContext(PatientContext)
    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)
    const eFaceCreateURL = `${process.env.REACT_APP_SERVER}/api/v1/the_efaces/${visCtx.pat_token}`
    const eFaceUpdateURL = (id) => `${process.env.REACT_APP_SERVER}/api/v1/the_efaces/${id}`
    const eFaceDeleteURL = (id) => `${process.env.REACT_APP_SERVER}/api/v1/the_efaces/${id}`
   

    const [groups, setGroups] = useState([])
    const [sortedQuestions, setSortedQuestions] = useState([])
    const [modalTitle, setModalTitle] = useState('')
    const [eFaceValues, setEFaceValues] = useState({})

    const [startDate, setStartDate] = useState(new Date())
    const [showError, setShowError] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false)

    let httpHeaders = (token) => {return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'accept': 'application/json',
        auth: authCtx.authMode,
    }}


    useEffect(() => {

        const dictPanel = dictCtx.dict_panel.find(pnl => pnl.display_name_short === panelName)

        if (dictPanel !== undefined) {
            const intakeForm = dictCtx.dict_intake_form.find(form => {
                return form.display_name_short === dictPanel.display_name_short.replace("_panel","")
            })

            if (intakeForm !== undefined) {
                const questions = dictCtx.dict_intake_form_question.filter(quest => {
                    return quest.DictIntakeFormQuestion.dict_intake_form_id === intakeForm.id
                })
                
                const comp = (a,b) => {
                    var agrp = (+a.DictIntakeFormQuestion.group_id) * 100 + (+a.DictIntakeFormQuestion.sort_position)
                    var bgrp = (+b.DictIntakeFormQuestion.group_id) * 100 + (+b.DictIntakeFormQuestion.sort_position)
                    return agrp - bgrp
                }
                // const sortedQuestions = questions.sort((a,b) => a.sort_position - b.sort_postion)
                const sortedQs = questions.sort(comp)
                // console.log("Sorted questions", sortedQs)
                setSortedQuestions(sortedQs)

                setModalTitle(`${intakeForm.display_name} - ${visCtx.pat_last}, ${visCtx.pat_first}`)

                // create result object for e-Face like {{display_name_short}}:{value}},...}
                // we will use this object to post results
                if (props.mode.mode === 'new') {
                    const fiVals = sortedQs.reduce((accum, element) => {
                        // set default value as half-way value
                        // set default value to 100 (2023-09-21) - requested by the users
                        const labVals = JSON.parse(element.DictIntakeFormQuestion.question_option_values)
                        // accum[element.DictIntakeFormQuestion.display_name_short] = labVals[labVals.length-1]/2
                        accum[element.DictIntakeFormQuestion.display_name_short] = 100
                        return accum
                    }, {})
    
                    setEFaceValues(fiVals)
                } else {
                    // should be editing
                    if (props.mode.mode === 'editing') {
                        // console.log("EFaceEditModal in editing mode: eface=",props.mode.eface)
                        // populate from eface
                        // set startDate from eface
                        setStartDate(moment(props.mode.eface.eface_date).toDate())
                        const fiVals = sortedQs.reduce((accum, element) => {
                            accum[element.DictIntakeFormQuestion.display_name_short] = props.mode.eface[element.DictIntakeFormQuestion.display_name_short]
                            return accum
                        }, {})
                        setEFaceValues(fiVals)
                    }
                    
                }

                // get group_ids of questions (only one id per group)
                const groups_c = sortedQs
                    .map((value, index, qs) => (index > 0 && qs[index-1].DictIntakeFormQuestion.group_id === value.DictIntakeFormQuestion.group_id) ? null : value.DictIntakeFormQuestion.group_id)
                    .filter(value => value !== null)

                setGroups(groups_c)
            }
        }

    }, [dictCtx.dict_panel.length, props.mode])

    const valueChanged = (question, value) => {
        setEFaceValues({...eFaceValues, [question]:value})
    }



    const saveEFace = async() => {
        try {            
            const formattedDate = startDate.toISOString().split('T')[0]
            // console.log("Save EFace formatted Date", formattedDate)

            const httpMethod = props.mode.eface === null ? "POST" : "PATCH"
            const url = props.mode.eface === null ? eFaceCreateURL : eFaceUpdateURL(props.mode.eface.id)

            const response = await fetch(url, {
                headers: httpHeaders(userPrefCtx.bearerToken),
                method: httpMethod,
                body: JSON.stringify({...eFaceValues, eface_date:formattedDate}),
            })

            if (response.status === 200) {
                const responseData = await response.json()
                // console.log("Created/updated e-Face", responseData)
                props.onClose()

            } else {
                console.log("Failed to create/update e-Face", response)
                if (!response.ok) {
                    throw new Error('Unprocessable Entity: ' + response.statusText);
               }
            }
        } catch (e) {
            console.log("Failed to create/update e-Face", e)
            setShowError(true);
        }

    }


    const closeConfirmDialog = (result) => {
        setShowConfirm(false)
        if (result === 'confirm') {
            if (props.mode.eface !== null && props.mode.eface.id !==null) {
                deleteEFace(props.mode.eface.id)
                // props.onSaveOrDelete()
                props.onClose()
            }

        }
    }

    const handleSave = () => {
        saveEFace()
        // call parent onSave - to let parent to refresh the eface view
        props.onSaveOrDelete()
        //props.onClose()
    }

    const deleteEFace = async (eface_id) => {
        const url = eFaceDeleteURL(eface_id)
        try {
            const response = await fetch(url, {
                headers: httpHeaders(userPrefCtx.bearerToken),
                method: "DELETE",
            })

            if (response.status === 200) {
                const responseData = await response.json()
                // console.log("Deleted e-Face", responseData)
                props.onSaveOrDelete()
            } else {
                console.log("Failed to delete e-Face", response)
                if (!response.ok) {
                    throw new Error('Unprocessable Entity: ' + response.statusText);
               }
            }
        } catch (e) {
            console.log("Failed to delete e-Face", e)
            setShowError(true);
        }
    }

    // delete eface
    const handleDelete = () => {
        setShowConfirm(true)

        // if (props.mode.eface !== null && props.mode.eface.id !==null) {
        //     deleteEFace(props.mode.eface.id)
        //     // props.onSaveOrDelete()
        //     props.onClose()
        // }

    }


    return (
        <>
            <ConfirmDialog 
                show={showConfirm}
                title='Please confirm'
                modal_class='delete-confirm-modal'
                title_class='delete-confirm-title'
                footer_class='delete-confirm-title'
                prompt='The eFace will be permamently deleted. Are you sure you want to delete this eFace? '
                prompt_class='diags-modal-delete-confirm-prompt'
                // cancel_button_text='Do not delete!'
                confirm_button_text='Delete'
                confirm_button_variant='danger'
                onClose={(result) => closeConfirmDialog(result)}
                icon={<BsSignStopFill />}
                iconColor='red'
                iconSize='1.3em'
            />
            <ErrorDialogModal
                setOpen={showError}
                close={() => setShowError(false)}
                componentName="EFace"
            />
        <Modal size="fullscreen" show={props.show} onHide={props.onClose} animation={false}
            style={{ visibility: (showError) ? 'hidden' : 'visible' }}
        >
            <Modal.Header closeButton>
                <Modal.Title><h4>{modalTitle}</h4></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='col-12'>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} 
                        className="datepicker-w100" 
                    />
                </div>
                <div className="row" id="allValues">
                    {groups.map((group_id, index) => 
                        <EFaceGroup 
                            key={index}
                            group_id={group_id}
                            questions={sortedQuestions.filter(q => q.DictIntakeFormQuestion.group_id === group_id)}
                            question_values={eFaceValues}
                            onChange={valueChanged}
                            // errors={errors}
                        />
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <div className="inline-block text-left">
                    <Button className='mx-2' variant="secondary" onClick={props.onClose}>
                        Close
                    </Button>
                    {props.mode.eface !== null &&
                        <Button className='mx-2 danger-button' variant="primary" onClick={handleDelete}>
                            Delete
                        </Button>
                    }
                </div>
                <div className='inline-block text-right'>
                    <Button className='mx-2' variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default EFaceEditModal
