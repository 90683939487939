import React, { useState, useContext, useEffect} from 'react'
import Form from "react-bootstrap/Form"
import { FormControl, FormGroup, FormLabel, FormText } from 'react-bootstrap'
import {BsJournalText} from 'react-icons/bs' 
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import PatientContext from '../../../context/patientContext'
import UserPrefContext from '../../../context/userPrefContext'
import AuthContext from '../../../context/authContext'
import ErrorDialogModal from '../modals/ErrorDialogModal'

function PatientComments(props) {

    const [commentText, setCommentText] = useState('')
    const [lastLoadedTime, setLastLoadedTime] = useState(null)
    const [showError, setShowError] = useState(false)
    const patCtx = useContext(PatientContext)
    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)
    const [buttonEnabled, setButtonEnabled] = useState(false)

    const httpHeaders = (bearerToken) => {
        return {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`,
            accept: "application/json",
            auth: authCtx.authMode,
        }
    }

    //console.log(patCtx.pat_token)
    const savePatientCommentsURL = `${process.env.REACT_APP_SERVER}/api/v1/patient_comments/${patCtx.pat_token}`

    
    useEffect(() => {
        // get comments
        let ignoreComments = false

        const getComments = async () => {
            const getCommentsURL = `${process.env.REACT_APP_SERVER}/api/v1/patient_comments/get_all_by_token/${patCtx.pat_token}`

            try {
                // console.log("Trying to get Comments - headers", httpHeaders(userPrefCtx.bearerToken))
                const response = await fetch(getCommentsURL, {
                    // headers: {
                    //     // 'Authorization': `Bearer ${token}`,
                    //     'Authorization': `Bearer ${userPrefCtx.bearerToken}`,
                    // },
                    headers: httpHeaders(userPrefCtx.bearerToken),

                })
    
                if (response.status === 200) {
                    if (!ignoreComments) {
                        const responseData = await response.json()
                        // console.log("Comments", responseData)
                        if (responseData.length > 0) {
                            setCommentText(responseData[0].comments)
                        } else {
                            setCommentText('')
                        }
                        
                        // console.log("One Visit visits_data", responseData.visits_data)
                    }
                    
                } else {
                    console.log("Failed to get patient comments", response)
                }
            } catch (e) {
                console.log("Failed to get patient comments", e)
                setShowError(true);
            }

    
        }

        if (userPrefCtx.bearerToken !== '' && patCtx.pat_token !== '') {
            getComments()
            setLastLoadedTime(props.last_loaded_time)
        }
        //setLastLoadedTime(props.last_loaded_time)

        return () => {
            ignoreComments = true
        }
    }, [ userPrefCtx.bearerToken, patCtx.pat_token , props.last_loaded_time ])
    
    

    const updateComments = async () => {

        const savePatientCommentsURL = `${process.env.REACT_APP_SERVER}/api/v1/patient_comments/${patCtx.pat_token}`

        try {
            const response = await fetch(savePatientCommentsURL, {
                headers: httpHeaders(userPrefCtx.bearerToken),
                method: "POST",
                body: JSON.stringify({
                    comments: commentText,
                })
            })

            if (response.status === 200) {

                const responseData = await response.json()
                // console.log("updated comments", responseData)
                // setCommentText(responseData[0].comments)
                // console.log("One Visit visits_data", responseData.visits_data)
                setButtonEnabled(false)
                
            } else {
                console.log("Failed to update patient comments", response)
                if (!response.ok) {
                    throw new Error('Unprocessable Entity: ' + response.statusText);
               }
            }
        } catch (e) {
            console.log("Failed to update patient comments", e)
            setShowError(true);

        }
        
    }
    const saveComment = () => {
        updateComments()
    }

    const textChanged = (event) => {
        setCommentText(event.target.value)
        setButtonEnabled(true)
        // props.onNoteChange(event.target.value)
    }



    return (
        <>
            <ErrorDialogModal
                setOpen={showError}
                close={() => setShowError(false)}
                componentName="Patient Comments"
            />
        
        <Form className='visit-dialog-form onevisit-bordered'>
            <div className='visit-dialog-form-header'>
                <div className='d-flex justify-content-between'>
                    <div className='inline-block text-left'>
                        <BsJournalText className='visit-detail-icon' />
                        Comments
                    </div>
                    <div className="inline-block text-right">
                        <Button className="mx-2" variant="primary" onClick={saveComment} disabled={!buttonEnabled}>
                            Save Comments
                        </Button>
                    </div>
                </div>
            </div>
            
            <FormGroup className='visit-dialog-form-group'>
                {/* <FormLabel>Visit Notes</FormLabel> */}
            
                    <Form.Control 
                        as="textarea" 
                        rows={3} 
                        value={commentText}
                        onChange={textChanged}
                        />

            </FormGroup>

        </Form>
    </>
    )
}

export default PatientComments
