import { createContext, useState, useContext, useEffect } from 'react';

const CompanyContext = createContext();

export const CompanyContextProvider = ({ children }) => {


    const defaultValue = {
        company_name: '',
        company_code: '',
        auth_mode: '',
        auto_logout_minutes: 999,
        inactivity_timer_minutes: 997,
        patient_search_mode: '',
        is_active: false,
        id: 0,
        allowed_origin_host: [],
        note_api_mode: null,
        created_at: null,
        updated_at: null,
    }


    const [company, setCompany] = useState(defaultValue)

    return (
        <CompanyContext.Provider
            value={{
                company: company,
                setCompany: setCompany,
            }}
        >
            {children}
        </CompanyContext.Provider>
    )
}

export default CompanyContext;