import React, {useState, useContext, useEffect, useRef} from 'react'
import UserPrefContext from '../../../context/userPrefContext'
import DictionaryContext from '../../../context/dictionaryContext'
import AuthContext from '../../../context/authContext'
import { useOktaAuth } from '@okta/okta-react'
import Table from 'react-bootstrap/Table'
import FaceInstrumentModal from '../faceinstr/FaceInstrumentModal'
import CompanyContext from '../../../context/companyContext'
import jwt_decode from 'jwt-decode'
import ErrorDialogModal from '../modals/ErrorDialogModal'
import Logout from '../../../Logout'

function Ipad() {
    const { authState, oktaAuth } = useOktaAuth()
    const componentMounted = useRef(true)
    const companyCtx = useContext(CompanyContext)
    const userPrefCtx = useContext(UserPrefContext)
    const dictContext = useContext(DictionaryContext)
    const authCtx = useContext(AuthContext)
    

    const [eforms, setEforms] = useState([])

    const [panel_name, setPanelName] = useState('')
    const [selectedPatient, setSelectedPatient] = useState(null)

    const [showError, setShowError] = useState(false)
    const [doLogout, setDoLogout] = useState(false)

    useEffect(() => {
        // console.log('Mounting iPad #1 - mounted=', componentMounted.current, 'userPrefCtx.bearerToken', userPrefCtx.bearerToken)
        // console.log("iPad useEffect 1 - authState", authState)
        componentMounted.current = true

        if (userPrefCtx.bearerToken === '') {
            if (!authState || !authState.isAuthenticated) {
                // console.log("iPad - not authenticated")

            } else {
                if (componentMounted.current) {
                    oktaAuth
                        .getUser()
                        .then((info) => {
                            if (componentMounted.current) {
                                userPrefCtx.setUserGroups(authState.accessToken.claims.groups)
                                userPrefCtx.setBearerTokenFunction(oktaAuth.getAccessToken())
                            }
                            
                        })
                        .catch((err) => {
                            console.error(err);
                        })
                }
            }
        }
        

        return () => {
            // console.log("iPad - unmounting useEffect #1")
            componentMounted.current = false
        }
    }, [authState, oktaAuth])



    useEffect(() => {
        // console.log("iPad useEffect 2 - companyCtx.company.auth_mode", companyCtx.company.auth_mode, "authCtx.authMode", authCtx.authMode)
        if (!!companyCtx.company.auth_mode && !!!authCtx.authMode) {
            if (companyCtx.company.auth_mode === 'OKTA') {
                authCtx.setAuthMode(companyCtx.company.auth_mode)
            } else {
                // check if session should be restored after page reload 
                dbAuthRestore(companyCtx.company.auth_mode)
            }
        }

        
    }, [companyCtx.company.auth_mode, authCtx.authMode])







    useEffect(() => {
        // console.log("iPad useEffect 3 - userPrefCtx.bearerToken", userPrefCtx.bearerToken)
        componentMounted.current = true
        // console.log('Mounting iPad #2. userPrefCtx.bearerToken', userPrefCtx.bearerToken)
        if (userPrefCtx.bearerToken !== '') {
            // console.log("iPad - #2 dictContext.dict_modal", dictContext.dict_modal)
            if (dictContext.dict_modal.length === 0) {
                loadDictionaries()
            }
        }

        return () => {
            // console.log("iPad - unmounting useEffect #2")
            componentMounted.current = false
        }
    }, [userPrefCtx.bearerToken])


    useEffect(() => {
        // console.log("iPad useEffect 4 - dictContext?.dict_modal?.length", dictContext?.dict_modal?.length, "authCtx.authMode", authCtx.authMode)
        componentMounted.current = true
        // console.log("iPad - mounting useEffect #3 - dictContext.dict_modal.length", dictContext.dict_modal.length)
        if (!!dictContext?.dict_modal?.length && !!authCtx.authMode) {
            getEforms()
        }

        return () => {
            // console.log("iPad - unmounting useEffect #3")
            componentMounted.current = false
        }
    }, [dictContext?.dict_modal?.length, authCtx.authMode])




    const loadDictionaries = async () => {
        const dictURL = `${process.env.REACT_APP_SERVER}/api/v1/dict_services/visit`
        const headers = { Authorization: `Bearer ${userPrefCtx.bearerToken}`, auth: authCtx.authMode, }

        // console.log("iPad - loading dictionaries: URL", dictURL, "headers", headers)
        const response = await fetch(dictURL, { headers })
            .then((res) => res.json())
            .then((data) => {
                // console.log("iPad - got dictionaries", data)
                if (componentMounted.current){ 

                    // console.log("iPad - settign up dictionaries")

                    dictContext.setAllDictionaries(data)
                }
                
            })
            .catch((err) => {
                console.log(err.message);
            })
    }




    const getEforms = async () => {

        const eformsURL = `${process.env.REACT_APP_SERVER}/api/v1/eform_requests/get_all_forms`
        // console.log("Ipad - bearer", userPrefCtx.bearerToken)
        // const headers = () => { return {authorization: `Bearer ${userPrefCtx.bearerToken}`, auth: authCtx.authMode, }}
        const headers = { Authorization: `Bearer ${userPrefCtx.bearerToken}`, auth: authCtx.authMode, }
        // console.log("iPad - getting eforms - headers", headers)
        const response = await fetch(eformsURL, {
            // headers: headers(),
            headers
        })
        .then((res) => res.json())
        .then((data) => {
            // console.log("Ipad - got eforms data", data)
            let pendingRequests = data
                .filter(ef => ef.EformRequest.completed_date === null)
                .sort((a,b) => new Date(a.EformRequest.created_at) - new Date(b.EformRequest.created_at))

            // console.log("Ipad - got eforms", pendingRequests)
            if (componentMounted.current) {
                // console.log("Ipad - settign up eforms")
                setEforms(pendingRequests)
            }
            
        })
        .catch((err) => {
            console.log(err.message);
        })
    }





    const patientSelected = (event) => {

        let all_tds = null
        let tr = null
        if (event.target.tagName === 'TD') {
            tr = event.target.parentNode
            all_tds = event.target.parentNode.querySelectorAll("td")
        } else if (event.target.tagName === 'TR') {
            tr = event.target
            all_tds = event.target.querySelectorAll("td")
        }
        
        let patient = {

        }
        if (!!tr) {
            const requestId = parseInt(tr.getAttribute('data-request-id'))
            const index = parseInt(tr.getAttribute('data-index'))
            patient.form_id = requestId

            // console.log("ipad - selected: index", index, "id", requestId)
            // find by index
            // console.log("Ipad - found by index", eforms[index])
            // find by id
            const pat = eforms.find(ef => ef.EformRequest.id === requestId)
            patient.patient_token = pat.Patient.patient_token
            // console.log("Ipad - found by id", pat)
        }

        all_tds.forEach(td => {
            // console.log("td.getAttribute('data-col')", td.getAttribute('data-col'))
            // console.log("td.value", td.textContent)
            patient[td.getAttribute('data-col')] = td.textContent
        })

        // console.log("Ipad - patient selected:", patient)
        setSelectedPatient(patient)
        setPanelName(`${patient.form_name}_panel`)
    }




    const isTokenExpired = token => {
        if (!!!token) {
            // console.log("isTokenExpired - yes (no token)", token)
            return true
        }

        let decoded = jwt_decode(token)
        if (!!!decoded.exp) {
            // console.log("isTokenExpired - yes (decoded.exp is null)", decoded.exp)
            return true
        }

        // console.log("isTokenExpired - new Date(decoded.exp*1000) < new Date()", new Date(decoded.exp*1000) < new Date())
        return new Date(decoded.exp*1000) < new Date()
    }

    const dbAuthRestore = (authMode) => {
        try {
            let data = localStorage.getItem("db-auth-access-return")
            // console.log("restoring Session - local storage", data, "!!data", !!data)
            if (!!data) {
                data = JSON.parse(data)
                // console.log("restoring Session - parsed data", data, "!!data.access_token", !!data.access_token, "data.access_token", data.access_token)
                
                if (!!data.access_token && !isTokenExpired(data.access_token)) {                    
                    // console.log("restoring Session - data", data)
                    // userPreferenceCtx.setBearerTokenFunction(data.access_token)
                    authCtx.setToken(data.access_token)
                    authCtx.setIsAuthenticated(true)
                    
                    authCtx.setAuthMode(authMode)

                    const uInfo = {
                        first_name: data.first_name,
                        last_name: data.last_name,
                        is_him_user: data.is_him_user,
                        is_ipad_user: data.is_ipad_user,
                        is_readonly: data.is_readonly,
                        is_site_admin: data.is_site_admin,
                        is_superuser: data.is_superuser,
                        is_writeuser: data.is_writeuser,
                        name: data.first_name + ' ' + data.last_name,
                        // email: 'unknown',
                        // email_verified: false,
                        family_name: data.last_name,
                        given_name: data.first_name,
                        // locale: '',
                        // preferred_username: '',
                        // zoneinfo: '',
                    }
        
                    authCtx.setUserInfo(uInfo)
                    // setUserInfo(uInfo)
                    // setIsAuthenticated(true)
                    // setToken(data.accessToken)

                    if (userPrefCtx.bearerToken !== data.access_token) {
                        userPrefCtx.setBearerTokenFunction(data.access_token)
                    }
                }
            }
            
        }
        catch (e) {
            console.log(e)
            setShowError(true)
        }
        
    }


    const loggedOut = () => {
        setDoLogout(false)
    }

    const onErrorClosed = () => {
        setShowError(false)
        setDoLogout(true)
    }

    return (
        <div>
            <ErrorDialogModal
                setOpen={showError}
                close={onErrorClosed}
                componentName="iPad Screen"
            />
            <Logout doLogout={doLogout} onLoggedOut={loggedOut} />
            {/* <h1>Massachusetts Eye and Ear</h1> */}

            <h3>Please Select Patient</h3>

            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>MRN</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>DOB</th>
                        <th>Form Name</th>
                        <th>Created Date</th>
                    </tr>
                </thead>
                <tbody>
                    {eforms.map((ef, ef_ix)=> {
                        return (
                            <tr key={ef_ix} onClick={patientSelected} data-request-id={ef.EformRequest.id} data-index={ef_ix}>
                                <td data-col='mrn'>{ef.Patient.emr_mrn}</td>
                                <td data-col='first_name'>{ef.Patient.first_name}</td>
                                <td data-col='last_name'>{ef.Patient.last_name}</td>
                                <td data-col='dob'>{new Date(ef.Patient.dob).toISOString().split('T')[0]}</td>
                                <td data-col='form_name'>{ef.EformRequest.form_name}</td>
                                <td data-col='created_date'>{ef.EformRequest.created_at.replace('T',' ').substring(0,19)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>


            {panel_name === "face_instrument_panel" && (
                <FaceInstrumentModal
                    mode='ipad'
                    // show={panel_name === "face_instrument_panel"}
                    panel_name={panel_name}
                    patient={selectedPatient}
                />
                )}
            
        </div>
    )
}

export default Ipad
