import React, { useState, useEffect, useContext, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ErrorDialogModal from '../ErrorDialogModal';
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import UserPrefContext from '../../../../context/userPrefContext';
import AuthContext from '../../../../context/authContext';
import classes from './AddDiagnosis.module.css'
//import FPdiagModal from './FPdiagModal';
//import OtherdiagModal from './OtherdiagModal';


function ChangeUserPW(props) {

  const userPrefCtx = useContext(UserPrefContext);
  const authCtx = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false)
  const [info, setInfo] = useState();
  const [groupNameList, setGroupNameList] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [diagTypeList, setdiagTypeList] = useState();

  const [groupNameValue, setGroupNameValue] = useState();
  const [diagTypeValue, setDiagTypeValue] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [openFPModal, setOpenFPModal] = useState(false);

  const [openOtherModal, setOpenOtherModal] = useState(false);

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmNewPasswordRef = useRef(null);


  //sending false back to the NewDictDiag modal to eventually close that modal when this modal is called
  //props.func(false);
  // useEffect(() => {


  // }, [props.sendDictDashboard?.dict_diag]);
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };



   const postAddUser = async (e) => {

    let currentNewPasswordRef =  newPasswordRef.current.value;
    let currentConfirmNewPasswordRef =  confirmNewPasswordRef.current.value;


    if(currentNewPasswordRef === currentConfirmNewPasswordRef){
      //console.log('they match!');

      setErrorMessage(null)
    
      // console.log(e)
    
      let saveUserURL = `${process.env.REACT_APP_SERVER}/api/v1/users/${props.sendIDToPW}`;
      //let saveUserURL = '';

      //console.log(props.sendObject)

      let oldObject = props.sendObject;

      oldObject.push({password: currentNewPasswordRef})

      const mergedObject = oldObject.reduce((result, currentObject) => {
        return { ...result, ...currentObject };
      }, {});
    
      //console.log(mergedObject)
    
      const sendObject = JSON.stringify(mergedObject);

    
      const responseData = await fetch(saveUserURL, {
        method: 'PATCH',
         body: sendObject,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userPrefCtx.bearerToken}`,
          auth: authCtx.authMode
        },
      //}).then((res) => res.json())
    }).then((res) => {
      // Check for 422 or 500 response
      if (!res.ok) {
        // if (res.status === 422) {
         throw new Error('Unprocessable Entity: ' + res.statusText);
        // } else if (res.status === 500) {
        //   throw new Error('Internal Server Error: ' + res.statusText);
        // } else {
        //   throw new Error('Unknown Error Occurred.');
        // }
        // setShowError(true);
      }
      return res.json();
    })
      .then((data) => {
         //console.log(data);
         //props.sendTrigger(data);
         props.close();
      })
      .catch((err) => {
         console.log(err.message);
         setShowError(true);
      });
    }
    else{
      console.log('dont match try again');
      setErrorMessage('Passwords do not match, please try again')
    }


  }

  const selectGroupName = (e) => {
    console.log('group name is: ', e.target.value)
    setGroupNameValue(e.target.value)

  }

  const selectDiagType = (e) => {
    console.log('diag type is: ', e.target.value)
    setDiagTypeValue(e.target.value)

  }

  return (
    <>
      <ErrorDialogModal
        setOpen={showError}
        close={() => setShowError(false)}
        componentName="Change User Password"
      />
      <Modal 
        //size="xl" 
        size="lg"
        show={props.setOpen} 
        onHide={props.close} 
        animation={false}
        backdrop="static"
        //style={{ zIndex: '9999'}}
        //dialogClassName={{ zIndex: '9999'}}
        dialogClassName={classes.custom_modal}
        style={{ visibility: (showError) ? 'hidden' : 'visible' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p>Enter Current Password</p>
          <Form.Control aria-label="Default select example" type="password" ref={passwordRef} /> */}
          <p>Enter New Password</p>
          <div className="password-input-container">
            <Form.Control aria-label="Default select example" type={showPassword1 ? 'text' : 'password'} ref={newPasswordRef} />
            <div className="password-toggle-dashboard" onClick={togglePasswordVisibility1}>
              {showPassword1 ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
          </div>
          </div>
          <p>Confirm New Password</p>
          <div className="password-input-container">
            <Form.Control aria-label="Default select example" type={showPassword2 ? 'text' : 'password'} ref={confirmNewPasswordRef} />
            <div className="password-toggle-dashboard" onClick={togglePasswordVisibility2}>
              {showPassword2 ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
          </div>
          </div>
          <p className="error_message_red_color">
          {errorMessage}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
          </Button>
          <Button variant="primary" onClick={postAddUser}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChangeUserPW;