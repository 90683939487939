import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ErrorDialogModal from '../ErrorDialogModal';
import UserPrefContext from '../../../../context/userPrefContext';
import AuthContext from '../../../../context/authContext';

function PromptDictInterventionDelete(props) {
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);


  //console.log(props)

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }

  const userPrefCtx = useContext(UserPrefContext);
  const authCtx = useContext(AuthContext);

  //console.log(visCtx.pat_bearer_token)

  //const ptCtx = PatientContextProvider;

  //console.log(ptCtx)

  const deleteDictDiag = async () => { 

        //console.log(userPrefCtx.bearerToken);

        const deleteDictDiagURL = process.env.REACT_APP_SERVER + `/api/v1/dict_interventions/` + props.sendDeleteID;

        const responseData = await fetch(deleteDictDiagURL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userPrefCtx.bearerToken}`,
            auth: authCtx.authMode
            //Authorization: `Bearer ${promptCtx.pat_bearer_token}`,
          },
        })
        //.then((res) => res.json())
        .then((res) => {
          // Check for 422 or 500 response
          if (!res.ok) {
               throw new Error('Unprocessable Entity: ' + res.statusText);
          }
          return res.json();
        })
        .then((data) => {
           //console.log(data);
           //This will send the data back to the NewDictDiag to trigger a delete in the dashboard
           props.sendToParent(data);
           //once it successfully deletes the diag, it will close the modal
           props.close();
        })
        .catch((err) => {
           //console.log(err.message);
           setShowError(true);
        });
  
  }


  return (
    <>
        <ErrorDialogModal
          setOpen={showError}
          close={() => setShowError(false)}
          componentName="Delete Dict Intervention"
        />
      <Modal size="xl" show={props.setOpen} onHide={props.close} animation={false} 
        style={{ visibility: (showError) ? 'hidden' : 'visible' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h3>Are you sure you want to delete this?</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
          </Button>
          <Button variant="secondary" onClick={deleteDictDiag}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PromptDictInterventionDelete;