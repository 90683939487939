import { createContext, useState, useContext, useEffect } from 'react';
import UserPrefContext from './userPrefContext';

const DictionaryContext = createContext();

export const DictionaryContextProvider = ({ children }) => {
    const [dictionaries, setDictionaries] = useState(null);
    const { userPreference } = useContext(UserPrefContext);
    const [userDept, setUserDept] = useState('FP');
    const [dictPanelDepts, setDictPanelDepts] = useState({})

    // let dict_visit_types = []

    const dict_department = dictionaries !== null ? dictionaries.dict_department : [];
    const dict_chart = dictionaries !== null ? dictionaries.dict_chart : [];
    const dict_table = dictionaries !== null ? dictionaries.dict_table : [];
    const dict_intake_form_question = dictionaries !== null ? dictionaries.dict_intake_form_question : [];
    const dict_panel = dictionaries !== null ? dictionaries.dict_panel : [];
    const dict_botox_map_src_image = dictionaries !== null ? dictionaries.dict_botox_map_src_image : [];
    const dict_botox_map = dictionaries !== null ? dictionaries.dict_botox_map : [];
    const dict_visit_name = dictionaries !== null ? dictionaries.dict_visit_name : [];
    const dict_intake_form = dictionaries !== null ? dictionaries.dict_intake_form : [];
    const dict_intervention = dictionaries !== null ? dictionaries.dict_intervention : [];
    const dict_diag = dictionaries !== null ? dictionaries.dict_diag : [];
    const dict_modal = dictionaries !== null ? dictionaries.dict_modal : [];
    const dict_site_loc = dictionaries !== null ? dictionaries.dict_site_loc : [];
    const dict_side = dictionaries !== null ? dictionaries.dict_side : [];
    const dict_panel_departments = dictionaries !== null ? dictPanelDepts : {};
    const dict_import_note_provider = dictionaries !== null ? dictionaries.dict_import_note_provider : []

    const dict_visit_types = (
        dictionaries !== null && dictionaries.dict_visit_name !== undefined ? dictionaries.dict_visit_name : []
    ).filter((vt) =>
            vt.user_dept === userDept &&
            vt.dictionary_detail === 'VISIT_TYPE' &&
            vt.dictionary_value !== ''
    ).sort((a, b) => (a.dictionary_value > b.dictionary_value ? 1 : -1))
    

    const setAllDictionaries = (all_dicts) => {
        // console.log("dictionaryContext - setting up dictionaries", all_dicts)
        setDictionaries(all_dicts);
        // set up dict_panel_departments hash table
        let panelDepts = {}
        all_dicts.dict_panel.forEach(dp => {
            panelDepts[dp.display_name_short] = dp.user_dept
        })
        // console.log("Dictionary Context - panelDepts", panelDepts)
        setDictPanelDepts(panelDepts)
        
        // setUserDept('FP')
    };

    const reset = () => {
        setDictionaries(null)
        setDictPanelDepts({})
    }

    // useEffect(() => {
    //     console.log("userPreference.length", userPreference.length)
    //     if (userPreference !== null && userPreference.length > 0 && userPreference.user_dept.length) {
    //         setUserDept(userPreference.user_dept)
    //     }

    // },[dictionaries])

    // useEffect(() => {
    //     // console.log("dictionaryContext - dict_visit_name", dict_visit_name)
    //     // console.log("dictionaryContext - userPreference", userPreference, "userDept", userDept)

    //     dict_visit_types = userDept !== '' ?
    //     dict_visit_name.filter(vt => vt.user_dept === userDept && vt.dictionary_detail === 'VISIT_TYPE' && vt.dictionary_value !== '')
    //     .sort((a,b) => a.dictionary_value > b.dictionary_value ? 1 : -1 )
    //     :
    //     []

    //     console.log("dictionaryContext - dict_visit_types", dict_visit_types)
    // }, [userDept, dict_visit_name])

    // create a dictionary of visit types so we don't do it for every single visit
    //  filter by: user department - from user Pref Context, exclude blank entries, and include only entries of "VISIT_TYPE"
    // sort in alphabetical order

    return (
    <DictionaryContext.Provider
        value={{
        setAllDictionaries,
        dictionaries,
        dict_department,
        dict_chart,
        dict_table,
        dict_intake_form_question,
        dict_panel,
        dict_botox_map_src_image,
        dict_botox_map,
        dict_visit_name,
        dict_intake_form,
        dict_intervention,
        dict_diag,
        dict_modal,
        dict_site_loc,
        dict_visit_types,
        dict_side,
        dict_panel_departments,
        dict_import_note_provider,
        reset,
        }}
    >
        {children}
    </DictionaryContext.Provider>
    );
};

export default DictionaryContext;
