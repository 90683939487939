import { createContext, useState, useContext, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

    const { authState, oktaAuth } = useOktaAuth();

    // auth types so far: DB, OKTA
    const [authMode, setAuthMode] = useState('')
    const [bearerToken, setBearerToken] = useState('')
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    const defaultUserInfo = {
        first_name: '',
        last_name: '',
        is_him_user: false,
        is_ipad_user: false,
        is_readonly: false,
        is_site_admin: false,
        is_superuser: false,
        is_writeuser: false,
        name: '',
        // email: '',
        // email_verified: false,
        family_name: '',
        given_name: '',
        // locale: '',
        // preferred_username: '',
        // zoneinfo: '',
    }

    const [userInfo, setUserInfo] = useState(defaultUserInfo)

    let token = null
    if (authMode === 'OKTA') {
        token = oktaAuth.getAccessToken()
    }
    // oktaAuth.getAccessToken()

    const logout = () => {
        // clear context
        setUserInfo(defaultUserInfo)
        setIsAuthenticated(false)
        setBearerToken('')
        setAuthMode('')
    }

    // const updatedAt = oktaUserInfo !== null ? oktaUserInfo.updated_at : []
    // const zoneInfo = oktaUserInfo !== null ? oktaUserInfo.zoneinfo : []

    return (
        <AuthContext.Provider
            value={{
                token: authMode === 'OKTA' ? oktaAuth.getAccessToken() : bearerToken,
                authMode: authMode,
                isAuthenticated: isAuthenticated,
                userInfo,
                setIsAuthenticated: setIsAuthenticated,
                setUserInfo: setUserInfo,
                setToken: setBearerToken,
                setAuthMode: setAuthMode,
                logout: logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;