import React, { useState, useEffect, useContext } from 'react';
import { format, parse } from 'date-fns'
import EditInterventionModal from '../modals/EditInterventionModal';
import PatientContext from '../../../context/patientContext';
import AuthContext from '../../../context/authContext';
import UserPrefContext from '../../../context/userPrefContext';
import styles from './InterventionPanelDisplayList.module.css'


const InterventionPanelDisplayList = (props) => {

  const [interventionMap, setInterventionMap] = useState();
  const [editInterventionTrigger, setEditInterventionTrigger] = useState();

  const [openEditInterventionModal, setOpenEditInterventionModal] = useState(false);
  

  const [editInterventionID, setEditInterventionID] = useState();
  const [editInterventionDictID, setEditInterventionDictID] = useState();
  const [editInterventionNotes, setEditInterventionNotes] = useState();
  const [editInterventionDate, setEditInterventionDate] = useState();

  const visCtx = useContext(PatientContext);
  const userPrefCtx = useContext(UserPrefContext)
  const authCtx = useContext(AuthContext)

  const interventionsURL = process.env.REACT_APP_SERVER + "/api/v1/interventions/get_all_by_token/" + visCtx.pat_token;

  useEffect(async() => {
    // console.log("InterventionPanelDisplayList - useEffect: userPrefCtx", userPrefCtx)
    if (userPrefCtx) {
      const headers = { Authorization: `Bearer ${userPrefCtx.bearerToken}`, auth: authCtx.authMode };

      try {
        const response = await fetch(interventionsURL, { headers })
        if (response.status === 200) {

          const responseData = await response.json();
            // console.log("Intervention Panel Display List - userPrefCtx.userPreference.intervention_dept", userPrefCtx.userPreference.intervention_dept.constructor);
          // get intervention departements to show 
          const interventionDepartments = userPrefCtx.userPreference.intervention_dept
            // console.log("Intervention Panel Display List - interventionDepartments", interventionDepartments, typeof interventionDepartments);
          //   console.log("Intervention Panel Display List - interventions", responseData);
            // only show interventions according to the user preferences
          const sortInterventionsVar = responseData.filter(idata => interventionDepartments.includes(idata.Intervention.user_dept));
          
          // This is for the sorting of the interventions on the panel by the intervention date
          sortInterventionsVar.sort((a,b) => (b['Intervention'].intervention_date > a['Intervention'].intervention_date) ? 1 : -1);
          setInterventionMap(sortInterventionsVar);

        } else {
          console.log("not 200");
        }
      } catch (e) {
        console.log(e);
      }


    }
    else {
      console.log('no token')
    } //The triggers for the useEffect will be when a new Intervention is created or if it is edited, patient context is needed to refresh the list on search
  }, [props.sendAddIntervention, editInterventionTrigger, visCtx.pat_token, userPrefCtx.userPreference.intervention_dept]);

  const clickInterventionList = (e) => {
    // console.log(e.target.dataset.userdept)
    // console.log(typeof e.target.dataset.userdept)
    if(e.target.dataset.userdept === userPrefCtx.userPreference.user_dept){
      //This function is sending the id to a modal that should be called here
    // console.log('clicked on li, the id is: ', e.target.value);
      //console.log('e.target.getAttribute(data-id) is: ', e.target.getAttribute('data-id'));
      let objectID = e.target.getAttribute('data-id');
      // console.log('e.target.dataset id is ', e.target.dataset.id)
      // console.log('e.target.dataset dict-id is ', e.target.dataset.dictid)
      // console.log('e.target.dataset notes is ', e.target.dataset.notes)
      // console.log('e.target.dataset obj is ', e.target.dataset.obj)
      let obj = JSON.parse(e.target.dataset.obj);
      //console.log(obj);
      //console.log('Intervention: ', obj.Intervention);
      //this will set the id to the usestate to send as a prop
      setEditInterventionDictID(e.target.dataset.dictid)
      setEditInterventionID(e.target.dataset.id)
      setEditInterventionNotes(e.target.dataset.notes)
      setEditInterventionDate(e.target.dataset.date)
      setOpenEditInterventionModal(true);
    }
  }

  const getEditInterventionfromChild = (data) => {
    setEditInterventionTrigger(data)
    //console.log(data);
  }

  const closeEditModal = (data) => {
    setOpenEditInterventionModal(false)
  }


  
  return (
    <div className={styles.main_intv_div}>
      <EditInterventionModal 
        key={Math.random()} 
        sendDictVisit={props.sendDictVisit}
        sendDictID={editInterventionDictID}
        sendID={editInterventionID}  
        sendNotes={editInterventionNotes}
        sendDate={editInterventionDate}
        setOpen={openEditInterventionModal}  
        getChild={getEditInterventionfromChild}
        //don't take out testprop, the editdiagnosisprompt is somehow running because of it
        //testProp={interventionMap}
        //close={() => setOpenEditInterventionModal(false)} 
        close={closeEditModal}
      />
      <ul key={'ul'} className={styles.intervention_list}>
      { interventionMap != null &&
        Object.keys(interventionMap).map((item, i) => (
        <div key={'div1'+i}>
          {/* <ul key={'ul'+i}> */}
            <li 
              value={interventionMap[item]['DictIntervention'].id} 
              data-id={interventionMap[item]['Intervention'].id}
              data-dictid={interventionMap[item]['DictIntervention'].id}
              data-notes={interventionMap[item]['Intervention'].notes}
              //data-userdept={interventionMap[item]['DictIntervention'].user_dept}
              data-userdept={interventionMap[item]['Intervention'].user_dept}
              // data-date={format(parse(interventionMap[item]['Intervention'].intervention_date.slice(0,10), 'yyyy-MM-dd', new Date()),'MM/dd/yyyy')}
              data-date={parse(interventionMap[item]['Intervention'].intervention_date.slice(0,10), 'yyyy-MM-dd', new Date())}
              //I'm sending the full object by stringifying it and will JSON Parse in the function
              data-obj={JSON.stringify(interventionMap[item])}
              //data-id={{id: JSON.stringify(interventionMap[item]['DictIntervention'].id), notes: interventionMap[item]['Intervention'].notes}}
              onClick={clickInterventionList}
              key={i}
              //className={styles.intervention_list_item}
              // className={interventionMap[item]['DictIntervention'].user_dept !== userPrefCtx.userPreference.user_dept ? styles.muted : styles.inherit}
              className={interventionMap[item]['Intervention'].user_dept !== userPrefCtx.userPreference.user_dept ? styles.muted : styles.inherit}
            >
              {interventionMap[item]['DictIntervention'].display_name_short}
              ({format(parse(interventionMap[item]['Intervention'].intervention_date.slice(0,10), 'yyyy-MM-dd', new Date()),'MM/dd/yyyy')})
            </li>
          {/* This is making sure that the comparison doesnt go out of bounds since the array starts at 0 */}
        { (item < (interventionMap.length - 1)) &&
          <div key={'div2'+i}>

          {/* This is comparing the current date to the next date in the array to see if there is a difference */}
          { format(parse(interventionMap[item]['Intervention'].intervention_date.slice(0,10), 'yyyy-MM-dd', new Date()),'MM/dd/yyyy') !=
              format(parse(interventionMap[++item]['Intervention'].intervention_date.slice(0,10), 'yyyy-MM-dd', new Date()),'MM/dd/yyyy') &&
              <div key={'div3'+i}>

              {/* The hr is creating a line below each of the different dates */}
              <hr key={'hr'+i} className={styles.hr_line}></hr>
              </div>
            }     
            </div>
          }
            {/* </ul> */}
        </div>
        ))
    } 
</ul>
    </div>
  );
};

export default InterventionPanelDisplayList;