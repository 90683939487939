import React, { useState, useRef, useContext, useEffect, Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BsFillPlusSquareFill } from "react-icons/bs";
import InterventionCalendar from "../calendar/InterventionCalendar";
import PatientContext from "../../../context/patientContext";
import AuthContext from "../../../context/authContext";
import ErrorDialogModal from "./ErrorDialogModal";
import UserPrefContext from "../../../context/userPrefContext";
import DictionaryContext from "../../../context/dictionaryContext";

function InterventionModal(props) {
  const [show, setShow] = useState(false);
  const [interventionID, setInterventionID] = useState();
  const [interventionDate, setInterventionDate] = useState();
  const [showError, setShowError] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const noteRef = useRef(null);

  //Using context to pass the patient token
  const interventionCtx = useContext(PatientContext);
  const authCtx = useContext(AuthContext)
  const userPrefCtx = useContext(UserPrefContext)
  const dictCtx = useContext(DictionaryContext)

  const dictType = dictCtx.dict_intervention;
  //console.log(dictType)

  const [isMounted, setIsMounted] = useState(true);

useEffect(() => {
  setIsMounted(true);
  return () => setIsMounted(false); // This cleanup function runs when the component is unmounted
}, []);

  var intArray = [];

  intArray.push(
    /*
      https://www.geeksforgeeks.org/lodash-_-groupby-method/
      This is a grouping function to get all the group display names and put into an array for the dropdown
    */
    _.groupBy(dictType, function (n) {
      //console.log('n.id inside the push',n.id)
      return n.group_display_name;
    })
  );
  //console.log(intArray);

  //This is the result of the groupby function, it is in an array, so I take the first node which countains all the display names
  let displayResult = intArray[0];
  //console.log(displayResult)

  const clickDropDown = (e) => {
    //console.log(e.target.value);
    //This will change the id based on what invervention is selected in the dropdown
    setInterventionID(e.target.value);
    if (e.target.value === "") {
      console.log("its blank");
    }
  };

  const getInterventionDate = (e) => {
    // This is getting the date from the intervention calendar child component
    setInterventionDate(e);
    //console.log(e);
  };

  const saveIntervention = async (e) => {
    //This is the function to post the intervention to the database
    if (
      interventionID === "" ||
      interventionID === null ||
      interventionID === undefined
    ) {
      alert("Please select an item in the list");
    } else {
      //console.log(interventionID);
      //console.log('save intervention function')
      //Setting this to the value of what's type in the notes section
      let noteValue = noteRef.current.value;
      //console.log(noteValue)

      //This is to get the string in the proper format
      var strDate = interventionDate.toLocaleDateString("en-US");
      //console.log(strDate)

      strDate = strDate.split("/");
      var dateResult = strDate[2] + "-" + strDate[0] + "-" + strDate[1];
      //console.log("The date is: ", dateResult);

      var strTime = interventionDate.toLocaleTimeString(`en-US`);
      //console.log(interventionDate.toLocaleTimeString(`en-US`))
      strTime = strTime.split(" ");
      var timeResult = strTime[0];
      //This will be the timestamp that will be sent to the db
      var fullTimeStamp = dateResult + " " + timeResult;
      //console.log(fullTimeStamp);

      const postInterventionURL =
        process.env.REACT_APP_SERVER +
        `/api/v1/interventions/` +
        interventionCtx.pat_token;

      const responseData = await fetch(postInterventionURL, {
        method: "POST",
        body: JSON.stringify({
          intervention_date: `${fullTimeStamp}`,
          notes: `${noteValue}`,
          dict_intervention_id: interventionID,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userPrefCtx.bearerToken}`,
          auth: authCtx.authMode,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data);
          //Sending data back to the visit panels so that it can trigger a rerender in the intervention list
          props.getChild(data);
          //This is to close the Modal after saving
          handleClose();
        })
        .catch((err) => {
          console.log(err.message);
          setShowError(true);
        });

      // //This is to close the Modal after saving
      // handleClose();
    }
  };

  return (
    <>
      { props.sendDiagList?.length > 0 &&
        <BsFillPlusSquareFill onClick={handleShow} className="icon-pointer"/>
      }  
      { props.sendDiagList?.length <= 0 &&
        <p>(Please Create Diagnosis First)</p>
      }
        <ErrorDialogModal
          setOpen={showError}
          close={() => setShowError(false)}
          componentName="Intervention"
        />

      <Modal show={show} onHide={handleClose} animation={false}
        style={{ visibility: (showError) ? 'hidden' : 'visible' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Intervention Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Intervention</h4>
          <Form.Select
            aria-label="Default select example"
            onChange={clickDropDown}
          >
            <option></option>
            {Object.entries(displayResult).map(([key, value], index) => {
              return (
                <React.Fragment key={index}>
                  <optgroup label={key} key={index}></optgroup>
                  {value.map((x, y) => (
                    <option value={JSON.stringify(value[y].id)} key={y}>
                      {" "}
                      {value[y].display_name}
                    </option>
                  ))}
                </React.Fragment>
              );
            })}
          </Form.Select>
          <InterventionCalendar func={getInterventionDate} />
          <h4>Notes</h4>
          <Form.Control
            onSubmit={saveIntervention}
            ref={noteRef}
            size="lg"
            type="text"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={saveIntervention}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InterventionModal;
