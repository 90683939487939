import React, {useContext, useEffect, useState} from 'react'
import PatientContext from '../../../context/patientContext'
import UserPrefContext from '../../../context/userPrefContext'
import AuthContext from '../../../context/authContext'
import {toLocalDate} from '../../../utils/Utils'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import VisitImage from './VisitImage'
import VisitVideo from './VisitVideo'
import VisitDocument from './VisitDocument'
import VisitType from './VisitType'
import VisitHTMLNote from './VisitHTMLNote'
import moment from 'moment'

// import axios from 'axios'

import { FormControl, FormGroup, FormLabel, FormText } from 'react-bootstrap'



function OneVisit(props) {

    const patCtx = useContext(PatientContext)
    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)
    const getVisitWithMediaURL = id => `${process.env.REACT_APP_SERVER}/api/v1/visits/with_media/${id}`
    const updateVisitURL = (id) => `${process.env.REACT_APP_SERVER}/api/v1/visits/${id}`

    const [visit, setVisit] = useState(null)
    const [imgArray, setImgArray] = useState([]) 
    const [videoArray, setVideoArray] = useState([]) 
    const [docArray, setDocArray] = useState([]) 


    const httpHeaders = (bearerToken) => {
        return {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`,
            accept: "application/json",
            auth: authCtx.authMode,
        }
    }


    // only show images, vidoes and documents from departments in selected in User Preferences
    const image_dept = userPrefCtx.userPreference.image_dept
    const video_dept = userPrefCtx.userPreference.video_dept
    const document_dept = userPrefCtx.userPreference.document_dept
    // console.log("One visit - props.visit", props.visit)

    useEffect(() => {
        // console.log("One Visit userPrefCtx", userPrefCtx)

        // axios.defaults.headers.common['Authorization'] = `Bearer ${userPrefCtx.bearerToken}`;

        

        // get visit with media
        let ignoreVisit = false
        const getVisit = async() => {
            try {
                const response = await fetch(getVisitWithMediaURL(props.visit.Visit.id), {
                    // headers: {
                    //     // 'Authorization': `Bearer ${token}`,
                    //     'Authorization': `Bearer ${userPrefCtx.bearerToken}`,
                    // },
                    headers: httpHeaders(userPrefCtx.bearerToken),
                })
    
                if (response.status === 200) {
                    if (!ignoreVisit) {
                        const responseData = await response.json()
                        // console.log("One visit", responseData)

                        setVisit(responseData.visits_data)
                        // console.log("One Visit visits_data", responseData.visits_data)
                    }
                    
                } else {
                    console.log("Failed to get visit", response)
                }
            } catch (e) {
                console.log("Failed to get visit", e)
            }
    
        }

        // this is used along with api api/v1/visits/get_all_by_token in AllVisits (a.k.a. old way)
        getVisit()
        // this is used along with api api/v1/visits/get_all_by_token_with_media in AllVisits (a.k.a. new way)
        // setVisit(props.visit)

        return () => {
            ignoreVisit = true
        }

    }, [props.visit])


    // split array of images into array of arrays of 4
    useEffect(() => {
        // console.log("One Visit useEffect b visit - visit:", visit)
        if (!!visit && !!visit.Images) {
            const splitSize = 4
            let images = []
            // do not show images from departments not in image_dept array
            const visit_images = visit.Images.filter(vimg => image_dept.includes(vimg.user_dept)).sort((a,b) => new Date(a.created_at) - new Date(b.created_at) )
            // for (let i = 0; i < visit.Images.length; i += splitSize) {
            //      images.push(visit.Images.slice(i, i + splitSize));
            // }
            for (let i = 0; i < visit_images.length; i += splitSize) {
                images.push(visit_images.slice(i, i + splitSize));
           }
            setImgArray(images)
        }
        
        // console.log("OneVisit - visit", visit)
        if (visit !== null && visit.video !== null) {
            const splitSize = 4
            let videos = []
            // do not show video from departments not in video_dept array
            const visit_videos = visit.video.filter(vvid => video_dept.includes(vvid.user_dept)).sort((a,b) => new Date(a.created_at) - new Date(b.created_at) )
            // for (let i = 0; i < visit.video.length; i += splitSize) {
            //      videos.push(visit.video.slice(i, i + splitSize));
            // }
            for (let i = 0; i < visit_videos.length; i += splitSize) {
                videos.push(visit_videos.slice(i, i + splitSize));
            }
            setVideoArray(videos)
        }

        if (visit !== null && visit.Document !== null) {
            const splitSize = 4
            let documents = []
            // do not show video from departments not in video_dept array
            const visit_docs = visit.Document.filter(vdoc => document_dept.includes(vdoc.user_dept)).sort((a,b) => new Date(a.created_at) - new Date(b.created_at) )
            // for (let i = 0; i < visit.Document.length; i += splitSize) {
            //      documents.push(visit.Document.slice(i, i + splitSize));
            // }
            for (let i = 0; i < visit_docs.length; i += splitSize) {
                documents.push(visit_docs.slice(i, i + splitSize));
           }
            setDocArray(documents)
        }

    }, [visit, userPrefCtx.userPreference.image_dept, userPrefCtx.userPreference.video_dept])

    const visitSelected = () => {
        // console.log("One Visit - visit slected with ID", props.visit.Visit.id)
        props.onVisitSelect(props.visit.Visit.id)
    }


    useEffect(() => {
        // need to re-render component
    }, [props.selectedImages])


    const visitTypeChanged = (new_visit_type) => {
        updateVisit(new_visit_type)
    }



    const updateVisit = async(new_visit_type) => {
        try {            
            // const formattedDate = visitDate.toISOString().split('T')[0]

            let httpMethod = "PATCH"
            let url=updateVisitURL(props.visit.Visit.id)
            const body = JSON.stringify({
                "visit_date": props.visit.Visit.visit_date,
                "visit_type": new_visit_type,
                "is_active": props.visit.Visit.is_active,
                "notes": []

            })

            // console.log("OneVisit updating visit: body", body, "url", url)

            // axios.patch(url, body)
            // .then(response => {
            //     // Handle response
            //     console.log(response.data);
            // })
            // .catch(err => {
            //     // Handle errors
            //     console.error(err);
            // });





            const response = await fetch(url, {
                headers: httpHeaders(userPrefCtx.bearerToken), //{'Authorization': `Bearer ${userPrefCtx.bearerToken}`},
                method: httpMethod,
                body: body,
            })

            if (response.status === 200) {
                const responseData = await response.json()
                // console.log("Created/updated e-Face", responseData)
                

            } else {
                console.log("Failed to update visit", response)
            }
        } catch (e) {
            console.log("Failed to update visit", e)
        }

    }


    // visit image selected/un-selected
    const visitImageSelected = (image, selected) => {
        props.visitImageSelected(image, selected)
    }


    // if visit is not set don't render
    // console.log("One Visit we have a visit (or not): ", visit)
    if (!!!visit || (!!visit && Object.keys(visit).length === 0)) {
        return (<></>)
    }
    // console.log("One Visit we have a visit: ", visit)
    return (
            

            <div className='onevisit-bordered'>
                <Container>
                    <Row className='d-flex align-items-center'>
                        <Col xs={4} md={3}>
                            <Button /*className='mx-2'*/
                                onClick={() => props.enlightenPanelButtonClicked()}
                                disabled={props.selectedImages.length < 1}
                            >
                                Enlighten Panel
                            </Button>
                        </Col>
                        <Col xs={4} md={3}>
                            <span /*className='one-visit-align-hor'*/ onClick={visitSelected}>
                                {/* <BsCalendar4 /> */}
                                <span>
                                {/* {visit.Visit.id} */}
                                 Visit Date: 
                                </span>
                                {/* <span className='one-visit-link'>{toLocalDate(visit.Visit.visit_date)}</span> */}
                                <span className='one-visit-link'>{moment(visit.Visit.visit_date).format('MM/DD/YYYY')}</span>
                            </span>
                        </Col>
                        <Col xs={4} md={6}>
                        {(!!visit.Visit.epic_visit_type === false && userPrefCtx.userPreference.user_dept === visit.Visit.user_dept) &&
                            <VisitType visit_type={visit.Visit.visit_type} onSelect={new_visit_type => visitTypeChanged(new_visit_type)}/>
                        }

                        {(!!visit.Visit.epic_visit_type || userPrefCtx.userPreference.user_dept !== visit.Visit.user_dept) &&
                            <FormGroup className='row'>
                                <FormLabel className='col-form-label col-sm-4 my-0'>Visit Type:</FormLabel>
                                <FormText className='col-sm-8 my-2'>{visit.Visit.visit_type}</FormText>
                            </FormGroup>
                        }

                        </Col>
                    </Row>
                    {visit.Note.length > 0 && 
                        <Row >
                            <Col xs={12}>
                            <VisitHTMLNote visit={visit}/>
                            {/* Note: <span className='text-start one-note-short-note'>{visit.Note[0].note_plain !== null ? visit.Note[0].note_plain : visit.Note[0].note_html}</span> */}
                            </Col>
                        </Row>
                    }
                    {imgArray.length > 0 && 
                       imgArray.map((img_row, row_ix) => {
                            // output images 4 in a row
                                return (
                                    <Row key={row_ix}>
                                        {img_row.map((img, img_ix) => {
                                            return (
                                                <Col xs={3} key={img.id} >
                                                    <VisitImage 
                                                        mode='thumb'
                                                        image={img} 
                                                        // key={img.id} 
                                                        patient_id={visit.Visit.patient_id}
                                                        onImageSelect={(img) => props.onImageSelect(img) }
                                                        isImageSelected={props.selectedImages.indexOf(img) >= 0}
                                                    />
                                                </Col>
                                            
                                            )
                                        })}
                                    </Row>
                                )
                            })
                    }

                    {videoArray.length > 0 && 
                       videoArray.map((vid_row, row_ix) => {
                            // output video 4 in a row
                                return (
                                    <Row key={row_ix}>
                                        {vid_row.map((v, v_ix) => {
                                            return (
                                                <Col xs={3} key={v.id} >
                                                    <VisitVideo 
                                                        mode='thumb'
                                                        video={v} 
                                                        // key={img.id} 
                                                        patient_id={visit.Visit.patient_id}/>
                                                </Col>
                                            
                                            )
                                        })}
                                    </Row>
                                )
                            })
                    }
                    
                    {docArray.length > 0 && 
                       docArray.map((doc_row, row_ix) => {
                            // output doc 4 in a row
                                return (
                                    <Row key={row_ix}>
                                        {doc_row.map((d, d_ix) => {
                                            return (
                                                <Col xs={3} key={d.id} >
                                                    <VisitDocument
                                                        mode='inline'
                                                        document={d} 
                                                        // key={img.id} 
                                                        patient_id={visit.Visit.patient_id}/>
                                                </Col>
                                            
                                            )
                                        })}
                                    </Row>
                                )
                            })
                    }
                    
                </Container>
                 

                
            </div>
    )
}

export default OneVisit
