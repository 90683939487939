import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, Redirect, useNavigate, Navigate} from 'react-router-dom';
import PatientContext from '../../../context/patientContext';
import UserPrefContext from '../../../context/userPrefContext';
import AuthContext from '../../../context/authContext';
import CompanyContext from '../../../context/companyContext';
import Table from 'react-bootstrap/Table';
import ErrorDialogModal from '../modals/ErrorDialogModal';

const EpicProviderAppointments = (props) => {

  const navigate = useNavigate();
  const patCtx = useContext(PatientContext);
  const authCtx = useContext(AuthContext)
  const userPrefCtx = useContext(UserPrefContext)
  const companyCtx = useContext(CompanyContext)

  const [showError, setShowError] = useState(false);
  const [appointments, setAppointments] = useState(false);
  
  //console.log(props.sendData);


  const selectMRN = async(e) => {
    //let stringMRN = e.target.getAttribute('data-mrn');
    let stringMRN = e;
    //console.log(stringMRN);

    const token = props.sendBearerToken;

    //This value is stringified
    // let url = `${process.env.REACT_APP_SERVER}/api/v1/search_services/patient_search_with_site_loc?search_token=${stringMRN}&patient_search_mode=${companyCtx.company.patient_search_mode}`;
    let url = `${process.env.REACT_APP_SERVER}/api/v1/search_services/patient_search_with_site_loc_epic?search_token=${stringMRN}&patient_search_mode=${companyCtx.company.patient_search_mode}`;

    if (url != null) {
      const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userPrefCtx.bearerToken}`,
            auth: authCtx.authMode,
          },
        })

        // const jsonResponse = await response.json()
        // if ( jsonResponse.response_code === 1) {
        //   //return jsonResponse.response_data
        //   createPatient(jsonResponse.response_data[0]);
        // }
        .then((res) => {
          if (!res.ok) {
             throw new Error('Unprocessable Entity: ' + res.statusText);
          }
          return res.json();
        })
        .then((data) => {
             //console.log(data)
            createPatient(data.response_data[0]);
        })
        .catch((err) => {
            console.log(err.message)
            //errorDialogComponent
            //setErrorDialogComponent('Set Profile Picture Button')
            setShowError(true);
        });
      }
  }

  const createPatient = async(e) => {

    let postURL = process.env.REACT_APP_SERVER + `/api/v1/patients/creat_patient_with_mrns`;

    // var strdob = e.dob;
    // strdob = strdob.split('/');
    // var dobresult = strdob[2] + '-' + strdob[0] + '-' + strdob[1];
    //console.log(dobresult);

    patCtx.pat_dob = e.dob
    patCtx.pat_dob = new Date(e.dob).toISOString().split("T")[0]
    patCtx.pat_mrn = e.local_site_mrn
    patCtx.pat_first = e.first_name
    patCtx.pat_last = e.last_name
    patCtx.pat_gender = e.gender
    patCtx.pat_email = e.email

    const responseData = await fetch(postURL, {
      method: 'POST',
      body: JSON.stringify({
        "emr_mrn": `${e.local_site_mrn}`,
        "first_name": `${e.first_name}`,
        "last_name": `${e.last_name}`,
        "gender": `${e.gender}`,
        // "dob": `${dobresult}`
        dob: new Date(e.dob).toISOString().split("T")[0]
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userPrefCtx.bearerToken}`,
        auth: authCtx.authMode,
      },
    }).then((res) => res.json())
    .then((data) => {
    //    console.log(data);

       patCtx.pat_token = data.patient_token
       patCtx.dob = data.dob
       patCtx.pat_first = data.first_name
       patCtx.pat_last = data.last_name
       patCtx.pat_mrn = data.emr_mrn
       patCtx.pat_gender = data.gender
       patCtx.pat_dob = data.dob


       patCtx.pat_active_mrn = data.emr_mrn
       patCtx.pat_local_site_mrn = data.emr_mrn
       patCtx.pat_profile_image_filename = data.file_name 
       patCtx.pat_id = data.id 
       patCtx.pat_middle = data.middle_name !== null ? data.middle_name : ''

    function formatDate(dateString) {
      //This function will convert yyyy-mm-dd to mm/dd/yyyy
      const [year, month, day] = dateString.split("-");
      return `${month}/${day}/${year}`;
    }

    function formatDate(dateString) {
      //This function will convert yyyy-mm-dd to mm/dd/yyyy
      const [year, month, day] = dateString.split("-");
      return `${month}/${day}/${year}`;
    }

    const formattedDate = formatDate(data.dob);

    navigate('/Visit', 
    {state: {
        firstName: data.first_name,
        lastName: data.last_name,
        middleName: data.middle_name !== null ? data.middle_name : '',
        mrn: data.emr_mrn,
        dob: formattedDate,
        gender: data.gender,
        patientToken: data.patient_token,
        bearerToken: props.sendBearerToken,
        patId: data.id,
        profileImageFileName: data.file_name,
        authMode: authCtx.authMode,
      }})
    
    })
    .catch((err) => {
       console.log(err.message);
    });
  }

  const selectRow = (e) => {
    console.log('in selectRow function ', e)
    // let stringMRN = e.target.getAttribute('data-mrn');
    // console.log(stringMRN);
  }

//add dob to the table

  return (
    <div className="row search_row_div">
        <ErrorDialogModal
          setOpen={showError}
          close={() => setShowError(false)}
          componentName="Provider Schedule"
        />
      <div 
        //className="col-sm-8 col-md-12 col-lg-12"
        className="col-sm-8 col-md-12 col-lg-8"
      >
      { props.sendData != null &&
      <Table bordered hover>
      <thead>
        <tr>
          <th>Appointment Date</th>
          <th>Appointment Status</th>
          <th>Appointment Time</th>
          <th>Patient MRN</th>
          <th>Patient Name</th>
          <th>Visit Type</th>
        </tr>
      </thead>
      <tbody>
        {
        Object.keys(props.sendData).map((item, i) => (
                                
        <tr 
          key={i} 
          // onClick={() => selectRow(props.sendData[item].patientMRN)}
          onClick={() => selectMRN(props.sendData[item].patientMRN)}
        >
          <td>{props.sendData[item].appointmentDate}</td>
          <td>{props.sendData[item].appointmentStatus}</td>
          <td>{props.sendData[item].appointmentTime}</td>
          <td 
            //onClick={selectMRN} 
            data-mrn={JSON.stringify(props.sendData[item].patientMRN)}
            //This is coming from the Dashboard.css
            className="provider_appt_mrn_selection patient-oddrow-mrns"
          >
            {/* <a href="#0">{props.sendData[item].patientMRN}</a> */}
            {props.sendData[item].patientMRN}
          </td>
          <td>{props.sendData[item].patientName?.split(',').join(', ')}</td>
          <td>{props.sendData[item].visitTypeName}</td>
        </tr>
        
        ))
        }
        
      </tbody>
    </Table>
    }

    { ((props.sendData?.length === 0 || props.sendData === undefined || props.sendData === null)&& 
       (props.sendORData?.length === 0 || props.sendORData === undefined || props.sendORData === null)) && 
      <div>
        <p>No Appointments</p>
      </div>
    }

    </div>
    </div>
  );
}
export default EpicProviderAppointments;