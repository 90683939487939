import Button from "react-bootstrap/Button";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState, Fragment, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import UserPreferencesList from "./UserPreferencesList";
import ErrorDialogModal from "../components/modals/ErrorDialogModal";
// import Form from "react-bootstrap/Form";
import classes from "./UserPreferencesModal.module.css";
import UserPrefContext from "../../context/userPrefContext";
import VisitPanelContext from "../../context/visitPanelContext";
import CopyUserPrefContext from "../../context/copyUserPrefContext";
import DictionaryContext from "../../context/dictionaryContext";
import AuthContext from "../../context/authContext";
import {useNavigate} from 'react-router-dom'
import CompanyContext from "../../context/companyContext";
// import DictionaryContext from "../../context/dictionaryContext";
import { isObjectEmpty } from "../../utils/Utils";

const UserPreferencesModal = (props) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [token, setToken] = useState(null);
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false)
    const [userInfo, setUserInfo] = useState(null);
    const [errors, setErrors] = useState({})


    const userPrefCtx = useContext(UserPrefContext);
    const copyUserPrefCtx = useContext(CopyUserPrefContext);
    const visitPanelCtx = useContext(VisitPanelContext);
    const authCtx = useContext(AuthContext)
    const companyCtx = useContext(CompanyContext)
    const dictCtx = useContext(DictionaryContext)

    let navigate = useNavigate();

    const handleClose = () => {
        setErrors({})

        // console.log("UserPreferenceModal - close/cancel button clicked. Mode = ", props.mode)
        // if unormal mode (shown via dropdown menu) close the dialog; otherwise log out
        if (props.mode === 'normal') {
            props.onHide();
        } else {
            props.onLogout();
        }
    }



    useEffect(() => {
        if (authState === null || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setToken(null);
            setUserInfo(null);
        } else {
            setToken(oktaAuth.getAccessToken());
            oktaAuth.getUser().then((info) => {
                setUserInfo(info);
            });
        }
    }, [authState, oktaAuth]); // Update if authState changes
  




    const handleSubmit = (e) => {
        e.preventDefault()
        let formErrors = { site_loc: null, user_dept: null}
        let error = false
        // console.log("SAVING ", userprefVal)
        let site_loc = userPrefCtx.userPreference?.site_loc
        if (!!!site_loc) {
            // get selected location from the form 
            let select = document.getElementsByName('site_dropdown');
            if (select.length > 0 && !!select[0].value) {
                site_loc =  select[0].value
            } else {
                formErrors = {...formErrors, site_loc: 'Site location is not selected'}
                error = true
            }
        }

        let prefs = {
            // user_id: userPrefCtx.User.id,
            site_loc: site_loc, //userPrefCtx.userPreference?.site_loc, 
            dict_botox_map_src_image_id:  !!userPrefCtx.userPreference?.dict_botox_map_src_image_id ? userPrefCtx.userPreference?.dict_botox_map_src_image_id : 0, 
            // dict_company_code_id: !!userPrefCtx.userPreference?.dict_company_code_id ? userPrefCtx.userPreference?.dict_company_code_id : companyCtx.company.id
            dict_company_code_id: companyCtx.company.id
        }
        // radio button value
        let radios = document.querySelectorAll("input[type='radio']")
        radios.forEach(radio => {
            if (radio.checked) {
                prefs[radio.getAttribute("data-name")] = radio.getAttribute("data-value")
            }
        })

        if (!!!prefs["user_dept"]) {
            formErrors = {...formErrors, user_dept: 'User department is not selected'}
            error = true
        }

        // console.log("UserPrefModal - errors:", formErrors)
        setErrors(formErrors)

        if (!error) {
            let chxboxes = document.querySelectorAll("input[type='checkbox']")
            chxboxes.forEach((chk) => {
                // make sure we have a property name for each checkbox group - otherwise if the whole group is unchecked the property will be missing from the result
                let propName = chk.getAttribute("data-name")
                // all checkbox values are arrays
                if (Object.keys(prefs).indexOf(propName) < 0 ) {
                    prefs[propName] = []
                }
                if (chk.checked) {
                    let propValue = chk.getAttribute("data-value")
                    // panel list elemens are integeres, other property elements are strings
                    if (propName === 'panel_list') {
                        prefs[propName].push(parseInt(propValue))
                    } else {
                        prefs[propName].push(propValue)
                    }
                }
            })
    
            let body = JSON.stringify(prefs)
            // console.log("Saving User Prefs BODY ", body)
    
            updatePrefs(body)

            setErrors({})
            props.onHide()

        }

        
    }

    const updatePrefs = async (body) => {
        const url = `${process.env.REACT_APP_SERVER}/api/v1/user_preferences/me`
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userPrefCtx.bearerToken}`,
            auth: authCtx.authMode,
        }

        const response = await fetch(url, {
            headers: headers,
            method: !isObjectEmpty(userPrefCtx.userPreference) ? "PATCH" : "POST",
            body: body
        })
        //.then((res) => res.json())
        .then((res) => {
            // Check for 422 or 500 response
            if (!res.ok) {
                 throw new Error('Unprocessable Entity: ' + res.statusText);
            }
            return res.json();
          })
        .then((data) => {
            // if user department changed we need to refresh dictionary context 
            const newPrefs = JSON.parse(body)
            // console.log("User Pref Dialog new preferences", newPrefs)
            if (userPrefCtx.userPreference?.user_dept !== newPrefs.user_dept) {
                updateDictionaries()
            }

            // POST doesn't return data anymore - use body to update context
            // console.log("Update user prefs - before", {...userPrefCtx.userPrefState})
            // console.log("Update user prefs - after", {...userPrefCtx.userPrefState, UserPreference: newPrefs})
            userPrefCtx.setUserPrefState({...userPrefCtx.userPrefState, UserPreference: newPrefs}) 
            copyUserPrefCtx.setUserPrefState({...copyUserPrefCtx.userPrefState, UserPreference: newPrefs}) 
        })
        .catch((err) => {
            console.log(err.message);
            setShowError(true);
        })
    }



    const updateDictionaries = async () => {
        const visitDictURL = `${process.env.REACT_APP_SERVER}/api/v1/dict_services/visit`
                
        const headers = { Authorization: `Bearer ${userPrefCtx.bearerToken}`, mode: "no-cors", auth: authCtx.authMode };

        try {
            // //console.log("Dashboard authCtx", authCtx)
            const response = await fetch(visitDictURL, { headers });
            if (response.status === 200) {
                const responseData = await response.json();
                dictCtx.setAllDictionaries(responseData);
            } else {
                console.log("not 200");
                setShowError(true);
            }
        } catch (e) {
            console.log(e);
            setShowError(true);
        }
    }

    return (
        <Fragment>
        {/* <form onSubmit={handleSubmit}>
            <div onClick={handleShow} className={'row container'}>
            {props.heading}{' '}
            </div> */}
        <ErrorDialogModal
            setOpen={showError}
            close={() => setShowError(false)}
            componentName="User Preferences"
        />
        <Modal size="xl" show={props.show} onHide={handleClose}
            style={{ visibility: (showError) ? 'hidden' : 'visible' }}
        >
            <Modal.Header className={classes.header} closeButton>
            <Modal.Title className={`${classes.title} ms-auto`}>
                {props.mode === 'normal' ? "Update User Preferences" : "Set User Preferences"}
            </Modal.Title>
            </Modal.Header>

            <Modal.Body className="mx-4">
            {/* <UserPreferencesList pref={pref} /> */}
            <UserPreferencesList errors={errors} />
            </Modal.Body>

            <Modal.Footer>
            <div
                className={`${classes.buttonleft} col d-flex justify-content-left align-items-start`}
            >
                <Button variant="outline-secondary" size="lg" onClick={handleClose}>
                    {props.mode === 'normal' ? "Close" : "Cancel"} 
                </Button>
            </div>
            <div className={`'col d-flex justify-content-end align-items-start'`}>
                <Button variant="outline-success" size="lg" onClick={handleSubmit}>
                Save Changes
                </Button>
            </div>
            </Modal.Footer>
        </Modal>
        {/* </form> */}
        </Fragment>
    )
}

export default UserPreferencesModal;
