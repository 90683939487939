//import React from 'react';
import React, { useState, useEffect, useContext } from 'react';
import DOMPurify from 'dompurify';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../../stylesheets/App.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import ReusableButton from '../buttons/reusableButton';

import NewDictDiag from '../modals/AdminActionModals/NewDictDiag';
import NewDictInt from '../modals/AdminActionModals/NewDictInt';
import SendSurveyByEmail from '../actions/SendSurveyByEmail';
import PatientConsent from '../actions/PatientConsent';
import PatientReportedOutcomeMeasures from '../actions/PatientReportedOutcomeMeasures';
import FreeTextSearchModal from '../modals/AdminActionModals/FreeTextSearchModal';
import UserPrefContext from '../../../context/userPrefContext'
import AuthContext from '../../../context/authContext';
import LoadingSpinner from '../shared/LoadingSpinner'
import { trackPromise } from 'react-promise-tracker'
import { toLocalDateTime } from '../../../utils/Utils'
import SearchOrModal from '../actions/SearchOrModal'
import SearchAndModal from '../actions/SearchAndModal'
import SystemConfigModal from '../modals/AdminActionModals/SystemConfigModal';
import ErrorDialogModal from '../modals/ErrorDialogModal';

const AdministrativeActions = (props) => {

  const [openDictDiagModal, setOpenDictDiagModal] = useState(false);
  const [openDictIntModal, setOpenDictIntModal] = useState(false);
  const [openFreeTextSearchModal, setOpenFreeTextSearchModal] = useState(false);
  const [openSystemConfigModal, setOpenSystemConfigModal] = useState(false);
  const [showSurveyByEmail, setShowSurveyByEmail] = useState(false)
  const [showPatientConsent, setShowPatientConsent] = useState(false)
  const [showOutcomeMeasures, setShowOutcomeMeasures] = useState(false)
  const [showSearchDialog, setShowSearchDialog] = useState(false)
  const [showReportByDiagnosis, setShowReportByDiagnosis] = useState(false)
  const [showReportByIntervention, setShowReportByIntervention] = useState(false)
  const [showError, setShowError] = useState(false)
  const [buttonWithError, setButtonWithError] = useState(false)
  const [systemConfigRetrieveData, setSystemConfigRetrieveData] = useState(false)
  const [isExpandedOnMobile, setIsExpandedOnMobile] = useState(false)

  const userPrefCtx = useContext(UserPrefContext)
  const authCtx = useContext(AuthContext)

  const [loadedTime, setLoadedTime] = useState(new Date());

  useEffect(async () => {

    if(userPrefCtx.dictDeviceType === ('desktop'|| 'ipad')){
      //This is to show the admin actions automatically when not in mobile
      setIsExpandedOnMobile(true);
    }

  }, [userPrefCtx.dictDeviceType]);
  

  const callNewDictDiag = () => {
    // console.log('in the callNewDictDiag function')
    setOpenDictDiagModal(true);

  }

  const retrieveNewDictDiagData = (data) => {
    props.sendToParent(data);
    //console.log(data);
  }

  const retrieveNewDictInterventionData = (data) => {
    props.sendToParent(data);
    //console.log(data);
  }
  
  const retrieveSystemConfigData = (data) => {
    //props.sendToParent(data);
    //console.log('data from admin actions: ',data);
    setSystemConfigRetrieveData(data);
  }

  const openIntv = (e) => {

    setOpenDictIntModal(true);

  }

  const closeDictDiag = (data) => {
    // console.log('This is the closeDictDiag function ', data);
    //setOpenDictDiagModal(data);

  }

    const sendOutcomeMeasures = () => {
        setShowOutcomeMeasures(true)
    }

    const closeOutcomeMeasures  = () => {
        setShowOutcomeMeasures(false)
    }


    const sendSurveyViaEmail = () => {
        setShowSurveyByEmail(true)
    }

    const closeSurveyByEmail = () => {
        setShowSurveyByEmail(false)
    }

    const openPatientConsent = () => {
        setShowPatientConsent(true)
    }

    const closePatientConsent = () => {
        setShowPatientConsent(false)
    }

    const openFreeTextSearch = () => {
      setOpenFreeTextSearchModal(true)
  }

  const closeFreeTextSearch = () => {
    setOpenFreeTextSearchModal(false)
  }

    const openSearchDialog = (search_type) => {
        setShowSearchDialog(true)
    }

    const closeSearchDialog = () => {
        setShowSearchDialog(false)
    }

    const closeReportByDiagnosis = () => {
        setShowReportByDiagnosis(false)
    }

    const closeReportByIntervention = () => {
        setShowReportByIntervention(false)
    }

    const openReportByIntervention = () => {
        setShowReportByIntervention(true)
    }

    const openReportByDiagnosis = () => {
        setShowReportByDiagnosis(true)
    }

    const openSystemConfiguration = () => {
      setOpenSystemConfigModal(true)
    }

    const closeSystemConfiguration = () => {
      setOpenSystemConfigModal(false)
    }

    const clickToExpand = () => {
      setIsExpandedOnMobile(true)
    }

    const clickToCollapse = () => {
      setIsExpandedOnMobile(false)
    }


    const reportRequested = (report_type) => {
        // testCreateFromJson()
        // return
        const createReport = async() => {
            try {
                const reportUrl = `${process.env.REACT_APP_SERVER}/api/v1/web_services/${report_type}`
                //const reportUrl = '';
                await fetch(reportUrl, {
                    headers: {
                        Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                        // 'Access-Control-Expose-Headers': '*',
                        // 'Access-Control-Allow-Headers': '*',
                        auth: authCtx.authMode,
                    }, 
    
                })
                .then((response) => {
                    const responseHeaders = [...response.headers]
                    const contentType = responseHeaders.findIndex(header => header[0] === 'content-type') >= 0 ? response.headers.get("content-type") : ''
                    const contentDisposition = responseHeaders.findIndex(header => header[0] === 'content-disposition') >= 0 ? response.headers.get("content-disposition") : ''
                    

                    console.log("reportRequested - content-type", contentType)
                    console.log("reportRequested - content-disposition", contentDisposition)
                    if (!response.ok) {
                      console.log('response is not ok - admin actions')
                      throw new Error('Unprocessable Entity: ' + response.statusText);
                    }
                    if (contentType && contentType.indexOf('application/json') >= 0) {
                        return response.json()
                            .then((data) => {
                                console.log("AdministrativeActions - reportRequested", data, "respose", response)
                            })
                    } else {
                      if (!response.ok) {
                        console.log('response is not ok - admin actions')
                        throw new Error('Unprocessable Entity: ' + response.statusText);
                      }
                        return response.blob()
                            .then((blob) => {
                                console.log(blob)
                                console.log(contentType)
                                const excelData = new Blob([blob], { type: contentType })
                                const url = window.URL.createObjectURL(excelData)
                                const a = document.createElement('a')
                                console.log(a)
                                a.style.display = 'none'
                                a.href = DOMPurify.sanitize(url, {SAFE_FOR_JQUERY:true})
                                // extract file name from disposition
                                const dispositionRegex = /filename=['"](?:.*?)['"]/gi
                                const match = contentDisposition.match(dispositionRegex)
                                if (!! match && match.length > 1) {
                                    a.download = `${match[1]}`
                                } else {
                                    // use filename with date and time in format report_name_yyyy_mm_dd_hhmmss
                                    a.download = `${report_type}_${toLocalDateTime(new Date(),'_').replace(/-/g, "_")}.xlsx`
                                }
                                // document.body.appendChild(a)
                                a.click()
                                window.URL.revokeObjectURL(url)
                            })
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                    setShowError(true);
                })
            }
            catch (e) {
                console.log(e)
            }
        }

        trackPromise(
            createReport()
        )
    }



  return(
  <div>
    <Navbar collapseOnSelect expand="lg" bg="light">
      <Nav.Link className="mx-auto">
        <h4 className="admin_header">
          Administrative Actions 
          { userPrefCtx.dictDeviceType === 'mobile' &&
          <>
          { isExpandedOnMobile === false &&
            <BsChevronUp 
              onClick={() => clickToExpand()}
            />
          }
          { isExpandedOnMobile === true &&
            <BsChevronDown 
              onClick={() => clickToCollapse()}
            />
          }
          </>
          }
        </h4>
        { isExpandedOnMobile === true &&
        <>
        <Row className="admin_row_padding">
          <Col xs={6} md={4} lg={2}><ReusableButton setVariant={'outline-primary'} setStyle={'admin_button_style'} buttonText={'New Patient-Reported Outcome Measures'} setClick={sendOutcomeMeasures}/></Col>
          <Col xs={6} md={4} lg={2}><ReusableButton setVariant={'outline-primary'} setStyle={'admin_button_style'} buttonText={'Report by eFace'} setClick={() => {reportRequested('eface_report'), setButtonWithError('Report by eFace')}} /></Col>
          <Col xs={6} md={4} lg={2}><ReusableButton setVariant={'outline-primary'} setStyle={'admin_button_style'} buttonText={'Report by Face Instrument'} setClick={() => {reportRequested('face_instrument_report'), setButtonWithError('Report by Face Instrument')}} /></Col>
          <Col xs={6} md={4} lg={2}><ReusableButton setVariant={'outline-primary'} setStyle={'admin_button_style'} buttonText={'Add Patient Consent'} setClick={openPatientConsent} /></Col>
          {/* <Col xs={6} md={4} lg={2}><ReusableButton setVariant={'outline-primary'} setStyle={'admin_button_style'} buttonText={'Report By Intervention'} setClick={openReportByIntervention}/></Col> */}
          <Col xs={6} md={4} lg={2}><ReusableButton setVariant={'outline-primary'} setStyle={'admin_button_style'} buttonText={'Report By Diag-Int AND Search'} setClick={openReportByDiagnosis}/></Col>
          {authCtx.userInfo?.is_site_admin &&
          <Col xs={6} md={4} lg={2}><ReusableButton setVariant={'outline-primary'} setStyle={'admin_button_style'} buttonText={'System Configuration'} setClick={openSystemConfiguration} /></Col>
          }
          </Row>
        <Row className="admin_row_padding2">
          <Col xs={6} md={4} lg={2}><ReusableButton setVariant={'outline-primary'} setStyle={'admin_button_style'} buttonText={'Report By Diag-Int OR Search'} setClick={() => openSearchDialog('or')}/></Col>
          <Col xs={6} md={4} lg={2}><ReusableButton setVariant={'outline-primary'} setStyle={'admin_button_style'} buttonText={'Free Text Search'} setClick={openFreeTextSearch}/></Col>
          <Col xs={6} md={4} lg={2}><ReusableButton setVariant={'outline-primary'} setStyle={'admin_button_style'} buttonText={'New/Modify Dictionary Diagnosis'} setClick={ () => callNewDictDiag() }/></Col>
          <NewDictDiag 
            sendDictDashboard={props.sendDictDashboard} 
            setOpen={openDictDiagModal} 
            close={() => setOpenDictDiagModal(false)} 
            sendToParent={retrieveNewDictDiagData}
            //func={closeDictDiag} 
          />
          <Col xs={6} md={4} lg={2}><ReusableButton setVariant={'outline-primary'} setStyle={'admin_button_style'} buttonText={'New/Modify Dictionary Interventions'} setClick={() => openIntv()} /></Col>
          <NewDictInt 
            sendDictDashboard={props.sendDictDashboard} 
            setOpen={openDictIntModal} 
            close={() => setOpenDictIntModal(false)} 
            sendToParent={retrieveNewDictInterventionData}
          />
          <Col xs={6} md={4} lg={2}><ReusableButton setVariant={'outline-primary'} setStyle={'admin_button_style'} buttonText={'Patient Consent Report'} setClick={() => {reportRequested('patient_consent_report'), setButtonWithError('Patient Consent Report')}} /></Col>
          <Col xs={6} md={4} lg={2}><ReusableButton setVariant={'outline-primary'} setStyle={'admin_button_style'} buttonText={'Send Survey via Email'} setClick={sendSurveyViaEmail}/></Col>
        </Row>
        </>
        }
      </Nav.Link>
    </Navbar>

    <PatientReportedOutcomeMeasures show={showOutcomeMeasures} onClose={closeOutcomeMeasures} />
    <SendSurveyByEmail show={showSurveyByEmail} onClose={closeSurveyByEmail}/>
    <PatientConsent show={showPatientConsent} onClose={closePatientConsent} />
    <SearchOrModal show={showSearchDialog} onClose={closeSearchDialog}/>
    <SearchAndModal reportBy='diagnosis' title='Diagnosis / Intervention' show={showReportByDiagnosis} onClose={closeReportByDiagnosis}/>
    <SearchAndModal reportBy='intervention' title ='' show={showReportByIntervention} onClose={closeReportByIntervention}/>
    <SystemConfigModal setOpen={openSystemConfigModal} close={closeSystemConfiguration} sendToAdminActions={retrieveSystemConfigData} sendUpdateToChild={systemConfigRetrieveData} />

    <LoadingSpinner size='100px' /*onEndOfPromise={() => setLoadedTime(new Date())}*/ />
    <FreeTextSearchModal setOpen={openFreeTextSearchModal} close={closeFreeTextSearch}/>
    <ErrorDialogModal
            setOpen={showError}
            close={() => setShowError(false)}
            componentName={buttonWithError}
    />
        
  </div>
);
  };

export default AdministrativeActions;
