import React, { useState, useRef, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import EditInterventionCalendar from '../calendar/EditInterventionCalendar';
import PromptEditInterventionDelete from './PromptEditInterventionDelete';
import PatientContext from '../../../context/patientContext';
import AuthContext from '../../../context/authContext';
import UserPrefContext from '../../../context/userPrefContext';
import DictionaryContext from '../../../context/dictionaryContext';
import ErrorDialogModal from './ErrorDialogModal';

function EditInterventionModal(props) {
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState();
  //This is to set the initial interventionID as the one in the props
  const [interventionID, setInterventionID] = useState();
  const [interventionDictID, setInterventionDictID] = useState(props.sendDictID);
  const [interventionDate, setInterventionDate] = useState();
  const [openDeletePromptModal, setOpenDeletePromptModal] = useState(false);
  const [showError, setShowError] = useState(false);

  const userPrefCtx = useContext(UserPrefContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const noteRef = useRef(null);

  const defaultDictID = props.sendDictID;

  //Using context to pass the patient token
  const interventionCtx = useContext(PatientContext);
  const authCtx = useContext(AuthContext);
  const dictCtx = useContext(DictionaryContext);

  //console.log('Diagnosis props', props.sendDictDiag);

  //const dictType = props.sendDictVisit.dict_intervention;
  const dictType = dictCtx.dict_intervention;
  //console.log(dictType)

  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false); // This cleanup function runs when the component is unmounted
  }, []);

  var intArray = [];

    intArray.push(_.groupBy(dictType, function(n) {
      //console.log('n.id inside the push',n.id)
    return n.group_display_name;
  }));
//   console.log("dictType", dictType, "intArray", intArray);

  let displayResult = intArray[0];
  //console.log(displayResult)
  //console.log('Diagnosis props', props.sendDictDiag.dict_diag);


  const clickDropDown = (e) => {
    //console.log(e.target.value)

    //console.log(e.target.dataset.obj)
    //console.log(e.target.dataset.name)
    //console.log(e.target.dataset.intervention_date)
    let attr = e.target.getAttribute('data-name');
    //console.log(attr)
    //let obj = JSON.parse(e.target.dataset.dispname);
    //console.log(obj);
    //This will change the id based on what intervention is selected in the dropdown
    setInterventionID(e.target.value)
  }

  const getInterventionDate = (e) => {
    // This is getting the date from the intervention calendar child component
    setInterventionDate(e);
      //console.log(e);
      //console.log(e.toISOString().split('T')[0]);
    }


  const saveIntervention = async (e) => {
    //This is the function to post the intervention to the database
    if(interventionID === '' ) {
      alert('Please select an item in the list')
    } else {

    //console.log('save intervention function')
    //Setting is to the value of what's type in the notes section
    let noteValue = noteRef.current.value;
    //console.log(noteValue)

    //This is to get the string in the proper format
    var strDate = interventionDate.toLocaleDateString("en-US");
    //console.log(strDate)

    let strToIso = interventionDate.toISOString();

    var fullTimeStamp = strToIso;
    //console.log(fullTimeStamp);


    // console.log('The interventionID is: ', interventionID)
    // console.log('The props.sendID is: ', props.sendID)

    //If there is no update to the dropdown, it will send the props value, otherwise it will send the useState value to the variable
    let sendIDtoDB = 0;
    if(interventionID != undefined){
        //console.log('The variable is not undefined');
        sendIDtoDB = interventionID;
    } else {
        //console.log('The variable is undefined');
        sendIDtoDB = props.sendDictID;
    }
    //console.log('sendIDtoDB is ', sendIDtoDB)

    const patchInterventionURL = process.env.REACT_APP_SERVER + `/api/v1/interventions/` + props.sendID;

    const responseData = await fetch(patchInterventionURL, {
      method: 'PATCH',
      body: JSON.stringify({
          "intervention_date": `${fullTimeStamp}`,
          "notes": `${noteValue}`,
          "dict_intervention_id": sendIDtoDB
       }),
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${props.bearerToken}`,
        Authorization: `Bearer ${userPrefCtx.bearerToken}`,
        auth: authCtx.authMode,
      },
    }).then((res) => res.json())
    .then((data) => {
       //console.log(data);

       let displayNameSet = '';

       let propLoop = props.sendDictVisit.dict_intervention;
   
       for (let i = 0; i < propLoop.length; i++) {
         if (JSON.stringify(propLoop[i].id) === sendIDtoDB){
           displayNameSet = propLoop[i].display_name_short;
           //makeJson = JSON.parse(displayNameSet);
           //console.log(displayNameSet);
         }
       }

       let makeInterventionIDJson = JSON.parse(sendIDtoDB);
       let makeIDJson = JSON.parse(props.sendID);
       //let newList = [...newJson];
       //console.log(newList )
   
       var jsonObject = { 
         dict_intervention_id: makeInterventionIDJson, 
         notes: noteValue, 
         id: makeIDJson,
         intervention_date: fullTimeStamp,
         display_name_short: displayNameSet
       };
   
       props.getChild(jsonObject);
   
       //This is to close the Modal after saving
       props.close();
    })
    .catch((err) => {
       console.log(err.message);
       setShowError(true)
    });

    //below is code to send the data to the front end right after its sent to the database

    // let displayNameSet = '';

    // let propLoop = props.sendDictVisit.dict_intervention;

    // for (let i = 0; i < propLoop.length; i++) {
    //   if (JSON.stringify(propLoop[i].id) === sendIDtoDB){
    //     displayNameSet = propLoop[i].display_name_short;
    //     //makeJson = JSON.parse(displayNameSet);
    //     console.log(displayNameSet);
    //   }
    // }
  
    //console.log(newJson)

    // let makeInterventionIDJson = JSON.parse(sendIDtoDB);
    // let makeIDJson = JSON.parse(props.sendID);
    // //let newList = [...newJson];
    // //console.log(newList )

    // var jsonObject = { 
    //   dict_intervention_id: makeInterventionIDJson, 
    //   notes: noteValue, 
    //   id: makeIDJson,
    //   intervention_date: fullTimeStamp,
    //   display_name_short: displayNameSet
    // };

    // props.getChild(jsonObject);

    // //This is to close the Modal after saving
    // props.close();
    }
  }

  const promptDeleteIntervention = (e) => {
    //props.close()
    setOpenDeletePromptModal(true)
  }

  const closeDeleteModal = (data) => {
    setOpenDeletePromptModal(false)
    props.close()
  }

  const getDeleteFromChild = (data) => {
    //setOpenDeletePromptModal(false)
    props.getChild(data);
  }


  return (
    <>
      <PromptEditInterventionDelete 
        key={Math.random()} 
        setOpen={openDeletePromptModal} 
        close={closeDeleteModal}
        sendID={props.sendID}
        getChild={getDeleteFromChild}
      />

      <ErrorDialogModal
        setOpen={showError}
        close={() => setShowError(false)}
        componentName="Intervention"
      />

      <Modal 
        show={props.setOpen} 
        onHide={props.close} 
        animation={false}
        //This is needed because when the Delete Intervention button is clicked, it needs to hide this modal while the child shows up
        style={{ visibility: (openDeletePromptModal || showError) ? 'hidden' : 'visible' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Intervention Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h4>Intervention</h4>    
        <Form.Select aria-label="Default select example" onChange={clickDropDown} defaultValue={props.sendDictID}> 
        <option key={'option1'}></option>
        
        {Object.entries(displayResult).map(([key, value], index) => {   
              
            return(    
              <React.Fragment key={index}>
                <optgroup label={key} key={'optgroup'+index}></optgroup>    
                {value.map((x,y) => 
                <option value={JSON.stringify(value[y].id)} data-name={JSON.stringify(value[y].display_name)} key={'optionval'+y+index} > {value[y].display_name}</option>
                )}
                     
              </React.Fragment>
            )}      
          )}  
          </Form.Select>     
        <EditInterventionCalendar func={getInterventionDate} sendDate={props.sendDate} />
        <h4>Notes</h4>
        <Form.Control onSubmit={saveIntervention} ref={noteRef} size="lg" type="text" defaultValue={props.sendNotes}  />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={promptDeleteIntervention}>
            Delete Intervention
          </Button>
          <Button variant="primary" onClick={saveIntervention}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditInterventionModal;