import React, {useState, useEffect} from 'react'
import PatientContext from "../../../context/patientContext"
import OneNote from './OneNote'
import './AllNotes.css'
import {BsJournals} from 'react-icons/bs' 


// props: 
// notes - list of all notes (currently all visits) sorted
function AllNotes(props) {
    // console.log("AllNOtes - props", props)
    const [notes, setNotes] = useState([])


    return (
        <div className='all-notes '>
            <div className='section-header d-flex justify-content-between '>
                <div className='inline-block text-left'>
                    <BsJournals className='visit-detail-icon' />
                    Notes
                </div>
                
            </div>



            { props.notes.map(visit => 
                <OneNote key={visit.Visit.id} visit={visit} />
            )}
            
            
        </div>
    )
}

export default AllNotes
