import { useOktaAuth } from "@okta/okta-react";
import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import DictionaryContext from "../../../context/dictionaryContext";
import UserPrefContext from "../../../context/userPrefContext";

// This is the api linked to this section: api/v1/search_services/retriev_provider_schedule

const Provider = (props) => {
    const dictCtx = useContext(DictionaryContext)
    const userPrefCtx = useContext(UserPrefContext);
    // console.log("Provider - dictCtx", dictCtx)

    // Sergey 2023-07-26 - physicianMap is not used anywhere
    // const [physicianMap, setPhysicianMap] = useState([]);
    let [physicianPointer, setPhysician] = useState("Select Provider");

    // useEffect(
    //     () => {
    //     // console.log("Provider props", props)
    //     setPhysicianMap(props.sendProviderProps);
    //     },
    //     [
    //     /*physicianMap*/
    //     ]
    // );
    useEffect(async () => {

      if(userPrefCtx.userPreference.provider_id != null){
        //console.log(userPrefCtx)
        //setPhysician(userPrefCtx.userPreference.provider_id);
        setPhysician(parseInt(userPrefCtx.userPreference.provider_id));
        props.func(parseInt(userPrefCtx.userPreference.provider_id));
      } else {
        //console.log(userPrefCtx)
      }

    }, [userPrefCtx.userPreference.provider_id]);

  // console.log('typeof(PhysicianMap): ', typeof(physicianMap))

  let handlePhysicianChange = (e) => {
    setPhysician(e.target.value);
    //console.log(e.target.value);
    //props.func(physicianPointer);
    props.func(e.target.value);
    // console.log('called the handlePhysicianChange function');
  };

  return (
    <div>
      <h4>Select Provider </h4>
      <Form.Select
        aria-label="Default select example"
        onChange={handlePhysicianChange}
        // className="provider-form"
        value={physicianPointer}
      >
        {/* <optgroup label="Provider"></optgroup> */}
        <option></option>
        {!!dictCtx.dict_import_note_provider && 
            dictCtx.dict_import_note_provider.map((item) => (
                <option key={item.epic_provider_id} value={item.epic_provider_id}>
                  {item.provider_name}
                </option>
            )
        )}
        
        {/* { props.sendProviderProps != null &&
        props.sendProviderProps.map((item) => (
          <option key={item.epic_provider_id} value={item.epic_provider_id}>
            {item.provider_name}
          </option>
        ))} */}
      </Form.Select>
    </div>
  );
};
export default Provider;
