import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BsPencilSquare } from 'react-icons/bs';
import UserPrefContext from '../../../context/userPrefContext';
import PatientContext from '../../../context/patientContext';
import AuthContext from '../../../context/authContext';
import { trackPromise } from 'react-promise-tracker'
import UpdatePatientInfoModal from './UpdatePatientInfoModal';
//import Update

function PatientInformation(props) {
  const [show, setShow] = useState(false);

  const [showPatientInfoModal, setShowPatientInfoModal] = useState(false);

  const [patientData, setPatientData] = useState();
  const [triggerUpdate, setTriggerUpdate] = useState();

  const patCtx = useContext(PatientContext);
  const userPrefCtx = useContext(UserPrefContext);
  const authCtx = useContext(AuthContext)

  // const uploadProfileImageURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/upload/set_as_profile_image/${patCtx.pat_token}`;
  const patientURL = `${process.env.REACT_APP_SERVER}/api/v1/patients/${patCtx.pat_token}`;

  //console.log(patCtx.pat_token)

  const openUpdateModal = () => {
    //return;
    //remove the return to have the usestate work properly
    setShowPatientInfoModal(true);
  }
  const closeUpdateModal = () => {
    setShowPatientInfoModal(false);
  }

  const triggerUpdateFunction = (data) => {
    setTriggerUpdate(data);
  }

  useEffect(async() => {
    if (userPrefCtx.bearerToken) {
      const headers = { Authorization: `Bearer ${userPrefCtx.bearerToken}`, auth: authCtx.authMode, };

      try {
        const response = await fetch(patientURL, { headers })
        if (response.status === 200) {

          const responseData = await response.json();
          //console.log('Patient data from PatientInformation.jsx: ', responseData);
          setPatientData(responseData);

        //This is sorting the the objects by when it was created and then putting it in a useState
        //setIntraopList(responseData.sort((a, b) =>a.created_at < b.created_at ? 1 : -1));
        //setIntraopList(responseData);

        } else {
          console.log("not 200");
        }
      } catch (e) {
        console.log(e);
      }


    }
    else {
      //console.log('no token')
    } //The triggers for the useEffect will be when there is a new patient selected or when a patch call is made in the child component
}, [userPrefCtx.bearerToken, patCtx.pat_token, triggerUpdate]);

function formatDate(inputDate) {
  const dateParts = inputDate.split('-');
  const [year, month, day] = dateParts;
  return `${month}/${day}/${year}`;
}


  return (
    <div>
        <UpdatePatientInfoModal
            setOpen={showPatientInfoModal}
            close={closeUpdateModal}
            patientData={patientData}
            sendTrigger={triggerUpdateFunction}
        />
          <div className="visit_record_div" 
            //onClick={openUpdateModal}
          >
            <h4>
            <span className="label">Name:{' '}</span>
            <div className="value">
              &nbsp;{patientData?.first_name}&nbsp;{patientData?.last_name}&nbsp;&nbsp;
              <BsPencilSquare onClick={openUpdateModal} className="icon-pointer" />
            </div>
            </h4>
            {/* <h4>DOB: {patientData?.dob}</h4> */}
            <h4>DOB: {patientData ? formatDate(patientData.dob) : 'N/A'}</h4>
            <h4>Gender: {patientData?.gender}</h4>
            <h4>MRN: {patientData?.emr_mrn}</h4>
        </div>
  </div>
  );
}

export default PatientInformation;