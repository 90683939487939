import React, {useEffect, useState} from 'react'
import parse from "html-react-parser"
import FollowupRow2 from './FollowupRow2'
import style from './FollowupGroup.module.css'

function FollowupGroup(props) {

    const [headers, setHeaders] = useState([])



    useEffect(() => {
        // if (props.group_id === 11) {
        //     console.log("FollowupGroup PROPS", props)
        // }
        

        // console.log("GROUP", props.group_id, "DATA", props.data)
        let columns = [];
        let qtypes = [];
        let qvalues = [];
        // in questions - if question_option is array - use it as column headers
        if (props.questions.length > 0) {
            if (props.questions[0].question_option !== null) {
                if (props.questions[0].question_option.startsWith("[")) {
                    // we have an array of headers - parse it
                    columns = JSON.parse(props.questions[0].question_option);
                    // console.log("COLUMNS", columns)
                    // make sure that we have arrays in question_type and possibly in question_option_values
                    if (props.questions[0].question_type !== null &&
                        props.questions[0].question_type.startsWith("[")) {
                            qtypes = JSON.parse(props.questions[0].question_type);
                    }
                }
            }
        }

        setHeaders(columns);

        

        // if (props.group_id === 2) {
        //     console.log("ProviderFollowupGroup - props", props, "headers", columns, "qtypes", qtypes)
        // }
        // setCapevData(props.data);

    }, [props.group_id, props.questions/*, props.data*/])


    return (
        <>


        <div>
            {props.questions[0].group_header1 &&
                <h3>{parse(`${props.questions[0].group_header1}`)}</h3>}
            {props.questions[0].group_header2 &&
                <h3>{parse(`${props.questions[0].group_header2}`)}</h3>}
            {props.questions[0].group_header3 &&
                <h3>{parse(`${props.questions[0].group_header3}`)}</h3>}


            <div className={`${style.bottom_border}`}>
                {headers.length > 0 && (

                    <div className={`row mb-3 ${style[props.questions[0].display_name_short]} `}>
                        {props.questions.map((q, row_index) => {
                                return (
                                    <FollowupRow2
                                        key={row_index}
                                        // errors={props.errors}
                                        question={q}
                                        // onValuesUpdate={updateValues}
                                        // data={capevData}
                                        // headers={headers}
                                        type="columns"
                                        // readOnly={props.readOnly}
                                        // setErrors={changeErrors}
                                        fu={props.fu}
                                    />
                                );
                            })}
                    </div>


                )}

                {headers.length === 0 &&
                    props.questions.map((q, row_index) => {
                        return (
                            <FollowupRow2
                                key={row_index}
                                // errors={props.errors}
                                question={q}
                                // onValuesUpdate={updateValues}
                                // data={capevData}
                                // headers={headers}
                                type="single"
                                // readOnly={props.readOnly}
                                // setErrors={changeErrors}
                                fu={props.fu}
                            />
                        );
                    })
                }
            </div>
        </div>

        </>
    )
}

export default FollowupGroup
