import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext,
  forwardRef, 
  useImperativeHandle
} from "react";
import DOMPurify from 'dompurify';
import "../../stylesheets/BotoxMap.css";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from 'fabric';
//import Container from 'react-bootstrap/Container';
import Container from 'react-bootstrap/Container';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import BotoxType from "../dropdowns/BotoxType";
import BotoxMapImage from "../dropdowns/BotoxMapImage";
import DictBotoxSrcImageUpload from "../fileuploads/DictBotoxSrcImageUpload";
import ColorBox from "../colorboxes/ColorBox";
import userPrefContext from "../../../context/userPrefContext";
import PatientContext from "../../../context/patientContext";
import DictionaryContext from "../../../context/dictionaryContext";
import AuthContext from "../../../context/authContext";
import CompanyContext from "../../../context/companyContext";
// import VisitWithPrefContext from "../../../context/visitWithPrefContext";
import pptxgen from 'pptxgenjs'


//this is setting the picture to the one in the public folder for now
//let picture = 'https://images.unsplash.com/photo-1676107779594-7a23bd99e07c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
const canvasWidth = '1337'
const canvasHeight = '663'

let DEFAULT_IMAGE = "";
// const publicImage = "botoxmap.png";
const publicImage = "botox-map-full.png";


const BotoxMap = forwardRef((props, ref) => {


  const userPrefCtx = useContext(userPrefContext);
  const patCtx = useContext(PatientContext);
  const dictCtx = useContext(DictionaryContext);
  const authCtx = useContext(AuthContext)
  const companyCtx = useContext(CompanyContext)
//   const visitWithPrefCtx = useContext(VisitWithPrefContext);
  // console.log('userPrefCtx is ', userPrefCtx)
  // console.log('userPrefCtx.dictBotoxMapSrcImage is ', userPrefCtx.dictBotoxMapSrcImage)

  const defaultImageFromDict = userPrefCtx.dictBotoxMapSrcImage?.file_name;
  //console.log(defaultImageFromDict)


  if(defaultImageFromDict === null || defaultImageFromDict === undefined){
    DEFAULT_IMAGE = publicImage;
    //console.log('defaultImageFromDict is null')
  } else {
    // DEFAULT_IMAGE = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${defaultImageFromDict}`
    // DEFAULT_IMAGE = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${defaultImageFromDict}?dict_company_code_id=${userPrefCtx.userPreference.dict_company_code_id}`
    DEFAULT_IMAGE = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${defaultImageFromDict}?dict_company_code_id=${companyCtx.company.id}`
  }

  const [saveBotoxID, setSaveBotoxID] = useState();

  const [drawState, setDrawState] = useState();

  const [drawModeText, setDrawModeText] = useState('Exit drawing mode');
  const [drawMode, setDrawMode] = useState('botox');

  const [botoxTypeSelect, setBotoxTypeSelect] = useState();

 const [botoxImageSelect, setBotoxImageSelect] = useState(DEFAULT_IMAGE);

 const [inputList, setInputList] = useState([]);

  const noteRef = useRef(null);

  const { editor, onReady } = useFabricJSEditor();

    const [radioValue, setRadioValue] = useState('botox')
    const radios = [
        { name: 'Botox', value: 'botox' },
        { name: 'Freehand', value: 'freehand' },
        { name: 'Off', value: 'off' },
    ]

  const _onReady = (canvas) => {
    //This function will provide the initial setup for the fabric canvas

    // Get todays date
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    var textDate = "Date: " + mm + "/" + dd + "/" + yyyy;
    var dateid = 1;
    if (props.sendEditBotoxMapData.botox_map_image_data != null) {
      canvas.loadFromJSON(props.sendEditBotoxMapData.botox_map_image_data, function () {
        canvas.setWidth(canvasWidth);
        canvas.setHeight(canvasHeight);

        //The color scales on the left side will be removed when it is initially loaded
        canvas.getObjects().forEach(function (obj) {
          //console.log(obj.type);
          if (obj.type === "group") {
            canvas.remove(obj);
          }
          if ((obj.type === "i-text" || obj.type === "text") && obj.text.startsWith("Date")) {
            canvas.remove(obj);
            const dateText = new fabric.IText(textDate, {
              left: 1175,
              top: 0,
              name: "texting",
              id: dateid,
              fontSize: 20,
            });
            canvas.add(dateText);
          }
        });

        canvas.renderAll();
        onReady(canvas);
      });

    } else {
      //console.log('It is null, in the else statement')
      const dateText = new fabric.IText(textDate, {
        left: 1175,
        top: 0,
        name: "texting",
        id: dateid,
        fontSize: 20,
      });
      canvas.add(dateText);

      canvas.setWidth(canvasWidth);
      canvas.setHeight(canvasHeight);
      onReady(canvas);
    }
  };

  // setting a default color before it gets changed
  let colorCircle = "";
  const varcolor = document.getElementById("colorpicker");
  const getCircleColor = () => {
    if (varcolor) {
      varcolor.addEventListener("click", function (e) {
        let rgbcol = e.target.style.backgroundColor;
        if (!!rgbcol) {
            let brush = editor.canvas.freeDrawingBrush
            brush.color = rgbcol

        }

        colorCircle = getBgColorHex(rgbcol);
        //console.log("color circle after ", colorCircle);
      });
      return colorCircle;
    } else {
      console.log("colorpicker doesnt exist yet");
    }
  };

  let lastCirclePos = null;
  let drawnPositions = [];

  const drawCircle = (o) => {
    //console.log("clicked circle");
    let colfunc = getCircleColor();
    const { offsetX, offsetY } = o.e;
  const currentPos = { x: offsetX, y: offsetY };


    var pointer = editor.canvas.getPointer(o.e);

    // const existingIndex = drawnPositions.findIndex((pos) => {
    //   return pos.x === currentPos.x && pos.y === currentPos.y;
    // });
    const existingIndex = drawnPositions.findIndex((pos) => {
      return pos.x === pointer.x && pos.y === pointer.y;
    });

    const existingPosition = drawnPositions.some((pos) => {
      return pos.x === pointer.x && pos.y === pointer.y;
    });

    const existingCircle = editor.canvas.getObjects().find((obj) => {
      return obj.type === "circle" && obj.left === pointer.x && obj.top === pointer.y;
    });


    //if (!lastCirclePos || currentPos.x !== lastCirclePos.x || currentPos.y !== lastCirclePos.y) {
    //if (!lastCirclePos || pointer !== lastCirclePos) {
      //console.log('existingIndex ', existingIndex)
      //if (existingIndex === -1) {  
        // if (!existingPosition) {     
    if (!existingCircle) {    
      // console.log('current pointer is ', pointer)
      // console.log('currentPos is ', currentPos)
      // console.log('lastCirclePos is ', lastCirclePos)
    var circle = new fabric.Circle({
      left: pointer.x,
      top: pointer.y,
      // left: currentPos.x,
      // top: currentPos.y,
      radius: 7,
      fill: colfunc,
      hasRotatingPoint: true,
      originX: "center",
      originY: "center",
    });

    //editor.canvas.add(circle);

    if (colfunc != "") {
      //This function will make sure that extra blank circles dont appear
      //console.log('the color is not blank!')
      editor.canvas.add(circle);
      // console.log('added a circle', circle)
      // console.log(drawnPositions);

      //drawnPositions.push(currentPos);
      //drawnPositions.push({ x: pointer.x, y: pointer.y });
    }
    //This will keep track of the positions so extra circles don't get added when there is a click
    drawnPositions.push({ left: pointer.x, top: pointer.y });
    lastCirclePos = pointer;

  }
  };

  function deleteAllCircles() {
    // Get the canvas instance
    if (typeof editor !== 'undefined') {
      // Canvas object exists
      // Do something with the canvas object
      //console.log(editor);
      const circles = editor.canvas.getObjects().filter(obj => obj.type === 'circle');
  
      // Remove each circle object from the canvas
      circles.forEach(circle => editor.canvas.remove(circle));
        
      // Update the canvas display
      editor.canvas.renderAll();
    } else {
      // Canvas object does not exist
      //console.log("Canvas object does not exist.");
    }
  }

  useEffect(() => {
    if (editor) {
      //This will only allow drawing to happen if the the text says to exit drawing mode

      if(/*drawModeText === 'Exit drawing mode' || */ radioValue === 'botox'){
        getCircleColor();
        //console.log('hexColor in useEffect is ', hexColor)
        //console.log('drawcircle from useeffect')
        editor.canvas.on("mouse:down", drawCircle);
      }


      if (radioValue === 'botox') {
        getCircleColor()
        editor.canvas.on("mouse:down", drawCircle);
      }
 
      //This function is to read double clicks
      editor.canvas.on('mouse:dblclick', function(e) {
        //console.log(e.target)
        if(e.target != null){
        if (e.target?.type === 'circle') {
          //console.log('The clicked object was a circle')
          onDeleteSelected();
        } else if (e.target.type === 'text') {
          //console.log('The clicked object was a text')
        }
      }
      })
    }
  }, [editor]);

  useEffect(() => {
    //This useEffect is to change the background image based on the dropdown
    if (editor) {
      //console.log('editor exists')
      //console.log(botoxImageSelect);

    var img2 = new Image();
    //img2.setAttribute('crossOrigin', 'anonymous');
    img2.crossOrigin = '*';
    img2.src = botoxImageSelect;
    //console.log(img2.src)

    if (props.sendEditBotoxMapData.botox_map_image_data != null || props.sendEditBotoxMapData.botox_map_image_data === []) {
      //do nothing, because it's loaded from the onReady function and doesn't need logic to load from useEffect
      //console.log(props.sendEditBotoxMapData.botox_map_image_data)

    } else {
      //If it's not set to edit it will load from url
      //new fabric.Image.fromURL(botoxImageSelect, function (img) {
        //console.log('in the 2nd useeffect')
        //publicImage

        //This is essentially going to set the botox map image to default if undefined is chosen
        let imageChangeVar = props.sendImageChange;
        //console.log(imageChangeVar)
        const checkIfUndefined = props.sendImageChange?.slice(-9);

        // var segment = imageChangeVar?.split('/dict_botox_map_src_image/')[1].split('/')[0];
        // console.log(segment);

        // Extract the segment between "/dict_botox_map_src_image/" and "?"
          var startIndex = imageChangeVar.indexOf('/dict_botox_map_src_image/') + '/dict_botox_map_src_image/'.length;
          var endIndex = imageChangeVar.indexOf('?');
          var segment = imageChangeVar.substring(startIndex, endIndex);

          // Check if the segment is equal to "undefined"
          var isUndefined = segment === 'undefined';

          //console.log(isUndefined);

        //console.log(checkIfUndefined);
        if(segment === 'undefined'){
        //if(checkIfUndefined === 'undefined'){
          imageChangeVar = publicImage
        }

        //console.log(props.sendImageChange)
        //console.log(imageChangeVar)
        // new fabric.Image.fromURL(props.sendImageChange, function (img) {
        new fabric.Image.fromURL(imageChangeVar, function (img) {
          img.set({
          setWidth: 1337,
          setHeight: 663,
          });
      
          editor.canvas.setBackgroundImage(img);
          editor.canvas.renderAll();
        },{crossOrigin: "*"});
    }
}
}, [editor, botoxImageSelect, props.sendImageChange]);

useEffect(() => {
  //console.log('this is the 3rd useeffect, should only run when there is a change in props.sendImageChange')
  deleteAllCircles()
}, [props.sendImageChange]);


useEffect(() => {

  const nameString = "Name: " + patCtx.pat_first + ' ' + patCtx.pat_last;

  if(editor){

    const checkForName = editor.canvas.getObjects().filter(obj => obj.text?.includes("Name"));
  
    // Remove each circle object from the canvas
    checkForName.forEach(circle => editor.canvas.remove(circle));
      
    // Update the canvas display
    //editor.canvas.renderAll();

  const dateText = new fabric.IText(nameString, {
    //left: 1000,
    left: 0,
    top: 0,
    name: "texting",
    //id: dateid,
    fontSize: 20,
  });
  if(props.sendAddNameCheck === true){
    editor.canvas.add(dateText);
    // console.log(editor.canvas.getObjects())
  } else if (props.sendAddNameCheck === false){
    // console.log(editor.canvas.object)
    // console.log(editor.canvas.getObjects())
    editor.canvas.remove(dateText);
  }

}

}, [editor, props.sendAddNameCheck]);


  const onDeleteSelected = () => {
    editor.deleteSelected();
  };

  /* This is to select the color */
  function getBgColorHex(elem) {
    if (elem) {
      var color = elem;
      var hex;
      if (color.indexOf("#") > -1) {
        //for IE
        hex = color;
      } else {
        var rgb = color.match(/\d+/g);
        hex =
          "#" +
          ("0" + parseInt(rgb[0], 10).toString(16)).slice(-2) +
          ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
          ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2);
      }
      return hex;
    }
  }

  if (props.sendEditBotoxMapData != null) {
    const jsonData = props.sendEditBotoxMapData.botox_map_image_data;
    //console.log(jsonData);
  }

  const saveBotox = async (e) => {
    //console.log('userPrefCtx is ', userPrefCtx.bearerToken)
    //console.log('patCtx is ', patCtx.pat_token);

    const SaveBotoxServer = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/${patCtx.pat_token}?dict_company_code_id=${companyCtx.company.id}`;
    //const SaveBotoxServer = '';


    if (props.colorScaleIncluded) {
        groupColorValues();
    }

    let canvasJSON = JSON.stringify(editor.canvas.toJSON());

    //console.log(canvasJSON)

    const black = document.getElementById("010101").value;
    const red = document.getElementById("DC143C").value;
    const purple = document.getElementById("6F339D").value;
    const green = document.getElementById("5BFF54").value;
    const blue = document.getElementById("2A84FF").value;
    const yellow = document.getElementById("FFFF00").value;
    const pink = document.getElementById("FC00FF").value;
    const lightpink = document.getElementById("FFCCFF").value;

    var selectBotoxType = document.getElementById("botox-type-select");

    var botoxTypeText =
      selectBotoxType.options[selectBotoxType.selectedIndex].text;
    //console.log("botox type text ", botoxTypeText);

    //let noteValue = noteRef.current.value;
    let noteValue = props.sendNoteRef.current.value;
    //console.log('noteValue is ', noteValue)
    //console.log('props.sendNoteRef is ', props.sendNoteRef)

    // If the botox map is fresh then it will send the botoxmapimage
    var selectBotoxMapImage = "";

    if (props.sendEditBotoxMapData.botox_map_image_data != null) {
      //console.log(props.sendEditBotoxMapData.botox_map_image_id);
      //console.log('props.sendBotoxTypeID is ', props.sendBotoxTypeID)
      //this is to set the id to the props if its edit, and checking if the length is 1
      if(props.sendBotoxTypeID.length === 1){
        selectBotoxMapImage = props.sendBotoxTypeID[0];
        //console.log('selectBotoxMapImage is ', selectBotoxMapImage)
      }
    } else {
      //var selectBotoxMapImage = document.getElementById('botox-image-select').value;
      selectBotoxMapImage = document.getElementById("botox-image-select").value;
      //console.log("selectBotoxMapImage is  ", selectBotoxMapImage);
    }

    var canvasImg = editor.canvas.toDataURL("image/png");
    //console.log(canvasImg);

    //DictionaryContext
    // console.log(dictCtx.dict_botox_map_src_image);
    // console.log(dictCtx.dict_botox_map_src_image[0].id);
    // console.log(typeof dictCtx.dict_botox_map_src_image[0].id);
    // console.log(typeof selectBotoxMapImage);

    const turnToInteger = parseInt(selectBotoxMapImage, 10);

    const findFile = dictCtx.dict_botox_map_src_image?.find(obj => obj.id === turnToInteger);
    // console.log(findFile);
    // console.log(findFile?.orig_file_name);

    let sendFileName = findFile?.orig_file_name;

    //console.log(sendFileName);

    if(sendFileName === undefined){
      //sendFileName = 'botoxmap.png'
      sendFileName = 'botox-map-full.png'
    }

    //console.log(sendFileName);

    //console.log(canvasJSON)

    //const fileStringTest = "google.png";
    // console.log('selectBotoxMapImage is ', selectBotoxMapImage)
    // console.log('botoxImageSelect is ', botoxImageSelect)

    const responseData = await fetch(SaveBotoxServer, {
      method: "POST",
      body: JSON.stringify({
        "botox_map_image_data": `${canvasJSON}`,
        "botox_map_type": `${botoxTypeText}`,
        //"botox_map_pen_color_navy_blue": `${black}`,
        "botox_map_pen_color_black": `${black}`,
        "botox_map_pen_color_red": `${red}`,
        "botox_map_pen_color_purple": `${purple}`,
        "botox_map_pen_color_green": `${green}`,
        "botox_map_pen_color_blue": `${blue}`,
        "botox_map_pen_color_yellow": `${yellow}`,
        "botox_map_pen_color_pink": `${pink}`,
        "botox_map_pen_color_lightpink": `${lightpink}`,
        "botox_map_other_desc": `${noteValue}`,
        //"file_name": `${fileStringTest}`,
        //"file_name": '.png',
        "file_name": `${sendFileName}`,
        "dict_botox_map_src_image_id": selectBotoxMapImage,
        "botox_map_image_base64": `${canvasImg}`
      }),
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userPrefCtx.bearerToken}`,
        auth: authCtx.authMode,
      },
    })
    //If there is an error, the dialog will show up, it's called outside of this component because of forward refs
    //.then((res) => res.json())
    .then((res) => {
      if (!res.ok) {
         throw new Error('Unprocessable Entity: ' + res.statusText);
      }
      return res.json();
    })
    .then((data) => {
      //console.log(data);
      // console.log(
      //   "botox_map_json_image_id is ",
      //   data.botox_map_json_image_id
      // );
      setSaveBotoxID(data.botox_map_json_image_id);

    //This is to send data to the parent
    //props.setChild(data.botox_map_json_image_id);
    props.setChild(data);
    //props.close()
    //setPosts(data);
    })
    .catch((err) => {
      console.log(err.message);
      //props.openFromChild();
      props.openErrorFromChild();
    });

  };

  if (saveBotoxID != null) {
    console.log("saveBotoxID is not null: ", saveBotoxID);
  }

  const pull_data = (data) => {
    //This is the function to get the color value from the BotoxType(child) component
    //This will need to be sent to the the dropdown/colorpicker boxes
    //console.log(data);
    setBotoxTypeSelect(data);
    // setColorArr(data);
  };


  const getBotoxImageFromChild = (data) => {
    //This is to get data from the BotoxMapImage component to change the 
    console.log(data);
    //console.log(dictCtx.dict_botox_map_src_image);
    //setBotoxImageSelect(data);
    //setBotoxImageSelect(text);

    //const srcProp = dictCtx.dict_botox_map_src_image;
    const srcProp = userPrefCtx.dictBotoxMapSrcImage;
    //console.log(srcProp)
    //console.log(dictCtx?.dict_botox_map_src_image);
    console.log(userPrefCtx?.dictBotoxMapSrcImage);
    //console.log(srcProp.length)
    let base64Data = []
    let fileNameArray = []

    for(let i = 0; i < srcProp.length; i++){
      // console.log(srcProp[i].id)
      // console.log(typeof srcProp[i].id);
      // console.log(typeof data);
      if(srcProp[i].id.toString() === data){
        //base64Data.push(srcProp[i].image_base64)
        fileNameArray.push(srcProp[i].file_name)
        //console.log(srcProp[i].image_base64)
      } else {
        //console.log(srcProp[i].id+ ' doesnt equal ' + data)
      }
    }

  //console.log(base64Data);
  console.log(fileNameArray[0]);

  // const imageURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${fileNameArray[0]}`
  // const imageURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${fileNameArray[0]}?dict_company_code_id=${userPrefCtx.userPreference.dict_company_code_id}&patient_id=${patCtx.patient_id}`
  // const imageURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${fileNameArray[0]}?dict_company_code_id=${userPrefCtx.userPreference.dict_company_code_id}`
  const imageURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${fileNameArray[0]}?dict_company_code_id=${companyCtx.company.id}`
  setBotoxImageSelect(imageURL);
  //setBotoxImageSelect(changeImageURL);
  //console.log(base64Data[0])

  }

  const checkRef = (data) => {
    //This is to check the ref for drawing mode, needs further implementation
    console.log("noteRef is ", noteRef.current.value);

    let noteValue = noteRef.current.value;

    console.log("noteValue is ", noteValue);
  };

  const exportImage = (data) => {
    // console.log("in the exportImage function");

    //This will add the color values to the left side
    if (props.colorScaleIncluded) {
        groupColorValues();
    }
    

    let imgUrl = editor.canvas.toDataURL({
      format: "png",
      quality: 0.8,
    });
    //console.log("url is ", imgUrl);

    //const url = window.URL.createObjectURL(imgUrl);
    const url = imgUrl;
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = DOMPurify.sanitize(url, {SAFE_FOR_JQUERY:true})
            // the filename you want
            a.download = 'botoxmaptest.png';
            // document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
  };

  const exportPPT = (data) => {

  }

  const groupColorValues = (e) => {
    var circle_010101 = "#010101";
    var circle_DC143C = "#DC143C";
    var circle_6F339D = "#6F339D";
    var circle_5BFF54 = "#5BFF54";
    var circle_2A84FF = "#2A84FF";
    var circle_FFFF00 = "#FFFF00";
    var circle_FC00FF = "#FC00FF";
    var circle_FFCCFF = "#FFCCFF";

    const blackVal = document.getElementById("010101").value;
    const redVal = document.getElementById("DC143C").value;
    const purpleVal = document.getElementById("6F339D").value;
    const greenVal = document.getElementById("5BFF54").value;
    const blueVal = document.getElementById("2A84FF").value;
    const yellowVal = document.getElementById("FFFF00").value;
    const pinkVal = document.getElementById("FC00FF").value;
    const lightpinkVal = document.getElementById("FFCCFF").value;

    circle_010101 = new fabric.Circle({
      left: 10,
      top: 58,
      radius: 3,
      fill: circle_010101,
      selectable: true,
      originX: "center",
      originY: "center",
    });
    circle_DC143C = new fabric.Circle({
      left: 10,
      top: 74,
      radius: 3,
      fill: circle_DC143C,
      selectable: true,
      originX: "center",
      originY: "center",
    });
    circle_6F339D = new fabric.Circle({
      left: 10,
      top: 90,
      radius: 3,
      fill: circle_6F339D,
      selectable: true,
      originX: "center",
      originY: "center",
    });
    circle_5BFF54 = new fabric.Circle({
      left: 10,
      top: 106,
      radius: 3,
      fill: circle_5BFF54,
      selectable: true,
      originX: "center",
      originY: "center",
    });
    circle_2A84FF = new fabric.Circle({
      left: 10,
      top: 122,
      radius: 3,
      fill: circle_2A84FF,
      selectable: true,
      originX: "center",
      originY: "center",
    });
    circle_FFFF00 = new fabric.Circle({
      left: 10,
      top: 138,
      radius: 3,
      fill: circle_FFFF00,
      selectable: true,
      originX: "center",
      originY: "center",
    });
    circle_FC00FF = new fabric.Circle({
      left: 10,
      top: 154,
      radius: 3,
      fill: circle_FC00FF,
      selectable: true,
      originX: "center",
      originY: "center",
    });
    circle_FFCCFF = new fabric.Circle({
      left: 10,
      top: 170,
      radius: 3,
      fill: circle_FFCCFF,
      selectable: true,
      originX: "center",
      originY: "center",
    });

    //var nameText = "Name: " + patCtx.pat_first + ' ' + patCtx.pat_last + "\n";
    var nameText = new fabric.IText("Name: " + patCtx.pat_first + ' ' + patCtx.pat_last, {
      // var nameText = new fabric.IText(patCtx.pat_first + ' ' + patCtx.pat_last, {
      left: 7,
      top: 35, // Adjust the top position as needed
      fontSize: 12, // Adjust the font size as needed
    });

    var textDate =
      "Black: " +
      blackVal +
      "\n" +
      "Red: " +
      redVal +
      "\n" +
      "Purple: " +
      purpleVal +
      "\n" +
      "Green: " +
      greenVal +
      "\n" +
      "Blue: " +
      blueVal +
      "\n" +
      "Yellow: " +
      yellowVal +
      "\n" +
      "Pink: " +
      pinkVal +
      "\n" +
      "LightPink: " +
      lightpinkVal;

    let myid = 2;

    var text = new fabric.IText(textDate, {
      left: 20,
      top: 50,
      name: "texting",
      id: myid,
      fontSize: 12,
    });

    var group = new fabric.Group(
      [
        circle_010101,
        circle_DC143C,
        circle_6F339D,
        circle_5BFF54,
        circle_2A84FF,
        circle_FFFF00,
        circle_FC00FF,
        circle_FFCCFF,
        text,
      ],
      {
        left: 10,
        top: 50,
        id: "group_circle_text",
      }
    );

    //var group = new fabric.Group([circle_010101, text], {
    //   var group;
    // if(props.sendAddNameCheck === true){
    // group = new fabric.Group(
    //   [
    //     nameText,
    //     circle_010101,
    //     circle_DC143C,
    //     circle_6F339D,
    //     circle_5BFF54,
    //     circle_2A84FF,
    //     circle_FFFF00,
    //     circle_FC00FF,
    //     circle_FFCCFF,
    //     text,
    //   ],
    //   {
    //     left: 10,
    //     top: 50,
    //     id: "group_circle_text",
    //   }
    // );
    // } else {
    //   group = new fabric.Group(
    //     [
    //       //nameText,
    //       circle_010101,
    //       circle_DC143C,
    //       circle_6F339D,
    //       circle_5BFF54,
    //       circle_2A84FF,
    //       circle_FFFF00,
    //       circle_FC00FF,
    //       circle_FFCCFF,
    //       text,
    //     ],
    //     {
    //       left: 10,
    //       top: 50,
    //       id: "group_circle_text",
    //     }
    //   );

    // }

    // if(props.sendAddNameCheck === true){
    //     group = new fabric.Group(
    //     [
    //       nameText,
    //       circle_010101,
    //       circle_DC143C,
    //       circle_6F339D,
    //       circle_5BFF54,
    //       circle_2A84FF,
    //       circle_FFFF00,
    //       circle_FC00FF,
    //       circle_FFCCFF,
    //       text,
    //     ],
    //     {
    //       left: 10,
    //       top: 50,
    //       id: "group_circle_text",
    //     }
    //   );
    // }

    editor.canvas.add(group);
  };


  const canvasHistory = [];

  const undo = () => {
    if (editor.canvas._objects.length > 0) {
      //This will take the object out of the canvas and push it to the array
      canvasHistory.push(editor.canvas._objects.pop());
    }
    editor.canvas.renderAll();
  };

  const redo = () => {
    if (canvasHistory.length > 0) {
      //This will take the object out of the array and put it in the canvas
      editor.canvas.add(canvasHistory.pop());
    }
  };

    const drawingModeChanged = (e) => {
        setRadioValue(e.currentTarget.value)

        switch (e.currentTarget.value) {
            case 'botox':
                editor.canvas.selection = false
                editor.canvas.on('mouse:down');
                editor.canvas.on('mouse:move');
                editor.canvas.on('mouse:up');
                // editor.canvas.forEachObject(function(o) {
                //     o.setCoords()
                // })
                editor.canvas.isDrawingMode = false
                break

            case 'freehand':
                editor.canvas.selection = false
                editor.canvas.off('mouse:down');
                editor.canvas.off('mouse:move');
                editor.canvas.off('mouse:up');

                editor.canvas.isDrawingMode = true
                let brush = editor.canvas.freeDrawingBrush
                brush.color = getCircleColor();
                brush.width = 3;
                break

            case 'off':
                editor.canvas.selection = true
                editor.canvas.off('mouse:down');
                editor.canvas.off('mouse:move');
                editor.canvas.off('mouse:up');
                editor.canvas.forEachObject(function(o) {
                    o.setCoords()
                })
                editor.canvas.isDrawingMode = false
                break
        }
        
    } 


  const childFunction = () => {
    //This is a function that will be called by useImperativeHandle and will then call saveBotox
    //console.log('Child function called!');
    saveBotox();
  };

  const childFunctionBotoxImage = () => {
    //This is a function that will be called by useImperativeHandle and will then call saveBotox
    console.log('Child function called!');
    getBotoxImageFromChild()
    //saveBotox();
  };

  useImperativeHandle(ref, () => ({
    //This is to pass the saveBotox function to the parent component
    childFunction
    ///childFunctionBotoxImage
    //saveBotox
  }));

  return (
      <div>
        <div className="prime_canvas_wrapper">
          <div className="canvas_wrapper">
            <FabricJSCanvas 
            //className="sample-canvas"
            id='botox-canvas'
            onReady={_onReady} 
            sendProps={botoxImageSelect}
            //fabric={fabric}
            //backgroundImage={text}
            backgroundImage={botoxImageSelect} 
            />
          </div>
        </div>
      {/* </div> */}

      <div className="buttons_below_canvas">
      <div className="container">
        {/* <div className="container"></div> */}
      <div className="botox_action_buttons_styling">
      {/* //make a className instead of style*/}
        <div>
          <div>
            <ButtonGroup >
                {radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        // variant="secondary"
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={drawingModeChanged}
                        className="btn btn-primary"
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
          </div>
        </div>
        
        <div>
          <div>
            <Button 
              id="undo-button"
              className={`btn btn-primary`}
              onClick={() => undo()}
            >
              Undo
            </Button>
          </div>
        </div>

        <div>
          <div>
            <Button 
              id="redo-button"
              className={`btn btn-primary`}
              onClick={redo}
            >
              Redo
            </Button>
          </div>
        </div>

        <div>
          <div>
            <Button 
              id="delete-button"
              className={`btn btn-primary`}
              onClick={onDeleteSelected}
            >
              Delete selected
            </Button>
          </div>
        </div>

        <div>
          <div>
            <Button 
              id="export-button"
              className={`btn btn-primary`}
              onClick={exportImage}
            >
              Export JPEG
            </Button>
          </div>
        </div>

        {/* <div>
          <div>
            <Button 
              id="powerpoint-button"
              className={`btn btn-primary`}
              //onClick={exportImage}
            >
              Export PowerPoint
            </Button>
          </div>
        </div> */}

        {props.sendEditBotoxMapData.botox_map_image_data === undefined && (
            // <div className="col">
              <div>
            <DictBotoxSrcImageUpload 
              visitTrigger={props.visitTrigger}
            />
            </div>
            )}

      </div>
      </div>
      </div>          
    </div>
  );
});

export default BotoxMap;
