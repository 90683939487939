import React, {useEffect, useState} from 'react'
import parse from 'html-react-parser'
import EFaceSlider from './EFaceSlider'

function EFaceGroup(props) {


    const [labels, setLabels] = useState([])
    const [labelValues, setLabelValues] = useState([])
    const [values, setValues] = useState()

    useEffect(() => {
        // console.log("FaceInstrumentQuestionGroup questions", props.questions, "question_values", props.question_values)
        setLabels(JSON.parse(props.questions[0].DictIntakeFormQuestion.question_option))
        setLabelValues(JSON.parse(props.questions[0].DictIntakeFormQuestion.question_option_values))

        // console.log("EFaceGroup: values", props.question_values)
        // const parse = require('html-react-parser')
        // setGroupHeader1(parse(props.questions[0].group_header1))

    }, [props.group_id, props.errors])



    const value_changed = (event) => {
        props.onChange(event.target.name, event.target.value)
    }

    return (
        <>
            {
                props.questions[0].DictIntakeFormQuestion.group_header1 && parse(`${props.questions[0].DictIntakeFormQuestion.group_header1}`)
            }
            {
                props.questions[0].DictIntakeFormQuestion.group_header2 && parse(`${props.questions[0].DictIntakeFormQuestion.group_header2}`)
            }
            {
                props.questions[0].DictIntakeFormQuestion.group_header3 && parse(`${props.questions[0].DictIntakeFormQuestion.group_header3}`)
            }
            <div className="row my-2 justify-content-center mx-0">
                <div className="col-md-12 text-center my-4">
                    <h3>
                        <div>{props.questions[0].DictIntakeFormQuestion.group_display_name}</div>
                    </h3>
                </div>

                {props.questions.map((q, index, allQs) => 
                    <div key={index} className="col-md-6 my-4">
                        <EFaceSlider question={q} onChange={props.onChange} value={props.question_values[q.DictIntakeFormQuestion.display_name_short]}/>
                    </div>
                )}

                
                
                
            </div>
        </>
    )
}

export default EFaceGroup
