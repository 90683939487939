import { useOktaAuth } from '@okta/okta-react';
import { cond } from 'lodash';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, Redirect, useNavigate, Navigate} from 'react-router-dom';
import AsyncSelect, { useAsync } from 'react-select/async';
import PatientContext from '../../../context/patientContext';
import UserPrefContext from '../../../context/userPrefContext';
import AuthContext from '../../../context/authContext';
import CompanyContext from '../../../context/companyContext';

const SearchBar = (props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [token, setToken] = useState(null);
  const [inputValue, setValue] = useState('');
  const [eventInput, setEventInput] = useState([]);
  const [patdob, setPatdob] = useState();
  const [patMRN, setPatMRN] = useState();
  const [patientData, setPatientData] = useState();
  const authCtx = useContext(AuthContext)
  const companyCtx = useContext(CompanyContext)

  //const selectRef = useRef(null);

  const navigate = useNavigate();
  let url = '';
  let postURL = '';

  const patCtx = useContext(PatientContext);
  const userPrefCtx = useContext(UserPrefContext)

//   console.log("SearchBar - userPrefCtx", userPrefCtx)

//   useEffect(() => {
//       if (!authState || !authState.isAuthenticated) {  
//       setToken(null);
//     } else {
//       setToken(oktaAuth.getAccessToken());
//     }
//   }, [authState, oktaAuth]);

  useEffect(() => {
    //console.log(props)
  }, [props.randomProps]);

  const processAuthMode = async(e) => {

    const regex = /\d+/g; // regular expression to match digits
    let matches = e.match(regex)
    //let matches2 = matches.splice(0, 1);
    //console.log(matches)

    const inputValueArray = e.split(',');
    //console.log(inputValueArray)

    const splitInputValueArrayBySpace = e.split(' ');

    // url = process.env.REACT_APP_SERVER + `/api/v1/search_services/patient_search_with_site_loc?search_token=${e}&patient_search_mode=${companyCtx.company.patient_search_mode}`;
    url = process.env.REACT_APP_SERVER + `/api/v1/search_services/patient_search_with_site_loc_epic?search_token=${e}&patient_search_mode=${companyCtx.company.patient_search_mode}`;

    if((authCtx.authMode === 'DB') && (e.length > 0)){
      //console.log(authCtx.authMode)

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userPrefCtx.bearerToken}`,
          auth: authCtx.authMode,
        },
      })

      const jsonResponse = await response.json()
      if ( jsonResponse.response_code === 1) {
        //console.log(jsonResponse.response_data)
        return jsonResponse.response_data
      } else{
          //console.log('cannot find anything');
      }
    } else if ((authCtx.authMode === 'AD' || authCtx.authMode === 'OKTA') && ((inputValueArray.length > 1 && inputValueArray[0].trim().length > 1 && inputValueArray[1].trim().length > 0) ||
          (splitInputValueArrayBySpace.length > 1 && splitInputValueArrayBySpace[0].trim().length > 1 && splitInputValueArrayBySpace[1].trim().length > 0) ||
          (matches && matches[0]?.length >= 3)
          )) {
            // console.log('in AD')
            // searchHandler(e)
            const response = await fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                auth: authCtx.authMode,
              },
            })
      
            const jsonResponse = await response.json()
            if ( jsonResponse.response_code === 1) {
              //console.log(jsonResponse.response_data)
              return jsonResponse.response_data
            } else{
                //console.log('cannot find anything');
            }
          }

  }

  const searchHandler = async (e) => {
    console.log(e)
    //console.log(authCtx.authMode)

    setEventInput(e);
    // url = process.env.REACT_APP_SERVER + `/api/v1/search_services/patient_search_with_site_loc?search_token=${e}&patient_search_mode=${companyCtx.company.patient_search_mode}`;
    url = process.env.REACT_APP_SERVER + `/api/v1/search_services/patient_search_with_site_loc_epic?search_token=${e}&patient_search_mode=${companyCtx.company.patient_search_mode}`;
    //console.log(typeof e)

    const regex = /\d+/g; // regular expression to match digits
    let matches = e.match(regex)
    //let matches2 = matches.splice(0, 1);
    //console.log(matches)

    const inputValueArray = e.split(',');
    //console.log(inputValueArray)

    const splitInputValueArrayBySpace = e.split(' ');
    // console.log('splitInputValueArrayBySpace: ', splitInputValueArrayBySpace);
    // console.log('splitInputValueArrayBySpace[0].trim().length: ', splitInputValueArrayBySpace[0].trim().length);
    // console.log('splitInputValueArrayBySpace[1].trim().length: ', splitInputValueArrayBySpace[1].trim().length);

    // This will only send requests after you have atleast a character, a comma, and 2 characters following the comma
    // if ((inputValueArray.length > 1 && inputValueArray[1].trim().length > 1) ||

    // This will only send requests after you have atleast 2 characters for last name, a comma, and 1 character following the comma
    // if ((inputValueArray.length > 1 && inputValueArray[0].trim().length > 1 && inputValueArray[1].trim().length > 0) ||
    //     (matches && matches[0]?.length >= 3)
    //     ) {

      //This is the typical search criteria for AD Mode
      // if ((inputValueArray.length > 1 && inputValueArray[0].trim().length > 1 && inputValueArray[1].trim().length > 0) ||
      //     (splitInputValueArrayBySpace.length > 1 && splitInputValueArrayBySpace[0].trim().length > 1 && splitInputValueArrayBySpace[1].trim().length > 0) ||
      //     (matches && matches[0]?.length >= 3)
      //     ) {

      if (e.length > 0) {

            //console.log('matches')

      const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userPrefCtx.bearerToken}`,
            auth: authCtx.authMode,
          },
        })

        const jsonResponse = await response.json()
        if ( jsonResponse.response_code === 1) {
          console.log(jsonResponse.response_data)
          return jsonResponse.response_data
        } else{
            console.log('cannot find anything');
        }
      }// else {
        //console.log('doesnt match')
        // if (matches && matches[0]?.length >= 3) {
        //   console.log("User entered 3 or more integers");
        //   const response = await fetch(url, {
        //     method: 'GET',
        //     headers: {
        //       'Content-Type': 'application/json',
        //       Authorization: `Bearer ${token}`,
        //     },
        //   })
  
        //   const jsonResponse = await response.json()
        //   if ( jsonResponse.response_code === 1) {
        //     //console.log(jsonResponse.response_data)
        //     return jsonResponse.response_data
        //   }
        // } else{
        //   console.log("doesnt match");
        //   console.log(matches.length)
        // }
     }
   // }

  const dropdownOptions = (e) => {
    //console.log('this is the value of e in dropdown options = ', e);
    return ` ${e.first_name} ${e.last_name}, MRN: ${e.local_site_mrn}, Gender: ${e.gender}, DOB: ${new Date(e.dob).toISOString().split("T")[0]}`
  }

    // handle input change event
    const handleInputChange = (e) => {
      setValue(e);
      // console.log('handleInputChange value of e is: ', e);
    };

  // handle selection
  const handleChange = async (e) => {

      postURL = process.env.REACT_APP_SERVER + `/api/v1/patients/creat_patient_with_mrns`;
      //console.log(postURL)

      //check if dob empty or alert if it is empty that date is empty and cant process it 
    if ((e?.dob != null) || (e?.local_site_mrn != null) || (e?.first_name != null) || (e?.last_name != null) || (e?.gender != null)) 
      {
    //   var strdob = e.dob;
    //   strdob = strdob.split('/');
    //   var dobresult = strdob[2] + '-' + strdob[0] + '-' + strdob[1];
      patCtx.pat_dob = new Date(e.dob).toISOString().split("T")[0] //e.dob
      patCtx.pat_mrn = e.local_site_mrn
      patCtx.pat_first = e.first_name
      patCtx.pat_last = e.last_name
      patCtx.pat_gender = e.gender
      patCtx.pat_email = e.email
      patCtx.pat_local_site_mrn = e.local_site_mrn
      patCtx.pat_active_mrn = e.active_mrn
      patCtx.pat_all_mrn = e.all_mrn

    //   console.log('e.all_mrn', e)

      const responseData = await fetch(postURL, {
        method: 'POST',
        body: (JSON.stringify({
          "emr_mrn": e.local_site_mrn,
          "first_name": e.first_name,
          "last_name": e.last_name,
          "gender": e.gender,
        //   "dob": dobresult,
            dob: new Date(e.dob).toISOString().split("T")[0],
          "all_mrn": e.all_mrn,
        })),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userPrefCtx.bearerToken}`,
          auth: authCtx.authMode,
        },
      }).then((res) => res.json())
      .then((data) => {
         //console.log('postURL data: ', data);

         //this is to set to the useState
         setPatientData(data);
        //console.log('patientData from SearchBar', patientData)
        //console.log('patientData from SearchBar: ', data)

        patCtx.pat_token = data.patient_token
        patCtx.pat_first = data.first_name
        patCtx.pat_last = data.last_name
        patCtx.pat_middle = data.middle_name !== null ? data.middle_name : ''
        patCtx.pat_mrn = data.emr_mrn
        patCtx.pat_dob = data.dob
        patCtx.pat_gender = data.gender
        patCtx.pat_email = data.email
        patCtx.pat_id = data.id
        patCtx.pat_profile_image_filename = data.file_name 
        patCtx.pat_email = data.email
        patCtx.pat_active_mrn = data.emr_mrn
        patCtx.pat_local_site_mrn = data.emr_mrn
        



        function formatDate(dateString) {
          //This function will convert yyyy-mm-dd to mm/dd/yyyy
          const [year, month, day] = dateString.split("-");
          return `${month}/${day}/${year}`;
        }

        const formattedDate = formatDate(data.dob);

         //console.log('patCtx in searchbar', patCtx)

      navigate('/Visit', 
      {state: {
        firstName: data.first_name,
        lastName: data.last_name,
        middleName: data.middle_name !== null ? data.middle_name : '',
        mrn: data.emr_mrn,
        dob: formattedDate,
        gender: data.gender,
        patientToken: data.patient_token,
        bearerToken: props.sendBearerToken,
        patId: data.id,
        profileImageFileName: data.file_name,
        authMode: authCtx.authMode,
      }})

      // if (selectRef.current) {
      //   console.log('trying to clear it', selectRef.current.value)

      //   selectRef.current.select.clearValue();
      // }

      // if (selectRef.current && selectRef.current.select) {
      //   console.log('trying to clear it', selectRef.current.value)
      //   selectRef.current.select.clearValue();
      // }
      
      
      })
      .catch((err) => {
         console.log(err.message);
      });

      //console.log('patient data is ', patientData);

  } else{
      if(e?.first_name === null) {
        alert('First name is undefined!')
      }
      if(e?.last_name === null) {
        alert('First name is undefined!')
      }
      if(e?.local_site_mrn === null) {
        alert('MRN is undefined!')
      }
      if(e?.dob === null) {
        alert('DOB is undefined!')
      }
      if(e?.gender === null) {
        alert('Gender is undefined!')
      }
  }
  }
  
  return (
    <div key={props.randomProps}>
      <PatientContext.Provider 
      value={{
        pat_dob: patdob
      }}
    >
      <AsyncSelect
        //ref={selectRef}
        isClearable
        //loadOptions={searchHandler}
        loadOptions={processAuthMode}
        placeholder={"Type Name (Last, First)"}
        getOptionValue={dropdownOptions}
        getOptionLabel={dropdownOptions}
        onInputChange={handleInputChange}
        onChange={handleChange}
        //onChange={processAuthMode}
      />
      </PatientContext.Provider>
    </div>
  );
}


export default SearchBar;

