import {createContext, useState, useContext} from 'react'

const UserPrefContext = createContext()

export const UserPrefContextProvider = ({children}) => {
    
    const [userPrefState, setUserPrefState] = useState(null)
    const [oktaUserInfo, setOktaUserInfo] = useState(null)
    const [contextBearerToken, setContextBearerToken] = useState(null)
    const [groups, setGroups] = useState([]) 
    const [deviceType, setDeviceType] = useState(null)

    const setAllUserPreferenceFunction = (all_dicts) => {
        //console.log("AllUserPreference ", all_dicts)
        setUserPrefState(all_dicts)
    }
    const setOktaUserInfoFunction = (user_info) => {
        setOktaUserInfo(user_info)
    }
    const setBearerTokenFunction = (value) => {
        //console.log("Bearer token", value)
        setContextBearerToken(value)
    }

    const setUserGroups = (user_groups) => {
        setGroups(user_groups)
        // console.log("UserPrefContext - setting up user groups as ", user_groups)
    }

    const setDeviceTypeFunction = (deviceType) => {
        setDeviceType(deviceType)
        // console.log("UserPrefContext - setting up user groups as ", user_groups)
    }

    // change just site_loc value in UserPreference section of UserPreferenceContext
    // newSiteLoc is just a string (value), bot an object
    const setUserSiteLoc = (newSiteLoc) => {
        setUserPrefState({...userPrefState, UserPreference: {...userPrefState.UserPreference, site_loc: newSiteLoc}})
    }

    const userGroups = groups

    const dictBotoxMapSrcImage = userPrefState !== null ? userPrefState.DictBotoxMapSrcImage : []
    const user = userPrefState !== null ? userPrefState.User : []
    const userPreference = userPrefState !== null ? userPrefState.UserPreference : {}
    const userPreferenceDictPanel = userPrefState !== null ? userPrefState.UserPreferenceDictPanel : []

    const email = oktaUserInfo !== null ? oktaUserInfo.email : ''
    const emailVerified = oktaUserInfo !== null ? oktaUserInfo.email_verified : ''
    const familyName = oktaUserInfo !== null ? oktaUserInfo.family_name : ''
    const givenName = oktaUserInfo !== null ? oktaUserInfo.given_name : ''
    const locale = oktaUserInfo !== null ? oktaUserInfo.locale : ''
    // const name = oktaUserInfo !== null ? oktaUserInfo.name : []
    const preferredUsername = oktaUserInfo !== null ? oktaUserInfo.preferred_username : ''
    // const sub = oktaUserInfo !== null ? oktaUserInfo.sub : []
    const updatedAt = oktaUserInfo !== null ? oktaUserInfo.updated_at : ''
    const zoneInfo = oktaUserInfo !== null ? oktaUserInfo.zoneinfo : ''

    const bearerToken = contextBearerToken !== null ? contextBearerToken : ''

    const dictDeviceType = deviceType !== null ? deviceType : ''

    

    return <UserPrefContext.Provider 
        value={{
            setAllUserPreferenceFunction,
            setOktaUserInfoFunction,
            setBearerTokenFunction,
            setUserGroups,
            userGroups,
            setUserPrefState,
            userPrefState,
            contextBearerToken,
            oktaUserInfo,
            dictBotoxMapSrcImage,
            user,
            userPreference,
            userPreferenceDictPanel,
            bearerToken,
            setDeviceTypeFunction,
            dictDeviceType,
            email,
            emailVerified,
            familyName,
            givenName,
            locale,
            // name,
            preferredUsername,
            // sub,
            updatedAt,
            zoneInfo,
            setUserSiteLoc,
        }}>
        {children}
    </UserPrefContext.Provider>
}

export default UserPrefContext