import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import AddNewUserModal from './AddNewUserModal';
import ErrorDialogModal from '../ErrorDialogModal';
import ChangeUserPW from './ChangeUserPW';
//import UsersList from '../../tables/UsersList';
//import UsersList from '../../tables/UsersList';
import SystemUsersList from '../../tables/SystemUserList';
import PatientContext from '../../../../context/patientContext';
import UserPrefContext from '../../../../context/userPrefContext';
import DictionaryContext from '../../../../context/dictionaryContext';
import AuthContext from '../../../../context/authContext';
//import ChangeUserPassword from './ChangeUserPassword';
// import UserPrefContext from '../../../context/userPrefContext';

function SystemMaintenance(props) {
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [usersList, setUsersList] = useState();
  const [sendObjectToPW, setSendObjectToPW] = useState();
  const [sendIDToPW, setSendIDToPW] = useState();
  const [editData, setEditData] = useState([]);
  const [headerMapping, setheaderMapping] = useState([])
  // const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState([]);
  const [formValues, setFormValues] = useState({});

  const [addUserUpdate, setAddUserUpdate] = useState();

  const patCtx = useContext(PatientContext);
  const userPrefCtx = useContext(UserPrefContext);
  const dictCtx = useContext(DictionaryContext);
  const authCtx = useContext(AuthContext);

  //console.log(props)

  const handleClose = () => setShow(false);
  const handleShow = () => {
    //setting the id to null when the new botox button is clicked
    //setEditData([null]);
    setShow(true);
    //console.log(editData)
    //setPanelID(null);
    //setEditData([null]);
  }

  const closeCurrentModal = () => {
    //This will disable all the save buttons when the modal is exited
    if(usersList?.length > 0) {
      setIsSaveDisabled(Array(Object.keys(usersList).length).fill(true));
    }
    props.close();
  }

  const handleChangePasswordClose = () => setOpenChangePassword(false);

  const handleChangePassword = (e) => {

    //console.log(e)

    let idForURL = document.getElementById('id_' + e).getAttribute('data-value');
    //console.log(idForURL)
    //console.log(idForURL.value)
    //This is to send the id to the password change modal so it can use it in the patch url
    setSendIDToPW(idForURL);
  
    //console.log(e)
  
    let saveUserURL = `${process.env.REACT_APP_SERVER}/api/v1/users/${idForURL}`;
  
    //This is find the user based on the id, and then it will be used to insert into list of objects after headerloop
    let findUserForToken = usersList.find(obj => obj['id'].toString() === idForURL);
  
    // console.log(typeof usersList[0].id)
    // console.log(typeof idForURL)
  
    // console.log(findUserForToken);
    // console.log(findUserForToken.user_token);
  
  
    const headerLoop = headerMapping.map((val, index) => 
    {
      //console.log(val)
      let obj = {}
      //let obj;
      const textElement = document.getElementById('text_' + val[1]+'_'+e);
      const checkBoxElement = document.getElementById('checkbox_' + val[1]+'_'+e);
      //console.log('text_' + val[1]+'_'+e)
      //console.log(textElement)
      if(textElement){
        //console.log(textElement.value)
        //obj[val[1]] = textElement.value;
        obj[val[1]] = textElement.value;
      }
      if(checkBoxElement){
        //console.log(checkBoxElement.value)
       // console.log(checkBoxElement.checked)
        //obj[val[1]] = checkBoxElement.checked;
        obj[val[1]] = checkBoxElement.checked;
      }
      return obj;
    }, {});
  
    //console.log(headerLoop);
    
    //inserting the user token since it's not a part of the header
    headerLoop.push({user_token: findUserForToken.user_token});
    //console.log(headerLoop);
    //At this point, the password change modal should get the list of objects, including the token so then the password field can be added later
    setSendObjectToPW(headerLoop)
  
    const mergedObject = headerLoop.reduce((result, currentObject) => {
      return { ...result, ...currentObject };
    }, {});
  
    //console.log(mergedObject)
  
    const sendObject = JSON.stringify(mergedObject);
    //setSendObjectToPW(mergedObject)

    setOpenChangePassword(true);
  }

  const usersURL = process.env.REACT_APP_SERVER + "/api/v1/users/";

  useEffect(async() => {
    if (userPrefCtx.bearerToken && props.setOpen) {
      //console.log(userPrefCtx.bearerToken)
      const headers = { Authorization: `Bearer ${userPrefCtx.bearerToken}` };

      const responseData = await fetch(usersURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userPrefCtx.bearerToken}`,
          auth: authCtx.authMode
          //Authorization: `Bearer ${promptCtx.pat_bearer_token}`,
        },
      }).then((res) => res.json())
      .then((data) => {
         //console.log(data);
         setUsersList(data);
         setIsSaveDisabled(Array(Object.keys(data).length).fill(true));
         //setIsSaveDisabled(Array(data.length).fill(true));
      })
      .catch((err) => {
         console.log(err.message);
         //setShowError(true);
      });


    }
    else {
      //console.log('no token')
    } //props.sendDataBack is to give an update from the parent when the you add a user from the child
}, [userPrefCtx.bearerToken, addUserUpdate, props.sendDataBack, props.setOpen, props.sendAnotherUpdate]);

// useEffect(() => {
//   if(isSaveDisabled.length > 0) {
//     setIsSaveDisabled(Array(Object.keys(usersList).length).fill(true));
//   }
// }, [usersList]);

useEffect(() => {
  // console.log("EFaceList - getting column mappings")
  // setheaderMapping(props.headerMapping)
  if (dictCtx.dict_table.length > 0) {
      //This goes through the array of objects to find the one that contains the user_maintenance
      const filterVar = dictCtx?.dict_table?.filter(obj => obj.column_name_short === 'user_maintenance');
      //console.log(filterVar)
      // setheaderMapping(JSON.parse(dictCtx.dict_table[2].column_name))
      // console.log(dictCtx.dict_table[2].column_name);

      //It gets the first index because it will only return one object from the filter
      setheaderMapping(JSON.parse(filterVar[0]?.column_name))
  }
  
}, [/*props.headerMapping*/ dictCtx.dict_table.length, addUserUpdate, props.sendDataBack])

const updateListFunction = (e) => {
  //This function is to update the state that will update the useeffect, will close the child modal first
  handleClose();
  setAddUserUpdate(e);
  props.updateParent(e);
  //console.log(e);
}

const clickOnSwitch = (e) => {
  //console.log("e.target.checked is ", e.target.checked)
  //console.log("e.target.value is ", e.target.value)
}

const handleInputChange = (e) => {
  // Enable Save button when any text field changes
  //setIsSaveDisabled(false); 
  const updatedSaveDisabled = [...isSaveDisabled];
  updatedSaveDisabled[e] = false;
  setIsSaveDisabled(updatedSaveDisabled);
};

// const handleInputChange = (i) => (event) => {
//   console.log(i)
//   const updatedSaveDisabled = [...isSaveDisabled];
//   updatedSaveDisabled[i] = false;
//   setIsSaveDisabled(updatedSaveDisabled);


//   const { name, value } = event.target;
//   const updatedFormValues = { ...formValues, [i]: { ...formValues[i], [name]: value } };
//   setFormValues(updatedFormValues);
// };

// const handleInputChange = (item, i) => (event) => {
//   const { name, value } = event.target;
//   const updatedFormValues = { ...formValues, [item]: { ...formValues[item], [name]: value } };
//   setFormValues(updatedFormValues);

//   const isSaveDisabledUpdated = { ...isSaveDisabled, [item]: Object.values(updatedFormValues[item]).some((fieldValue) => fieldValue === '') };
//   setIsSaveDisabled(isSaveDisabledUpdated);
// };

const clickSave = async (e) => {

  const updatedSaveDisabled = [...isSaveDisabled];
  updatedSaveDisabled[e] = true;
  setIsSaveDisabled(updatedSaveDisabled);
  // let dataObject = e.target.getAttribute('data-obj');
  // console.log(dataObject)

  //let rowData = document.getElementById('system-user-row').value;
  //console.log(rowData);
  // let idForURL = document.getElementById('id_'+ e);
  let idForURL = document.getElementById('id_' + e).getAttribute('data-value');
  //console.log(idForURL)
  //console.log(idForURL.value)

  //console.log(e)

  let saveUserURL = `${process.env.REACT_APP_SERVER}/api/v1/users/${idForURL}`;

  //This is find the user based on the id, and then it will be used to insert into list of objects after headerloop
  let findUserForToken = usersList.find(obj => obj['id'].toString() === idForURL);

  // console.log(typeof usersList[0].id)
  // console.log(typeof idForURL)

  // console.log(findUserForToken);
  // console.log(findUserForToken.user_token);


  const headerLoop = headerMapping.map((val, index) => 
  {
    //console.log(val)
    let obj = {}
    //let obj;
    const textElement = document.getElementById('text_' + val[1]+'_'+e);
    const checkBoxElement = document.getElementById('checkbox_' + val[1]+'_'+e);
    //console.log('text_' + val[1]+'_'+e)
    //console.log(textElement)
    if(textElement){
      //console.log(textElement.value)
      //obj[val[1]] = textElement.value;
      obj[val[1]] = textElement.value;
    }
    if(checkBoxElement){
      //console.log(checkBoxElement.value)
      //console.log(checkBoxElement.checked)
      //obj[val[1]] = checkBoxElement.checked;
      obj[val[1]] = checkBoxElement.checked;
    }
    return obj;
  }, {});

  //console.log(headerLoop);
  
  //inserting the user token since it's not a part of the header
  headerLoop.push({user_token: findUserForToken.user_token});
  //console.log(headerLoop);

  const mergedObject = headerLoop.reduce((result, currentObject) => {
    return { ...result, ...currentObject };
  }, {});

  //console.log(mergedObject)

  const sendObject = JSON.stringify(mergedObject);

  const responseData = await fetch(saveUserURL, {
    method: 'PATCH',
     body: sendObject,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userPrefCtx.bearerToken}`,
      auth: authCtx.authMode
    },
  // }).then((res) => res.json())
}).then((res) => {
    // Check for 422 or 500 response
    if (!res.ok) {
      // if (res.status === 422) {
      //   throw new Error('Unprocessable Entity: ' + res.statusText);
      // } else if (res.status === 500) {
      //   throw new Error('Internal Server Error: ' + res.statusText);
      // } else {
      //   throw new Error('Unknown Error Occurred.');
      // }
      setShowError(true);
    }
    return res.json();
  })
  .then((data) => {
     //console.log(data);
     //props.sendTrigger(data);
     //props.close();
  })
  .catch((err) => {
     //console.log(err.message);
     setShowError(true);
  });

}


  return (
    <>
    <AddNewUserModal 
      setOpen={show}
      close={handleClose}
      closeAndUpdateParent={updateListFunction}
    />
    <ChangeUserPW
      setOpen={openChangePassword}
      close={handleChangePasswordClose}
      usersList={usersList}
      sendObject={sendObjectToPW}
      sendIDToPW={sendIDToPW}
      //closeAndUpdateParent={updateListFunction}
    />
    <ErrorDialogModal
      setOpen={showError}
      close={() => setShowError(false)}
      componentName="System Maintenance"
    />
      <Modal 
        size="fullscreen" 
        show={props.setOpen} 
        //onHide={props.close} 
        onHide={closeCurrentModal}
        animation={false} 
        style={{ visibility: (show || openChangePassword || showError) ? 'hidden' : 'visible' }}
        >
        <Modal.Header closeButton>
          <Modal.Title>System Maintenance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Button variant="primary" 
            onClick={handleShow}
            //This button should open the System Maintenance Modal
        >
            Add New Users
        </Button>
          {/* <SystemUsersList sendUsers={usersList} sendHeader={headerMapping}/> */}
        { usersList != null &&
      <Table bordered hover>
      <thead>
        <tr>
          {headerMapping.map((val, index) =>
              <th scope="col" key={index} className='mx-4 px-10 text-left'>{val[0]}</th> 
          )}
        </tr>
      </thead>
      <tbody>
        {
        Object.keys(usersList).map((item, i) => (
                                
        <tr 
          //key={i + Math.random()} 
          key={'user_'+i + usersList[item].id} 
          id='system-user-row'
          value={item}
          //className='no-border'
          //This removes the borders, but you can comment out to bring the table back
          className="no-border-between-columns"
        >
          {headerMapping.map((val, index) => {
              if (["checkbox"].includes(val[2])) {
              return (
                <td scope="col" key={index} className='mx-4 px-10 text-left'>
                  <div className="form-check form-switch">
                    <input 
                      className="form-check-input" 
                      type="checkbox" 
                      role="switch" 
                      //id="flexSwitchCheckDefault" 
                      defaultChecked={usersList[item][val[1]]} 
                      id={'checkbox_'+val[1]+'_'+i}
                      //defaultChecked={usersList[item]['is_'+val[1]]} 
                      onClick={clickOnSwitch}
                      onChange={() => handleInputChange(i)}
                    />
                  </div>
                </td>
              );
            } else if(["text"].includes(val[2])) {
              return (
                <td scope="col" 
                  key={index} 
                  className='mx-4 px-10 text-left' 
                  //id={'text_'+val[1]}
                  data-obj={JSON.stringify(usersList[item][val[1]])}
                >
                <Form.Control
                  type="text" 
                  defaultValue={usersList[item][val[1]]} 
                  id={'text_'+val[1]+'_'+i}
                  //onChange={handleInputChange}
                  onChange={() => handleInputChange(i)}
                  //onChange={handleInputChange(i)}
                 />
                </td>
              );
            }
            else {
              return (
                <td 
                  scope="col" 
                  key={index} 
                  className='mx-4 px-10 text-left' 
                  id={val[1]+'_'+i} 
                  //value={usersList[item][val[1]]}
                  data-value={usersList[item][val[1]]}
                >
                  {usersList[item][val[1]]} {headerMapping[val]}
                </td>
              );
            }
          })}
          <td>
          <Button 
            variant="primary"
            //disabled={isSaveDisabled}
            disabled={isSaveDisabled[i]}
            // onClick={clickSave}
            onClick={() => clickSave(i)}
            //onClick={() => clickSave(formValues[i])}
            //onClick={() => clickSave(formValues[item])}
            size="lg"

          >
            Save
          </Button>
          </td>
          <td>
          <Button 
            variant="warning" 
            //onClick={handleChangePassword}
            onClick={() => handleChangePassword(i)}
            size="sm"
          >
            Change Password
          </Button>
          </td>
        </tr>
        ))
        }
        
      </tbody>
    </Table>
    }
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            //onClick={props.close}
            onClick={closeCurrentModal}
            >
            Close
          </Button>
          {/* <Button variant="secondary" onClick={deleteBotox}>
            Confirm
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SystemMaintenance;