import React, {useEffect, useState} from 'react'
import parse from 'html-react-parser'
import GrbasQuestionRow from './GrbasQuestionRow'



function GrbasQuestionGroup(props) {
    const [headers, setHeaders] = useState([])
    const [grbasData, setGrbasData] = useState([])


    useEffect(() => {

        // console.log("CapeVQuestionGroup PROPS", props)

        // console.log("Grbas Question Group", props.group_id, "DATA", props.data)
        let columns = []
        let qtypes = []
        let qvalues = []
        // in questions - if question_option is array - use it as column headers
        if (props.questions.length > 0) {
            if (props.questions[0].DictIntakeFormQuestion.question_option !== null) {
                if (props.questions[0].DictIntakeFormQuestion.question_option.startsWith('[')) {
                    // we have an array of headers - parse it
                    columns = JSON.parse(props.questions[0].DictIntakeFormQuestion.question_option)
                    // console.log("COLUMNS", columns)
                    // make sure that we have arrays in question_type and possibly in question_option_values
                    if (props.questions[0].DictIntakeFormQuestion.question_type !== null && props.questions[0].DictIntakeFormQuestion.question_type.startsWith('[')) {
                        qtypes = JSON.parse(props.questions[0].DictIntakeFormQuestion.question_type)
                    }

                    
                }
            }
        }

        setHeaders(columns)
        setGrbasData(props.data)


    }, [props.group_id, props.questions, props.data])



    // called from the child component to update values
    const updateValues = (new_values) => {
        props.onChange(new_values)
    }

    



    return (
        <div>
            {
                props.questions[0].DictIntakeFormQuestion.group_header1 && parse(`${props.questions[0].DictIntakeFormQuestion.group_header1}`)
            }
            {
                props.questions[0].DictIntakeFormQuestion.group_header2 && parse(`${props.questions[0].DictIntakeFormQuestion.group_header2}`)
            }
            {
                props.questions[0].DictIntakeFormQuestion.group_header3 && parse(`${props.questions[0].DictIntakeFormQuestion.group_header3}`)
            }

            <div className="no_border">
                {headers.length > 0 && (
                <table className="table table-sm table-borderless">
                    <thead>
                        <tr>
                            {headers.map((hdr,index) => 
                                <td scope="col" key={index}>{hdr}</td>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {props.questions.map((q, row_index) => {
                            return (
                                <GrbasQuestionRow 
                                    key={row_index}
                                    question={q}
                                    onValuesUpdate={updateValues}
                                    data={grbasData}
                                    type='table'
                                    readOnly={props.readOnly}
                                />
                            )
                        })}
                    </tbody>
                </table>
                )}

                {
                    headers.length === 0 && (

                            props.questions.map((q, row_index) => {
                            return (

                                <GrbasQuestionRow 
                                    key={row_index}
                                    question={q}
                                    onValuesUpdate={updateValues}
                                    data={grbasData}
                                    type='single'
                                    readOnly={props.readOnly}
                                />

                            )

                        })

                    )
                }
            </div>

            <hr />
        </div>
    )
}

export default GrbasQuestionGroup
