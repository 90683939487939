import React from "react";

const PatientContext = React.createContext({
  pat_last: '',
  pat_first: '',
  pat_middle: '',
  pat_mrn: '',
  pat_gender: '',
  pat_dob: '',
  pat_token: '',
  pat_id: 0,
  pat_profile_image_filename: '',
  pat_email: '',

  //These are added fields to adjust for api change
  pat_active_mrn: '',
  pat_local_site_mrn: '',
  pat_all_mrn: {},
});

export const PatientContextProvider = (props) => {

  const contextValue = {
    pat_first,
    pat_last,
    pat_middle,
    pat_mrn,
    pat_gender,
    pat_dob,
    pat_token,
    pat_id,
    pat_profile_image_filename,
    pat_email,
    pat_active_mrn,
    pat_local_site_mrn,
    pat_all_mrn,
  };

  return (
    <PatientContext.Provider value={contextValue}>
      {props.children}
    </PatientContext.Provider>
  );
};

export default PatientContext;