import React, { useState, useContext, useEffect } from "react";
import PatientContext from "../../../context/patientContext";
import UserPrefContext from "../../../context/userPrefContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import VisitNotesDetail from "./VisitNotesDetail";
import VisitMediaDetail from "./VisitMediaDetail";
import ErrorDialogModal from "../modals/ErrorDialogModal";
import DictionaryContext from "../../../context/dictionaryContext";
import AuthContext from "../../../context/authContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {BsSignStopFill} from 'react-icons/bs'
import ConfirmDialog from "../shared/ConfirmDialog";

function VisitDialog(props) {
    const visitDefaults = {
        visit_data: {
            Visit: {
                epic_visit_type: null,
                visit_type: null,
                is_image_visit: null,
                is_video_visit: null,
                is_document_visit: null,
                is_active: true,
                visit_date: new Date().toISOString().split("T")[0],
            },
            Note: [],
            Images: [],
            video: [],
            Document: [],
        },
    };

    const [visitDate, setVisitDate] = useState(new Date());
    const [visit, setVisit] = useState(visitDefaults);
    const [visitData, setVisitData] = useState({});
    const [mode, setMode] = useState("new");
    const [noteText, setNoteText] = useState('')
    const [notePDF, setNotePDF] = useState('')
    const [visitType, setVisitType] = useState('')
    const [isEpicVisit, setIsEpicVisit] = useState(false)
    // use visitDataChanged to signal that visits should be releaded because some data has been changed in the child components
    const [visitDataChanged, setVisitDataChanged] = useState(false)
    const [readOnly, setReadOnly] = useState(false)
    const [showError, setShowError] = useState(false)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const patCtx = useContext(PatientContext)
    //const visCtx = useContext(VisitContext)
    // const userPrefCtx = useContext(UserPrefContext)

    // const {dictionaries, dict_visit_name} = useContext(dictionaryContext)
    const userPrefCtx = useContext(UserPrefContext)
    const { dict_visit_name } = useContext(DictionaryContext);
    const authCtx = useContext(AuthContext)

    const createVisitURL = `${process.env.REACT_APP_SERVER}/api/v1/visits/visit_with_note/${patCtx.pat_token}`;
    const updateVisitURL = (id) =>
        `${process.env.REACT_APP_SERVER}/api/v1/visits/${id}`;
    const deleteVisitURL = (id) =>
        `${process.env.REACT_APP_SERVER}/api/v1/visits/${id}`;
    const getVisitURL = (id) =>
        `${process.env.REACT_APP_SERVER}/api/v1/visits/with_media/${id}`;

    const [visitTypes, setVisitTypes] = useState([]);

    const httpHeaders = (bearerToken) => {
        return {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`,
            accept: "application/json",
            auth: authCtx.authMode,
        }
    }

    useEffect(() => {
        //console.log("Visit Dialog props", props)
        if (props.visit_id > 0) {
            // get visit details
            loadVisit(props.visit_id);
            setMode("edit");
        } else {
            setVisitData(visitDefaults.visit_data);
            setNoteText('')
            setVisitType('')
            setIsEpicVisit(false)
            setMode("new");
            setVisitDate(new Date())
        }

        setVisitTypes(
            dict_visit_name
                .filter((vt) => vt.user_dept === userPrefCtx.userPreference.user_dept && vt.dictionary_value !== "")
                .sort((vn1, vn2) => vn1.dictionary_value > vn2.dictionary_value ? 1 : -1)
        );
    }, [props.visit_id, props.last_loaded_time, props.show]);
 


    const loadVisit = async (id) => {
        try {
            const httpMethod = "GET";
            const url = getVisitURL(id);

            const response = await fetch(url, {
                headers: httpHeaders(userPrefCtx.bearerToken),
                method: httpMethod,
            });

            if (response.status === 200) {
                const responseData = await response.json();
                // if visit not found the API returns empty object; it may happen after the deleting the visit
                
               
                // console.log("load visit - responseData", responseData)
                if (Object.keys(responseData).length === 0) {
                    // props.onHide()
                    // console.log("load visit - responseData 2", responseData)
                    setVisitData({})
                    return
                }
                const vdata = responseData;

                setVisitData(vdata.visits_data)
                if (vdata.visits_data.Note !== null && vdata.visits_data.Note !== undefined && vdata.visits_data.Note.length > 0) {
                    if (!!vdata.visits_data.Note[0].note_html) {
                        setNoteText(vdata.visits_data.Note[0].note_html)
                    } else if (!!vdata.visits_data.Note[0].note_plain) {
                        setNoteText(vdata.visits_data.Note[0].note_plain)
                    } else if (!!vdata.visits_data.Note[0] && vdata.visits_data.Note[0].hasOwnProperty('note_file_name') && !!vdata.visits_data.Note[0].note_file_name) {
                        setNotePDF(vdata.visits_data.Note[0].note_file_name)
                    } else {
                        setNotePDF('')
                    }
                } else {
                    setNoteText('')
                    setNotePDF('')
                }

                // vdata.visits_data.Note && props.visit.Note.hasOwnProperty('note_file_name') && !!props.visit.Note.note_file_name

                if (vdata.visits_data.Visit.epic_visit_type !== null && vdata.visits_data.Visit.epic_visit_type !== undefined && vdata.visits_data.Visit.epic_visit_type === true) {
                    setIsEpicVisit(true)
                    setVisitType(vdata.visits_data.Visit.epic_visit_type || '')
                } else {
                    setIsEpicVisit(false)
                    setVisitType(vdata.visits_data.Visit.visit_type || '')
                }

                setVisitDate(vdata.visits_data.Visit.visit_date)

                // if visit from different department - make it read only
                if (vdata.visits_data.Visit.user_dept === userPrefCtx.userPreference.user_dept) {
                    setReadOnly(false)
                } else {
                    setReadOnly(true)
                }
                // setVisit(responseData.Visit)
                // setVisitDate(moment(responseData.Visit.visit_date).toDate())
            } else {
                console.log("Failed to get visit", response);
            }
        } catch (e) {
            console.log("Failed to get visit", e);
        }
    };



    const visitAction = async (action) => {
        try {

            // console.log("visitAction - visitDate type", typeof visitDate, "visitDate value", visitDate)
        // const formattedDate = typeof visitDate === 'string' ? visitDate.split("T")[0] : visitDate.toISOString().split("T")[0];
        const formattedDate = typeof visitDate === 'string' ? visitDate : visitDate.toISOString();

        let httpMethod = "GET";
        let url = "";
        switch (action) {
            case "create":
            httpMethod = "POST";
            url = createVisitURL;
            break;

            case "delete":
            httpMethod = "DELETE";
            url = deleteVisitURL(props.visit_id);
            break;

            case "edit":
            httpMethod = "PATCH";
            url = updateVisitURL(props.visit_id);
            break;
            }

            let body = ''
            if (mode === "new") {
                const notes =  noteText !== '' ? [{
                    note_plain: noteText
                }] : []
                body = JSON.stringify({
                    visit_date: formattedDate,
                    visit_type: visitType,
                    is_active: true,
                    epic_visit_type: false,
                    notes: notes
                })
            }


            if (mode === "edit") {
                const notes =  noteText !== '' ? [{
                    note_plain: noteText
                }] : []

                body = JSON.stringify({
                    visit_date: formattedDate,
                    visit_type: visitType,
                    is_active: true,
                    notes: notes
                })
            }

            // console.log("MODE", mode, "PATCH BODY", body)

            // console.log("VisitDialog updating visit: body", body, "url", url)

            let response = ''

            let request = httpMethod === "DELETE" ? 
                {
                    headers: httpHeaders(userPrefCtx.bearerToken),
                    method: httpMethod,
                }
                :
                {
                    headers: httpHeaders(userPrefCtx.bearerToken),
                    method: httpMethod,
                    body: body,
                }
            if (httpMethod === "DELETE") {
                // no body on delete 
                response = await fetch(url, {
                    headers: httpHeaders(userPrefCtx.bearerToken),
                    method: httpMethod,
                });
            } else {
                response = await fetch(url, {
                    headers: httpHeaders(userPrefCtx.bearerToken),
                    method: httpMethod,
                    body: body,
                });
            }
            

            if (response.status === 200) {
                const responseData = await response.json();
                // console.log("updated visit", responseData)
                props.onSave() 
                props.onHide()
            } else {
                console.log("Failed to create/update visit", response);
                if (!response.ok) {
                    throw new Error('Unprocessable Entity: ' + response.statusText);
               }
            }
        } catch (e) {
            console.log("Failed to create/update visit", e);
            setShowError(true);
        }
    }



    // const visitDataChanged = (newVisitData) => {

    //     console.log("VISIT DIALOG visitDataChanged - newVisitData ", newVisitData)
    //     setVisit(newVisitData.Visit)
    //     setVisitData(newVisitData)
    // }



    const handleSave = () => {
        // console.log("Visit Dialog: mode", mode, "visit id", visit.id)
        visitAction(mode === "new" ? "create" : "edit") 
        // force reloading of all visits in AllVisits component     
        props.onSave() 
        setVisitData({})
        props.onHide()
    };

    const handleDelete = () => {
        // alert("visit delete clicked!");
        // visitAction("delete")
        // props.onSave() 
        // props.onHide()
        setShowConfirmDelete(true)
        // props.onVisitDelete(props.visit_id)
    }

    const handleClose = () => {
        // console.log("Visit Dialog - visitDataChanged", visitDataChanged)
        if (visitDataChanged) {
            // something changed - force reloading of all visits in AllVisits component    
            props.onSave() 
        }
        setVisitData({})
        props.onHide()
    }



    const visitNoteChanged = (note_text) => {
        setNoteText(note_text)
    }

    const visitDateChanged = (date) => {
        setVisitDate(date)
    }

    const visitTypeChanged = (newVisitType) => {
        setVisitType(newVisitType)
    }



    // if visitData not set then don't render
    // console.log("Visit Dialog: visitData", visitData)



    if (Object.keys(visitData).length === 0) {
        // console.log("Visit Dialog: DO NOT RENDER")
        return null
    }


    const closeConfirmDialog = (result) => {
        setShowConfirmDelete(false)
        if (result === 'confirm') {
            props.onVisitDelete(props.visit_id)
        }
    }


    return (
        <>
        <ErrorDialogModal
            setOpen={showError}
            close={() => setShowError(false)}
            componentName="Visit Dialog"
        />

        {showConfirmDelete &&
        <ConfirmDialog 
            show={showConfirmDelete}
            title='Please confirm'
            modal_class='delete-confirm-modal'
            title_class='delete-confirm-title'
            footer_class='delete-confirm-title'
            prompt='Are you sure you want to delete this visit? All visit data will be permanently deleted'
            prompt_class='delete-confirm-prompt'

            // cancel_button_text='Do not delete!'
            confirm_button_text='Delete'
            confirm_button_variant='danger'
            onClose={(result) => closeConfirmDialog(result)}
            icon={<BsSignStopFill />}
            iconColor='red'
            iconSize='1.3em'
        />
        }
        
        <Modal
            size="fullscreen"
            show={props.show}
            onHide={handleClose}
            animation={false}
            style={{ visibility: (showError) ? 'hidden' : 'visible' }}
        >
            <Modal.Header closeButton>
                <Container>
                    {props.visit_id <= 0 && (
                    <Row>
                        <Col xs={12}>
                        <Modal.Title className="visit-dialog-title">
                            <h3>New Patient Information</h3>
                        </Modal.Title>
                        </Col>
                    </Row>
                    )}

                    <Row>
                    <Col xs={4} md={3} lg={2}>
                        Name:
                    </Col>
                    <Col>
                        {patCtx.pat_first} {patCtx.pat_last}
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={4} md={3} lg={2}>
                        Date Of Birth:
                    </Col>
                    <Col>{patCtx.pat_dob}</Col>
                    </Row>
                    <Row>
                    <Col xs={4} md={3} lg={2}>
                        Gender:
                    </Col>
                    <Col>{patCtx.pat_gender}</Col>
                    </Row>
                    <Row>
                    <Col xs={4} md={3} lg={2}>
                        MRN:
                    </Col>
                    <Col>{patCtx.pat_mrn} </Col>
                    </Row>
                </Container>
            </Modal.Header>

            <Modal.Body>
                <VisitNotesDetail
                    // visit_data={visitData}
                    visit_types={visitTypes}
                    // onVisitDataChanged={visitDataChanged}
                    is_epic_visit={isEpicVisit}
                    visit_type={visitType}
                    visit_note={noteText}
                    visit_pdf={notePDF}
                    patient_id={patCtx.pat_id}
                    visit_date={visitDate}
                    onNoteChange={visitNoteChanged}
                    onDateChanged={visitDateChanged}
                    onVisitTypeChanged={visitTypeChanged}
                    readOnly={readOnly}
                />

                <VisitMediaDetail 
                    visit_data={visitData} 
                    onMediaChanged={() => setVisitDataChanged(true) }
                    readOnly={readOnly}
                    isExistingVisit={props.visit_id > 0}
                />
            </Modal.Body>

            <Modal.Footer className="justify-content-between">
                <div className="inline-block text-left">
                    <Button className="mx-2" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {!isEpicVisit && mode !== "new" && !readOnly &&
                        <Button
                            className="mx-2 danger-button"
                            variant="secondary"
                            onClick={handleDelete}
                        >
                            Delete Visit
                        </Button>
                    }
                </div>
                {!isEpicVisit && !readOnly &&
                    <div className="inline-block text-right">
                        <Button className="mx-2" variant="primary" onClick={handleSave}>
                            Save Changes
                        </Button>
                    </div>
                }
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default VisitDialog;
