import React, { useContext, useEffect, useState } from "react"
import PatientContext from "../../../context/patientContext"
import UserPrefContext from '../../../context/userPrefContext'
import DictionaryContext from "../../../context/dictionaryContext";
import AuthContext from "../../../context/authContext";
import OneVisit from "./OneVisit";

// props:
// selectedImages - array
function AllVisits(props) {
    const patCtx = useContext(PatientContext)
    const userPrefCtx = useContext(UserPrefContext)
    const dictContext = useContext(DictionaryContext)
    const authCtx = useContext(AuthContext)
    // url for "old way" of loading visits. That requires to call getVisit() in OneVisit component
    const getAllVisitsURL = `${process.env.REACT_APP_SERVER}/api/v1/visits/get_all_by_token/${patCtx.pat_token}`
    // // url for "new way" of loading visits. That requires to call setVisit(props.visit) in OneVisit component (instead of getVisit())
    // const getAllVisitsURL = `${process.env.REACT_APP_SERVER}/api/v1/visits/get_all_by_token_with_media/${patCtx.pat_token}`
    const getVisitWithMediaURL = id => `${process.env.REACT_APP_SERVER}/api/v1/visits/with_media/${id}`
    const [visits, setVisits] = useState([]);
    const [visitTypes, setVisitTypes] = useState([]);
    const [lastLoadedTime, setLastLoadedTime] = useState(null)
    const [lastVisitDept, setLastVisitDept] = useState([])

    useEffect(() => {
        // console.log(userPrefCtx)
        // console.log("AllVisits - loading component")
        let ignoreVisits = false;
        const getAllVisits = async () => {
            try {
                // console.log("ALL VISITS BEARER TOKEN", userPrefCtx.bearerToken)
                const response = await fetch(getAllVisitsURL, {
                    headers: {
                    Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                    auth: authCtx.authMode,
                    },
                });
                // console.log("AllVisit - getAllVisits - response.status", response.status, "ignoreVisits", ignoreVisits)
                if (response.status === 200) {
                    if (!ignoreVisits && !!userPrefCtx.userPreference.visit_dept) {
                        const responseData = await response.json();
                        // only show visits from departments in visitDepartments
                        const visitDepartments = userPrefCtx.userPreference.visit_dept
                        // console.log("All Visits -userPrefCtx", userPrefCtx, "visitDepartments", userPrefCtx.userPreference.visit_dept)
                        // console.log("Get all visits", responseData)
                        const filteredVisits = responseData
                            .filter(vdata => visitDepartments.includes(vdata.Visit.user_dept))
                            .sort((a, b) =>
                                new Date(a.Visit.visit_date) < new Date(b.Visit.visit_date) ? 1 : -1
                            )
                        // setVisits(
                        //     responseData
                        //         .filter(vdata => visitDepartments.includes(vdata.Visit.user_dept))
                        //         .sort((a, b) =>
                        //             new Date(a.Visit.visit_date) < new Date(b.Visit.visit_date) ? 1 : -1
                        //         )
                        // );
                        setVisits(filteredVisits)
                        setLastVisitDept(userPrefCtx.userPreference.visit_dept)
                        setLastLoadedTime(props.last_loaded_time)

                        // set All Notes array in Visit - used by AllNotes component
                        // props.setAllNotes(responseData.sort((a, b) => new Date(a.Visit.visit_date) < new Date(b.Visit.visit_date) ? 1 : -1))
                        props.setAllNotes(filteredVisits)
                    }
                } else {
                    console.log("Failed to get all visits", response);
                }
            } catch (e) {
                console.log("Failed to get all visits", e);
            }
        };

        // console.log("All Visits - lastLoadedTime", lastLoadedTime, "props.last_loaded_time", props.last_loaded_time)
        const arraysAreTheSame = (a,b) => {
            // console.log("a, type of a",a, typeof a)
            // console.log("b, type of b",b, typeof b)
            if (a.length !== b.length) {
                // console.log("return false")
                return false
            }
            
            // console.log("a.sort()",a.sort())
            // console.log("b.sort()",b.sort())
            return a.sort().join() === b.sort().join()
        }
        if (userPrefCtx.bearerToken !== '' && 
            (lastLoadedTime === null || (lastLoadedTime !== props.last_loaded_time) || !arraysAreTheSame(lastVisitDept,userPrefCtx.userPreference.visit_dept))) {
            // console.log("All Visits - Loading all visits: userPrefCtx.userPreference.visit_dept", userPrefCtx.userPreference.visit_dept)
            // setLastVisitDept(userPrefCtx.userPreference.visit_dept)
            getAllVisits()
            // setLastLoadedTime(props.last_loaded_time)
        }


        return () => {
            // console.log("AllVisits - unloading component")
            ignoreVisits = true;
        };
        //The context bearer token was added as a dependency, since this component was loading before the visit page's useEffect
    }, [patCtx.pat_token, userPrefCtx.bearerToken, props.last_loaded_time, userPrefCtx.userPreference.visit_dept]);



    // useEffect(() => {
    //     // console.log("AllVisits props", props)
    //     if (
    //         props.dict_visit !== null &&
    //         props.dict_visit.dict_visit_name !== null &&
    //         props.dict_visit.dict_visit_name !== undefined
    //     ) {
    //         setVisitTypes(
    //         props.dict_visit.dict_visit_name
    //             // DEBUG - HARDCODE DEPARTMENT UNTIL WE HAVE IT IN USER PREFERENCES
    //             .filter((vt) => vt.user_dept === "FP" && vt.dictionary_value !== "")
    //             .sort((vn1, vn2) =>
    //             vn1.dictionary_value > vn2.dictionary_value ? 1 : -1
    //             )
    //         );
    //     }
    // }, [props.dict_visit]);

    useEffect(() => {
        setVisitTypes(dictContext.dict_visit_types)
    }, [dictContext.dict_visit_types])


    return (
        <div>
            {visits.length > 0 &&
            visits.map((v) => (
                <OneVisit
                key={v.Visit.id}
                visit={v}
                visit_types={visitTypes}
                onVisitSelect={props.onVisitSelect}
                onImageSelect={(image) => props.onImageSelect(image)}
                selectedImages={props.selectedImages}
                enlightenPanelButtonClicked={() => props.enlightenPanelButtonClicked()}
                />
            ))}
        </div>
    );
}

export default AllVisits;
