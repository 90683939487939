import React, { useState, useEffect, useContext } from 'react';
//import ProviderAppointments from '../tables/ProviderAppointments';
//import EpicSchedule from '../tables/EpicSchedule';
import ORSchedule from '../tables/ORSchedule';
//import ProviderAppointments from '../tables/ProviderAppointments';
import EpicProviderAppointments from '../tables/EpicProviderAppointments';
import AuthContext from '../../../context/authContext';
import UserPrefContext from '../../../context/userPrefContext';

const ServEpicSchedule = (props) => {

  const [appts, setAppts] = useState([]);
  const [ORAppts, setORAppts] = useState([]);
  const [combinedAppts, setCombinedAppts] = useState([]);
  const authCtx = useContext(AuthContext);
  const userPrefCtx = useContext(UserPrefContext);

   let url = process.env.REACT_APP_SERVER + `/api/v1/search_services/retrieve_provider_schedule_epic?providerID=${props.sendProvider}&visit_date=${props.sendCalendar}`;
  //let url = process.env.REACT_APP_SERVER + `/api/v1/search_services/retrieve_provider_schedule_epic?providerID=1014637&visit_date=2023-12-18`;
  const userPrefProvSchedule = `${process.env.REACT_APP_SERVER}/api/v1/user_preferences/prov_schedule`;

  //console.log(props.sendProvider)
  //console.log(props.sendCalendar)

  const token = props.sendBearerToken;
  const id = props.sendProvider;
  const date = props.sendCalendar;

  useEffect(async () => {
    
    if ((token) && (id != null)) {
        //console.log('this is the epic schedule')
      const headers = { Authorization: `Bearer ${token}`, auth: authCtx.authMode, };
      try {
        const response = await fetch(url, { headers });
        //console.log(res.status);
        if (response.status === 200) {
          const data = await response.json();
          //console.log(data.response_data)
          setAppts(data.response_data);
          setORAppts(data.or_response_data)

          let provArray = data.response_data
          let orArray = data.or_response_data
          
          //I commented this out because it was causing the calenders to be null
          // const mergedArray = [...provArray, ...orArray];
          // setCombinedAppts(mergedArray);

          //setAppts(data);
          //console.log('schedule response', response)
          //console.log(data.response_data);
        //   console.log(data);

        } else {
          //console.log(res.status, res.detail);
          //console.log('not 200', response);
          //console.log()
          //if (!res.ok) {
            throw new Error('Unprocessable Entity: ' + response.statusText);
          //}
          //setAppts(null);
        }
      } catch (e) {
        //console.log('in the catch block',e);
        
        //This will make sure that when there is no appointments for a provider, the calendar won't show
        setAppts(null);
        setORAppts(null);
        setCombinedAppts(null);
      }

      try {
        const response = await fetch(userPrefProvSchedule, { 
          method: "PATCH",
          body: JSON.stringify({
            //"dict_botox_map_src_image_id": e.target.value
            "provider_id": id,
            "visit_date": date
          }),
          //headers 
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userPrefCtx.bearerToken}`,
            auth: authCtx.authMode,
          },
        });
        //console.log(res.status);
        if (response.status === 200) {
          const data = await response.json();
          //setAppts(data.response_data);
          //setAppts(data);
          //console.log('schedule response', response)
          //console.log(data.response_data);
        //   console.log(data);

        } else {
          //console.log(res.status, res.detail);
          //console.log('not 200', response);
          //console.log()
          //if (!res.ok) {
            //throw new Error('Unprocessable Entity: ' + response.statusText);
          //}
          //setAppts(null);
        }
      } catch (e) {
        console.log('in the catch block',e);
      }
    } else {
      //This will help to not show the calendar if nothing is selected
      setAppts(null);
      setORAppts(null);
      setCombinedAppts(null);
    }
  }, [id, date]);

  const takeInput = (id, date) => {

  }

  return (
    <div>
    {/* <EpicSchedule 
      sendData={appts} 
      sendORData={ORAppts}
      sendCombinedData={combinedAppts}
      sendBearerToken={props.sendBearerToken}
    /> */}
    {/* <ProviderAppointments
      sendData={appts} 
      sendORData={ORAppts}
      sendCombinedData={combinedAppts}
      sendBearerToken={props.sendBearerToken}
    /> */}
    <EpicProviderAppointments
      sendData={appts} 
      sendORData={ORAppts}
      sendCombinedData={combinedAppts}
      sendBearerToken={props.sendBearerToken}
    />
    <ORSchedule
      sendData={ORAppts} 
      sendBearerToken={props.sendBearerToken}
    />
   </div>
  );
};

export default ServEpicSchedule;