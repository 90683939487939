import React, {useContext} from 'react'
import classes from './VisitVideo.module.css'
import CompanyContext from '../../../context/companyContext'

function VisitVideo(props) {
    const companyCtx = useContext(CompanyContext)
    const videoURL = (filename, patient_id, mode) => `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/video/${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}`

    // mode = thumb|normal
    // thumb mode used for showing video in the list if visit (inline mode); in thumb mode click on the video should be passed to default
    // normal mode used to show videos in Visit Dialog; in normal mode click toggles selection 
    const toggleSelected = (event) => {
        if (props.mode === 'normal') {
            event.preventDefault()
            props.onSelect(props.video)
        }

    }

    return (
        <div className={`visit-image ${props.isSelected ? 'border-selected' : 'border-not-selected'}`} onClick={event => toggleSelected(event)} >
            <video className='visit-image' src={videoURL(props.video.file_name, props.patient_id)} controls></video>
        </div>
    )
}

export default VisitVideo
