import React, {useState, useEffect, useContext} from 'react'
import Modal from "react-bootstrap/Modal"
import Button from 'react-bootstrap/Button'
import Container from "react-bootstrap/Container"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import OnsetDate from '../calendar/OnsetDate'
import Form from 'react-bootstrap/Form'
import DictionaryContext from '../../../context/dictionaryContext'
import PatientContext from '../../../context/patientContext'
import UserPrefContext from '../../../context/userPrefContext'
import AuthContext from '../../../context/authContext'
import DatePicker from 'react-datepicker'
// import { forEach } from 'lodash'
// import PromptEditDiagnosisDelete from '../modals/PromptEditDiagnosisDelete'
import ConfirmDialog from '../shared/ConfirmDialog'
import ErrorDialogModal from '../modals/ErrorDialogModal'
import {BsSignStopFill } from 'react-icons/bs'

function DiagsModal(props) {

    const dictionaryContext = useContext(DictionaryContext)
    const patCtx = useContext(PatientContext)
    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)


    // const [show, setShow] = useState(false)
    const [diagType, setDiagType] = useState('')
    const [onsetDate, setOnsetDate] = useState(new Date())
    const [sides, setSides] = useState([])
    const [affectedSide, setAffectedSide] = useState('')
    const [diags, setDiags] = useState({})
    const [loadedDiag, setLoadedDiag] = useState([])

    const [diagGroups, setDiagGroups] = useState([])
    // const [totalItemsCount, setTotalItemsCount]= useState(0)
    const [colCount, setColCount] = useState(3)
    const [noteText, setNoteText] = useState('')

    const [showConfirm, setShowConfirm] = useState(false)
    const [showError, setShowError] = useState(false)
    const [mode, setMode] = useState('')

    

    // handle screen size changes
    useEffect(() => {

        // console.log("DiagsModal - 1: props", props)
        // set column number as: xs={12} md={6} lg={4}
        const handleScreenResize = () => {
            if (window.innerWidth < 768 ) {
                // < md
                setColCount(1)
            } else if (window.innerWidth < 992 ) {
                // < lg
                setColCount(2)
            } else {
                // >= lg
                setColCount(3)
            }
        }

        window.addEventListener('resize', handleScreenResize)

        

        return () => {
            window.removeEventListener('resize', handleScreenResize)
        }
    },[])


    useEffect(() => {
        // console.log("DiagsModal - 2: props", props)
        if (props.show) {
            // set mode depending on the props.diag_id
            if (props.diag_id > 0) {
                setMode('edit')
                // console.log("DiagsModal - mode edit")
            } else {
                setMode('new')
                // console.log("DiagsModal - mode new")
            }
        }
        
    }, [props.diag_id, props.show])



    useEffect(() => {
        // console.log("DiagsModal - 3: props", props)
        // console.log("DiagsModal - use Effect: mode", mode)
        if (props.show && mode !== '') {
            if (mode === 'edit' && props.diag_id > 0) {
                // load diagnosis
                const loadDiag = async (diag_id) => {
                    
                    try {
                        const getDiagURL = `${process.env.REACT_APP_SERVER}/api/v1/diags/${diag_id}`
                        const response = await fetch(getDiagURL, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${userPrefCtx.bearerToken}`,
                                'accept': 'application/json',
                                auth: authCtx.authMode,
                            },
                        })
            
                        if (response.status === 200) {
                            const responseData = await response.json()
                            // console.log("diagnosis loaded", responseData)
                            setLoadedDiag(responseData)
                            // set dialog type
                            setDiagType(responseData[0].DictDiag.diag_type)
                        } else {
                            console.log("Failed to get all diagnoses", response)
                        }
                    } catch (e) {
                        console.log("Failed to get all diagnoses", e)
                    }
                }
    
                loadDiag(props.diag_id)
    
            } else {
                // new mode
                setDiagType(props.diag_type)
            }
        }
        
    }, [props.diag_i, mode, props.show])





    useEffect(() => {
        // console.log("DiagsModal - 4: props", props)
        // console.log("DiagsModal UseEffect diagType", diagType, "mode", mode, "colCount", colCount)

        if (props.show && mode !== '') {
            setSides(dictionaryContext.dict_side.filter(side => side.side_type === 'face').sort((sideA, sideB) => sideA.sort_position - sideB.sort_position))

            // set diags array - split to columms depending on the screen size
            const diagTypes = dictionaryContext.dict_diag
                .filter(dt => dt.diag_type === diagType)
                .sort((dtA, dtB) => dtA.sort_position - dtB.sort_position)
            // set diags object
            let tempDiags = {}
            diagTypes.forEach(diag => {
                tempDiags[diag.id] = diagType === diag.diag_type && diag.display_name === 'Facial Paralysis' && mode === 'new'
            })
    
            // console.log("tempDiags", tempDiags)
            // if edit mode 
            if (mode === 'edit' && loadedDiag.length > 0) {
                // set affected side and date
                // console.log("Affected Side", loadedDiag[0].Diag.affected_side !== 'undefined' ? loadedDiag[0].Diag.affected_side : '')
                setAffectedSide(loadedDiag[0].Diag.affected_side !== 'undefined' ? loadedDiag[0].Diag.affected_side : '')
                setNoteText(loadedDiag[0].Diag.notes)
                setOnsetDate(new Date(loadedDiag[0].Diag.onset_date))
                // update values from loaded diagnosis
                loadedDiag.forEach(d => {
                    // console.log(`tempDiags - before setting up property ${d.DictDiag.id} to true`, tempDiags)
                    tempDiags[d.DictDiag.id] = true
                })
            } 
            // console.log("tempDiags - after load", tempDiags)
            setDiags(tempDiags)
    
            
    
            const groups = [... new Set(diagTypes.map(d => d.group_display_name))]
    
            // console.log("Groups", groups)
            // console.log("Sorted diag types", diagTypes)
            const itemCount = groups.length + diagTypes.length
            // setTotalItemsCount(itemCount)
            // console.log("totalItemsCount", itemCount)
            // console.log("diagTypes", diagTypes)
    
            
    
            const groupCount = groups.map(grp => {
                return {
                    group: grp, 
                    items: diagTypes
                        .filter(dt => dt.group_display_name === grp)
                        .sort((dtA, dtB) => dtA.display_name < dtB.display_name ? -1 : 1),
                }}).map(g => {
                    return {
                        group: g.group, 
                        count: g.items.length,
                        items: g.items,
                    }
                })
    
            // break it to array of 3 (columns)
            let cols = []
            let oneCol = []
            let size = 0
            let itemsLeft = itemCount
            let columnsLeft = colCount
            groupCount.map(grp => {
                oneCol.push(grp)
                size += grp.count + 1
                if(size >= itemsLeft/columnsLeft) {
                    cols.push(oneCol)
                    columnsLeft -= 1
                    itemsLeft -= size
                    oneCol = []
                    size = 0
                }
            })
            // push elements for last column
            if (oneCol.length > 0) {
                cols.push(oneCol)
            } 
            // console.log("groupCount", cols)
            setDiagGroups(cols)
    
    
            // if (props.diag_type !== '') {
            //     props,setShow(true)
            // } else {
            //     props.setShow(false)
            // }
        }

        



    }, [diagType, props.diag_id, mode, colCount, props.show])




    const handleClose = () => {
        props.onClose()
    }

    const selectAffectedSide = (e) => {
        setAffectedSide(e.target.value)
        // console.log("selected side", e.target.value, e.target)

    }

    const textChanged = (event) => {
        setNoteText(event.target.value)
    }

    const checkboxClicked = (event) => {
        // console.log("check box clicked", event.target.value, "checked?", event.target.checked)
        // get column index
        const colIx = event.target.getAttribute('data-col-ix')
        // update value in the array
        setDiags({...diags, [event.target.value]: event.target.checked})
    }

    const handleSave = (operation) => {
        if (operation === 'delete') {
            setShowConfirm(true)
        } else {
            saveChanges(operation)
            //props.onClose()
        }
    }






    const saveChanges = async (operation) => {
        const createDiagURL = `${process.env.REACT_APP_SERVER}/api/v1/diags/${patCtx.pat_token}`
        const updateDiagURL = `${process.env.REACT_APP_SERVER}/api/v1/diags/${props.diag_id}`
        
        
        const checkedIds = Object.keys(diags).map(key => {return {id: key, value: diags[key]}}).filter(d => d.value === true).map(d => d.id)

        const method = mode === 'new' ? "POST" : (operation === 'save' ? "PATCH" : "DELETE")
        const url = mode === 'new' ? createDiagURL : updateDiagURL

        let diagsBody = {
            onset_date: onsetDate.toISOString(),
            diagnosis_type: diagType,
            notes: noteText,
            given_acyclovir: "string",
            given_valtrex: true,
            date_of_first_movement: onsetDate.toISOString(),
            tracheostomy_at_first_enctr: "string",
            decannulated: "string",
            affected_side: affectedSide,
            dict_diag_id: checkedIds,
        }

        // console.log("body to POST", diagsBody)
        try {
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userPrefCtx.bearerToken}`,
                    'accept': 'application/json',
                    auth: authCtx.authMode,
                },
                method: method,
                body: operation === 'save' ? JSON.stringify(diagsBody) : null,
            })

            if (response.status === 200) {
                const responseData = await response.json()
                // console.log(`Created Face Instruments: ${responseData}`)

                props.sendResponsetoVisitPanels(responseData)
                // let the parent know that the list of diagnoses should be refreshed
                props.onDiagUpdated()
                props.onClose()
                

            } else {
                console.log("Failed to create diagnosis", response)
            }
        } catch (e) {
            console.log("Catch: Failed to create diagnosis", e)
            // props.onClose()
            setShowError(true)
        }
    }



    const closeConfirmDialog = (result) => {
        setShowConfirm(false)
        if (result === 'confirm') {
            saveChanges('delete')
        }
    }


    return (
        <>
        <ConfirmDialog 
            show={showConfirm}
            title='Please confirm'
            modal_class='delete-confirm-modal'
            title_class='delete-confirm-title'
            footer_class='delete-confirm-title'
            prompt='Are you sure you want to delete this diagnosis?'
            prompt_class='diags-modal-delete-confirm-prompt'
            // cancel_button_text='Do not delete!'
            confirm_button_text='Delete'
            confirm_button_variant='danger'
            onClose={(result) => closeConfirmDialog(result)}
            icon={<BsSignStopFill />}
            iconColor='red'
            iconSize='1.3em'
        />
        <ErrorDialogModal
            setOpen={showError}
            close={() => setShowError(false)}
            componentName="Diagnosis"
        />

        <Modal show={props.show} onHide={handleClose} animation={false} size="fullscreen"
        //This will hide the diagnosis modal when the error modal shows up
        style={{ visibility: (showError) ? 'hidden' : 'visible' }}
        
        >
            <Modal.Header closeButton>
                <Modal.Title>{diagType} Diagnosis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ flex: 0.2 }}>
                            <div>
                                <h4>Onset Date</h4>
                                <DatePicker selected={onsetDate} onChange={(date) => setOnsetDate(date)} className="datepickerstyle" />
                            </div>
                            {/* <OnsetDate func={(date) => setOnsetDate(date)} /> */}
                        </div>
                        <div style={{ flex: 0.2 }}>
                            <h4>Affected Side</h4>
                            <Form.Select
                                // aria-label="Default select example"
                                onChange={selectAffectedSide}
                                value={affectedSide}
                               
                            >
                                <option value=''></option>
                                {sides.map(item => (
                                <option key={item.id} value={item.display_name}>{item.display_name}</option>
                            ))}
                            </Form.Select>
                        </div>
                    </div>
                    <Container >
                        <Row>
                            {diagGroups.map((col, col_ix) => {
                                    return (
                                        <Col key={`col_${col_ix}`} xs={12} md={6} lg={4}> 
                                            {col.map((grp, grp_ix) =>{
                                                return (
                                                <div key={grp_ix}>
                                                    <label style={{fontWeight: "bold"}} >{grp.group}</label>
                                                    <ul style={{textAlign: "left", listStyleType: "none", marginBlockStart: 0, paddingInlineStart: "0px"}}>
                                                    {grp.items.map(gi => {
                                                        return (
                                                        <li key={`diag_${gi.id}`} >
                                                            <input id={`diag_${gi.id}`}
                                                                // key={`diag_${gi.id}`} 
                                                                style={{marginRight: 5 }} 
                                                                type="checkbox" 
                                                                value={gi.id}
                                                                checked={diags[gi.id]}
                                                                // defaultChecked={gi.diag_type === props.diag_type && gi.display_name === 'Facial Paralysis'}
                                                                onChange={checkboxClicked}
                                                                data-col-ix={col_ix}
                                                                />
                                                                    
                                                            <label htmlFor={`diag_${gi.id}`} style={{whiteSpace:"normal", display:"inline"}}>{gi.display_name}</label>
                                                        </li>
                                                        )
                                                    })}
                                                    </ul>
                                                </div>
                                                )
                                            })}
                                            
                                        </Col>
                                    )})
                                }
                           
                        </Row>
                    </Container>
                    <div>
                        <h4>Notes/Other Diagnosis</h4>
                        <Form.Control 
                            as="textarea" 
                            rows={2} 
                            value={noteText}
                            onChange={textChanged}
                            placeholder="Please Enter Notes or Description"
                        />
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <div className="inline-block text-left">
                    <Button className='mx-2' variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {mode === 'edit' &&               
                        <Button className='mx-2 danger-button' variant="secondary" onClick={() => handleSave('delete')}>
                            Delete
                        </Button>
                    }
                </div>

                <div className='inline-block text-right'>
                    <Button className='mx-2' variant="primary" onClick={() => handleSave('save')}>
                        Save Changes
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default DiagsModal
