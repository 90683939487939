import React, {useEffect, useState, useRef} from 'react'
import parse from 'html-react-parser'
import { forEach } from 'lodash'
import DatePicker from 'react-datepicker'

function CapeVQuestionRow(props) {
    const panelName='capev_panel'
    const [readOnly, setReadOnly] = useState(false)
    const trRef = useRef()
    const [sum, setSum] = useState(0)
    const [elementsToSum, setElementsToSum] = useState([])
    const [values, setValues] = useState([])
    const [elements, setElements] = useState([])

    useEffect(() => {
        // if (props.question.group_id === 12) {
            // console.log("CapeVQuestionRow props", props)
        // }

        // build HTML elements
        let qtypes = []
        let qvalues = []
        let htmls = []
        let headers = []
        let mappedOptions = []
        
        
        // in question - get question_option_values, question_option and question_type
        if (props.question.DictIntakeFormQuestion.question_type !== null && props.question.DictIntakeFormQuestion.question_type.startsWith('[')) {
            qtypes = JSON.parse(props.question.DictIntakeFormQuestion.question_type)

            // DEBUG
            // if (props.question.question_option_values === "[\"\",0,0,0,\"c\",\"i\",0]") {
            //     qtypes.push("label")
            // }
        }

        if (props.question.DictIntakeFormQuestion.question_option_values !== null && props.question.DictIntakeFormQuestion.question_option_values.startsWith('[')) {
            qvalues = JSON.parse(props.question.DictIntakeFormQuestion.question_option_values)

            // DEBUG
            // if (props.question.question_option_values === "[\"\",0,0,0,\"c\",\"i\",0]") {
            //     qvalues.push("/100")
            // }
        }

        if (props.question.DictIntakeFormQuestion.question_option !== null && props.question.DictIntakeFormQuestion.question_option.startsWith('[')) {
            headers = JSON.parse(props.question.DictIntakeFormQuestion.question_option)

            // DEBUG
            // if (props.question.question_option_values === "[\"\",0,0,0,\"c\",\"i\",0]") {
            //     headers.push("")
            // }
        }

        if (props.question.DictIntakeFormQuestion.mapped_property !== null && props.question.DictIntakeFormQuestion.mapped_property.startsWith('[')) {
            mappedOptions = JSON.parse(props.question.DictIntakeFormQuestion.mapped_property)
        } else if (props.question.DictIntakeFormQuestion.mapped_property === null && qtypes.length === 1) {
            mappedOptions.push(props.question.DictIntakeFormQuestion.display_name_short)
        }

        // remove 1st blank element from the arrays
        if (qtypes.length > 0 && qvalues.length > 0 && mappedOptions.length > 0 && qtypes[0] === '' && qvalues[0] === '' && headers.length > 0 && headers[0] === '' && mappedOptions[0] === '') {
            // console.log("SHIFTING... qtypes", qtypes, "qvalues", qvalues, "headers", headers)
            qtypes.shift()
            qvalues.shift()
            headers.shift()
            mappedOptions.shift()
            // console.log("SHIFTED... qtypes", qtypes, "qvalues", qvalues, "headers", headers)
        }
                
        // build html elements
        htmls = qtypes.map((type, index) => {
            // console.log("ROW: type", type, "index", index, "headers", headers)
            switch(type) {
                case 'number':
                    const suffix = headers.length > index ? '_' + headers[index].toLowerCase(): ''
                    return {
                        html: 'input', 
                        type: 'number', 
                        name: mappedOptions.length > index ? mappedOptions[index] : `${props.question.display_name_short}${suffix}`,
                        calc_sum_element: null,
                    }
                break
                case 'radio':
                    return {
                        html: 'input', 
                        type: 'radio', 
                        name: mappedOptions.length > index ? mappedOptions[index] : props.question.display_name_short,
                        value: qvalues[index]
                    }
                break
                case 'date':
                    
                    return {
                        html: 'datepicker', 
                        type: 'datepicker', 
                        name: mappedOptions.length > index ? mappedOptions[index] : props.question.display_name_short
                    }
                    // return new Date()
                break
                case 'text':
                    return {
                        html: 'input', 
                        type: 'text', 
                        name: mappedOptions.length > index ? mappedOptions[index] : props.question.display_name_short
                    }
                break
                case 'label':
                    return {html: 'label', type: 'label', name: props.question.display_name_short, value: qvalues[index]}
                break
                case 'textarea':
                    return {
                        html: 'textarea', 
                        type: 'textarea', 
                        name: mappedOptions.length > index ? mappedOptions[index] : props.question.display_name_short
                    }
                break;
                default:
                    if (type.startsWith('input_sum_protected')) {
                        const suffix = headers.length > index ? '_' + headers[index].toLowerCase(): ''
                        // console.log("PRSED TOTAL", parseCalculatedElement(type))
                        return {
                            html: 'input', 
                            type: 'input_sum_protected', 
                            name: mappedOptions.length > index ? mappedOptions[index] : `${props.question.display_name_short}${suffix}`,
                            // calculate_sum_of = list of elements to add
                            calculate_sum_of: parseCalculatedElement(type),
                        }
                    } else {
                        return null
                    }

            }
        })
        setElements(htmls)

        // create array of data in form {name: value}
        const element_names = htmls.map((el, ix) => {
            return { [el.name]: props.data[el.name]}
        })

        const vals = element_names.reduce((accum, el) => {
            const key = Object.getOwnPropertyNames(el)[0]
            accum = {...accum, ...el}
            return accum
        }, {})

        // if (props.question.group_id === 8) {
        //     console.log("ELEMENTS", htmls)
        //     console.log("ELEMENT NAMES", element_names)
        //     console.log("VALUES OF ELEMENTS", vals)
        // }
        // console.log("VALUES OF ELEMENTS", vals)
        setValues(vals)


        // console.log("HTML ELEMENTS", htmls)
        
        
        // setValues(props.data)

    }, [props.data, props.question])



    const parseCalculatedElement = (element) => {

        // console.log("CapeVQuestionGroup parsing input_sum_protected", element)
        const pattern = /^input_sum_protected\([0-9-]+\)$/g
        const regex = /[0-9]+/g
        if (element.match(pattern) !== null) {
            const match = element.match(regex)
            // we should have in match something like [a,b] - if the input type was input_sum_protected(a-b)
            if (match !== null && match.length == 2 && parseInt(match[0]) < parseInt(match[1])) {
                let elementsList = []
                for (let i = parseInt(match[0]); i <= parseInt(match[1]); i++) {
                    elementsList.push(i)
                }
                return elementsList
            }
        }
        // something is wrong
        return null
    }


    const date_value_changed = (new_date, name) => {
        let new_data = values
        new_data[name] = new_date
        props.onValuesUpdate(new_data)
    }

    const value_changed = (event, cell_index) => {
        // custom attribute of the element data-input-type shoudl contain type
        // let new_vals = values
        let new_data = values

        // console.log("elelemnt", event.target)
        const name = event.target.getAttribute('name')
        // console.log("elelemnt", event.target, "name", name)
        // different processing for numeric vs non-numeric values
        if (event.target.getAttribute('data-input-type') === 'number') {
            // allow only numeric characters
            const textVal = event.target.value.replace(/[^0-9]+/, '')
            new_data[name] = +(textVal)
            // new_vals = values.map((val, ix) => (ix !== cell_index ? val : +(textVal)))
        } else if (event.target.getAttribute('data-input-type') === 'text' || event.target.getAttribute('data-input-type') === 'textarea') {
            // new_vals = values.map((val, ix) => (ix !== cell_index ? val : event.target.value))
            new_data[name] = event.target.value
        } else if (event.target.getAttribute('data-input-type') === 'radio') {
            // new_vals = values.map((val, ix) => (ix !== cell_index ? val : event.target.value))
            new_data[name] = event.target.value
        }
        // console.log("NEW VALUES AFTER UPDATE", new_vals)


        // calculate new total (if needed)
        const calculated_sum_elements = elements.filter((el,ix) => el.type === 'input_sum_protected')
        
        if (calculated_sum_elements.length > 0) {

            // console.log("calculated_sum_elements", calculated_sum_elements)


            // take the first one - as of now we can processonly one calculated field
            // update value for sum element
            const calculate_sum_of = calculated_sum_elements[0].calculate_sum_of
            // console.log("calculate_sum_of", calculate_sum_of)

            const elems_to_sum = elements.filter((v,i) => calculated_sum_elements[0].calculate_sum_of.includes(i) )
            // console.log("elems_to_sum", elems_to_sum)
            const total = elems_to_sum.reduce((a,b) => {
                // console.log("CALCULATION TOTAL a:", a, "b:", b, "new_data[a.name]", new_data[a.name], "new_data[b.name]", new_data[b.name])
                return a.hasOwnProperty('name') ? new_data[a.name]+new_data[b.name] : a+new_data[b.name]
            })
            // console.log("total", total)
            
            // get name of the total element
            new_data[calculated_sum_elements[0].name] = total

            // let newErrors = props.errors.filter(er => er !== props.question.display_name_short).filter(er => er !== calculated_sum_elements[0].name)
            // if (total > 100) {
            //     newErrors.push(calculated_sum_elements[0].name)
            //     newErrors.push(props.question.display_name_short)
            //     // console.log("PROPS", props)
                
            // } 
            // console.log("NEW ERRORS", newErrors)
            // props.setErrors(newErrors)

        }
        
        // update values
        setValues(new_data)

        // console.log("Updating data:", new_data)
        props.onValuesUpdate(new_data)

    }


    const renderDisplayName = () => {
        const element = 
            <>
                {/* {props.errors.length > 0 && props.errors.includes(props.question.display_name_short) ? 
                    <span className='text-danger'>{props.question.display_name}</span> 
                    :
                    <span>{props.question.display_name}</span>
                } */}
                <span>{props.question.DictIntakeFormQuestion.display_name}</span>
            </>

        return element
    }


    const renderElelment = (el, ix, renderType) => {
        const element = 
            <div key={ix}>
                {el.type === 'number' &&
                    <input 
                        type={el.type} 
                        name={el.name} 
                        onChange={(event) => value_changed(event, ix)}
                        data-input-type={el.type}
                        value={values[el.name] !== undefined ? values[el.name] : 0}
                        placeholder={0}
                        readOnly={props.readOnly}
                    />
                }

                {el.type === 'text' &&
                    <input 
                        type={el.type} 
                        name={el.name} 
                        onChange={(event) => value_changed(event, ix)}
                        data-input-type={el.type}
                        value={values[el.name] !== undefined ? values[el.name] : ''}
                        readOnly={props.readOnly}
                    />
                }

                {el.type === 'datepicker' &&
                    <DatePicker 
                        selected={values[el.name]} 
                        data-input-type={el.type} 
                        name={el.name} 
                        onChange={(date) => date_value_changed(date, el.name)} 
                        readOnly={props.readOnly}
                    />
                }


                {el.type === 'radio' &&
                    <input 
                        type={el.type} 
                        name={el.name} 
                        onChange={(event) => value_changed(event, ix)}
                        data-input-type={el.type}
                        value={el.value}
                        checked={el.value === values[el.name]}
                        disabled={props.readOnly}
                    />
                }       

                {el.type === 'textarea' &&
                    <textarea
                        className='capev-textarea'
                        name={el.name} 
                        data-input-type={el.type}
                        value={values[el.name] !== undefined ? values[el.name] : ''}

                        onChange={(event) => value_changed(event, ix)}
                        readOnly={props.readOnly}
                    />
                }

                {el.type === 'label' &&
                    <label
                        name={el.name} 
                        data-input-type={el.type}
                    >{el.value}
                    </label>
                }   

                {el.type === 'input_sum_protected' &&
                    <input 
                        // className={`${props.errors.includes(el.name) ? 'capev-error-field' : 'total-protected'}`}
                        className='total-protected'
                        type="text" 
                        name={el.name} 
                        data-input-type="input_sum_protected"
                        readOnly
                        value={values[el.name] !== undefined ? values[el.name] : 0}
                    />
                }
            </div>
            return element
    }
    


    return (
        <>
            {props.type === 'table' &&
                <tr ref={trRef}>
                    {
                        // do not output display name if empty
                        props.question.DictIntakeFormQuestion.display_name !== null && props.question.DictIntakeFormQuestion.display_name !== "" &&
                        <td >
                            {renderDisplayName()}
                        </td>
                    }
                    {elements.map((el, ix) => {
                        return (
                            el !== null && (
                            <td key={ix}>
                                {renderElelment(el,ix)}
                            </td>
                            )
                        )
                        
                    })}
                </tr>
            }

            {props.type === 'single' && (
                <>
                    {
                        // do not output display name if empty
                        props.question.DictIntakeFormQuestion.display_name !== null && props.question.DictIntakeFormQuestion.display_name !== "" &&
                        renderDisplayName()
                    }

                    {elements.map((el, ix) => {
                        return (
                            el !== null &&                             
                                renderElelment(el,ix, props.type)
                        )

                    })}
                </>
            )}
        </>


    )
}

export default CapeVQuestionRow
