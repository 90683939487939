import React, { useState, useEffect, useContext, useRef } from "react";
import Form from "react-bootstrap/Form";
//import DictionaryContext from "../../../context/DictionaryContext";
import DictionaryContext from "../../../context/dictionaryContext";
import "../../stylesheets/BotoxMap.css";

const BotoxType = (props) => {
  const dictCtx = useContext(DictionaryContext);
  
  let valueSelected = "";

  if (props.sendEditBotoxMapData.botox_map_type != null) {
  // This is to set the default value based on whether it is edit or new canvas
    valueSelected = props.sendEditBotoxMapData.botox_map_type;
  } else {
    //console.log('botoxtype else statement, valueSelected: ', valueSelected)
    valueSelected = "Botox A";
  }

//selectArray will breakdown the dictionary into 4 objects for the botox types
  const selectArray = [
    ...new Map(
      dictCtx.dict_botox_map.map((item) => [item['DictBotoxMap'].id, item])
    ).values(),
  ];

  let initialBotoxIndex = 0;

  selectArray.forEach(function (element) {
    //This will loop through the array so that one of the objects equal to the value selected from the edit or default data
    if (element['DictBotoxMap'].dictionary_detail === valueSelected) {
      initialBotoxIndex = element['DictBotoxMap'].id;
    }
  });


  //The botox pointer needs to be set to whatever the selectedvalue is
  let [botoxPointer, setBotoxPointer] = useState(initialBotoxIndex);

  useEffect(() => {
    props.sendBotoxPointerToParent(botoxPointer);
    //console.log(botoxPointer)

  }, [botoxPointer]);


  const mapBotox = [
    ...new Map(
      dictCtx.dict_botox_map.map((item) => [item['DictBotoxMap'].id, item])
    ).values(),
  ];
  //console.log('mapBotox ', mapBotox)


  let handleBotoxTypeChange = (e) => {
    //console.log("handleBotoxTypeChange e.target.value is ", e.target.value);
    props.setColorValueSource('default')
    setBotoxPointer(e.target.value);
    props.sendBotoxPointerToParent(e.target.value);
  };


  return (
    <div>
      <div>
        <Form.Select
          aria-label="Default select example"
          onChange={handleBotoxTypeChange}
          id="botox-type-select"
          //defaultValue={valueSelected}
          //value={valueSelected}
          //Im using the botox pointer in here instead of using selected options to equal the valueSelected, this is getting rid of the warning
          value={botoxPointer}
        >
          {mapBotox.map((x) => (
            <option
              value={x['DictBotoxMap'].id}
              //selected={x['DictBotoxMap'].dictionary_detail === valueSelected}
              data-name={x['DictBotoxMap'].dictionary_detail}
              key={x['DictBotoxMap'].dictionary_detail}
            >
              {x['DictBotoxMap'].dictionary_detail}
            </option>
          ))}
        </Form.Select>
      </div>
    </div>
  );
};
export default BotoxType;
