import jwt_decode from 'jwt-decode'

// export const buildFaceInstrumentGroups = (dictionaries, panelId) => 
// {

//     const dictPanel = dictionaries.dict_panel.find(pnl => {
//         return pnl.id === panelId
//     })
    
//     if (dictPanel !== undefined) {
//         // find dict_intake_form by display_name_short:
//         // display_name_short in dict_panle looks like "panelname_panel"
//         // display_name_short in dict_intake_form looks like "panelname"
//         const intakeForm = dictionaries.dict_intake_form.find(form => {
//             return form.display_name_short === dictPanel.display_name_short.replace("_panel","")
//         })
    
//         if (intakeForm !== undefined) {
//             // setIntakeFormId(intakeForm.id)
//             // sort questions by sort_position
//             const questions = dictionaries.dict_intake_form_question.filter(quest => {
//                 return quest.DictIntakeFormQuestion.dict_intake_form_id === intakeForm.id
//             })
            
//             const comp = (a,b) => {
//                 var agrp = (+a.DictIntakeFormQuestion.group_id) * 100 + (+a.DictIntakeFormQuestion.sort_position)
//                 var bgrp = (+b.DictIntakeFormQuestion.group_id) * 100 + (+b.DictIntakeFormQuestion.sort_position)
    
//                 // console.log("sort:", typeof anum, typeof bnum, (anum - bnum))
//                 return agrp - bgrp
//             }
//             // const sortedQuestions = questions.sort((a,b) => a.sort_position - b.sort_postion)
//             const sortedQuestions = questions.sort(comp)

//             // console.log("Utils - sorted questions", sortedQuestions)
 
//             // get group_ids of questions (only one id per group)
//             const groups = sortedQuestions
//                 .map((value, index, qs) => (index > 0 && qs[index-1].DictIntakeFormQuestion.group_id === value.DictIntakeFormQuestion.group_id) ? null : value.DictIntakeFormQuestion.group_id)
//                 .filter(value => value !== null)

//             // build array of group objects: {group_id: 1, group_questions: [questions]}
//             const group_questions = groups.map((group_id) => {
//                 // const group_qs = sortedQuestions.filter((q) => q.group_id === group_id)
//                 return {group_id: group_id, group_questions: sortedQuestions.filter((q) => q.DictIntakeFormQuestion.group_id === group_id), chart: dictionaries.dict_chart.find(chart => chart.group_id === group_id)}
//             })

//             return group_questions
   
//         }
//     }

//     return []

// }




export const toLocalDate = (date) => {
    var local = new Date(date);
    local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
}

export const toLocalDateTime = (date, separator) => {
    var local = new Date(date);
    local.setMinutes(local.getMinutes() - local.getTimezoneOffset());

    return local.toJSON().slice(0,19).replace('T',!!separator ? separator : '@').replace(/:/g, '')
}

// the following code is taken from this article: https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript/12900504#12900504
export const getFileExtension = (path) => {
    var basename = path.split(/[\\/]/).pop(),  // extract file name from full path ...
                                               // (supports `\\` and `/` separators)
        pos = basename.lastIndexOf(".");       // get last position of `.`

    if (basename === "" || pos < 1)            // if file name is empty or ...
        return "";                             //  `.` not found (-1) or comes first (0)

    return basename.slice(pos + 1);            // extract extension ignoring `.`
}



export const fileExtensionType = (extension) => {
    let type = ''
    if (extension === null) {
        return type
    }
    
    switch (extension.toLowerCase()) {
        case 'doc':
        case 'docx':
            type = 'word'
            break;
        case 'xls':
        case 'xlsx':
        case 'csv':
            type = 'excel'
            break;
        case 'pdf':
            type = 'pdf'
            break;
        case 'txt':
            type = 'text'
            break;
        case 'zip':
        case 'rar':
        case 'tar':
        case '7z':
        case 'gzip':
        case 'lz':
        case 'lzh':
            type = 'compressed'
            break
        case 'jpg':
        case 'jpeg':
        case 'tiff':
        case 'psd':
        case 'img':
        case 'gif':
        case 'bmp':
            type = 'image'
            break
        case 'mpg':
        case 'mp4':
        case 'mov':
        case 'wmv':
        case 'avi':
        case 'mkv':
            type = 'video'
            break
        default:
            type = 'file'
    }

    return type
}

export const isTokenExpired = token => {
    if (!!!token) {
        // console.log("isTokenExpired - yes (no token)", token)
        return true
    }

    let decoded = jwt_decode(token)
    if (!!!decoded.exp) {
        // console.log("isTokenExpired - yes (decoded.exp is null)", decoded.exp)
        return true
    }

    // console.log("isTokenExpired - new Date(decoded.exp*1000) < new Date()", new Date(decoded.exp*1000) < new Date())
    return new Date(decoded.exp*1000) < new Date()
}


export const isObjectEmpty = (objectName) => { return Object.keys(objectName).length === 0 } 

