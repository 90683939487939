import React, { useState, useEffect, useContext, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import { trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../shared/LoadingSpinner'
import ErrorDialogModal from '../ErrorDialogModal';
import ConfirmDialog from '../../shared/ConfirmDialog';
//import ConfirmDialogWithSpinner from '../../shared/ConfirmDialogWithSpinner';
import { BsArrowRight, BsFillExclamationTriangleFill } from "react-icons/bs";
import UserPrefContext from '../../../../context/userPrefContext';
import AuthContext from '../../../../context/authContext';
import CompanyContext from '../../../../context/companyContext';
import SelectPatient from '../../shared/SelectPatient';
import SelectPatientOnlyDB from '../../shared/SelectPatientOnlyDB';
import classes from './AddDiagnosis.module.css'


function MergePatientModal(props) {

  const userPrefCtx = useContext(UserPrefContext);
  const authCtx = useContext(AuthContext);
  const companyCtx = useContext(CompanyContext);

  //console.log(companyCtx.company.id)

  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [confirmDialogText, setConfirmDialogText] = useState(null)
  const [mergeToPatientData, setMergeToPatientData] = useState(null);
  const [mergeWithPatientData, setMergeWithPatientData] = useState(null)
  const [mergeWithPatientError, setMergeWithPatientError] = useState('');
  const [mergeToPatientError, setMergeToPatientError] = useState('')

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const closeAndClearData = () => {
    setMergeToPatientData(null)
    setMergeWithPatientData(null)
    setMergeToPatientError('')
    setMergeWithPatientError('')
    props.close()
  };

    const handleChangeMergeWith = (data) => {
        // patient selected from the search patient dropdown - set email
        setMergeWithPatientData(data)
        //console.log(data);
    }

    const handleClearMergeWith = () => {
        // clear everything
        setMergeWithPatientData(null)
    }

    const handleChangeMergeTo = (data) => {
        // patient selected from the search patient dropdown - set email
        setMergeToPatientData(data)
        //console.log(data);
    }

    const handleClearMergeTo = () => {
        // clear everything
        setMergeToPatientData(null)
    }

    const openConfirmDialogModal = () => {
      if(mergeToPatientData === null){
        setMergeToPatientError(true);
      }
      if(mergeWithPatientData === null){
        setMergeWithPatientError(true);
      }
      if((mergeToPatientData !== null) && (mergeWithPatientData !== null)){
        setConfirmDialogText('This process cannot be reversed, are you sure you want to proceed?')
        setShowConfirm(true)
      }
    }


    const MergePatients = async () => {
      //console.log(mergeToPatientData, mergeWithPatientData)

      if((mergeToPatientData === null) || (mergeWithPatientData === null)){
        //console.log('cannot proceed because both have not been selected')
        //console.log(mergeToPatientData?.patient_token, mergeWithPatientData?.patient_token )
      }
      else{
        //console.log('both are valid');

        const mergePatientURL = `${process.env.REACT_APP_SERVER}/api/v1/patients/merge_patient_db?token_merge_to=${mergeToPatientData.patient_token}&token_merge_with=${mergeWithPatientData.patient_token}&dict_company_code_id=${companyCtx.company.id}`;
        const responseData = await fetch(mergePatientURL, {
          method: 'POST',
          //body: sendObject,
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userPrefCtx.bearerToken}`,
              auth: authCtx.authMode
          },
      })
      //.then((res) => res.json())
      .then((res) => {
          // Check for 422 or 500 response
          if (!res.ok) {
              throw new Error('Unprocessable Entity: ' + res.statusText);
              //throw new Error;
          }
          return res.json();
      })
      .then((data) => {
          //console.log(data);
          //setShowConfirm(false);
          setMergeToPatientData(null);
          setMergeWithPatientData(null);
          //console.log('completed')
          props.closeAll();
          //  props.sendTrigger(data);

      })
      .catch((err) => {
          console.log(err.message);
          setShowError(true);
      });
      }
    }


    const formatVariableName = (variableName) => {
      //This function is to take the underscore out put a space and uppercase the first letters
      return variableName
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };

    const closeConfirmDialog = (result) => {
      //console.log(result)

      if(result === 'confirm'){
        setShowConfirm(false);
        trackPromise(MergePatients());
        //MergePatients()
      } else if (result === 'cancel') {
      setShowConfirm(false)
      }
  }

  function formatDate(dateString) {
    const dateParts = dateString.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
  
    // Create a new Date object using the provided date string
    const originalDate = new Date(year, month - 1, day);
  
    // Extract month, day, and year in the desired format "mm/dd/yyyy"
    const formattedMonth = (originalDate.getMonth() + 1).toString().padStart(2, '0');
    const formattedDay = originalDate.getDate().toString().padStart(2, '0');
    const formattedYear = originalDate.getFullYear();
  
    // Construct the formatted date string in "mm/dd/yyyy" format
    const formattedDate = `${formattedMonth}/${formattedDay}/${formattedYear}`;
  
    return formattedDate;
  }

  const testMerge = () => {
    trackPromise(MergePatients());
  }


  return (
    <>
      <ErrorDialogModal
        setOpen={showError}
        close={() => setShowError(false)}
        componentName="Merge Patient"
      />
      <Modal 
        size="fullscreen"
        show={props.setOpen} 
        onHide={closeAndClearData}
        animation={false}
        backdrop="static"
        style={{ visibility: (showError, showConfirm) ? 'hidden' : 'visible' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Merge Existing Patients</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="merge-container">
            <div className="merge-section">
              <h1>Merge To</h1>
              <SelectPatientOnlyDB
                  error={mergeToPatientError} 
                  onErrorReset={() => setMergeToPatientError('')}
                  onChange={handleChangeMergeTo} 
                  onClear={handleClearMergeTo}
              />
              { mergeToPatientError &&
                <p className="red-text">Please Select A Patient</p>
              }
            </div>
            <div className="merge-section merge-arrow-icon">
              <BsArrowRight />
            </div>
            <div className="merge-section">
              <h1>Merge With</h1>
              <SelectPatient 
                  error={mergeWithPatientError} 
                  onErrorReset={() => setMergeWithPatientError('')}
                  onChange={handleChangeMergeWith} 
                  onClear={handleClearMergeWith}
              />
              { mergeWithPatientError &&
                <p className="red-text">Please Select A Patient</p>
              }
            </div>
          </div>
          <div className="merge-button-div">
          <Button variant="primary" 
            onClick={openConfirmDialogModal}
          >
            Merge
          </Button>
          {/* <Button variant="primary" 
            onClick={testMerge}
          >
            Test Merge
          </Button> */}
          </div>
          <LoadingSpinner size='100px' />
          <div className="merge-container2">
            <div className="merge-section2">
                    { mergeToPatientData != undefined &&
        <div className="two_column_container">
                      {Object.keys(mergeToPatientData)
        .filter((key) => key !== "patient_token" && key !== "id" && key !== "file_name")
        .map((key, index) => (
            
          <div key={key} className="patient_column">
            <p>{formatVariableName(key)}</p>
            { key !== "dob" &&
              <Form.Control 
                defaultValue={mergeToPatientData[key]}
                id={'patient_'+key}
                disabled
              />
            }
            {   key === "dob" &&

                <Form.Control 
                  defaultValue={formatDate(mergeToPatientData[key])}
                  id={'patient_'+key}
                  disabled
                />
            }
            </div>
            ))}
              </div>
              }
            </div>
            <div className="merge-section merge-arrow-icon">
            </div>
            <div className="merge-section2">
          { mergeWithPatientData != undefined &&
        <div className="two_column_container">
                      {Object.keys(mergeWithPatientData)
        .filter((key) => key !== "patient_token" && key !== "id" && key !== "file_name")
        .map((key, index) => (
            
          <div key={key} className="patient_column">
            <p>{formatVariableName(key)}</p>
            { key !== "dob" &&
            <Form.Control 
                        defaultValue={mergeWithPatientData[key]}
                        id={'patient_'+key}
                        disabled
            />
            }
              {   key === "dob" &&

                <Form.Control 
                  defaultValue={formatDate(mergeWithPatientData[key])}
                  id={'patient_'+key}
                  disabled
                />
            }
            </div>
            ))}
              </div>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" 
            onClick={closeAndClearData}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmDialog
        show={showConfirm}
        iconColor='red'
        icon={<BsFillExclamationTriangleFill />}
        title='Warning'
        // modal_class='diags-modal-delete-confirm-modal'
        title_class={classes['alert-title']}
        // footer_class='diags-modal-delete-confirm-title'
        prompt={confirmDialogText}
        prompt_class={classes['alert-prompt']}
        cancel_button_text='No'
        confirm_button_text='Yes'
        confirm_button_variant='primary'
        onClose={(result) => closeConfirmDialog(result)}
        />
       <ErrorDialogModal
          setOpen={showError}
          close={() => setShowError(false)}
          componentName="Merge Patient"
        />
    </>
  );
}

export default MergePatientModal;