import React, {useRef, useContext, useEffect, useState} from 'react'
import { useOktaAuth } from "@okta/okta-react";
// import UserContext from '../context/UserContext'
import {useNavigate} from 'react-router-dom'
import AuthContext from './context/authContext'
import CompanyContext from './context/companyContext';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import './AutoLogout.css'
import Logout from './Logout';


// call onLogout function for clean up
function AutoLogout(/*{onLogout}*/) {
    const authCtx = useContext(AuthContext)
    const companyCtx = useContext(CompanyContext)
    // const {Logout, isLoggedIn} = useContext(UserContext)
    const { authState, oktaAuth } = useOktaAuth()
    const [show, setShow] = useState(false)
    const [doLogout, setDoLogout] = useState(false)


    // maximum inactive period in seconds - if user is inactive for that time they will be shown an inactivity dialog
    const INACTIVITY_TIMER_MINUTES = (companyCtx.company.inactivity_timer_minutes || process.env.REACT_APP_INACTIVITY_TIMER_MINUTES)
    // period in milliseconds to log out the user due to inactivity
    const AUTO_LOGOUT_MINUTES = (companyCtx.company.auto_logout_minutes || process.env.REACT_APP_AUTO_LOGOUT_MINUTES)
    // fall back value just in case if INACTIVITY_TIMER_MILLISECONDS >= AUTO_LOGOUT_MILLISECONDS
    const DEFAULT_BEFORE_TIMEOUT_MINUTES = 2

    // console.log("Autologout: INACTIVITY_TIMER_MILLISECONDS", INACTIVITY_TIMER_MILLISECONDS)
    // console.log("Autologout: AUTO_LOGOUT_MILLISECONDS", AUTO_LOGOUT_MILLISECONDS)

    // maximum inactive period in seconds - if user is inactive for that time they will be auto-log out
    // const MAX_INACTIVITY_MILLISECONDS = process.env.REACT_APP_MAX_INACTIVE_MINUTES*60*1000
    // const MAX_INACTIVITY_MILLISECONDS = 180000
    const SHOW_WARNING_BEFORE_LOGOUT_MINUTES = AUTO_LOGOUT_MINUTES > INACTIVITY_TIMER_MINUTES ? INACTIVITY_TIMER_MINUTES : DEFAULT_BEFORE_TIMEOUT_MINUTES
    // seconds before showing warning 
    // const SHOW_WARNING_BEFORE_LOGOUT_MILLISECONDS = SHOW_WARNING_BEFORE_LOGOUT_MINUTES * 60 * 1000

    let navigate = useNavigate();

    const secondsLeft = useRef(999)
    const secondsLeftSpan = useRef()

    const wTimer = useRef()
    const lInterval = useRef()

    // timeoout till showing warning (milliseconds)
    // const warningTimeout = MAX_INACTIVITY_MILLISECONDS - SHOW_WARNING_BEFORE_LOGOUT_MILLISECONDS
    const warningTimeout = SHOW_WARNING_BEFORE_LOGOUT_MINUTES * 60 * 1000
    // timer to show warning dialog
    let warningTimer
    // interval for updating seconds count in the warning dialog
    let logoutInterval

    // when this timer runs out - show warning dialog
    const startWarningTimer = (timeout) => {
        // console.log(`Autologout: WarningTimer started with timeout ${timeout/1000} seconds; will trigger at ${new Date(new Date().getTime() + timeout)}`)
        // console.log("Autologout: authCtx.isAuthenticated", authCtx.isAuthenticated)
        // console.log("Autologout: authState", authState)
        if (authCtx.isAuthenticated || true) {
            warningTimer = setTimeout(showIdleWarning, timeout)
            wTimer.current = warningTimer
            document.addEventListener('mousemove', handleMouseMovement)
        }

    }


    const onLogout = () => {
        try {
                console.log("signing out from OKTA")
                oktaAuth.signOut()
                console.log("signed out from OKTA")
        }
        catch (e) {
            console.log("error", e)
        }

        try {
            localStorage.removeItem("db-auth-access-return")
        }
        catch (e) {
            console.log("error", e)
        }

        navigate('/')
        try {
            authCtx.logout()
        }
        catch (e) {
            console.log("error", e)
        }
        
        
    }

    // show idle warning
    const showIdleWarning = () => {
        setShow(true)
        secondsLeft.current = (AUTO_LOGOUT_MINUTES - SHOW_WARNING_BEFORE_LOGOUT_MINUTES) * 60
        secondsLeftSpan.current.textContent = `${Math.trunc(secondsLeft.current/60)} minute(s) ${secondsLeft.current%60} second(s)`

        document.removeEventListener('mousemove', handleMouseMovement, false);


        

       
        // setup interval to trigger every second to update time left
        // by the end of 60 seconds period - logout
        logoutInterval = setInterval( () => {
            // console.log(`logout interval - ${secondsLeft.current} left`)
            if (secondsLeft.current > 0) {
                secondsLeft.current -= 1;
                secondsLeftSpan.current.textContent = `${Math.trunc(secondsLeft.current/60)} minute(s) ${secondsLeft.current%60} second(s)`
            } else {
                // console.log("** warningTimer **", warningTimer)
                if (wTimer.current !== undefined) {
                    // console.log("** Clear Timeout on warningTimer **", warningTimer)
                    clearTimeout(wTimer.current);
                }
                if (lInterval.current !== undefined) { 
                    clearInterval(lInterval.current);
                }

                // close the dialog
                // const dialog = document.getElementById('autoLogoutBoxToggle')
                // if (dialog) {
                //     dialog.checked = false
                // }
                setShow(false)
                // onLogout()
                setDoLogout(true)
                // Logout()
                // navigate('/login')
            }
            
        }, 1000)

        lInterval.current = logoutInterval

        // console.log("* Logout interval", logoutInterval)

        // show warning dialog
        // const dialog = document.getElementById('autoLogoutBoxToggle')
        // if (dialog) {
        //     dialog.checked = true
        // }
        setShow(true)
    }

    // close the warning Box and reset timers (user is active)
    const closeWarningDialog = () => {
        setShow(false)
        restartTimers()
    }

    // reset all timers
    const restartTimers = () => {
        // console.log(`restartTimers: warningTimer ${wTimer.current}`)
        if (wTimer.current !== undefined) {
            clearTimeout(wTimer.current);
        }

        // console.log(`restartTimers: logoutInterval ${lInterval.current}`)
        if (lInterval.current !== undefined) { 
            clearInterval(lInterval.current);
        }

        startWarningTimer(warningTimeout);
    }

    // mouse movement handler
    const handleMouseMovement = (e) => {
        restartTimers();
    }

    useEffect(() => {
        if (authCtx.authMode !== 'OKTA' && authCtx.isAuthenticated) {
            startWarningTimer(warningTimeout)
        }
        

        return () => {

            // console.log("******* Autologout CLEAN UP *******")

            // cleanup function
            // console.log("Autologout warningTimer", wTimer.current)
            if (wTimer.current !== undefined) {
                // console.log("Autologout clear warningTimer")
                clearTimeout(wTimer.current);
            }

            // console.log("Autologout logoutInterval", lInterval.current)
            if (lInterval.current !== undefined) { 
                // console.log("Autologout clear logoutInterval")
                clearInterval(lInterval.current);
            }
            // remove mouse handler
            document.removeEventListener('mousemove', handleMouseMovement, false);
        }
    }, [authCtx.isAuthenticated])
    

    const logout = () => {
        setShow(false)
        // onLogout()
        setDoLogout(true)
    }

    const loggedOut = () => {
        setDoLogout(false)
    }

    return (
        <>
            <Modal size="md" backdrop="static" centered show={show} backdropClassName='inactivityBackdrop' >
                <Modal.Header>
                    <Modal.Title><h1>Session Timeout Warning</h1></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="py-4 text-xl text-gray-600">Due to inactivity, your session will expire in: <span ref={secondsLeftSpan}>XXXXX</span></p>
                    {/* <p className="py-4 text-lg text-gray-600">Are you still here?</p> */}
                </Modal.Body>
                <Modal.Footer /*className='justify-content-between'*/>
                    <Button className='mx-2  fs-5 text' variant="secondary" onClick={logout}>
                        Logout
                    </Button>
                    <Button className='mx-2  fs-5 text' variant="primary" onClick={closeWarningDialog} autoFocus>
                        Keep me signed in
                    </Button>
                </Modal.Footer>
            </Modal>

            <Logout doLogout={doLogout} onLoggedOut={loggedOut} />

        </>
    )
}

export default AutoLogout
