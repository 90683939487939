import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EditInterventionCalendar = (props) => {
  //This will set the prop in the usestate to get to the proper format
  const [startDate, setStartDate] = useState(new Date(props.sendDate));

  //console.log(startDate);
  //console.log(props.sendDate)
  useEffect(() => {
    props.func(startDate)

  }, [startDate]);
  //props.func(startDate)
  
  return (
    <div>
      <h4>Intervention Date</h4>
      <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="datepickerstyle" />
    </div>
  );
};

export default EditInterventionCalendar;