import React, {useState} from "react"
import './LoadingSpinner.css'
import { usePromiseTracker } from "react-promise-tracker"
import ClipLoader from "react-spinners/ClipLoader"
import BounceLoader from "react-spinners/BounceLoader"

function LoadingSpinner(props) {

    const { promiseInProgress } = usePromiseTracker()
    const color = '#0d6efd';
    const [start, setStart] = useState(false)

    if (promiseInProgress === true) {
        if (!start) {
            setStart(true)
        }

        return (
            <div className='spinner'>
                <ClipLoader
                    
                    color={color}
                    loading={promiseInProgress}
                    // cssOverride={override}
                    size={!!props.size ? props.size: '150px'}
                    // aria-label="Loading Spinner"
                    // data-testid="loader"
                />
            </div>
            
        )
    } else {
        if (start) {
            // console.log("no promise in progress")
            // on end call props.onEndOfPromise
            
            setStart(false)
            if (!!props.onEndOfPromise) {
                // props.onEndOfPromise()
                setTimeout(() => {props.onEndOfPromise()}, 200 )
            }

            // setTimeout(() => {setStart(false); props.onEndOfPromise()}, 2000 )
            
        }
        
        return null
    }
    
}

export default LoadingSpinner
