import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import FaceInstrumentCharts from './FaceInstrumentCharts'


function FaceInstrumentChartsModal(props) {

    const handleClose = () => {
        props.onClose()
    }

    return (
        <Modal size="fullscreen" show={props.show} onHide={handleClose} animation={false} scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title><h4>Face Instrument Charts</h4></Modal.Title>
            </Modal.Header>
        <Modal.Body>
            <FaceInstrumentCharts
                lastLoadedTime={props.lastLoadedTime}
                lastUpdatedTime={props.lastUpdatedTime}
                mode='dialog'
                width={'98%'}
                viewType='full'
            />
        </Modal.Body>

        <Modal.Footer className='justify-content-between'>

            
            <div >
                <Button className='mx-2' variant="secondary" onClick={handleClose}>
                    Close
                </Button>

            </div>
            
        </Modal.Footer>
    </Modal>







    )
}

export default FaceInstrumentChartsModal

