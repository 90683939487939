import React, { useState, useContext, useEffect } from 'react'
import DictionaryContext from '../../../context/dictionaryContext'
import { BsFillPlusSquareFill, BsPencilSquare } from 'react-icons/bs'                
import 'react-datepicker/dist/react-datepicker.css'
import EFaceListModal from './EFaceListModal';
import EFaceEditModal from './EFaceEditModal';


function EFaceModal(props) {

    const dictCtx = useContext(DictionaryContext)


    // values: null - no eface action; {list, null} - showing list of efaces; {new, null} - showing eface dialog for entering new eface data; {editing, eface} - editing eface
    const [eFaceMode, setEFaceMode] = useState({mode: 'hide', eface: null})

    const [updateDeleteCount, setUpdateDeleteCount] = useState(0)

    



    // child dialog was closed
    const handleClose = () => {
        // console.log("******* handleClose - mode", eFaceMode)
        switch (eFaceMode.mode) {
            case 'list':
                // close button was clicked in the list mode - change to hide
                // if any of the efaces were updated or deleted let know the parent component to refresh
                // console.log("Closing the list - updateDeleteCount", updateDeleteCount)
                if (updateDeleteCount > 0) {
                    forceRefreshEFaces()
                    setUpdateDeleteCount(0)
                }
                setEFaceMode({mode:'hide', eface:null})
                break;

            case 'new':
                // close/save button was clicked in the new mode - change to hide
                setEFaceMode({mode:'hide', eface:null})
                break;
            case 'editing':
                // close/save/delete button was clicked in the list mode - change to list
                setEFaceMode({mode:'list', eface:null})
                break;
        } 
    }
    const handleShow = () => {
        setEFaceMode({mode: 'new', eface: null})
        // setShow(true)
    }

    const handleShowList = () => {
        setEFaceMode({mode: 'list', eface: null})
        // setShowList(true)
    }

    

    // eface clicked for editing
    const onSelect = (eface) => {
        // console.log("EFaceModal: onSelect - eface", eface)
        setEFaceMode({mode:'editing', eface: eface})
    }

    const forceRefreshEFaces = () => {
        // let know parent component that data has been changed, so it can refresh
        setTimeout(() => {
            // console.log("@@@@@@@@@ calling onUpdate of the parent component")
            props.onUpdate()
        }, 200)
    }

    // this will be called from the EFace List if an eface was updated or deleted
    // we'll use it to let the paremt component know to update eface view when the list dialog is closed
    const onUpdateOrDelete = () => {
        setUpdateDeleteCount(updateDeleteCount+1)
    }

    return (
        <>
            <BsFillPlusSquareFill onClick={handleShow} className="icon-pointer"/>
            <BsPencilSquare onClick={handleShowList} className="icon-pointer"/>

            {eFaceMode && (eFaceMode.mode === 'new' || eFaceMode.mode === 'editing' ) &&
                <EFaceEditModal mode={eFaceMode} /*show={show}*/ 
                    show={true} 
                    // dictVisit={props.dictVisit} 
                    // dictPanelId={props.dictPanelId} 
                    onClose={handleClose} 
                    onSaveOrDelete={eFaceMode.mode === 'new' ? forceRefreshEFaces : onUpdateOrDelete}
                />
            }

            {eFaceMode && eFaceMode.mode === 'list' &&
                <EFaceListModal /*show={showList}*/ 
                    show={true} 
                    // columnMapping={JSON.parse(props.dictVisit.dict_table[0].column_name)} 
                    //columnMapping={JSON.parse(dictCtx.dict_table[0].column_name)} 
                    onClose={handleClose} 
                    onSelect={onSelect}
                    unUpdateOrDelete={onUpdateOrDelete}
                    updateDeleteCount={updateDeleteCount}
                />
            }
        </>
    )
}

export default EFaceModal
