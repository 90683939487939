import React, { useState, useContext, useEffect, useRef } from 'react'
import UserPrefContext from '../../../context/userPrefContext'
import AuthContext from '../../../context/authContext'
import CompanyContext from '../../../context/companyContext'
import Form from "react-bootstrap/Form"
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import {BsCameraFill, BsCameraReelsFill, BsSignStopFill, BsFillExclamationTriangleFill} from 'react-icons/bs' 
import VisitImage from './VisitImage'
import VisitVideo from './VisitVideo'
import VisitDocument from './VisitDocument'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../shared/LoadingSpinner'
import ConfirmDialog from "../shared/ConfirmDialog";
import ErrorDialogModal from '../modals/ErrorDialogModal'

function VisitMediaDetail(props) {

    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)
    const companyCtx = useContext(CompanyContext)

    const [imgArray, setImgArray] = useState([]) 
    const [videoArray, setVideoArray] = useState([]) 
    const [docArray, setDocArray] = useState([]) 
    const [selectedImages, setSelectedImages] = useState([])
    const [selectedVideos, setSelectedVideos] = useState([])
    const [selectedDocuments, setSelectedDocuments] = useState([])
    const [deleteEnabled, setDeleteEnabled] = useState(false)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [showError, setShowError] = useState(false)
    const uploadFileRef = useRef()

    const deleteImageURL = (imageId) => `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/image/${imageId}`
    const deleteVideoURL = (videoId) => `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/video/${videoId}`
    const deleteDocumentURL = (docId) => `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/document/${docId}`
    const httpHeaders = (bearerToken) => {
        return {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`,
            accept: "application/json",
            auth: authCtx.authMode,
        }
    }


    useEffect(() => {
        // console.log("Visit Media Detail - props.visit_data",props.visit_data)
        if (props.visit_data.Visit !== null && props.visit_data.Images !== null) {
            // const splitSize = 4
            // let images = []
            // for (let i = 0; i < props.visit_data.Images.length; i += splitSize) {
            //      images.push(props.visit_data.Images.slice(i, i + splitSize));
            // }
            // setImgArray(images.sort((a,b) => new Date(a.created_at) > new Date(b.created_at) ? 1 : -1 ))

            setImgArray(buildArrayOfRows(props.visit_data.Images))
        }
        
        if (props.visit_data.Visit !== null && props.visit_data.video !== null) {
            // const splitSize = 4
            // let videos = []
            // for (let i = 0; i < props.visit_data.video.length; i += splitSize) {
            //     videos.push(props.visit_data.video.slice(i, i + splitSize));
            // }
            // setVideoArray(videos.sort((a,b) => new Date(a.created_at) > new Date(b.created_at) ? 1 : -1 ))

            setVideoArray(buildArrayOfRows(props.visit_data.video))
        }

        if (props.visit_data.Visit !== null && props.visit_data.Document !== null) {
            // const splitSize = 4
            // let documents = []
            // for (let i = 0; i < props.visit_data.Document.length; i += splitSize) {
            //      documents.push(props.visit_data.Document.slice(i, i + splitSize));
            // }
            // setDocArray(documents.sort((a,b) => new Date(a.created_at) > new Date(b.created_at) ? 1 : -1 ))

            setDocArray(buildArrayOfRows(props.visit_data.Document))
        }
        
    }, [props.visit_data])



    useEffect(() => {
        if (selectedImages.length + selectedVideos.length + selectedDocuments.length > 0) {
            // enable delete button
            setDeleteEnabled(true)
        } else {
            // disable delete button
            setDeleteEnabled(false)
        }

    }, [selectedImages.length, selectedVideos.length, selectedDocuments.length])


    // builds and returns array of rows, each row is 4 elements long (except the last one that can be shorter)
    const buildArrayOfRows = (originalArray) => {
        const splitSize = 4
        let newArray = []
        let oldArray = originalArray.sort((a,b) => new Date(a.created_at) > new Date(b.created_at) ? 1 : -1 )

        for (let i = 0; i < oldArray.length; i += splitSize) {
            newArray.push(oldArray.slice(i, i + splitSize));
        }
        return newArray
    }


    const deleteMediaFile = async (media, mediaType) => {
        try {            
            let httpMethod = "DELETE"
            let url = ""
            switch(mediaType) {
                case 'image':
                    url=deleteImageURL(media.id)
                    break
                case 'video':
                    url=deleteVideoURL(media.id)
                    break
                case 'document':
                    url=deleteDocumentURL(media.id)
                    break
            }
            

            const response = await fetch(url, {
                headers: httpHeaders(userPrefCtx.bearerToken), 
                method: httpMethod,
            })

            if (response.status === 200) {
                const responseData = await response.json()
                // console.log("image deleted", responseData)

                // remove image from image array

            } else {
                console.log("Failed to update visit", response)
            }
        } catch (e) {
            console.log("Failed to update visit", e)
        }

    
    }


    

    const onImageSelect = (image) => {
        // console.log("Image selected", image)
        // add or remove image from array
        let newImageList = selectedImages
        if (newImageList.indexOf(image) == -1) {
            // add element
            newImageList.push(image);
        } else {
            // remove element
            const index = selectedImages.indexOf(image)
            if (index >= 0) {
                newImageList.splice(index, 1)
            }
        }
        setSelectedImages([...newImageList])
    }


    const onVideoSelect = (video) => {
        // console.log("Image selected", image)
        // add or remove image from array
        let newVideoList = selectedVideos
        if (newVideoList.indexOf(video) == -1) {
            // add element
            newVideoList.push(video);
        } else {
            // remove element
            const index = selectedVideos.indexOf(video)
            if (index >= 0) {
                newVideoList.splice(index, 1)
            }
        }
        setSelectedVideos([...newVideoList])
    }
    
    const onDocumentSelect = (document) => {
        // add or remove image from array
        let newDocumentList = selectedDocuments
        if (newDocumentList.indexOf(document) == -1) {
            // add element
            newDocumentList.push(document);
        } else {
            // remove element
            const index = selectedDocuments.indexOf(document)
            if (index >= 0) {
                newDocumentList.splice(index, 1)
            }
        }
        setSelectedDocuments([...newDocumentList])

        // console.log("onDocumentSelect - newDocumentList", newDocumentList)
    }


    const handleDelete = () => {
        setShowConfirmDelete(true)
    }
    const doDelete = () => {
        if (selectedImages.length > 0) {
            let newImgArray = imgArray
            selectedImages.forEach(img => {
                deleteMediaFile(img, 'image')
                newImgArray = removeElementFromArrayOfRows(newImgArray, img)
            })
            setImgArray([...newImgArray])
            // remove deleted images from image array
            setSelectedImages([])
            // signal to parent component that visit media changed - it will force to realod
            props.onMediaChanged()
        }

        if (selectedVideos.length > 0) {
            let newVideoArray = videoArray
            selectedVideos.forEach(video => {
                deleteMediaFile(video, 'video')
                newVideoArray = removeElementFromArrayOfRows(newVideoArray, video)
            })
            setVideoArray([...newVideoArray])
            // remove deleted videos from video array
            setSelectedVideos([])
            // signal to parent component that visit media changed - it will force to realod
            props.onMediaChanged()
        }

        if (selectedDocuments.length > 0) {
            let newDocArray = docArray
            selectedDocuments.forEach(doc => {
                deleteMediaFile(doc, 'document')
                newDocArray = removeElementFromArrayOfRows(newDocArray, doc)
            })
            setDocArray([...newDocArray])
            // remove deleted documents from doc array
            setSelectedDocuments([])
            // signal to parent component that visit media changed - it will force to realod
            props.onMediaChanged()
        }
    }


    // return array of Rows without removed elements
    const removeElementFromArrayOfRows = (array, element) => {
        let newArray = []
        // array of rows is array of array, so we have to search in each internal array
        array.forEach(row => {
            row.forEach(el => {
                if (el !== element) {
                    newArray.push(el)
                }
            })
        })
        
        // build and return new array of rows
        return buildArrayOfRows(newArray)
    }



    const handleFileEvent = (e) => {
        
        // console.log("handle file event", e.target.files)
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        // console.log("File upload - files", chosenFiles)

        const files = new FormData();

        chosenFiles.forEach((val, index) => {
            files.append("files", chosenFiles[index]);
        });

        const filesUploadURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/upload/from_visit/${props.visit_data.Visit.id}?dict_company_code_id=${companyCtx.company.id}`;
        const headers = {
            // 'Content-Type': chosenFiles[0].typ,
            Authorization: `Bearer ${userPrefCtx.bearerToken}`,
            accept: "application/json",
            auth: authCtx.authMode,
          };

        let resp = {}
        trackPromise(
            fetch(filesUploadURL, {
                headers: headers,
                method: "POST",
                body: files,
            })
            .then((response) => {
                if (!response.ok) {
                    console.log(response.json())
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                if (!!data.Result && data.Result === 'OK') {
                    // all good
                } else if (!!data.message) {
                    console.log("VisitMediaDetail - message", data.message)
                    setShowError(true)
                }
            })
            // .then((data) => console.log(data))
            .catch((error) => { console.error(`Error uploading media for ${props.visit_data.Visit.id}: `, error) })
        )

    }



    const handleUpload = () => {
        uploadFileRef.current?.click();
        

    }


    const closeConfirmDialog = (result) => {
        setShowConfirmDelete(false)
        if (result === 'confirm') {
            doDelete()
        }
    }


    return (
        <>
            {showConfirmDelete &&
            <ConfirmDialog 
                show={showConfirmDelete}
                title='Please confirm'
                modal_class='delete-confirm-modal'
                title_class='delete-confirm-title'
                footer_class='delete-confirm-title'
                prompt='Are you sure you want to delete selected media? Media will be permanently deleted'
                prompt_class='delete-confirm-prompt'
                // cancel_button_text='Do not delete!'
                confirm_button_text='Delete'
                confirm_button_variant='danger'
                onClose={(result) => closeConfirmDialog(result)}
                icon={<BsSignStopFill />}
                iconColor='red'
                iconSize='1.3em'
            />
            }

            {/* {showError &&
            <ConfirmDialog 
                show={showError}
                iconColor='red'
                iconSize='1.3em'
                icon={<BsFillExclamationTriangleFill />}
                title='Error: Visit Media'
                // modal_class='diags-modal-delete-confirm-modal'
                title_class='delete-confirm-prompt'
                // footer_class='diags-modal-delete-confirm-title'
                prompt='Something went wrong. Please contact admin'
                prompt_class='delete-confirm-title'
                cancel_button_text=''
                confirm_button_text='OK'
                confirm_button_variant='primary'
                onClose={() => setShowError(false)}
            />
            } */}
            <ErrorDialogModal
                setOpen={showError}
                close={() => setShowError(false)}
                componentName="Visit Media"
            />

            <Form className='visit-dialog-form'>
                <div className='visit-dialog-form-header'>
                    <div className='d-flex justify-content-between'>
                        <div className='inline-block text-left'>
                            <BsCameraFill className='visit-detail-icon' />
                            Media
                        </div>
                        
                        {!props.readOnly && props.isExistingVisit && 
                        <div className="inline-block text-right">
                            <input
                                id="mediaUpload"
                                type="file"
                                multiple
                                className="d-none"
                                ref={uploadFileRef}
                                // accept='application/pdf, image/png'
                                onChange={handleFileEvent}
                                // disabled={fileLimit}
                            />

                            <label htmlFor="mediaUpload">
                                <a className={`btn btn-primary mx-2` }>
                                    Upload Files
                                </a>
                            </label>

                            <Button className="mx-2" variant="danger" onClick={handleDelete} disabled={!deleteEnabled}>
                                Delete Selected
                            </Button>
                        </div>
                        }
                    </div>
                </div>
                

                <FormGroup className='visit-dialog-form-group'>
                    {/* {Object.keys(props.visit_data).length > 0  && */}

                    {imgArray.length > 0 && 
                        imgArray.map((img_row, row_ix) => {
                            // output images 4 in a row
                                return (
                                    <Row key={`img_${row_ix}`} className='visit-media-detail-row-space'>
                                        {img_row.map((img, img_ix) => {
                                            return (
                                                <Col xs={3} key={`img_${img_ix}`}>
                                                    <VisitImage 
                                                        mode='normal'
                                                        image={img} 
                                                        key={img.id} 
                                                        patient_id={props.visit_data.Visit.patient_id}
                                                        // onImageSelect={imageSelected}
                                                        onImageSelect={(img) => onImageSelect(img) }
                                                        isImageSelected={selectedImages.indexOf(img) >= 0}
                                                    />
                                                </Col>
                                            
                                            )
                                        })}
                                    </Row>
                                )
                            })
                    }
                    <div className='visit-media-detail-row-space'></div>
                    {videoArray.length > 0 && 
                        videoArray.map((vid_row, row_ix) => {
                            // output videos 4 in a row
                                return (
                                    <Row key={`vid_${row_ix}`} >
                                        {vid_row.map((video, v_ix) => {
                                            return (
                                                <Col xs={3} key={`vid_${v_ix}`}>
                                                    <VisitVideo
                                                        mode='normal'
                                                        video={video} 
                                                        key={video.id} 
                                                        patient_id={props.visit_data.Visit.patient_id}
                                                        onSelect={(video) => onVideoSelect(video) }
                                                        isSelected={selectedVideos.indexOf(video) >= 0}
                                                    />
                                                </Col>
                                            
                                            )
                                        })}
                                    </Row>
                                )
                            })
                    }


                    <div className='visit-media-detail-row-space'></div>
                    {docArray.length > 0 && 
                        docArray.map((doc_row, row_ix) => {
                            // output documents 4 in a row
                                return (
                                    <Row key={`doc_${row_ix}`} >
                                        {doc_row.map((doc, d_ix) => {
                                            return (
                                                <Col xs={3} key={`doc_${d_ix}`}>
                                                    <VisitDocument 
                                                        document={doc} 
                                                        mode='full'
                                                        patient_id={props.visit_data.Visit.patient_id}
                                                        onSelect={(doc) => onDocumentSelect(doc) }
                                                        isSelected={selectedDocuments.indexOf(doc) >= 0}
                                                    />
                                                </Col>
                                            
                                            )
                                        })}
                                    </Row>
                                )
                            })
                    }
                                
                
                </FormGroup>
                <LoadingSpinner size='100px' /*onEndOfPromise={() => setLoadedTime(new Date())}*/ />
            </Form>
        </>
    )
}

export default VisitMediaDetail
