import React, {useContext, useEffect, useState, useRef} from 'react'
import UserPrefContext from '../src/context/userPrefContext'
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import InputGroup from "react-bootstrap/InputGroup";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import styles from './LoginBoxDB.module.css'
import jwt_decode from 'jwt-decode'
import AuthContext from './context/authContext';
import './LoginBoxDB.css'
import CompanyContext from './context/companyContext';


function LoginBoxDB(props) {

    const [errors, setErrors] = useState([])
    const [prevUserIsIpad, setPrevUserIsIpad] = useState(false)
    const [prevUserIsHim, setPrevUserIsHim] = useState(false)
    const [prevUserIsRegular, setPrevUserIsRegular] = useState(false)

    const [showPassword, setShowPassword] = useState(false);
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };



    
    const userid = useRef()
    const password = useRef()
    const entity = useRef()

    const companyCtx = useContext(CompanyContext)
    const authCtx = useContext(AuthContext)
    const userPrefCtx = useContext(UserPrefContext)

    //console.log("LoginBoxDB - props", props, "companyCtx.company.auth_mode", companyCtx.company.auth_mode)
    

    let navigate = useNavigate();

    // const bg = props.mode === 'autolock' ? {
    //     overlay: {
    //     //   background: "#FFFF00"
    //     background: "#999999"
    //     }
    // } : { 
    //     overlay: {
    //         background: "#999999"
    //     }
    // }


    const [showDBLogin, setShowDBLogin] = useState(false)

    const handleLogin = (event) => {
        event.preventDefault();
        // console.log("LoginBoxDB: companyCtx", companyCtx.company)

        const loginToDB = async () => {
            let url = `${process.env.REACT_APP_SERVER}/api/v1/login/access-token`
            if (companyCtx.company.auth_mode === 'AD') {
                url = `${process.env.REACT_APP_SERVER}/api/v1/login/access-token-ad/${companyCtx.company.id}`
            }
            const headers = {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json',
                'Auth': companyCtx.company.auth_mode,
            }

            const response = await fetch(url, {
                headers: headers,
                method: 'POST',
                body: new URLSearchParams({
                    username: userid.current.value,
                    password: password.current.value,
                    grant_type: '',
                    scope: '',
                    client_id: '',
                    client_secret: '',
                })
            })
            .then((res) => res.json())
            .then((data) => {
                // console.log("returned from login: ", data, "userPrefCtx.bearerToken", userPrefCtx.bearerToken)
                if (!!data.access_token && !!data.token_type) {
                    // dbAuth.setupAuth(data, userid.current.value)
                    //dbAuthSetup(data, userid.current.value)
                    // console.log("LoginBox - setting up local storage and contexts - ", JSON.stringify(data))
                    // save return in the local storage - will be used to restore sesssion on page reload


                    // fake ipad user
                    // data = {...data, is_ipad_user: true}

                    // fake him user
                    // data = {...data, is_him_user: true}

                    localStorage.setItem("db-auth-access-return", JSON.stringify(data))

                    // console.log("LoginBox - set local storage and contexts - ", JSON.stringify(data))
                    // const temp = localStorage.getItem("db-auth-access-return")
                    // console.log("localStorage - db-auth-access-return", temp)


                    // console.log("LoginBox: setting up context: authCtx", authCtx, "companyCtx", companyCtx)

                    // setup auth context
                    authCtx.setAuthMode(companyCtx.company.auth_mode)
                    authCtx.setIsAuthenticated(true)
                    authCtx.setToken(data.access_token)
                    authCtx.setUserInfo({
                        first_name: data.first_name,
                        last_name: data.last_name,
                        is_him_user: data.is_him_user,
                        is_ipad_user: data.is_ipad_user,
                        is_readonly: data.is_readonly,
                        is_site_admin: data.is_site_admin,
                        is_superuser: data.is_superuser,
                        is_writeuser: data.is_writeuser,
                        name: data.first_name + ' ' + data.last_name,
                        // email: 'unknown',
                        // email_verified: false,
                        family_name: data.last_name,
                        given_name: data.first_name,
                        // locale: 'en_US',
                        // preferred_username: '',
                        // zoneinfo: 'America/Los_Angeles'
                    })
                    if (!!!userPrefCtx.bearerToken || userPrefCtx.bearerToken !== data.access_token) {
                        userPrefCtx.setBearerTokenFunction(data.access_token)
                    }

                    if (!!props.onLogin) {
                        props.onLogin()
                    } else {
                        // different type of user - show page depending on the user
                        if (data.is_him_user) {
                            navigate('/him')
                        } else if (data.is_ipad_user) {
                            navigate('/ipad')
                        } else {
                            navigate('/')
                        }
                        
                    }

                    if (!!props.onHide) {
                        props.onHide()
                    } else {
                        // hide
                        setShowDBLogin(false)
                    }

                } else if (!!data.detail) {
                    if (typeof data.detail === "string") {
                        setErrors([data.detail])
                    } else if (typeof data.detail === "object" && !!data.detail.length && data.detail.length > 0) {
                        let e = []
                        data.detail.forEach(dd => {
                            if (!!dd.loc && dd.loc.length > 1) {
                                e.push(`${dd.loc[1]} ${dd.msg}`)
                            }
                        })
                        setErrors([...e])
                    }
                }
            })
            .catch((err) => {
                console.log(err.message);
            })
        }

        loginToDB()


    }

    // const handleCancel = () => {
    //     props.onHide()
    // }




    return (
        <div className={styles.fullscreen}>
            <Modal size="sm" backdrop="static" centered show={props.show || showDBLogin} 
                /*styles={bg}*/ 
                // backdropClassName={props.mode === 'autolock' ? styles.gray: styles.white} 
                backdropClassName={styles.white}
            >
                <Modal.Header>
                    <Modal.Title><h1>EnlightenMD</h1></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!!props.showMessage ? 
                        <div className='w-100 text-center fs-5 text message'>{props.showMessage}</div>
                        :
                        <div className='w-100 text-center fs-4 text'>Sign In</div>
                    }
                    
                    <Form onSubmit={handleLogin}>
                        {/* <Row className='my-3'>      
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="entity" >{companyCtx.company.auth_mode === 'AD' ? 'Domain' : 'Entity'}</Form.Label>
                                <Form.Control
                                    placeholder={companyCtx.company.auth_mode === 'AD' ? 'Domain' : 'Entity'}
                                    type='text'
                                    name="entity"
                                    autoFocus
                                    // required
                                    ref={entity}
                                />
                                </Form.Group>
                        </Row> */}
                        <Row className='my-3'>      
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="userid" >{companyCtx.company.auth_mode === 'AD' ? 'Network User Name' : 'Username'}</Form.Label>
                                <Form.Control
                                    placeholder={companyCtx.company.auth_mode === 'AD' ? 'Network User Name' : 'Username'}
                                    type='text'
                                    name="userid"
                                    required
                                    ref={userid}
                                />
                                {/* <Form.Control.Feedback type="invalid">Email address is required</Form.Control.Feedback> */}
                                </Form.Group>
                        </Row>

                        <Row className='my-3'>      
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="password" >Password</Form.Label>
                                {/* <InputGroup> */}
                                <div className="password-input-container">
                                <Form.Control
                                    placeholder='Password'
                                    //type='password'
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    required
                                    ref={password}
                                />
                                {/* <Form.Control.Feedback type="invalid">Password is required</Form.Control.Feedback> */}
                                {/* <InputGroup.Append> */}
                                    {/* <Button variant="outline-secondary" onClick={togglePasswordVisibility}> */}
                                        {/* <BsFillEyeFill onClick={togglePasswordVisibility}>
                                            {showPassword ? 'Hide' : 'Show'}
                                        </BsFillEyeFill> */}
                                        <div className="password-toggle" onClick={togglePasswordVisibility}>
                                            {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
                                        </div>
                                    {/* </Button> */}
                                    {/* </InputGroup.Append> */}
                                    {/* </InputGroup> */}
                                    </div>
                                </Form.Group>
                        </Row>

                        <Row>
                            <ul className={`${styles['no-bullets']} ${styles['red']}`}>
                            {errors.map((err,err_i) => <li key={err_i}>{err}</li>)}
                            </ul>
                        </Row>

                        <Row>
                            <div className='col'>
                                <Button className='w-100 btn-large fs-5 text' variant="primary" /*onClick={handleLogin}*/ type="submit">
                                    Login
                                </Button>
                            </div>
                        </Row>
                    </Form>
                </Modal.Body>

            </Modal>

        </div>
    )
}

export default LoginBoxDB
