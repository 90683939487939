import React, {useEffect, useState} from 'react'

function EFaceSlider(props) {

    const [labels, setLabels] = useState([])
    const [labelValues, setLabelValues] = useState([])
    const [rangeValue, setRangeValue] = useState(0)

    const labelStyles = ["text-start", "text-center", "text-end"]

    useEffect(() => {
        // console.log("EFaceSlider question_option:", props.question.question_option)
        // console.log("EFaceSlider question_option parsed:", JSON.parse(props.question.question_option))
        // console.log("EFaceSlider question/value:", props.question, props.value)
        setLabels(JSON.parse(props.question.DictIntakeFormQuestion.question_option))
        const labVals = JSON.parse(props.question.DictIntakeFormQuestion.question_option_values)
        setLabelValues(labVals)
        setRangeValue(props.value)

    }, [props.question])

    const rangeChanged = (event) => {
        setRangeValue(event.target.value)
        props.onChange(props.question.DictIntakeFormQuestion.display_name_short, event.target.value)
    } 

    

    return (
        <>
            <div className="row ">

                <div className="col-1">&nbsp;</div>
                <div className="col-10">
                    <div className="row text-center  my-4">
                        <h4>{props.question.DictIntakeFormQuestion.display_name}: <span>{rangeValue}</span> </h4>
                    </div>
                    <div className="row text-center">
                        <div className="col">


                            <input type="range" 
                                onChange={rangeChanged}
                                className="col-12"
                                min={labelValues[0]} max={labelValues[labelValues.length-1]} 
                                value={rangeValue} 
                                // data-toggle="tooltip" data-placement="top"
                                // title={`Current value: ${rangeValue}`}
                                />
                        </div>
                    </div>
                    <div className="row ">
                        {labelValues.map((val, i) => 
                            <div key={i} className={`col-4 ${labelStyles[i]}`}>{val}</div>
                        )}
                    </div>

                    <div className="row">
                        {labels.map((val, i) => 
                            <div key={i} className={`col-4 ${labelStyles[i]}`}>{val}</div>
                        )}
                    </div>
                </div>
                <div className="col-1">&nbsp;</div>
            </div>
        </>
    )
}

export default EFaceSlider
