import React, {useEffect, useState} from 'react'
import parse from 'html-react-parser'
import './FaceInstrumentQuestionGroup.css'

function FaceInstrumentQuestionGroup(props) {

    // console.log("FaceInstrumentQuestionGroup props", props)
    const [answers, setAnswers] = useState([])
    const [groupHeader1, setGroupHeader1] = useState('')
    // const parse = require('html-react-parser')

    const [values, setValues] = useState()

    useEffect(() => {
        // console.log("FaceInstrumentQuestionGroup parsing:", props.questions[0].question_option)
        // question answers
        const q_answ = JSON.parse(props.questions[0].DictIntakeFormQuestion.question_option)
        // question values
        const q_vals = JSON.parse(props.questions[0].DictIntakeFormQuestion.question_option_values)
        // set answers as array - each element has properties: answer and value
        const q_answ_val = q_answ.map((a, i) => ({answer: a, value: q_vals[i]}))

        // console.log("**** FaceInstrumentQuestionGroup **** q_answ_val", q_answ_val)


        setAnswers(q_answ_val)
        // setAnswers(JSON.parse(props.questions[0].question_option))

        // const parse = require('html-react-parser')
        // setGroupHeader1(parse(props.questions[0].group_header1))

    }, [props.group_id, props.errors])

    // const q_answers = JSON.parse(props.questions[0].question_option)

    const value_changed = (event) => {
        // console.log("Value changed",event.target)
        // console.log("Value changed name",event.target.name)
        // console.log("Value changed value",event.target.value)

        props.onChange(event.target.name, event.target.value)

    }

    return (
        <>
            {
                props.questions[0].DictIntakeFormQuestion.group_header1 && parse(`${props.questions[0].DictIntakeFormQuestion.group_header1}`)
            }
            {
                props.questions[0].DictIntakeFormQuestion.group_header2 && parse(`${props.questions[0].DictIntakeFormQuestion.group_header2}`)
            }
            {
                props.questions[0].DictIntakeFormQuestion.group_header3 && parse(`${props.questions[0].DictIntakeFormQuestion.group_header3}`)
            }
            <div className="border">
                
                <table className="table table-sm table-borderless">
                    <thead>
                        <tr>
                            {answers.map((opt,index) => 
                                <td scope="col" key={index}>{opt.answer}</td>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {props.questions.map((q, index, allQs) => 
                            <tr key={index}>
                                <td scope="row">
                                    <ul>
                                        <li>
                                            {props.errors.length > 0 && props.errors.includes(q.DictIntakeFormQuestion.display_name_short) ? 
                                                <span className='text-danger'>{q.DictIntakeFormQuestion.display_name}</span> 
                                                :
                                                <span>{q.DictIntakeFormQuestion.display_name}</span>
                                            }
                                            {/* {q.display_name} */}
                                        </li>
                                    </ul>
                                </td>
                                {answers.filter((opt,ix) => ix !== 0).map((opt,index) =>
                                    
                                    <td key={index}>
                                        <input className={`face-instruments-form-check-input ${props.errors.length > 0 && props.errors.includes(q.DictIntakeFormQuestion.display_name_short) ? 'error' : 'good'}`}
                                            type={q.DictIntakeFormQuestion.question_type} 
                                            name={q.DictIntakeFormQuestion.display_name_short} 
                                            id={`${q.DictIntakeFormQuestion.display_name_short}_${index}`} 
                                            value={opt.value}
                                            onChange={value_changed}>
                                        
                                        </input>
                                    </td>
                                )}
                                
                            </tr>
                        )}
                    </tbody>
                </table>
                
            </div>
        </>
    )
}

export default FaceInstrumentQuestionGroup
