import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { BsFillPlusSquareFill } from "react-icons/bs";
import ReusableButton from "../../buttons/reusableButton";
import AddDiagnosis from "./AddDiagnosis";
import EditDictDiag from "./EditDictDiag";
import PromptDictDiagDelete from "./PromptDictDiagDelete";
//import UserPrefContext from "../../../../context/userPrefContext";
//import FPdiagModal from './FPdiagModal';
//import OtherdiagModal from './OtherdiagModal';

function NewDictDiag(props) {
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [openFPModal, setOpenFPModal] = useState(false);

  const [openOtherModal, setOpenOtherModal] = useState(false);

  const [openAddDiagModal, setOpenAddDiagModal] = useState(false);
  const [openAddDiagModal2, setOpenAddDiagModal2] = useState(false);
  const [openEditDiagModal, setOpenEditDiagModal] = useState(false);
  const [openDeleteDiagModal, setOpenDeleteDiagModal] = useState(false);

  const [deleteID, setDeleteID] = useState();

  const [editID, setEditID] = useState();
  const [editGroupDisplayName, setEditGroupDisplayName] = useState();
  const [editDiagType, setEditDiagType] = useState();
  const [editDisplayNameShort, setEditDisplayNameShort] = useState();
  const [editUniqueID, setEditUniqueID] = useState();
  const [editSortPosition, setEditSortPosition] = useState();

  const [alphaProp, setalphaProp] = useState();

  const [check, setCheck] = useState(false);

  //console.log(props.sendDictDashboard)
  //console.log('props.setOpen is ',props.setOpen)

  const checkOpen = props.setOpen;

  if (props.setOpen === true) {
    //setCheck(true);
  }

  if (props.func != null) {
    //console.log('props.func is ', props.func)
  }


  useEffect(() => {
  //This is to sort them alphabetically
  if(props.sendDictDashboard?.dict_diag != undefined){
    
  // const alphaProp2 = props.sendDictDashboard.dict_diag?.sort((a, b) =>
  //   a.group_display_name.localeCompare(b.group_display_name)
  // );

  const alphaProp3 = props.sendDictDashboard.dict_diag?.sort((a, b) => {
    // Sort by group_display_name
    const groupSort = a.group_display_name.localeCompare(b.group_display_name);
    if (groupSort !== 0) {
      return groupSort;
    }
  
    // Sort by diag_type
    const diagSort = a.diag_type.localeCompare(b.diag_type);
    if (diagSort !== 0) {
      return diagSort;
    }
  
    // Sort by display_name
    return a.display_name.localeCompare(b.display_name);
  });

  //setalphaProp(alphaProp2);
  setalphaProp(alphaProp3);
  //console.log(alphaProp2)

  }

  }, [props.sendDictDashboard?.dict_diag]);

  // console.log(alphaProp)


  const openAddDiag = () => {
    setOpenAddDiagModal(true);
  };

  const openAddDiag2 = () => {
    setOpenAddDiagModal2(true);
  };


  const closeAddDiag = () => {
    setOpenAddDiagModal(false);
    props.close()
  };

  const closeAddDiag2 = () => {
    setOpenAddDiagModal2(false);
    props.close()
  };

  const closeEditDiag = () => {
    setOpenEditDiagModal(false);
    props.close()
  };

  const closeDeleteDiag = () => {
    setOpenDeleteDiagModal(false);
    props.close()
  };

  const callAddDiag = () => {
    console.log("in the callAddDiag function");
    setOpenAddDiagModal(true);
    //props.close()
  };

  const closeOnAddDiag = (data) => {
    //This is the function to get the color value from the BotoxType(child) component
    console.log("This is the closeOnAddDiag function ", data);

    //need to pass the data (false value) to the adminactions to close this modal when the AddDiagnosis component is clicked on

    //this is to
    //props.func(data)
    props.func(false);
    checkOpen = false;
    setCheck(false);
    //console.log()
    props.close();

    //setIdData(data);
    // setColorArr(data);
  };

  const closeOnEditDiag = (data) => {
    //This is the function to get the color value from the BotoxType(child) component
    console.log("This is the closeOnAddDiag function ", data);

    //need to pass the data (false value) to the adminactions to close this modal when the AddDiagnosis component is clicked on

    //this is to
    //props.func(data)
    props.func(false);
    checkOpen = false;
    setCheck(false);
    //console.log()
    props.close();

    //setIdData(data);
    // setColorArr(data);
  };

  const showModal = (e) => {};

  const handleEditClick = (id, groupDisplayName, diagType, displayNameShort, uniqueID, sortPosition, alphaprop) => {
    // console.log(id, groupDisplayName, diagType, displayNameShort, uniqueID, sortPosition);
    // console.log(alphaprop)
    setEditID(id);
    setEditGroupDisplayName(groupDisplayName);
    setEditDiagType(diagType);
    setEditDisplayNameShort(displayNameShort);
    setEditUniqueID(uniqueID);
    setEditSortPosition(sortPosition);

    setOpenEditDiagModal(true)
  };

  const handleDeleteClick = (id) => {
    //console.log(e);
    setDeleteID(id);
    //console.log(id);
    setOpenDeleteDiagModal(true)
  };


  const retrieveEditData = (data) => {
    props.sendToParent(data);
    //console.log(data)
  }

  const retrieveAddData = (data) => {
    props.sendToParent(data);
    //console.log(data)
  }

  const retrieveDeleteData = (data) => {
    props.sendToParent(data);
    //console.log(data)
  }

  return (
    <>
      <Modal
        id="new-dict-diag-modal"
        size="fullscreen"
        show={props.setOpen}
        //show={!openAddDiagModal}
        //onHide={props.close}
        onHide={() => {
          props.close();
          //closeAddDiag(); // Close the Child modal when the Parent modal is closed
        }}
        animation={false}
        //This is needed because when the Add, Edit or Delete Diagnosis buttons are clicked, it needs to hide this modal while the child shows up
        style={{ visibility: (openAddDiagModal2 || openEditDiagModal || openDeleteDiagModal) ? 'hidden' : 'visible' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>New/Modify Diagnosis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Diagnosis</h3>
          <Button 
            //onClick={() => callAddDiag()}
            //onClick={openAddDiag}
            onClick={() => {
              openAddDiag(); 
              openAddDiag2();}}
          >
            Add Diagnosis
          </Button>
          <AddDiagnosis
            sendDictDashboard={props.sendDictDashboard}
             setOpen={openAddDiagModal}
             setOpen2={openAddDiagModal2}
            //setOpen={() => {openAddDiagModal(); props.close();}}
            //close={() => setOpenAddDiagModal(false)}
            close={closeAddDiag}
            close2={closeAddDiag2}
            closeParent={props.close}
            sendToParent={retrieveAddData}
            //func={closeOnAddDiag}
          />
          <EditDictDiag
            sendDictDashboard={props.sendDictDashboard}
            editID={editID}
            editGroupDisplayName={editGroupDisplayName}
            editDiagType={editDiagType}
            editDisplayNameShort={editDisplayNameShort}
            editUniqueID={editUniqueID}
            editSortPosition={editSortPosition}
            setOpen={openEditDiagModal}
            //close={() => setOpenEditDiagModal(false)}
            close={closeEditDiag}
            sendToParent={retrieveEditData}
            func={closeOnEditDiag}
          />
          <PromptDictDiagDelete 
            setOpen={openDeleteDiagModal}
            //close={() => setOpenDeleteDiagModal(false)}
            close={closeDeleteDiag}
            sendDeleteID={deleteID}
            sendToParent={retrieveDeleteData}
          />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Group Name</th>
                <th>Diagnosis Main Type</th>
                <th>Diagnosis Name</th>
              </tr>
            </thead>
            <tbody>
              { alphaProp != null &&
              Object.keys(alphaProp).map((item, i) => (
                <tr key={item}>
                  <td>{alphaProp[item].id}</td>
                  <td>{alphaProp[item].group_display_name}</td>
                  <td>{alphaProp[item].diag_type}</td>
                  <td>{alphaProp[item].display_name_short}</td>
                  <td>
                    <Button 
                      className="btn btn-warning"
                      onClick={() => 
                        handleEditClick(alphaProp[item].id, alphaProp[item].group_display_name,
                                        alphaProp[item].diag_type, alphaProp[item].display_name_short,
                                        alphaProp[item].unique_id, alphaProp[item].sort_position, alphaProp[item])
                      }
                    >
                      Edit
                    </Button>
                  </td>
                  <td>
                    <Button className="btn btn-danger" onClick={() => handleDeleteClick(alphaProp[item].id)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewDictDiag;
