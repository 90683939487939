import React, { useState, useRef, useContext, Fragment } from "react";
import { useNavigate } from 'react-router-dom';
import AsyncSelect, { useAsync } from 'react-select/async';
import moment from 'moment';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BsFillPlusSquareFill } from "react-icons/bs";
import PatientContext from "../../../../context/patientContext";
import UserPrefContext from "../../../../context/userPrefContext";
import AuthContext from "../../../../context/authContext";

function FreeTextSearchModal(props) {
  const [eventInput, setEventInput] = useState([]);
  const [patientData, setPatientData] = useState();

  const navigate = useNavigate();


  //Using context to pass the patient token
  const patCtx = useContext(PatientContext);
  const userPrefCtx = useContext(UserPrefContext);
  const authCtx = useContext(AuthContext);

  const searchHandler = async (e) => {

    setEventInput(e);
    let url = process.env.REACT_APP_SERVER + `/api/v1/search_services/free_text_search?search_token=${e}`;

        if(e.length > 2) {

            //console.log('matches')

      const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userPrefCtx.bearerToken}`,
            auth: authCtx.authMode,
          },
        })

        const jsonResponse = await response.json()
        //console.log(jsonResponse)
        return jsonResponse
    //     if ( jsonResponse.response_code === 1) {
    //       console.log(jsonResponse.response_data)
    //       return jsonResponse.response_data
    //     } else{
    //         console.log('cannot find anything');
    //     }
    //   } else {
    //     console.log('in the else')
      }
    }

    const dropdownOptions = (e) => {
        //console.log('this is the value of e in dropdown options = ', e);
        let formattedDate = moment(e.dob).format('MM/DD/YYYY');
        return ` ${e.first_name} ${e.last_name}, MRN: ${e.emr_mrn}, Gender: ${e.gender}, DOB: ${formattedDate}`
      }

    //   const handleInputChange = (e) => {
    //     setValue(e);
    //     // console.log('handleInputChange value of e is: ', e);
    //   };

    const handleChange = async (e) => {

        let postURL = process.env.REACT_APP_SERVER + `/api/v1/patients/creat_patient_with_mrns`;
        //console.log(postURL)
  
        //check if dob empty or alert if it is empty that date is empty and cant process it 
      if ((e?.dob != null) || (e?.emr_mrn != null) || (e?.first_name != null) || (e?.last_name != null) || (e?.gender != null)) 
        {
        // var strdob = e.dob;
        var strdob = moment(e.dob).format('MM/DD/YYYY');
        strdob = strdob.split('/');
        var dobresult = strdob[2] + '-' + strdob[0] + '-' + strdob[1];
        patCtx.pat_dob = e.dob
        patCtx.pat_mrn = e.emr_mrn
        patCtx.pat_first = e.first_name
        patCtx.pat_last = e.last_name
        patCtx.pat_gender = e.gender
        patCtx.pat_email = e.email
        patCtx.pat_local_site_mrn = e.local_site_mrn
        patCtx.pat_active_mrn = e.active_mrn
        patCtx.pat_all_mrn = e.all_mrn
  
      //   console.log('e.all_mrn', e)
  
        const responseData = await fetch(postURL, {
          method: 'POST',
          body: (JSON.stringify({
            "emr_mrn": e.emr_mrn,
            "first_name": e.first_name,
            "last_name": e.last_name,
            "gender": e.gender,
            "dob": dobresult,
            "all_mrn": e.all_mrn,
          })),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userPrefCtx.bearerToken}`,
            auth: authCtx.authMode,
          },
        }).then((res) => res.json())
        .then((data) => {
           //console.log('postURL data: ', data);
  
           //this is to set to the useState
           setPatientData(data);
          //console.log('patientData from SearchBar', patientData)
          //console.log('patientData from SearchBar: ', data)
  
          patCtx.pat_token = data.patient_token
          patCtx.pat_first = data.first_name
          patCtx.pat_last = data.last_name
          patCtx.pat_middle = data.middle_name !== null ? data.middle_name : ''
          patCtx.pat_mrn = data.emr_mrn
          patCtx.pat_dob = data.dob
          patCtx.pat_gender = data.gender
          patCtx.pat_email = data.email
          patCtx.pat_id = data.id
          patCtx.pat_profile_image_filename = data.file_name 
          patCtx.pat_email = data.email
          patCtx.pat_active_mrn = data.emr_mrn
          patCtx.pat_local_site_mrn = data.emr_mrn
          
  
  
  
          function formatDate(dateString) {
            //This function will convert yyyy-mm-dd to mm/dd/yyyy
            const [year, month, day] = dateString.split("-");
            return `${month}/${day}/${year}`;
          }
  
          const formattedDate = formatDate(data.dob);
  
           //console.log('patCtx in searchbar', patCtx)
  
        navigate('/Visit', 
        {state: {
          firstName: data.first_name,
          lastName: data.last_name,
          middleName: data.middle_name !== null ? data.middle_name : '',
          mrn: data.emr_mrn,
          dob: formattedDate,
          gender: data.gender,
          patientToken: data.patient_token,
          bearerToken: props.sendBearerToken,
          patId: data.id,
          profileImageFileName: data.file_name 
        }})
        
        })
        .catch((err) => {
           console.log(err.message);
        });
  
        //console.log('patient data is ', patientData);
  
    } else{
        if(e?.first_name === null) {
          alert('First name is undefined!')
        }
        if(e?.last_name === null) {
          alert('First name is undefined!')
        }
        if(e?.emr_mrn === null) {
          alert('MRN is undefined!')
        }
        if(e?.dob === null) {
          alert('DOB is undefined!')
        }
        if(e?.gender === null) {
          alert('Gender is undefined!')
        }
    }
    }


  return (
    <>
      <Modal 
        show={props.setOpen} 
        onHide={props.close} 
        animation={false}
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Free Text Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <AsyncSelect
        isClearable
        loadOptions={searchHandler}
        placeholder={"Enter Text (Please enter 3 characters or more)"}
        getOptionValue={dropdownOptions}
        getOptionLabel={dropdownOptions}
        //onInputChange={handleInputChange}
        onChange={handleChange}
      />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FreeTextSearchModal;
