import React, { useState, useContext, useEffect } from 'react'
import PatientContext from '../../../context/patientContext'
import UserPrefContext from '../../../context/userPrefContext'
import DictionaryContext from '../../../context/dictionaryContext'
import AuthContext from '../../../context/authContext'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { BsFillPlusSquareFill, BsFillExclamationTriangleFill } from 'react-icons/bs'
import GrbasQuestionGroup from './GrbasQuestionGroup'
import ConfirmDialog from '../shared/ConfirmDialog'
import ErrorDialogModal from '../modals/ErrorDialogModal'
import classes from './Grbas.module.css'

function GrbasModal(props) {
    const panelName='grbas_panel'
    const [readOnly, setReadOnly] = useState(false)
    const patCtx = useContext(PatientContext)
    const userPrefCtx = useContext(UserPrefContext)
    const dictCtx = useContext(DictionaryContext)
    const authCtx = useContext(AuthContext)
    const [groups, setGroups] = useState([])
    const [sortedQuestions, setSortedQuestions] = useState([])
    // const [errors, setErrors] = useState([])
    const [modalTitle, setModalTitle] = useState('')
    const [grbasData, setGrbasData] = useState([])

    const [showConfirm, setShowConfirm] = useState(false)
    const [showError, setShowError] = useState(false);
    const [confirmDialogText, setConfirmDialogText] = useState('')

    const [errors, setErrors] = useState([])

    const GrbasCreateURL = `${process.env.REACT_APP_SERVER}/api/v1/grbas/${patCtx.pat_token}`
    const GrbasUpdateURL = id => `${process.env.REACT_APP_SERVER}/api/v1/grbas/${id}`

    const initialData = {
        grbas_date: new Date(),
        grbas_voice_no: 0,
        grbas_g: '',
        grbas_r: '',
        grbas_b: '',
        grbas_a: '',
        grbas_s: '',
    }


    const httpHeaders = (token) => {
        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'accept': 'application/json',
            auth: authCtx.authMode,
        }
    }

    // props.data is null if new; otherwise it has capeV data to edit
    useEffect(() => {
        let allTypesAndOptions = []
        if (dictCtx.dict_panel.length > 0) {
            // get value of the panel from dict_panel
            const dictPanel = dictCtx.dict_panel.find(pnl => pnl.display_name_short === panelName)

            if (dictPanel !== undefined) {
                // find dict_intake_form by display_name_short:
                // display_name_short in dict_panle looks like "panelname_panel"
                // display_name_short in dict_intake_form looks like "panelname"
                const intakeForm = dictCtx.dict_intake_form.find(form => {
                    return form.display_name_short === dictPanel.display_name_short.replace("_panel","")
                })

                if (intakeForm !== undefined) {
                    // sort questions by sort_position
                    let questions = dictCtx.dict_intake_form_question.filter(quest => {
                        return quest.DictIntakeFormQuestion.dict_intake_form_id === intakeForm.id
                    })
                    
                    
                    const comp = (a,b) => {
                        var agrp = (+a.DictIntakeFormQuestion.group_id) * 100 + (+a.DictIntakeFormQuestion.sort_position)
                        var bgrp = (+b.DictIntakeFormQuestion.group_id) * 100 + (+b.DictIntakeFormQuestion.sort_position)

                        // console.log("sort:", typeof anum, typeof bnum, (anum - bnum))
                        return agrp - bgrp
                    }
                    

                    // const sortedQuestions = questions.sort((a,b) => a.sort_position - b.sort_postion)
                    const sortedQs = questions.sort(comp)
                    // console.log("CapeVModal - Sorted questions", sortedQs)

                    setSortedQuestions(sortedQs)
                    // console.log("sortedQs", sortedQs)

                    // set Modal Title with patient name
                    setModalTitle(`${intakeForm.display_name} - ${patCtx.pat_last}, ${patCtx.pat_first}`)
                    

                    // get group_ids of questions (only one id per group)
                    const groups_c = sortedQs
                        .map((value, index, qs) => (index > 0 && qs[index-1].DictIntakeFormQuestion.group_id === value.DictIntakeFormQuestion.group_id) ? null : value.DictIntakeFormQuestion.group_id)
                        .filter(value => value !== null)

                    setGroups(groups_c)
                }
            }
        }
        
    }, [dictCtx.dict_panel.length])


    useEffect(() => {
        // set readOnly Mode
        if (userPrefCtx.userPrefState.UserPreference.user_dept === dictCtx.dict_panel_departments[panelName]) {
            setReadOnly(false)
        } else {
            setReadOnly(true)
        }
    }, [userPrefCtx.userPrefState.UserPreference.user_dept, dictCtx.dict_panel_departments])


    useEffect(() => {
        // console.log("******* CapeVModal props ******", props)
        let ignoreGrbas = false

        const getGrbasById = async(id) => {
            try {
                const GrbasGetByIdURL = `${process.env.REACT_APP_SERVER}/api/v1/grbas/${id}`
                const response = await fetch(GrbasGetByIdURL, {
                    headers: httpHeaders(userPrefCtx.bearerToken),
                })
    
                if (response !== null) {
                    const responseData = await response.json()
                    if (responseData.hasOwnProperty('grbas_date')) {
                        // console.log("GrbasModal - data", responseData)
                        setGrbasData({...responseData, grbas_date: new Date(responseData.grbas_date)})
                    } else {
                        // console.log("Failed to get GRBAS with id", id, responseData)
                        if (responseData.hasOwnProperty('detail')) {
                            setConfirmDialogText(responseData.detail)
                            setShowConfirm(true)
                        }
                    }
                } else {
                    console.log("Failed to get Grbas with id", id, response)
                }
            } catch (e) {
                console.log("Failed to get Grbas with id", id, e)
            }
        }

        // props.data is either null (new Grbas) or Grbas Id
        if (userPrefCtx.bearerToken !== '') {
            if (props.data === null || props.data.length === 0) {
                setGrbasData(initialData)
            } else {
                getGrbasById(props.data[0])
            }
        }
        


        return () => {
            ignoreGrbas = true
        }

    },[props.data, userPrefCtx.bearerToken])


    const valueChanged = (new_values) => {
        // console.log("faceInstrumentValues", faceInstrumentValues)
        // console.log("Question value changed! Question:", question, ", Value:", value)
        // setFaceInstrumentValues({...faceInstrumentValues, [question]:value})
        // console.log("CAPEV MODAL NEW VALUES", new_values)
        setGrbasData({...grbasData, ...new_values})

    }



    const grbasOperation = async(operation) => {
        try {
            const id = grbasData.hasOwnProperty('id') ? grbasData.id : ''
            const httpMethod = id === '' ? "POST" : (operation === 'delete' ? "DELETE" : "PATCH")
            const url = id === '' ? GrbasCreateURL : GrbasUpdateURL(id)
            console.log('grbasData: ', grbasData);

            const response = await fetch(url, {
                headers: httpHeaders(userPrefCtx.bearerToken),
                method: httpMethod,
                body: JSON.stringify(grbasData),
            })

            if (response.status === 200) {
                const responseData = await response.json()
                // console.log("Created/updated e-Face", responseData)
                
                //This is to send data back to the visit panels so that the grbas list can be updated whenever there is a change here
                props.sendResponsetoVisitPanels(responseData);
                props.onAnyDataChange(true)
                props.onClose()

            } else {
                console.log("Failed to create/update/delete Grbas", response)
                if (!response.ok) {
                    throw new Error('Unprocessable Entity: ' + response.statusText);
               }
            }
        } catch (e) {
            console.log("Failed to create/update/delete Grbas", e)
            setShowError(true);
        }
    }



    // validate and save changes
    const handleSave = () => {
        // validate data (grbas data) before saving - make sure all string values are set
        let err_messages = []
        Object.keys(grbasData).forEach((key) => {
            if (typeof grbasData[key] === "string" && !!!grbasData[key]) {
                err_messages.push(key)
            }
        })
        setErrors(err_messages)

        if (err_messages.length === 0) {

            
            // console.log("SAVING DATA", grbasData)

        grbasOperation('new or update')
        // force refresh Grbas List
        // props.onAnyDataChange(true)
        // props.onClose()
    }
}






    const handleDelete = () => {
        grbasOperation('delete')
        // force refresh Grbas List
        // props.onAnyDataChange(true)
        // props.onClose()
    }


    const handleClose = () => {
        // setErrors([])
        props.onClose()
    }

    const closeConfirmDialog = (result) => {
        setShowConfirm(false)
        handleClose()
    }


    return (
        <>
            {!readOnly &&
                <BsFillPlusSquareFill onClick={props.handleShow} className="icon-pointer"/>
            }

            {!showConfirm &&
            <Modal size="fullscreen" show={props.show} onHide={handleClose} 
                style={{ visibility: (showError) ? 'hidden' : 'visible' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title><h4>{modalTitle}</h4></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>

                        {groups.map((group_id, index) => 
                            <GrbasQuestionGroup 
                                key={index}
                                group_id={group_id}
                                questions={sortedQuestions.filter(q => q.DictIntakeFormQuestion.group_id === group_id)}
                                onChange={valueChanged}
                                // errors={errors}
                                // mode='new'
                                data={grbasData}
                                readOnly={readOnly}
                                // setErrors={changeErrors}
                            />
                        )}
                    </div>
                    <div>
                        {errors.length > 0 && 
                            <span className={classes["error-msg"]}>{`${errors.join(', ')} should have value(s)`}</span>
                        }
                    </div>
                </Modal.Body>

                <Modal.Footer className='justify-content-between'>
                        <div className="inline-block text-left">
                            <Button className='mx-2' variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            {props.data !== null && props.data.length > 0 && !readOnly &&             
                                <Button className='mx-2 danger-button' variant="secondary" onClick={handleDelete}>
                                    Delete
                                </Button>
                            }
                        </div>

                        {!readOnly &&
                            <div className='inline-block text-right'>
                                <Button className='mx-2' variant="primary" onClick={handleSave}>
                                    Save Changes
                                </Button>
                            </div>
                        }
                </Modal.Footer>
            </Modal>
            }
            <ConfirmDialog 
                show={showConfirm}
                iconColor='red'
                icon={<BsFillExclamationTriangleFill />}
                title='Something went wrong'
                // modal_class='diags-modal-delete-confirm-modal'
                title_class={classes['alert-title']}
                // footer_class='diags-modal-delete-confirm-title'
                prompt={confirmDialogText}
                prompt_class={classes['alert-prompt']}
                cancel_button_text=''
                confirm_button_text='OK'
                confirm_button_variant='primary'
                onClose={(result) => closeConfirmDialog(result)}
            />
            <ErrorDialogModal
                setOpen={showError}
                close={() => setShowError(false)}
                componentName="Grbas"
            />
        </>
    )
}


export default GrbasModal;
