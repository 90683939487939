import React, { useState, useContext, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import Form from "react-bootstrap/Form"
import { FormControl, FormGroup, FormLabel, FormText } from 'react-bootstrap'
import {BsFillPersonFill} from 'react-icons/bs' 
import moment from 'moment'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import VisitType from './VisitType'
import DOMPurify from 'dompurify'
import styles from './VisitNotesDetail.module.css'
import CompanyContext from '../../../context/companyContext'

function VisitNotesDetail(props) {

    const companyCtx = useContext(CompanyContext)
    const [visitDate, setVisitDate] = useState(new Date())
    const [noteText, setNoteText] = useState('')
    const [visitType, setVisitType] = useState('')
    const [embedHeight, setEmbedHeight] = useState('290px')
    const refPdf = useRef()


    const getPdfNoteURL = (pdf_name) => {
        return `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/visit_notes/${pdf_name}?patient_id=${props.patient_id}&dict_company_code_id=${companyCtx.company.id}#toolbar=0&navpanes=0&scrollbar=1&view=fitH`
    }

   
    useEffect(() => {
        // console.log("Visit Note Details props:", props )
        setNoteText(props.visit_note)
        setVisitDate(new Date(props.visit_date))
        setVisitType(props.visit_type)
    }, [props.visit_note, props.visit_date, props.visit_type])


    

    const dateChanged = (date) => {
        setVisitDate(date)
        props.onDateChanged(date)
    }


    const textChanged = (event) => {
        setNoteText(event.target.value)
        props.onNoteChange(event.target.value)
    }

    const visitTypeChanged = (selectedValue) => {
        // console.log("Visit Note Details - selected visit type", selectedValue)
        setVisitType(selectedValue)
        // const newVisitData = {...props.visit_data.Visit, visit_type: selectedValue }
        // props.onVisitDataChanged({...props.visit_data, Visit: newVisitData})
        props.onVisitTypeChanged(selectedValue)
    }
 
    return (
        <Form className='visit-dialog-form'>
            <div className='visit-dialog-form-header'>
                <BsFillPersonFill className='visit-detail-icon' />
                Visit Details
            </div>
            <Row>
                <Col xs={4}>
                    <FormGroup className='visit-dialog-form-group row'>
                        <FormLabel className='col-form-label col-sm-3 my-0'>Visit Date</FormLabel>
                        <div className={styles.divInlineBlock} >
                            <DatePicker selected={visitDate} onChange={(date) => dateChanged(date)} 
                                className="datepicker-w100" 
                                disabled={props.is_epic_visit}
                                readOnly={props.readOnly}
                            />
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={8}>
                    {!props.is_epic_visit &&
                        <VisitType 
                            className='visit-dialog-form-group' 
                            visit_type={props.visit_type} 
                            onSelect={visitTypeChanged}
                            readOnly={props.readOnly}
                        />
                    }

                    {props.is_epic_visit &&
                        <FormGroup className='visit-dialog-form-group'>
                            <FormLabel>Visit Type</FormLabel>
                            <p>
                            <FormText>{props.visit_type}</FormText>
                            </p>
                        </FormGroup>
                    }
                    
                </Col>
            </Row>
            

            <FormGroup className='visit-dialog-form-group'>
                <FormLabel>Visit Notes</FormLabel>
                
                {!props.is_epic_visit && 
                    <Form.Control 
                        as="textarea" 
                        rows={3} 
                        value={noteText}
                        onChange={textChanged}
                        readOnly={props.readOnly}
                        />
                }
                {props.is_epic_visit &&
                    <div className='visit-notes-detail-bordered'
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(noteText) }} />
                }

                {!!props.visit_pdf && 
                    <div className={styles['visitPdfNote']} ref={refPdf}>
                        <div>
                            <iframe className='d-flex w-100'
                                src={getPdfNoteURL(props.visit_pdf)} 
                                type='application/pdf' 
                                width='100%' 
                                // height='100%'
                                height={embedHeight}
                            >
                            
                            </iframe>
                        </div>
                    </div>

                }
            </FormGroup>

        </Form>
    )
}

export default VisitNotesDetail
