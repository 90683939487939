import React, { useState, useEffect, useContext } from 'react';
import PatientContext from '../../../context/patientContext';
import UserPrefContext from '../../../context/userPrefContext';
import AuthContext from '../../../context/authContext';
import ReturnBotoxImage from './ReturnBotoxImage';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styles from './ReturnBotoxImage.module.css'


const BotoxMapPanelDisplayList = (props) => {

  const [botoxList, setBotoxList] = useState();
  const [imgArray, setImgArray] = useState([]) 

  const visCtx = useContext(PatientContext);
  const userPrefCtx = useContext(UserPrefContext)
  const authCtx = useContext(AuthContext)

  const botoxMapListURL = process.env.REACT_APP_SERVER + "/api/v1/botox_maps/get_all_by_token/" + visCtx.pat_token;

  useEffect(async() => {
    if (userPrefCtx.bearerToken) {
      const headers = { Authorization: `Bearer ${userPrefCtx.bearerToken}`, auth: authCtx.authMode };

      try {
        const response = await fetch(botoxMapListURL, { headers })
        if (response.status === 200) {

          const responseData = await response.json();
          //console.log('Botox Map, get all by token response: ', responseData);

        //This is sorting the the objects by when it was created and then putting it in a useState
        setBotoxList(responseData.sort((a, b) =>a['BotoxMapJsonImage'].created_at < b['BotoxMapJsonImage'].created_at ? 1 : -1));

        } else {
          console.log("not 200");
        }
      } catch (e) {
        console.log(e);
      }


    }
    else {
      console.log('no token')
    } //The triggers for the useEffect will be when a new BotoxMap is created, edited, or deleted
}, [userPrefCtx.bearerToken, props.sendUpdate, props.sendDeleteTrigger, visCtx.pat_token]);

useEffect(() => {
    if (botoxList !== null || botoxList !== undefined) {
        const splitSize = 3
        let images = []
        for (let i = 0; i < botoxList?.length; i += splitSize) {
             images.push(botoxList.slice(i, i + splitSize));
        }
        //console.log(images);
        setImgArray(images);
    }

}, [botoxList])



  
  return (
    <div>
        {imgArray.length > 0 && 
        imgArray.map((img_row, row_ix) => {
            // output images 3 in a row
            return (
                <Row key={`img_${row_ix}`}>
                    {img_row.map((img, img_ix) => {
                        return (
                            <Col xs={4} md={9} lg={7} xl={4} key={`img_${img_ix}`} className={styles.padding_underneath_thumbnails}>
                                {/* <h4>{img['BotoxMapJsonImage']?.id}</h4> */}
                                {/* {console.log(img)} */}
                                <ReturnBotoxImage 
                                    mode='thumb'
                                    // image={img['BotoxMapImage']?.file_name}
                                    image={img}
                                    patient_id={img['BotoxMapJsonImage'].patient_id}
                                    onImageSelect={(img, imgId, botoxTypeId, object) => props.onImageSelect(img, imgId, botoxTypeId, object) }
                                    // onImageSelect={(object) => props.onImageSelect(object) }
                                    // isImageSelected={props.selectedBotoxImages.indexOf(img['BotoxMapImage']?.file_name) >= 0}
                                    //isImageSelected={props.selectedBotoxImages.indexOf(img['BotoxMapImage']?.file_name) >= 0}
                                    isImageSelected={props.selectedBotoxImages.indexOf(img) >= 0}
                                    //isImageSelected={props.selectedBotoxImages.indexOf(img['BotoxMapJsonImage']?.id) >= 0}
                                    image_id={img['BotoxMapImage']?.botox_map_json_image_id}
                                    botox_type_id={img['BotoxMapImage']?.dict_botox_map_src_image_id}
                                    // sendObject={img['BotoxMapImage']}
                                    sendObject={img}
                                />
                            </Col>
                        
                        )
                    })}
                </Row>
            )
            })
    }

    </div>
  );
};

export default BotoxMapPanelDisplayList;