import React, { useState, useContext, useEffect} from 'react'
import PatientContext from '../../../context/patientContext'
import UserPrefContext from '../../../context/userPrefContext'
import AuthContext from '../../../context/authContext'
// import {toLocalDate} from '../../../utils/Utils'
import moment from 'moment'

function GrbasList(props) {
    const patCtx = useContext(PatientContext)
    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)
    const GrbasGetAllURL = `${process.env.REACT_APP_SERVER}/api/v1/grbas/get_all_by_token/${patCtx.pat_token}`
    const [grbases, setGrbases] = useState([])

    // useEffect(async() => {
    //     if (userPrefCtx.bearerToken !== null) {
    //         let ignoreGrbases = false

            // const getAllGrbases = async() => {
            //     try {
            //         const response = await fetch(GrbasGetAllURL, {
            //             headers: {
            //                 'Authorization': `Bearer ${userPrefCtx.bearerToken}`,
            //                 auth: authCtx.authMode,
            //             },
        
    //                 })
        
    //                 if (response !== null) {
    //                     const responseData = await response.json()
    //                     // console.log("Get all e-Faces", responseData)
    //                     // sort them by eface_date
    //                     setGrbases(responseData.sort((a,b) => new Date(a.grbas_date) > new Date(b.grbas_date) ? 1 : -1))
    //                 } else {
    //                     console.log("Failed to get all Grbases", response)
    //                 }
    //             } catch (e) {
    //                 console.log("Failed to get all Grbases", e)
    //             }
        
    //         }
    
    //         // refresh the list only when required (caused by the change of data in GrbasModal)
    //         if (props.refreshRequired) {
    //             getAllGrbases()
    //             props.onAnyDataChange(false)
    //         }
    //     }

    //     return () => {
    //         ignoreGrbases = true
    //     }
    // },[props.refreshRequired])

    useEffect(async() => {
        if (userPrefCtx.bearerToken) {
          const headers = { Authorization: `Bearer ${userPrefCtx.bearerToken}`, auth: authCtx.authMode, };
    
          try {
            const response = await fetch(GrbasGetAllURL, { headers })
            if (response.status === 200) {
    
              const responseData = await response.json();
              //console.log('All Intraops: ', responseData);
    
            //This is sorting the the objects by when it was created and then putting it in a useState
            setGrbases(responseData.sort((a,b) => new Date(a.grbas_date) > new Date(b.grbas_date) ? 1 : -1))
            //setIntraopList(responseData);
    
            } else {
              console.log("not 200");
            }
          } catch (e) {
            console.log(e);
          }
    
    
        }
        else {
          console.log('no token')
        } //The triggers for the useEffect will be when a new Capev is created, edited, or deleted
    }, [userPrefCtx.bearerToken, patCtx.pat_token, props.refreshRequired, props.sendGrbasModalResponse]);




    

    
    const GrbasClicked = (event) => {
        // console.log("CapeV clicked")
        const grbas_id = event.target.getAttribute('data-grbas-id')

        // get data - don't use data in capeVs - the values may have changed

        // console.log("CAPEV ID SELECTED", capev_id)
        props.onSelect(grbas_id)
        // if (capev !== null) {
        //     let data = [capev]
        //     data = data.map(d => ({...d, capev_date: new Date(d.capev_date)}))
        //     props.onSelect(data)
        // }
        // console.log("CapeV id", capev_id)
        // let data = capeVs.filter(capev => capev.id === parseInt(capev_id))
        // adjust capev_date to Date type 
        // data = data.map(d => ({...d, capev_date: new Date(d.capev_date)}))
        // console.log("CAPEV DATA SELECTED", data)
        // props.setData(data)
        // props.onSelect(data)
    }


    return (
        <ul>

            {grbases.length > 0 && grbases.map((grbas) => {
                    return (
                        <li key={grbas.id} 
                            data-grbas-id={grbas.id}
                            onClick={GrbasClicked}
                        >
                            {/* {toLocalDate(grbas.grbas_date)} */}
                            {moment(grbas.grbas_date).format('MM/DD/YYYY')}
                        </li>
                    )}
                )
            }
        </ul>
    )
}

export default GrbasList
