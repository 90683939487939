import React from 'react'
import Modal from 'react-bootstrap/Modal'
import EFaceList from './EFaceList'
import Button from 'react-bootstrap/Button'


function EFaceListModal(props) {

    // console.log("EFaceListModal - props", props)

    const onSelect = (eface) => {
        props.onSelect(eface)
    }

  return (
        <Modal dialogClassName="modal-80w" show={props.show} onHide={props.onClose} animation={false} scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title><h4>The eFace</h4></Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-4" >
                <EFaceList onSelect={onSelect} unUpdateOrDelete={props.unUpdateOrDelete}  updateDeleteCount={props.updateDeleteCount}/>
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <div >
                    <Button className='mx-2' variant="secondary" onClick={props.onClose}>
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
  )
}

export default EFaceListModal
