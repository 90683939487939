import React, {useEffect, useState, useCallback} from 'react';
// import parse from 'html-react-parser';
import DatePicker from 'react-datepicker';
import Form from 'react-bootstrap/Form'
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';


function CreatePatientGroups(props) {
    const [selectedDate, setSelectedDate] = useState();
    const [selectOptions, setSelectOptions] = useState([])


    useEffect(() => {

        props.fields?.forEach((obj, index) => {
            if(obj.question_type === 'select'){
                const currentDropdownOptions = JSON.parse(obj?.question_option) || [];
                const currentDropdownOptionsValue = JSON.parse(obj?.question_option_values) || [];


                let options = currentDropdownOptions.map((opt,index) => {return {option: opt, value: currentDropdownOptionsValue[index]} })
                setSelectOptions(options)
            } else if (obj.question_type === 'date') {
                if (!!obj.defaultValue)
                    setSelectedDate(new Date(obj.defaultValue))
            } else {
                setSelectedDate(null)
            }
        });
    }, [props])

    

    const onBlurEvent = (e) => {
        // console.log('on blur', e)
        if(e === 'emr_mrn'){
          // props.checkIfMrnExists(e.target.value);
          let mrnValue = document.getElementById(e).value
          // props.checkIfMrnExists(e);
          props.checkIfMrnExists(mrnValue);
          // console.log(mrnValue)
          //console.log(e.target)
        }
    }

    // const onChangeInput = (e) => {
    //     console.log('on changeinput', e.target.value)
    //     setStringCheck(e.target.value)
    // }

    return (
        <>
            <div className="two_column_container">
                {props.fields.map((object, index) => 
                    <div key={index} className="patient_column">
 
                        <Form.Group className='my-1'>
                            <Form.Label htmlFor={object.display_name_short}>{object?.display_name}{object?.question_required ? '*':''}
                            </Form.Label>
                            {object?.question_type === 'input' &&
                            <Form.Control 
                                    id={object.display_name_short}
                                    name={object.display_name_short}
                                    onBlur={() => onBlurEvent(object.display_name_short)}
                                    // onChange={onChangeInput}
                                    required={object.question_required}
                                    isInvalid={!!props.errors[object.display_name_short]}
                                    defaultValue={object.defaultValue}
                            />
                            }

                            {object?.question_type === 'date' &&
                                <>
                                    <div></div>
                                    <DatePicker className={`form-control ${!!props.errors[object.display_name_short] ? 'is-invalid': ''}`}
                                        id={object.display_name_short}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        selected={selectedDate}
                                        onChange={(date) => setSelectedDate(date)} 
                                        required={object.question_required}
                                        isInvalid={!!props.errors[object.display_name_short]}
                                    />
                                    <div className={`invalid-feedback ${!!props.errors[object.display_name_short] ? 'is-invalid': ''}`}>
                                    {props.errors[object.display_name_short]}
                                    </div>
                                </>
                            }

                            {object?.question_type === 'select' &&
                                <Form.Control
                                    as="select"
                                    id={object.display_name_short}
                                    isInvalid={!!props.errors[object.display_name_short]}
                                >
                                    <option value='' ></option>
                                    {selectOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                        {option.option}
                                    </option>
                                    ))}
                                </Form.Control>
                            }


                            <Form.Control.Feedback type="invalid" >
                                {props.errors[object.display_name_short]}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                )}
            </div>
        </>
    )
}

export default CreatePatientGroups;