import React, {useState, useContext, useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import ErrorDialogModal from '../modals/ErrorDialogModal'
import UserPrefContext from '../../../context/userPrefContext'
import DictionaryContext from '../../../context/dictionaryContext'
import AuthContext from '../../../context/authContext'
import SelectPatient from '../shared/SelectPatient'

function PatientReportedOutcomeMeasures(props) {

    const userPrefCtx = useContext(UserPrefContext)
    const dictContext = useContext(DictionaryContext)
    const authCtx = useContext(AuthContext)

    const [surveys, setSurveys] = useState([])
    const [surveyError, setSurveyError] = useState('')
    const [patientError, setPatientError] = useState('')

    const [patientData, setPatientData] = useState(null)

    const [showError, setShowError] = useState(false);

    useEffect(() => {
        const uniqueSurveys = [... new Set(dictContext.dict_intake_form_question
            .filter(ifquest => ifquest.DictIntakeForm.is_send_email === true)
            .map(ifquest => (JSON.stringify({
            name: ifquest.DictIntakeForm.display_name,
            short_name: ifquest.DictIntakeForm.display_name_short,
            form_id: ifquest.DictIntakeForm.id,
        }))))].map(s => JSON.parse(s))

        // console.log("Surveys:", uniqueSurveys)
        setSurveys(uniqueSurveys)
    }, [dictContext.dict_intake_form_question])



    const handleClose = () => {
        props.onClose()
    }

    const handleChange = (data) => {
        // patient selected from the search patient dropdown - set email
        // console.log("handleChange", data)
        setPatientData(data)
    }


    // patient cleared from the serach field
    const handleCleared = () => {
        setPatientData(null)
    }


    const handleSave = () => {
        let error = false
        resetAllErrors()
        if (patientData === null) {
            setPatientError('Patient is not selected')
            error = true
        } 
        const survey = document.getElementById('survey_selection')
        if (survey.value === '') {
            setSurveyError('Survey is not selected')
            error = true
        }

        if (!error) {
            saveRequest(survey)
            // alert('Not implemented yet!')
            props.onClose()
        }

    }


    const resetAllErrors = () => {
        setPatientError('')
        setSurveyError('')
    }


    const saveRequest = async (survey) => {

        const url = `${process.env.REACT_APP_SERVER}/api/v1/eform_requests/${patientData.patient_token}`
        // get form id
        const surveyData = surveys.find(s => s.short_name === survey.value)
        let form_id = 0
        if (surveyData !== undefined) {
            form_id = surveyData.form_id
        } else {
            console.log("Can't get form id for survey - something went wrong!")
            return
        }
        const body=JSON.stringify({
            dict_intake_form_id: form_id,
            form_name: survey.value
        })

        // console.log("POST body", body)


        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                auth: authCtx.authMode,
            },
            body: body
        })
        //.then((res) => res.json())
        .then((res) => {
            // Check for 422 or 500 response
            if (!res.ok) {
                 throw new Error('Unprocessable Entity: ' + res.statusText);
            }
            return res.json();
          })
        .then((data) => {
            console.log('form request submitted: ', data)
            // if (data.email !== null) {
            //     // setEmail(data.email)
            // }
        })
        .catch((err) => {
            console.log(err.message);
            setShowError(true);
        })
    }


    return (
        <>
        <ErrorDialogModal
          setOpen={showError}
          close={() => setShowError(false)}
          componentName="New Patient Reported Assessments"
        />
        <Modal 
            backdrop="static"
            size="lg" 
            show={props.show} 
            animation={false} 
            onHide={() => props.onClose('cancel')} 
            style={{ visibility: (showError) ? 'hidden' : 'visible' }}
        >
            <Modal.Header closeButton>
                <Modal.Title>New Patient Reported Assessments</Modal.Title>
            </Modal.Header>
            <Modal.Body>              
                <SelectPatient 
                    error={patientError} 
                    onErrorReset={() => setPatientError('')}
                    // onErrorReset={resetAllErrors}
                    onChange={handleChange} 
                    onClear={handleCleared}/>

                <Form.Group>
                    <Form.Label htmlFor="surveys">Select Survey:
                        {surveyError !== '' &&
                            <span className='send-survey-error'>{surveyError}</span>
                        }
                    </Form.Label>
                    <Form.Select
                        aria-label="Select survey" 
                        id="survey_selection"
                        className={surveyError !== '' ? 'red-border' : ''}
                        onChange={() => setSurveyError('')}
                        defaultValue={surveys.length > 0 ? surveys[0].short_name : ''}
                    >
                        <option value=''>Select Survey</option>
                        {surveys.map((s, si) => (
                            <option key={`survey_${si}`} value={s.short_name}>{s.name}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                

                
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <div className="inline-block text-left">
                    <Button className='mx-2' variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </div>

                <div className='inline-block text-right'>
                    <Button className='mx-2' variant="primary" onClick={handleSave}>
                        Save Record
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default PatientReportedOutcomeMeasures
